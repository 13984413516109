// names
// backgrounds etc...
import { BROOD_COVEN_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: BROOD_COVEN_ID,
    moniker: "Brood Coven",
    restrictions: restrictions,
    equipment: [
        {
            name: "Blasting Charge",
            cost: 2
        },
        {
            name: "Demolition Charge",
            cost: 4
        },
        {
            name: "Heavy Weapon Bipod",
            cost: 2
        },
        {
            name: "Flash Visor",
            cost: 1
        },
        {
            name: "Mining Tool Rig",
            cost: 3
        },
        {
            name: "Structural Surveyor",
            cost: 3
        }
    ]
};

export default info;

import { FELLGORE_RAVAGERS } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const ExactionSquadController = {
    id: FELLGORE_RAVAGERS,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length < 10) {
            passed = false;
            messages.push("Must include 10 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Fellgor Ironhorn";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Fellgor Ironhorn");
        }

        // only 1 of these units
        [
            "Fellgor Deathknell",
            "Fellgor Fluxbray",
            "Fellgor Gnarlscar",
            "Fellgor Gorehorn",
            "Fellgor Herd-goad",
            "Fellgor Mangler",
            "Fellgor Shaman",
            "Fellgor Toxhorn",
            "Fellgor Vandal"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        return {
            passed,
            messages
        };
    },

    // randomiseTeamName: (names) => {
    //     const first = names[0][Math.floor(Math.random() * names[0].length)];
    //     const second = names[1][Math.floor(Math.random() * names[1].length)];

    //     return `${first} ${second}`;
    // },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default ExactionSquadController;

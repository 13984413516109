const strategicAssets = [
    "Engineering Bay",
    "Intelligence Network",
    "Surveillance System",
    "Expanded Armoury",
    "Med Bay",
    "War Shrine",
    "Comms Network",
    "Tactical Uplink",
    "Stimm Stash"
];

export default strategicAssets;

// names
// backgrounds etc...
import { PHOBOS_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: PHOBOS_ID,
    moniker: "Phobos Strike Team",
    restrictions: restrictions,
    battleHonours: [
        "Ghost",
        "Assassin",
        "Mobile",
        "Raider",
        "Guerrilla",
        "Dynamic"
    ],
    killTeamNames: [
        [
            "Bringers",
            "Damned",
            "True Believers",
            "Holders",
            "Butchers",
            "Rampagers"
        ],
        [
            "of Blood",
            "of the Creed",
            "of the Gods",
            "of the Truth",
            "Abominant",
            "Malevolent"
        ]
    ],
    operativeNames: [
        [
            // ultramarines
            "Marius",
            "Agnathio",
            "Ollonius",
            "Cato",
            "Titus",
            "Gaius",

            // fists
            "Lydus",
            "Darnath",
            "Lexandro",
            "Nereus",
            "Tylaeus",
            "Vorn",

            // ravin'
            "Ordias",
            "Kyrin",
            "Vykus",
            "Tyris",
            "Navaer",
            "Syras",

            // dark heretics
            "Azkarael",
            "Zakael",
            "Rhellion",
            "Zoreal",
            "Azathor",
            "Nemator",

            // space vikings
            "Gunnar",
            "Ulfrich",
            "Laars",
            "Sven",
            "Wulfgar",
            "Erik",

            // blood angels
            "Amareus",
            "Erasmus",
            "Rafael",
            "Morlaeo",
            "Leonid",
            "Faustian",

            // salamanders
            "Dak'Lyr",
            "Sho'Tan",
            "Tsu'Gar",
            "Xavus",
            "Nubari",
            "Vorr'n",

            // iron hands
            "Arrven",
            "Gorrloch",
            "Shorrgol",
            "Galorr",
            "Dorrghun",
            "Kaagos",

            // scars
            "Khajog",
            "Suberei",
            "Khoros",
            "Jaygor",
            "Selgei",
            "Jurgah"
        ],
        [
            // ultras surname
            "Chronus",
            "Tarentus",
            "Dysorius",
            "Cassus",
            "Acastian",
            "Varenus",

            // fists
            "Hagen",
            "Mirhen",
            "Garadon",
            "Lytanus",
            "Danithor",
            "Markov",

            // ravin'
            "Kaed",
            "Solari",
            "Solaq",
            "Qeld",
            "Vaanes",
            "Torvaec",

            // dark heretics
            "Zaborial",
            "Azdallon",
            "Zacharus",
            "Astathor",
            "Seraphus",
            "Kaelon",

            // space vikings
            "Ironaxe",
            "Icefang",
            "Orksbane",
            "the Red",
            "Wyrmslayer",
            "Thunderhowl",

            // blood angels
            "Belarius",
            "Seraphan",
            "Moriar",
            "Raneil",
            "Castivar",
            "Arteros",

            // salamanders
            "K'Gosi",
            "Jurr",
            "Dallor",
            "Shenn",
            "Ta'Phor",
            "Rhoshan",

            // iron hands
            "Graevarr",
            "Bannus",
            "Lydriik",
            "Terrek",
            "Korvaan",
            "Urloch",

            // scars
            "Gatughan",
            "Ghol",
            "Jaghol",
            "Kandakh",
            "Mologhai",
            "Ujumm"
        ]
    ],
    history: [
        "Dynamic Strategists",
        "Predators",
        "Individuals",
        "Speartip",
        "Shockforce",
        "Proving Ground"
    ],
    baseOfOperations: [
        "Impulsors",
        "Gunships",
        "Shroud-zone",
        "Ever Mobile",
        "Deep Wilds",
        "Remote Ruin"
    ],
    squadQuirk: [
        "Furious",
        "Patient Hunters",
        "Guerilla Tactics",
        "The Calculus of War",
        "Excruciators",
        "Vengeful"
    ],
    strategicAssets: [
        "Lodestar-class Auspex",
        "Shroud-skulls",
        "Enhanced Surveillance System"
    ],
    equipment: [
        {
            name: "Grapnel Launcher",
            cost: 1
        },
        {
            name: "Grav-chute",
            cost: 2
        },
        {
            name: "Smoke Grenade",
            cost: 3
        },
        {
            name: "Shock Grenade",
            cost: 3
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Throwing Knife",
            cost: 2
        },
        {
            name: "Purity Seal",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Artificer's Blade",
            cost: 3
        },
        {
            name: "Umbra Shroud",
            cost: 3
        },
        {
            name: "Exogheist Scrambler",
            cost: 2
        },
        {
            name: "Aerial Tracking Augur",
            cost: 3
        },
        {
            name: "Enhanced Optic",
            cost: 2
        },
        {
            name: "Reaper Rounds",
            cost: 3
        }
    ],
    specOps: ["Tormentus Doctrine", "Scout and Sabotage"]
};

export default info;

// raw KT data
import { LEGIONARY_ID } from "../../../constants/factions";

const killTeam = {
    id: LEGIONARY_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Legionary Chosen",
            limit: 1,
            leader: true,
            weapons: [
                ["Plasma pistol, Daemon blade", "Bolt pistol, Daemon blade"]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Legionary Aspiring Champion",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Plasma pistol, Tainted chainsword",
                    "Plasma pistol, Power weapon",
                    "Plasma pistol, Power fist",
                    "Plasma pistol, maul",
                    "Tainted bolt pistol, Tainted chainsword",
                    "Tainted bolt pistol, Power weapon",
                    "Tainted bolt pistol, Power fist",
                    "Tainted bolt pistol, maul"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Legionary Warrior",
            limit: 5,
            weapons: [["Boltgun, Fists", "Bolt pistol, Chainsword"]],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Legionary Gunner",
            limit: 1,
            weapons: [
                ["Flamer, Fists", "Meltagun, Fists", "Plasma gun, Fists"]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Legionary Heavy Gunner",
            limit: 1,
            weapons: [
                [
                    "Heavy bolter, Fists",
                    "Missile launcher, Fists",
                    "Reaper chainsword, Fists"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Legionary Icon Bearer",
            limit: 1,
            weapons: [["Boltgun, Fists", "Bolt pistol, Chainsword"]],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Legionary Annointed",
            limit: 1,
            weapons: [["Bolt pistol, Daemonic claw"]],
            specialisms: ["combat", "staunch", "scout"]
        },
        {
            name: "Legionary Butcher",
            limit: 1,
            weapons: [["Bolt pistol, Double-handed chain axe"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Legionary Balefire Acolyte",
            limit: 1,
            weapons: [["Tainted bolt pistol, Fell dagger"]],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Legionary Shrivetalon",
            limit: 1,
            weapons: [["Bolt pistol, Flensing blades"]],
            specialisms: ["combat", "scout"]
        }
    ]
};

export default killTeam;

// names
// backgrounds etc...
import { GREY_KNIGHT_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: GREY_KNIGHT_ID,
    moniker: "Grey Knight",
    restrictions: restrictions,
    equipment: [
        {
            name: "Psybolt Ammunition",
            cost: 3
        },
        {
            name: "Sanctic Blessing",
            cost: 2
        },
        {
            name: "Hexagrammic Ward",
            cost: 2
        },
        {
            name: "Truesilver Armour",
            cost: 3
        },
        {
            name: "Purity Seal",
            cost: 3
        },
        {
            name: "Psyk-out Grenade",
            cost: 2
        }
    ]
};

export default info;

import { TALONS_OF_THE_EMPEROR_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const TalonsOfTheEmperorController = {
    id: TALONS_OF_THE_EMPEROR_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Custodian Guard Leader" ||
                    operative.unit === "Sister of Silence Superior"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Must include only 1 Custodian Guard Leader/Sister of Silence Superior"
            );
        }

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Custodian Guard") {
                if (operativesInFireTeams[index].length !== 2) {
                    passed = false;
                    messages.push(
                        "Custodian Guard Fire Team must include 2 operatives"
                    );
                }
            } else if (fireTeam === "Sister of Silence") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Sister of Silence Fire Team must include 5 operatives"
                    );
                }
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default TalonsOfTheEmperorController;

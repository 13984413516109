import styled from "styled-components";
import theme from "./theme";

const StyledExport = styled.div`
    text-align: center;

    .export__buttons {
        max-width: 20em;
        margin: 0 auto;
        padding-top: 2em;
    }

    .loading {
        font-family: ${theme.fonts.header};
        text-transform: uppercase;
        color: ${theme.colors.orange};
    }

    .player-name {
        padding-bottom: 1em;

        label {
            display: block;
            font-family: ${theme.fonts.header};
            text-transform: uppercase;
            letter-spacing: 0.05em;
            font-size: 0.8em;
            padding-bottom: 0.6em;
        }

        input {
            width: 80%;
            padding: 0.7em;
            border-radius: 0;
            border: solid 2px ${theme.colors.darkGrey};
        }
    }

    .export__button {
        margin-top: 1em;

        button {
            font-family: ${theme.fonts.header};
            background: ${theme.colors.orange};
            border: none;
            border-radius: 0;
            padding: 1em;
            width: 80%;
            transition: all 0.3s ease;
            color: ${theme.colors.black};

            &:hover {
                background: ${theme.colors.darkGrey};
                color: ${theme.colors.white};
            }
        }
    }
`;

export default StyledExport;

// names
// backgrounds etc...
import { HIVE_FLEET_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: HIVE_FLEET_ID,
    moniker: "Hive Fleet",
    restrictions: restrictions,
    equipment: [
        {
            name: "Flesh Hooks",
            cost: 2
        },
        {
            name: "Acid Maw",
            cost: 2
        },
        {
            name: "Extended Chitin (Tyranid Warrior)",
            cost: 3
        },
        {
            name: "Extended Chitin",
            cost: 2
        },
        {
            name: "Feeder Tendrils",
            cost: 2
        },
        {
            name: "Toxin Sacs",
            cost: 2
        },
        {
            name: "Adrenal Glands",
            cost: 2
        }
    ]
};

export default info;

// raw KT data
import { VOID_DANCER_ID } from "../../../constants/factions";

const killTeam = {
    id: VOID_DANCER_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Lead Player",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Fusion Pistol, Blade",
                    "Fusion Pistol, Caress",
                    "Fusion Pistol, Embrace",
                    "Fusion Pistol, Kiss",
                    "Fusion Pistol, Power Weapon",
                    "Neuro Disruptor, Blade",
                    "Neuro Disruptor, Caress",
                    "Neuro Disruptor, Embrace",
                    "Neuro Disruptor, Kiss",
                    "Neuro Disruptor, Power Weapon",
                    "Shuriken Pistol, Blade",
                    "Shuriken Pistol, Caress",
                    "Shuriken Pistol, Embrace",
                    "Shuriken Pistol, Kiss",
                    "Shuriken Pistol, Power Weapon"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Death Jester",
            limit: 1,
            weapons: [["Shrieker cannon, Shrieker blade"]],
            specialisms: ["marksman"]
        },
        {
            name: "Player",
            limit: 7,
            weapons: [
                [
                    "Fusion Pistol, Blade",
                    "Fusion Pistol, Caress",
                    "Fusion Pistol, Embrace",
                    "Fusion Pistol, Kiss",
                    "Neuro Disruptor, Blade",
                    "Neuro Disruptor, Caress",
                    "Neuro Disruptor, Embrace",
                    "Neuro Disruptor, Kiss",
                    "Shuriken Pistol, Blade",
                    "Shuriken Pistol, Caress",
                    "Shuriken Pistol, Embrace",
                    "Shuriken Pistol, Kiss"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Shadowseer",
            limit: 1,
            weapons: [["Neuro Disruptor", "Shuriken Pistol"]],
            specialisms: ["marksman", "scout"]
        }
    ]
};

export default killTeam;

import React, { useContext } from "react";
import AppStateContext from "../context";
import StyledOperativeSpecOps from "../styles/StyledOperativeSpecOps";
import {
    getAvailableBattleHonours,
    getBattleScars,
    getFactionData
} from "../controllers/DataController";
import { arrContains } from "../controllers/HelpersController";
import RandomiseIcon from "./icons/RandomiseIcon";
import ExperienceIcon01 from "./icons/ExperienceIcon01";
import ExperienceIcon02 from "./icons/ExperienceIcon02";
import ExperienceIcon03 from "./icons/ExperienceIcon03";
import ExperienceIcon04 from "./icons/ExperienceIcon04";
import ExperienceIcon05 from "./icons/ExperienceIcon05";

const Operative = (props) => {
    const { dispatch } = useContext(AppStateContext);
    const { operative, id } = props;
    const factionData = getFactionData(operative.faction);

    /*
    faction: null,
    unit: null,
    moniker: "",
    equipment: [],
    weapons: [],
    battleHonours: [],
    battleScars: [],
    specialism: null,
    restedTally: 0,
    experience: 0,
    showSpecOps: false,
    inKillTeam: false
    */

    const update = (value, key) => {
        const newOperativeObj = operative;

        newOperativeObj[key] = value;

        dispatch({
            type: "UPDATE_OPERATIVE",
            payload: {
                operativeId: id,
                operative: newOperativeObj
            }
        });
    };

    return (
        <StyledOperativeSpecOps>
            <div className="row row--inline">
                <label>Name:</label>
                <div className="operative-name">
                    <input
                        value={operative.moniker}
                        placeholder={"Operative name..."}
                        onChange={(event) => {
                            update(event.target.value, "moniker");
                        }}
                    />
                    {factionData.controller.randomiseOperativeName && (
                        <button
                            onClick={(event) => {
                                update(
                                    factionData.controller.randomiseOperativeName(
                                        factionData.info.operativeNames
                                    ),
                                    "moniker"
                                );
                            }}
                            className="operative-name__icon"
                        >
                            <RandomiseIcon />
                        </button>
                    )}
                </div>
            </div>

            <div className="row">
                <label
                    style={{
                        display: "block",
                        textAlign: "center",
                        paddingRight: 0
                    }}
                >
                    Rank (updates with experience):
                </label>
                <div className="exp-icons">
                    <div className="exp-icon--active">
                        <ExperienceIcon01 />
                    </div>
                    <div
                        className={
                            operative.experience >= 6 ? "exp-icon--active" : ""
                        }
                    >
                        <ExperienceIcon02 />
                    </div>
                    <div
                        className={
                            operative.experience >= 16 ? "exp-icon--active" : ""
                        }
                    >
                        <ExperienceIcon03 />
                    </div>
                    <div
                        className={
                            operative.experience >= 31 ? "exp-icon--active" : ""
                        }
                    >
                        <ExperienceIcon04 />
                    </div>
                    <div
                        className={
                            operative.experience >= 51 ? "exp-icon--active" : ""
                        }
                    >
                        <ExperienceIcon05 />
                    </div>
                </div>

                <div className="counters">
                    <div className="counter">
                        <label>Experience:</label>
                        <div>
                            <button
                                onClick={() => {
                                    update(
                                        operative.experience === 0
                                            ? 0
                                            : operative.experience - 1,
                                        "experience"
                                    );
                                }}
                            >
                                -
                            </button>
                            <input
                                disabled
                                value={operative.experience}
                                readOnly
                                type="number"
                            />
                            <button
                                onClick={() => {
                                    update(
                                        operative.experience + 1,
                                        "experience"
                                    );
                                }}
                            >
                                +
                            </button>
                        </div>
                    </div>

                    <div className="counter">
                        <label>Rested Tally:</label>
                        <div>
                            <button
                                onClick={() => {
                                    update(
                                        operative.restedTally === 0
                                            ? 0
                                            : operative.restedTally - 1,
                                        "restedTally"
                                    );
                                }}
                            >
                                -
                            </button>
                            <input
                                disabled
                                value={operative.restedTally}
                                readOnly
                                type="number"
                            />
                            <button
                                onClick={() => {
                                    update(
                                        operative.restedTally + 1,
                                        "restedTally"
                                    );
                                }}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row row--inline">
                <label>Specialism:</label>
                {/* icon */}
                <div className="row__select-wrap">
                    <select
                        value={operative.specialism}
                        onChange={(event) => {
                            update(event.target.value, "specialism");
                        }}
                    >
                        <option value="default" default>
                            Select specialism...
                        </option>
                        {factionData.killTeam.operatives
                            .find((factionOperative) => {
                                return factionOperative.name === operative.unit;
                            })
                            .specialisms.map((specialism) => {
                                return (
                                    <option key={specialism} value={specialism}>
                                        {specialism.charAt(0).toUpperCase() +
                                            specialism.slice(1)}
                                    </option>
                                );
                            })}
                        {/* <option value="combat">Combat</option>
                    <option value="staunch">Staunch</option>
                    <option value="marksman">Marksman</option>
                    <option value="scout">Scout</option> */}
                    </select>
                </div>
            </div>

            <div className="row row--list">
                <label>Battle Honours:</label>
                <div className="list-items">
                    {operative.battleHonours.map((item, index) => {
                        return (
                            <div key={index}>
                                <span>{item}</span>
                                <button
                                    onClick={() => {
                                        update(
                                            operative.battleHonours.filter(
                                                (battleHonour) => {
                                                    return (
                                                        battleHonour !== item
                                                    );
                                                }
                                            ),
                                            "battleHonours"
                                        );
                                    }}
                                >
                                    Delete
                                </button>
                            </div>
                        );
                    })}
                </div>

                <div className="row__select-wrap">
                    <select
                        value="Add new item..."
                        onChange={(event) => {
                            const newHonoursArr = operative.battleHonours;
                            const newHonour = event.target.value;

                            if (!arrContains(newHonoursArr, newHonour)) {
                                newHonoursArr.push(newHonour);
                            }

                            dispatch({
                                type: "UPDATE_SPEC_OPS",
                                payload: {
                                    key: "battleHonours",
                                    value: newHonoursArr
                                }
                            });
                        }}
                    >
                        <option default>
                            {operative.specialism !== "default"
                                ? "Add new battle honour..."
                                : "Choose specialism first..."}
                        </option>
                        {operative.specialism !== "default" &&
                            getAvailableBattleHonours(
                                factionData.id,
                                operative
                            ).map((item, index) => (
                                <option key={`${item}-${index}`}>{item}</option>
                            ))}
                    </select>
                </div>
            </div>

            <div className="row row--list">
                <label>Battle Scars:</label>
                <div className="list-items">
                    {operative.battleScars.map((item, index) => {
                        return (
                            <div key={index}>
                                <span>{item}</span>
                                <button
                                    onClick={() => {
                                        const filteredArr =
                                            operative.battleScars;

                                        for (
                                            let i = 0;
                                            i < filteredArr.length;
                                            i++
                                        ) {
                                            const element = filteredArr[i];

                                            if (element === item) {
                                                filteredArr.splice(i, 1);

                                                break;
                                            }
                                        }

                                        update(filteredArr, "battleScars");
                                    }}
                                >
                                    Delete
                                </button>
                            </div>
                        );
                    })}
                </div>

                <div className="row__select-wrap">
                    <select
                        value="Add new item..."
                        onChange={(event) => {
                            const newScars = operative.battleScars;
                            newScars.push(event.target.value);

                            dispatch({
                                type: "UPDATE_SPEC_OPS",
                                payload: {
                                    key: "battleScars",
                                    value: newScars
                                }
                            });
                        }}
                    >
                        <option default>Add new battle scar...</option>
                        {getBattleScars().map((scar, index) => (
                            <option key={index}>{scar}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row">
                <label>Notes:</label>
                <textarea
                    value={operative.notes}
                    placeholder="Operative notes..."
                    onChange={(event) => {
                        update(event.target.value, "notes");
                    }}
                ></textarea>
            </div>
        </StyledOperativeSpecOps>
    );
};

export default Operative;

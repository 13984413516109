import { VETERAN_GUARDSMAN_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const veteranGuardsmanController = {
    id: VETERAN_GUARDSMAN_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (
            operativesInKillTeam.length !== 10 ||
            operativesInKillTeam.length !== 14
        ) {
            passed = false;
            messages.push(
                "Must include 10 operatives, or 14 operatives with the Trooper Veterans Ancillary Support"
            );
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Sergeant Veteran";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Sergeant Veteran");
        }

        // only 1 of non boy units
        [
            "Confidant Veteran",
            "Zealot Veteran",
            "Medic Veteran",
            "Comms Veteran",
            "Demolition Veteran",
            "Bruiser Veteran",
            "Hardened Veteran",
            "Sniper Veteran",
            "Spotter Veteran",
            "Gunner Veteran - equipped with a bayonet and grenade launcher",
            "Gunner Veteran - equipped with a bayonet and meltagun",
            "Gunner Veteran - equipped with a bayonet and plasma gun",
            "Gunner Veteran - equipped with a bayonet and flamer"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit} operative`);
            }
        });

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        return `${names[Math.floor(Math.random() * names.length)]}`;
    }
};

export default veteranGuardsmanController;

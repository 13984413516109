// raw KT data
import { TRAITOR_SPACE_MARINE_ID } from "../../../constants/factions";

const killTeam = {
    id: TRAITOR_SPACE_MARINE_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Chaos Space Marine",
            units: [
                "Chaos Space Marine Aspiring Champion",
                "Chaos Space Marine Warrior",
                "Chaos Space Marine Gunner",
                "Chaos Space Marine Heavy Gunner",
                "Chaos Space Marine Icon Bearer"
            ]
        },
        {
            name: "Chaos Cultist",
            units: [
                "Chaos Cultist Champion",
                "Chaos Cultist Fighter",
                "Chaos Cultist FiGunnerhter"
            ]
        }
    ],
    operatives: [
        {
            name: "Chaos Space Marine Aspiring Champion",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Boltgun, Fists",
                    "Bolt pistol, Chainsword",
                    "Bolt pistol, Power fist",
                    "Bolt pistol, Power weapon",
                    "Plasma pistol, Chainsword",
                    "Plasma pistol, Power fist",
                    "Plasma pistol, Power weapon"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Chaos Space Marine Warrior",
            limit: 3,
            weapons: [["Boltgun, Fists", "Bolt pistol, Chainsword"]],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Chaos Space Marine Gunner",
            limit: 1,
            weapons: [["Fists"], ["Flamer", "Meltagun", "Plasma gun"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Chaos Space Marine Heavy Gunner",
            limit: 1,
            weapons: [["Fists"], ["Heavy bolter", "Missile launcher"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Chaos Space Marine Icon Bearer",
            limit: 1,
            weapons: [["Boltgun, Fists", "Bolt pistol, Chainsword"]],
            specialisms: ["combat", "staunch", "marksman"]
        },
        //
        {
            name: "Chaos Cultist Champion",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Autogun, Gun butt",
                    "Autopistol, Brutal assault weapon",
                    "Shotgun, Brutal assault weapon"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Chaos Cultist Fighter",
            limit: 8,
            weapons: [
                ["Autogun, Gun butt", "Autopistol, Brutal assault weapon"]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Chaos Cultist FiGunnerhter",
            limit: 8,
            weapons: [["Flamer, Gun butt", "Heavy stubber, Gun butt"]],
            specialisms: ["combat", "marksman"]
        }
    ]
};

export default killTeam;

import { COMMORRITE_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const CommorriteController = {
    id: COMMORRITE_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains up to 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Sybarite" ||
                    operative.unit === "Hekatrix"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push("Maximum of 1 Sybarite/Hekatrix");
        }

        // singles for kill team
        if (
            operativesInKillTeam.filter((operative) => {
                return "Kabalite Heavy Gunner" === operative.unit;
            }).length > 1
        ) {
            passed = false;
            messages.push("Maximum of 1 Kabalite Heavy Gunner operatives");
        }

        if (
            list.fireTeams.filter((team) => {
                return team === "Wych";
            }).length === 2
        ) {
            if (
                operativesInKillTeam.filter((operative) => {
                    return "Wych Fighter" === operative.unit;
                }).length > 3
            ) {
                passed = false;
                messages.push("Maximum of 3 Wych Fighter operatives");
            }
        } else if (
            operativesInKillTeam.filter((operative) => {
                return "Wych Fighter" === operative.unit;
            }).length > 2
        ) {
            passed = false;
            messages.push("Maximum of 2 Wych Fighter operatives");
        }

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Kabalite") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Kabalite Fire Team must include 5 operatives"
                    );
                }

                if (
                    operativesInKillTeam.filter((operative) => {
                        return "Kabalite Gunner" === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push("Maximum of 1 Kabalite Gunner operatives");
                }
            }

            //
            if (fireTeam === "Wych") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push("Wych Fire Team must include 5 operatives");
                }
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default CommorriteController;

// raw KT data
import { IMPERIAL_GUARD_ID } from "../../../constants/factions";

const killTeam = {
    id: IMPERIAL_GUARD_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Guardsman",
            units: [
                "Guardsman Sergeant",
                "Guardsman Trooper",
                "Guardsman Comms",
                "Guardsman Gunner equipped with: Bayonet, Flamer",
                "Guardsman Gunner equipped with: Bayonet, Grenade launcher",
                "Guardsman Gunner equipped with: Bayonet, Meltagun",
                "Guardsman Gunner equipped with: Bayonet, Plasma gun",
                "Guardsman Gunner equipped with: Bayonet, Sniper rifle"
            ]
        },
        {
            name: "Tempestus Scion",
            units: [
                "Tempestor",
                "Tempestus Scion Trooper",
                "Tempestus Scion Comms",
                "Tempestus Scion Gunner equipped with: Gun butt, Flamer",
                "Tempestus Scion Gunner equipped with: Gun butt, Grenade launcher",
                "Tempestus Scion Gunner equipped with: Gun butt, Hot-shot volley gun",
                "Tempestus Scion Gunner equipped with: Gun butt, Meltagun",
                "Tempestus Scion Gunner equipped with: Gun butt, Plasma gun"
            ]
        }
    ],
    operatives: [
        {
            name: "Guardsman Sergeant",
            limit: 1,
            leader: true,
            weapons: [
                ["Bolt pistol", "Laspistol", "Plasma pistol"],
                ["Chainsword", "Power weapon"]
            ],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Guardsman Trooper",
            limit: 7,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Guardsman Comms",
            limit: 7,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Guardsman Gunner equipped with: Bayonet, Flamer",
            limit: 1,
            weapons: [["Bayonet, Flamer"]],
            specialisms: ["marksman"]
        },
        {
            name: "Guardsman Gunner equipped with: Bayonet, Grenade launcher",
            limit: 1,
            weapons: [["Bayonet, Grenade launcher"]],
            specialisms: ["marksman"]
        },
        {
            name: "Guardsman Gunner equipped with: Bayonet, Meltagun",
            limit: 1,
            weapons: [["Bayonet, Meltagun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Guardsman Gunner equipped with: Bayonet, Plasma gun",
            limit: 1,
            weapons: [["Bayonet, Plasma gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Guardsman Gunner equipped with: Bayonet, Sniper rifle",
            limit: 1,
            weapons: [["Bayonet, Sniper rifle"]],
            specialisms: ["marksman"]
        },
        //
        {
            name: "Tempestor",
            limit: 1,
            weapons: [
                ["Bolt pistol", "Hot-shot laspistol", "Plasma pistol"],
                ["Chainsword", "Power fist", "Power weapon"]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Tempestus Scion Trooper",
            limit: 5,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Tempestus Scion Comms",
            limit: 5,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Flamer",
            limit: 1,
            weapons: [["Gun butt, Flamer"]],
            specialisms: ["marksman"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Grenade launcher",
            limit: 1,
            weapons: [["Gun butt, Grenade launcher"]],
            specialisms: ["marksman"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Hot-shot volley gun",
            limit: 1,
            weapons: [["Gun butt, Hot-shot volley gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Meltagun",
            limit: 1,
            weapons: [["Gun butt, Meltagun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Plasma gun",
            limit: 1,
            weapons: [["Gun butt, Plasma gun"]],
            specialisms: ["marksman"]
        }
    ]
};

export default killTeam;

import styled from "styled-components";
import theme from "./theme";

const StyledOperativeSpecOps = styled.div`
    border-top: solid 1px ${theme.colors.grey};

    .row {
        padding: 0.6em 1rem;
        background: ${theme.colors.offWhite};
        margin-bottom: 2px;

        &:last-child {
            margin-bottom: 0;
        }

        &:nth-child(odd) {
            background: ${theme.colors.altOffWhite};
        }

        &.row--inline {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div {
                flex-grow: 2;
            }

            select {
                // text-align: right;
            }
        }

        label {
            font-family: ${theme.fonts.header};
            text-transform: uppercase;
            color: ${theme.colors.orange};
            display: inline-block;
            padding-right: 1em;
        }
    }

    .row__select-wrap {
        position: relative;

        &::after {
            position: absolute;
            right: 0;
            top: 0;
            content: "▾";
            font-weight: 700;
            font-size: 1.2em;
            // content: "﹀";
            // content: "︾";
        }
    }

    select {
        width: 100%;
        background: none;
        border: none;
        font-family: ${theme.fonts.body};
        color: ${theme.colors.black};
        // letter-spacing: 0.01em;
        font-size: 1.1em;
        line-height: 1.6em;
        padding-right: 0.3em;
        font-weight: 700;

        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        &:focus {
            outline: none;
        }
    }

    .row--list {
        .row__select-wrap {
            &::after {
                top: 1em;
            }
        }

        select {
            margin-top: 0.5em;
            padding: 0.3em 0;
            border-top: solid 2px ${theme.colors.orange};
            font-size: 1.1em;
            letter-spacing: 0;
        }

        .list-items > * {
            display: flex;
            justify-content: space-between;
            padding: 0.25em 0;
            font-size: 1.1em;

            border-top: solid 1px ${theme.colors.grey};

            &:first-child {
                border-top: none;
            }
        }

        button {
            background: none;
            border: solid 2px #888;
            color: #888;
            font-family: ${theme.fonts.header};
            text-transform: uppercase;

            &:hover {
                border-color: ${theme.colors.darkGrey};
                color: ${theme.colors.darkGrey};
            }
        }
    }

    textarea {
        display: block;
        width: 100%;
        min-height: 4em;
        background: none;
        border: none;
        padding: 0;
        font-family: ${theme.fonts.body};

        &:focus {
            outline: none;
        }
    }

    input {
        background: none;
        border: none;
    }

    .operative-name {
        display: flex;

        input {
            flex-grow: 2;
            padding: 0.5em;
            text-align: right;
        }
    }

    .operative-name__icon {
        width: 2em;
        height: 2em;
        display: block;
        background: none;
        border: none;
        position: relative;

        svg {
            width: 80%;
            height: 80%;
            position: absolute;
            top: 10%;
            left: 10%;
        }
    }

    .counter {
        text-align: center;
        padding-bottom: 1em;

        label {
            padding-right: 0;
            padding-bottom: 0.5em;
        }

        > div {
            display: flex;
            justify-content: center;
            align-items: stretch;
            background: ${theme.colors.orange};

            input {
                font-size: 2em;
                line-height: 1.4em;
                text-align: center;
                background: ${theme.colors.offWhite};
                font-family: ${theme.fonts.header};
                padding: 0 0 0 0.55em;
                margin: 0;
                width: 2.6em;
                display: block;
                border: solid 2px ${theme.colors.orange};
                border-radius: 0;
                opacity: 1;

                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
            }

            button {
                display: block;
                width: 3em;
                border-radius: 0;
                border: solid 2px ${theme.colors.orange};
                background: ${theme.colors.orange};
                font-family: ${theme.fonts.header};
                color: ${theme.colors.white};
                font-size: 1.2em;
            }
        }
    }

    .exp-icons {
        display: flex;
        justify-content: center;
        padding-top: 0.6em;
        padding-bottom: 0.6em;

        div {
            width: 3.7em;
            height: 3em;
            position: relative;
            margin: 0 0.6em;

            &:nth-child(2),
            &:nth-child(3) {
                width: 3.9em;
            }

            &:nth-child(4),
            &:nth-child(5) {
                width: 4.8em;
            }
        }

        svg {
            width: 100%;
            height: 100%;
        }

        .exp-icon--active {
            svg {
                fill: ${theme.colors.orange};
            }
        }
    }

    @media (min-width: 20em) {
        .counters {
            display: flex;
            justify-content: center;

            .counter {
                margin: 0 1em;
            }
        }
    }
`;

export default StyledOperativeSpecOps;

import { GREENSKIN_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const GreenskinController = {
    id: GREENSKIN_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Boy Boss Nob" ||
                    operative.unit === "Clan Kommando Nob" ||
                    operative.unit === "Spanner Speshulist"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Must include only 1 Boy Boss Nob/Clan Kommando Nob/Spanner Speshulist"
            );
        }

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Boy") {
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return operative.unit === "Boy Boss Nob";
                    }).length > 0 &&
                    operativesInFireTeams[index].length !== 6
                ) {
                    passed = false;
                    messages.push(
                        "Boy Fire Team with Boy Boss Nob must include 6 operatives"
                    );
                } else if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push("Boy Fire Team must include 5 operatives");
                }

                // singles for kill team
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return "Boy Gunner" === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(`Maximum of 1 Boy Gunner in Boy fire team`);
                }
            }

            if (fireTeam === "Clan Kommando") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Clan Kommando Fire Team must include 5 operatives"
                    );
                }
            }

            if (fireTeam === "Speshulist") {
                if (operativesInFireTeams[index].length !== 4) {
                    passed = false;
                    messages.push(
                        "Speshulist Fire Team must include 4 operatives"
                    );
                }
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default GreenskinController;

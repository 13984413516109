// names
// backgrounds etc...
import { TRAITOR_SPACE_MARINE_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: TRAITOR_SPACE_MARINE_ID,
    moniker: "Traitor Space Marine",
    restrictions: restrictions,
    equipment: [
        {
            name: "Belt Feed (Heavy bolter)",
            cost: 3
        },
        {
            name: "Belt Feed",
            cost: 2
        },
        {
            name: "Malefic Rounds",
            cost: 2
        },
        {
            name: "Dark Blessing",
            cost: 3
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Grisly Trophy",
            cost: 3
        },
        {
            name: "Suspensor System",
            cost: 3
        },
        {
            name: "Sacrificial Dagger",
            cost: 3
        }
    ]
};

export default info;

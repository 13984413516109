import { PATHFINDER_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const PathfinderController = {
    id: PATHFINDER_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Shas'ui Pathfinder";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Shas'ui Pathfinder operative");
        }

        [
            "Blooded Pathfinder",
            "Drone Controller Pathfinder",
            "Assault Grenadier Pathfinder",
            "Medical Technician Pathfinder",
            "Transpectral Interference Pathfinder",
            "Communications Specialist Pathfinder",
            "Marksman Pathfinder",
            "MV1 Gun Drone",
            "MV4 Shield Drone",
            "MV7 Marker Drone",
            "MB3 Recon Drone",
            "MV31 Pulse Accelerator Drone",
            "MV33 Grav-inhibitor Drone"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        if (
            operativesInKillTeam.filter((operative) => {
                return "Weapons Expert Pathfinder" === operative.unit;
            }).length > 2
        ) {
            passed = false;
            messages.push(`Maximum of 2 Weapons Expert Pathfinder operatives`);
        }

        //
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "MB3 Recon Drone";
            }).length > 0 &&
            operativesInKillTeam.length > 12
        ) {
            passed = false;
            messages.push(
                "Pathfinder Fire Team with MB3 Recon Drone cannot include more than 12 operatives"
            );
        } else if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "MB3 Recon Drone";
            }).length > 0 &&
            operativesInKillTeam.length < 12
        ) {
            passed = false;
            messages.push(
                "Pathfinder Fire Team with MB3 Recon Drone must include 12 operatives"
            );
        } else if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "MB3 Recon Drone";
            }).length === 0 &&
            operativesInKillTeam.length !== 13
        ) {
            passed = false;
            messages.push("Pathfinder Fire Team must include 13 operatives");
        }

        //
        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default PathfinderController;

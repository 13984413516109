import { SPACE_MARINE_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const SpaceMarineController = {
    id: SPACE_MARINE_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Intercessor Sergeant" ||
                    operative.unit === "Assault Intercessor Sergeant" ||
                    operative.unit === "Incursor Sergeant" ||
                    operative.unit === "Infiltrator Sergeant" ||
                    operative.unit === "Reiver Sergeant" ||
                    operative.unit === "Heavy Intercessor Sergeant" ||
                    operative.unit === "Tactical Marine Sergeant" ||
                    operative.unit === "Scout Sergeant" ||
                    operative.unit === "Deathwatch Veteran Watch Sergeant"
                );
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Sergeant");
        }

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            const selectedTeam = [
                "Intercessor",
                "Assault Intercessor",
                "Incursor",
                "Infiltrator",
                "Reiver",
                "Deathwatch Veteran"
            ].find((team) => team === fireTeam);

            if (selectedTeam) {
                // if (operativesInFireTeams[index].length !== 5) {
                //     passed = false;
                //     messages.push(
                //         `${selectedTeam} Fire Team must include 5 operatives`
                //     );
                // }

                // balance dataslate Q2 2022
                if (operativesInFireTeams[index].length !== 6) {
                    passed = false;
                    messages.push(
                        `${selectedTeam} Fire Team must include 6 operatives`
                    );
                }
            }

            if (fireTeam === "Heavy Intercessor") {
                // if (operativesInFireTeams[index].length !== 4) {
                //     passed = false;
                //     messages.push(
                //         "Heavy Intercessor Fire Team must include 4 operatives"
                //     );
                // }

                // balance dataslate Q2 2022
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Heavy Intercessor Fire Team must include 5 operatives"
                    );
                }

                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            "Heavy Intercessor Heavy Gunner" === operative.unit
                        );
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 1 Heavy Intercessor Heavy Gunner`
                    );
                }
            }

            if (fireTeam === "Tactical Marine") {
                if (operativesInFireTeams[index].length !== 6) {
                    passed = false;
                    messages.push(
                        "Tactical Marine Fire Team must include 6 operatives"
                    );
                }

                [
                    "Tactical Marine Gunner",
                    "Tactical Marine Heavy Gunner"
                ].forEach((unit) => {
                    if (
                        operativesInFireTeams[index].filter((operative) => {
                            return unit === operative.unit;
                        }).length > 1
                    ) {
                        passed = false;
                        messages.push(`Maximum of 1 ${unit}`);
                    }
                });
            }

            if (fireTeam === "Scout") {
                if (operativesInFireTeams[index].length !== 10) {
                    passed = false;
                    messages.push("Scout Fire Team must include 10 operatives");
                }

                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return "Scout Heavy Gunner" === operative.unit;
                    }).length > 2
                ) {
                    passed = false;
                    messages.push("Maximum of 2 Scout Heavy Gunner operatives");
                }

                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return "Scout Sniper Warrior" === operative.unit;
                    }).length > 2
                ) {
                    passed = false;
                    messages.push(
                        "Maximum of 3 Scout Sniper Warrior operatives"
                    );
                }
            }

            if (fireTeam === "Deathwatch Veteran") {
                [
                    "Deathwatch Veteran Fighter",
                    "Deathwatch Veteran Gunner",
                    "Deathwatch Veteran Heavy Gunner"
                ].forEach((unit) => {
                    if (
                        operativesInFireTeams[index].filter((operative) => {
                            return unit === operative.unit;
                        }).length > 1
                    ) {
                        passed = false;
                        messages.push(`Maximum of 1 ${unit}`);
                    }
                });
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default SpaceMarineController;

const restrictions = () => {
    return (
        <div>
            <p>
                A <strong className="faction">HUNTER CLADE</strong> kill team
                consists of:
            </p>
            <ul>
                <li>
                    1 <strong className="faction">HUNTER CLADE</strong>{" "}
                    operative selected from the following list:
                    <ul>
                        <li>
                            <strong>SKITARII RANGER ALPHA</strong>
                        </li>
                        <li>
                            <strong>SKITARII VANGUARD ALPHA</strong>
                        </li>
                        <li>
                            <strong>SICARIAN RUSTSTALKER PRINCEPS</strong>
                        </li>
                        <li>
                            <strong>SICARIAN INFILTRATOR PRINCEPS</strong>
                        </li>
                    </ul>
                </li>
                <li>
                    9 <strong className="faction">HUNTER CLADE</strong>{" "}
                    operatives selected from the following list:
                    <ul>
                        <li>
                            <strong>SKITARII RANGER MARKSMAN</strong>
                        </li>
                        <li>
                            <strong>SKITARII RANGER DIKTAT</strong>
                        </li>
                        <li>
                            <strong>SKITARII RANGER GUNNER</strong>
                        </li>
                        <li>
                            <strong>SKITARII RANGER SURVEYOR</strong>
                        </li>
                        <li>
                            <strong>SKITARII VANGUARD SHOCKTROOPER</strong>
                        </li>
                        <li>
                            <strong>SKITARII VANGUARD DIKTAT</strong>
                        </li>
                        <li>
                            <strong>SKITARII VANGUARD GUNNER</strong>
                        </li>
                        <li>
                            <strong>SKITARII VANGUARD SURVEYOR</strong>
                        </li>
                        <li>
                            <strong>SICARIAN RUSTSTALKER ASSASSIN</strong>
                        </li>
                        <li>
                            <strong>SICARIAN INFILTRATOR TRACKER</strong>
                        </li>
                    </ul>
                </li>
            </ul>

            {/* prettier-ignore */}
            <p>Your kill team must include more <strong>SKITARII RANGER</strong> and <strong>SKITARII VANGUARD</strong> operatives than it does <strong>SICARIAN</strong> operatives.</p>

            {/* prettier-ignore */}
            <p>Your kill team can only include up to two <strong>GUNNER</strong> operatives, unless it contains three or fewer <strong>SICARIAN</strong> operatives, in which case it can include up to three <strong>GUNNER</strong> operatives. Each <strong>GUNNER</strong> operative must be equipped with different ranged weapons.</p>

            {/* prettier-ignore */}
            <p>Your kill team can only include up to one <strong>DIKTAT</strong> and one <strong>SURVEYOR</strong> operative.</p>
        </div>
    );
};

export default restrictions;

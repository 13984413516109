import React from "react";

const ExperienceIcon04 = () => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 164.34 102.16"
    >
        <path
            d="M756.78,125.27h90.47L802,197Z"
            transform="translate(-719.9 -125.27)"
        />
        <path
            d="M860.2,135.15,802,227.43l-58.19-92.27c4.6,0,8.52-.4,12.29.18,1.67.26,3.45,2.22,4.49,3.86,13.12,20.7,26.12,41.48,39.17,62.23.59.94,1.4,1.75,2.4,3,5.89-9.18,11.63-18,17.23-26.89,8.41-13.34,16.68-26.77,25.15-40.07a5.67,5.67,0,0,1,3.78-2.22C852,135,855.59,135.15,860.2,135.15Z"
            transform="translate(-719.9 -125.27)"
        />
        <path
            d="M734.2,167.62c8.1,0,15.65-.06,23.19.06a3.92,3.92,0,0,1,2.84,1.42c3.73,5.7,7.29,11.51,11.36,18-8.44,0-16,0-23.56,0a2.69,2.69,0,0,1-2-.9C742.06,180.05,738.18,173.88,734.2,167.62Z"
            transform="translate(-719.9 -125.27)"
        />
        <path
            d="M869.61,167.62c-3.86,6.32-7.09,11.84-10.62,17.17a5.64,5.64,0,0,1-3.93,2.21c-7.25.21-14.51.1-22.55.1,3.94-6.34,7.48-12.17,11.19-17.89a4,4,0,0,1,2.82-1.52C853.93,167.57,861.34,167.62,869.61,167.62Z"
            transform="translate(-719.9 -125.27)"
        />
        <path
            d="M847.6,163.12c3.86-6.19,7.25-11.72,10.78-17.16a3.39,3.39,0,0,1,2.48-1.25c7.37-.09,14.75-.05,23.38-.05-4.15,6.46-7.67,12.05-11.34,17.54-.42.62-1.73.87-2.63.88C863,163.15,855.8,163.12,847.6,163.12Z"
            transform="translate(-719.9 -125.27)"
        />
        <path
            d="M786.11,210.07c-8.23,0-15.6.1-23-.1a5,5,0,0,1-3.29-2.16c-3.48-5.15-6.76-10.45-10.72-16.66,8.65,0,16.59,0,24.53,0,.36,0,.82.45,1.05.81C778.38,197.78,782,203.58,786.11,210.07Z"
            transform="translate(-719.9 -125.27)"
        />
        <path
            d="M854.88,191.09c-3.91,6.25-7.11,11.59-10.6,16.73a5.48,5.48,0,0,1-3.81,2.16c-7.11.21-14.23.1-22.57.1,4.26-6.65,8.06-12.63,12-18.56.25-.38,1.2-.41,1.82-.41C839.08,191.09,846.48,191.09,854.88,191.09Z"
            transform="translate(-719.9 -125.27)"
        />
        <path
            d="M719.9,144.64c8.45,0,15.62-.1,22.77.1a5.16,5.16,0,0,1,3.46,2.16c3.4,5,6.57,10.14,10.43,16.19-8.66,0-16.47,0-24.29-.05a2.88,2.88,0,0,1-2-1.25C727,156.44,723.75,151,719.9,144.64Z"
            transform="translate(-719.9 -125.27)"
        />
    </svg>
);

export default ExperienceIcon04;

// raw KT data
import { DEATH_GUARD_ID } from "../../../constants/factions";

const killTeam = {
    id: DEATH_GUARD_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Plague Marine",
            units: [
                "Plague Marine Champion",
                "Plague Marine Warrior",
                "Plague Marine Gunner",
                "Plague Marine Heavy Gunner",
                "Plague Marine Fighter",
                "Plague Marine Icon Bearer"
            ]
        },
        {
            name: "Poxwalker",
            units: ["Poxwalker"]
        }
    ],
    operatives: [
        {
            name: "Plague Marine Champion",
            limit: 1,
            leader: true,
            weapons: [
                ["Bolt pistol", "Boltgun", "Plasma pistol"],
                ["Plague knife", "Plague sword", "Power fist"]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Plague Marine Warrior",
            limit: 2,
            weapons: [["Boltgun, Plague knife"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Plague Marine Gunner",
            limit: 1,
            weapons: [
                [
                    "Plague knife, Meltagun",
                    "Plague knife, Plague belcher",
                    "Plague knife, Plasma gun"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Plague Marine Heavy Gunner",
            limit: 1,
            weapons: [
                ["Plague knife, Blight launcher", "Plague knife, Plague spewer"]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Plague Marine Fighter",
            limit: 1,
            weapons: [
                [
                    "Bolt pistol, Bubotic axe, Mace of contagion",
                    "Bolt pistol, Flail of corruption",
                    "Bolt pistol, Great plague cleaver",
                    "Bolt pistol, Plague knives"
                ]
            ],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Plague Marine Icon Bearer",
            limit: 1,
            weapons: [["Boltgun, Plague knife"]],
            specialisms: ["staunch"]
        },
        {
            name: "Poxwalker",
            limit: 8,
            weapons: [["Improvised weapon"]],
            specialisms: []
        }
    ]
};

export default killTeam;

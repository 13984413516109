import { KASRKIN_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const KasrkinController = {
    id: KASRKIN_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length !== 10) {
            passed = false;
            messages.push("Must include 10 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Kasrkin Sergeant";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Kasrkin Sergeant");
        }

        // only 1 of these units
        [
            "Kasrkin Combat Medic",
            "Kasrkin Demo-trooper",
            "Kasrkin Gunner equipped with: Gun butt, Flamer",
            "Kasrkin Gunner equipped with: Gun butt, Grenade launcher",
            "Kasrkin Gunner equipped with: Gun butt, Hot-shot volley gun",
            "Kasrkin Gunner equipped with: Gun butt, Meltagun",
            "Kasrkin Gunner equipped with: Gun butt, Plasma gun",
            "Kasrkin Recon-trooper",
            "Kasrkin Sharpshooter",
            "Kasrkin Vox-trooper"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}.`);
            }
        });

        // max 4 gunner types
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Flamer" ||
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Grenade launcher" ||
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Hot-shot volley gun" ||
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Meltagun" ||
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Plasma gun" ||
                    operative.unit === "Kasrkin Sharpshooter"
                );
            }).length > 4
        ) {
            passed = false;
            messages.push(
                `Maximum of 4 KASRKIN GUNNER and KASRKIN SHARPSHOOTER units.`
            );
        }

        return {
            passed,
            messages
        };
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];

        return `${first}`;
    }
};

export default KasrkinController;

// names
// backgrounds etc...
import { EXACTION_SQUAD } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: EXACTION_SQUAD,
    moniker: "Exaction Squad",
    restrictions: restrictions,
    battleHonours: [
        "Obstinate",
        "Precinct Champion",
        "Rigid Determination",
        "No Lenience",
        "Judicious Efficiency",
        "Priority Endowment"
    ],
    // killTeamNames: [
    //     ["Splintered", "Twisted", "Inexorable", "Unsated", "Void", "Shadowed"],
    //     ["Rapture", "Vein", "Fang", "Blade", "Gauntlet", "Talon"]
    // ],
    operativeNames: [
        [
            "Mergen",
            "Izmal",
            "Katriona",
            "Ferenz",
            "Amellise",
            "Sebastian",
            "Jinan",
            "Umberto",
            "Chula",
            "Nyls",
            "Thaeda",
            "Peytr",
            "Alyss",
            "Sembert",
            "Cethad",
            "Dimas",
            "Mirrad",
            "Hendrych",
            "Kirhan",
            "Karlas",
            "Adjen",
            "Stepan",
            "Chengaz",
            "Thorren",
            "Vey",
            "Josef",
            "Luzia",
            "Herzog",
            "Kirse",
            "Danel",
            "Ceska",
            "Jacob",
            "Odorna",
            "Berthold",
            "Denah",
            "Maximillian"
        ],
        [
            "Gonderssen",
            "Drestein",
            "Bekker",
            "Klombas",
            "Tonnag",
            "van Haydn",
            "Decascion",
            "Lofflin",
            "Bravvos",
            "Strolke",
            "Smitz",
            "Mahon",
            "Calamor",
            "Koni",
            "Vorne",
            "Tekkord",
            "Dolas",
            "Karyet",
            "Bedu",
            "Zirongo",
            "Cagnas",
            "Sablik",
            "Belk",
            "Gerantus",
            "Karsonne",
            "Sepkos",
            "Kozski",
            "Kalladan",
            "Karta",
            "Falksberg",
            "Perdan",
            "Varrus",
            "Kron",
            "Sontal",
            "Skoll",
            "Walheim"
        ]
    ],
    history: [
        "Bibliocrypt Wardens",
        "Progena Bonds",
        "True Believers in the Lex",
        "Haunted by Guilt",
        "Recalled to Hunt",
        "Officers of the Judge"
    ],
    baseOfOperations: [
        "Frontier Watch-house",
        "Riot Control Vehicles",
        "Repurposed Gang Lair",
        "Drop off Stakeout",
        "Commandeered Transports",
        "Requisitioned Chambers"
    ],
    squadQuirk: [
        "Deafening Declamation",
        "Talismans of Judgement",
        "By the Lex, I am Witness",
        "Excruciators",
        "Absolutists",
        "Clandestine Insertion"
    ],
    strategicAssets: [
        "Writ of Judgement",
        "Portable Bibliocrypt",
        "Scrutineer-class Detection Suite"
    ],
    equipment: [
        {
            name: "Shotpistol",
            cost: 1
        },
        {
            name: "Reinforced Mirror-visor",
            cost: 1
        },
        {
            name: "Manacles",
            cost: 2
        },
        {
            name: "Strobing Phosphor-lumen",
            cost: 1
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Stun Grenade",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Flak-weave Greatcoat",
            cost: 2
        },
        {
            name: "Executioner Rounds",
            cost: 1
        },
        {
            name: "Relic Silencer (Scoped Shotpistol)",
            cost: 3
        },
        {
            name: "Relic Silencer",
            cost: 2
        },
        {
            name: "'Last Breath' Gas Bomb",
            cost: 2
        },
        {
            name: "Volition Inhibitor",
            cost: 3
        },
        {
            name: "CX-19 Web Agent",
            cost: 1
        }
    ],
    specOps: ["Sieze Lawbreaker", "Crush Dissent"]
};

export default info;

import React from "react";

const ExperienceIcon05 = () => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 164.64 102.07"
    >
        <path
            d="M987,159.35c1.52-8-3.2-12.59-6.39-17.79s-6.47-10.54-10-16.25h89.65c-4.62,11.25-15,19.4-15.36,32.56,3.88-5.93,8.11-11.66,11.51-17.84,2.11-3.84,4.42-5.64,8.8-5,2.6.37,5.29.07,8.89.07l-58.47,92.3-58.16-92.3c4.5,0,8.29-.38,11.93.17,1.71.26,3.6,2,4.65,3.59C978.36,145.35,982.4,152.05,987,159.35ZM1015.69,194l1.75,2,9.71-2.37a8.21,8.21,0,0,0-.17-2.38c-1.35-3.71-1.35-5.91,3.7-4.39,1.24.37,3.26-.44,4.38-1.37,4-3.36,4.92-10.92,2.64-15.59a8,8,0,0,1-.2-5.88c2.91-6.92,4.35-13.88.82-20.85-5.87-11.59-21.74-16.18-34.24-10.2-12.22,5.85-16.05,17.68-10,31.25,1,2.24,1.27,3.83-.35,6.06-2.83,3.92-.74,13.2,3.32,15.84a5,5,0,0,0,3.66.79c4.63-1.44,5.34.23,3.82,4.26a5.46,5.46,0,0,0-.05,2.23l9.52,2.71Z"
            transform="translate(-933.5 -125.31)"
        />
        <path
            d="M985.19,187c-8.46,0-16,0-23.59-.06a3.19,3.19,0,0,1-2.18-1.37c-3.75-5.68-7.4-11.42-11.66-18,8.61,0,16.16,0,23.7.06a3.36,3.36,0,0,1,2.36,1.39C977.53,174.73,981.12,180.53,985.19,187Z"
            transform="translate(-933.5 -125.31)"
        />
        <path
            d="M1083.25,167.53c-3.93,6.36-7.24,12-10.87,17.4a5.64,5.64,0,0,1-4,2c-7.1.2-14.2.09-22.46.09,2.91-4.56,6-8.3,7.84-12.57,2.59-6,6.3-8,12.73-7.08C1071.64,168.13,1077,167.53,1083.25,167.53Z"
            transform="translate(-933.5 -125.31)"
        />
        <path
            d="M1098.14,144.07c-4.05,6.36-7.55,12.07-11.34,17.57-.64.93-2.54,1.33-3.87,1.35-7,.13-13.92.07-22.11.07,4.23-6.47,8-12.34,11.92-18.1.42-.62,1.74-.84,2.65-.85C1082.63,144,1089.87,144.07,1098.14,144.07Z"
            transform="translate(-933.5 -125.31)"
        />
        <path
            d="M999.76,210c-8.39,0-15.89.1-23.38-.1a5.13,5.13,0,0,1-3.52-2.19c-3.41-5.27-6.56-10.69-10.18-16.67,8.23,0,15.9,0,23.57.05a3.18,3.18,0,0,1,2.21,1.31C992.13,198,995.69,203.61,999.76,210Z"
            transform="translate(-933.5 -125.31)"
        />
        <path
            d="M1031.58,210c3.92-6.39,7.2-12,10.78-17.37.63-.95,2.46-1.51,3.75-1.54,7.1-.15,14.21-.07,22.19-.07-3.8,6.32-7.23,12.18-10.86,17.9-.46.71-2,1-3,1C1047.14,210,1039.89,210,1031.58,210Z"
            transform="translate(-933.5 -125.31)"
        />
        <path
            d="M970.18,163.05c-8.48,0-15.76.07-23-.07a4.38,4.38,0,0,1-3.1-1.67c-3.43-5.17-6.65-10.47-10.55-16.71,8.48,0,16,0,23.52.07a3.46,3.46,0,0,1,2.31,1.53C962.82,151.48,966.2,156.84,970.18,163.05Z"
            transform="translate(-933.5 -125.31)"
        />
        <path
            d="M1013.36,175.19c-3.8,1.66-6.09,3.18-8.56,3.56a7,7,0,0,1-5.28-1.82c-.89-.91-.4-3.2-.52-4.87,2,0,4-.3,5.92.12C1007.36,172.71,1009.67,173.83,1013.36,175.19Z"
            transform="translate(-933.5 -125.31)"
        />
        <path
            d="M1019.44,174.71c3.28-1.21,6.51-2.72,9.89-3.46,1-.23,2.54,1.66,3.82,2.57-1.33,1.74-2.68,4.94-4,4.92-3.23-.05-6.44-1.58-9.66-2.51Z"
            transform="translate(-933.5 -125.31)"
        />
        <path
            d="M1012.27,184.88l3.33-6.81,3.78,6.81Z"
            transform="translate(-933.5 -125.31)"
        />
    </svg>
);

export default ExperienceIcon05;

// names
// backgrounds etc...
import { FARSTALKER_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: FARSTALKER_ID,
    moniker: "Farstalker Kinbad",
    restrictions: restrictions,
    battleHonours: [
        "Savage",
        "Eye on the Mark",
        "Trapper",
        "Wiry",
        "Leathery Physiology",
        "Clandestine"
    ],
    // killTeamNames: [[], []],
    operativeNames: [
        [
            "Kra",
            "Gohk",
            "Ahkra",
            "Dohra",
            "Cho",
            "Byahk",
            "Grahm",
            "Khor",
            "Ohrak",
            "Tehk",
            "Chok",
            "Khrek",
            "Tobok",
            "Obak",
            "Grark",
            "Byahm",
            "Doryc",
            "Te",
            "Khrob",
            "Jiynko",
            "Ahoc",
            "Obyn",
            "Anghor",
            "Avhra",
            "Yuka",
            "Doakh",
            "Byek",
            "Gho",
            "Lucu",
            "Tohra",
            "Dra",
            "Ahahk",
            "Gerba",
            "Alhar",
            "Bakor",
            "Tebek"
        ],
        [
            "'to",
            "Cha",
            "'ka",
            "'yo",
            "Grok",
            "'ah",
            "'ohk",
            "Ek",
            "'tcha",
            "",
            "'ya",
            "Ahk",
            "Ba",
            "'tcho",
            "'ke",
            "Ot",
            "Ak",
            "'hrakh",
            "Che",
            "'yc",
            "Khe",
            "",
            "'grakh",
            "'ab",
            "'cha",
            "Ohk",
            "Ye",
            "'grekh",
            "Da",
            "'gr",
            "Ekh",
            "Yo",
            "'eht",
            "",
            "Rek",
            "'tche"
        ],
        [
            "Gota",
            "Krrah",
            "Ch'choh",
            "Tohrrok",
            "Ga'ah",
            "Kyrek",
            "Ghorka",
            "Drr'rr",
            "Yo'toh",
            "Rhekk",
            "Prok",
            "Teleb",
            "Talar",
            "Pre'lek",
            "Yrr'dk",
            "Goba",
            "Ta'bak",
            "Ga'toh",
            "Yabek",
            "Cho'yar",
            "Rhehor",
            "Kaa'he",
            "Rrok",
            "Kyr'am",
            "Mebekh",
            "Batam",
            "Dyr'yn",
            "Gabt",
            "Krarh",
            "Yr'be",
            "Drekh",
            "Orak",
            "Caroch",
            "Akchan",
            "Trosk",
            "Belet"
        ]
    ],
    history: [
        "Ingested Intellect",
        "Cautious Killers",
        "Exiles",
        "Survivors",
        "Bestial Brawn",
        "Wily"
    ],
    baseOfOperations: [
        "Knarloc Herd",
        "Forest Lair",
        "Cave Hideaway",
        "Nomadic",
        "Prize Transports",
        "Star Skiff"
    ],
    squadQuirk: [
        "Shadow Fighters",
        "Endurance Hunters",
        "Go for the Throat",
        "Encircle",
        "Only the Strong Survive",
        "Shock Troopss"
    ],
    strategicAssets: ["Spoils Safe", "Weapon Workbench", "Meat Locker"],
    equipment: [
        {
            name: "Quill Grenade",
            cost: 2
        },
        {
            name: "Piercing Shot",
            cost: 2
        },
        {
            name: "Toxin Shot",
            cost: 2
        },
        {
            name: "Meat",
            cost: 3
        },
        {
            name: "Trophy",
            cost: 4
        },
        {
            name: "Ritual Blade",
            cost: 2
        },
        {
            name: "Kroot Pistol",
            cost: 1
        }
    ],
    rareEquipment: [
        {
            name: "Kinblade",
            cost: 3
        },
        {
            name: "Knarloc Hide",
            cost: 2
        },
        {
            name: "Kroothawk Totem",
            cost: 3
        },
        {
            name: "Ancient Flintlock",
            cost: 2
        },
        {
            name: "Kin Totem",
            cost: 2
        },
        {
            name: "Windmark",
            cost: 1
        }
    ],
    specOps: ["Kin, First and Foremost", "Guns for Hire"]
};

export default info;

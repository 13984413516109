// raw KT data
import { CADRE_MERCENARY_ID } from "../../../constants/factions";

const killTeam = {
    id: CADRE_MERCENARY_ID,
    operatives: [
        {
            name: "Kroot Carnivore Leader",
            limit: 1,
            leader: true,
            weapons: [["Kroot rifle, Rifle blades"]],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Kroot Carnivore Warrior",
            limit: 12,
            weapons: [["Kroot rifle, Rifle blades"]],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Kroot Hound",
            limit: 4,
            weapons: [["Ripping fangs"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Krootox",
            limit: 1,
            weapons: [["Kroot gun, Krootox fists"]],
            specialisms: ["combat", "staunch", "marksman"]
        }
    ]
};

export default killTeam;

// raw KT data
import { CHAOS_DAEMON_ID } from "../../../constants/factions";

const killTeam = {
    id: CHAOS_DAEMON_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Bloodletter",
            units: [
                "Bloodreaper",
                "Bloodletter Fighter",
                "Bloodletter Icon Bearer",
                "Bloodletter Horn Bearer"
            ]
        },
        {
            name: "Daemonette",
            units: [
                "Allurress",
                "Daemonette Fighter",
                "Daemonette Icon Bearer",
                "Daemonette Horn Bearer"
            ]
        },
        {
            name: "Plaguebearer",
            units: [
                "Plagueridden",
                "Plaguebearer Fighter",
                "Plaguebearer Icon Bearer",
                "Plaguebearer Horn Bearer"
            ]
        },
        {
            name: "Pink Horror",
            units: [
                "Pink Horror Iridescent",
                "Pink Horror Fighter",
                "Pink Horror Icon Bearer",
                "Pink Horror Horn Bearer"
            ]
        },
        {
            name: "Blue Horror",
            units: ["Blue Horror"]
        }
    ],
    operatives: [
        {
            name: "Bloodreaper",
            limit: 1,
            leader: true,
            weapons: [["Hellblade"]],
            specialisms: ["combat"]
        },
        {
            name: "Bloodletter Fighter",
            limit: 6,
            weapons: [["Hellblade"]],
            specialisms: ["combat"]
        },
        {
            name: "Bloodletter Icon Bearer",
            limit: 1,
            weapons: [["Hellblade"]],
            specialisms: ["combat"]
        },
        {
            name: "Bloodletter Horn Bearer",
            limit: 1,
            weapons: [["Hellblade"]],
            specialisms: ["combat"]
        },
        //
        {
            name: "Allurress",
            limit: 1,
            leader: true,
            weapons: [["Claws"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Daemonette Fighter",
            limit: 6,
            weapons: [["Claws"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Daemonette Icon Bearer",
            limit: 1,
            weapons: [["Claws"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Daemonette Horn Bearer",
            limit: 1,
            weapons: [["Claws"]],
            specialisms: ["combat", "scout"]
        },
        //
        {
            name: "Plagueridden",
            limit: 1,
            leader: true,
            weapons: [["Plaguesword"]],
            specialisms: ["staunch"]
        },
        {
            name: "Plaguebearer Fighter",
            limit: 6,
            weapons: [["Plaguesword"]],
            specialisms: ["staunch"]
        },
        {
            name: "Plaguebearer Icon Bearer",
            limit: 1,
            weapons: [["Plaguesword"]],
            specialisms: ["staunch"]
        },
        {
            name: "Plaguebearer Horn Bearer",
            limit: 1,
            weapons: [["Plaguesword"]],
            specialisms: ["staunch"]
        },
        //
        {
            name: "Pink Horror Iridescent",
            limit: 1,
            leader: true,
            weapons: [["Coruscating flames, Fists"]],
            specialisms: ["marksman"]
        },
        {
            name: "Pink Horror Fighter",
            limit: 6,
            weapons: [["Coruscating flames, Fists"]],
            specialisms: ["marksman"]
        },
        {
            name: "Pink Horror Icon Bearer",
            limit: 1,
            weapons: [["Coruscating flames, Fists"]],
            specialisms: ["marksman"]
        },
        {
            name: "Pink Horror Horn Bearer",
            limit: 1,
            weapons: [["Coruscating flames, Fists"]],
            specialisms: ["marksman"]
        },
        //
        {
            name: "Blue Horror",
            limit: 8,
            weapons: [["Fizzing flames, Fists"]],
            specialisms: ["marksman", "scout"]
        }
    ]
};

export default killTeam;

import { DEATH_GUARD_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const DeathGuardController = {
    id: DEATH_GUARD_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains up to 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Plague Marine Champion";
            }).length > 1
        ) {
            passed = false;
            messages.push("Maximum of 1 Plague Marine Champion");
        }

        // singles for kill team
        [
            "Plague Marine Gunner",
            "Plague Marine Heavy Gunner",
            "Plague Marine Fighter",
            "Plague Marine Icon Bearer"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit} operative`);
            }
        });

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Plague Marine") {
                // if (
                //     operativesInFireTeams[index].filter((operative) => {
                //         return operative.unit === "Plague Marine Champion";
                //     }).length > 0 &&
                //     operativesInFireTeams[index].length > 3
                // ) {
                //     passed = false;
                //     messages.push(
                //         "Plague Marine Fire Team cannot include more than 3 operatives"
                //     );
                // } else if (
                //     operativesInFireTeams[index].filter((operative) => {
                //         return operative.unit === "Plague Marine Champion";
                //     }).length > 0 &&
                //     operativesInFireTeams[index].length < 3
                // ) {
                //     passed = false;
                //     messages.push(
                //         "Plague Marine Fire Team with Plague Marine Champion must include 3 operatives"
                //     );
                // } else if (
                //     operativesInFireTeams[index].filter((operative) => {
                //         return operative.unit === "Plague Marine Champion";
                //     }).length === 0 &&
                //     operativesInFireTeams[index].length !== 2
                // ) {
                //     passed = false;
                //     messages.push(
                //         "Plague Marine Fire Team must include 2 operatives"
                //     );
                // }

                // balance dataslate Q2 2022
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return operative.unit === "Plague Marine Champion";
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(
                        "Cannot include more than 1 Plague Marine Champion"
                    );
                }

                if (operativesInFireTeams[index].length !== 3) {
                    passed = false;
                    messages.push(
                        "Plague Marine Fire Team must include 3 operatives"
                    );
                }

                // not all the pro dudes
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit === "Plague Marine Gunner" ||
                            operative.unit === "Plague Marine Heavy Gunner" ||
                            operative.unit === "Plague Marine Fighter"
                        );
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(
                        "Cannot include Plague Marine Gunner, Plague Marine Heavy Gunner or Plague Marine Fighter together in the same fire team"
                    );
                }
            }

            //
            if (fireTeam === "Poxwalker") {
                if (operativesInFireTeams[index].length !== 8) {
                    passed = false;
                    messages.push(
                        "Poxwalker Fire Team must include 8 operatives"
                    );
                }
            }
        });

        // only 1 poxwalker team
        if (
            list.fireTeams.filter((team) => {
                return team === "Poxwalker";
            }).length > 1
        ) {
            passed = false;
            messages.push("Max 1 Poxwalker Fire Team");
        }

        //
        return {
            passed,
            messages
        };
    }
};

export default DeathGuardController;

import React from "react";

const ExperienceIcon03 = () => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 135.29 102.65"
    >
        <path
            d="M557.55,125h91l-45.48,72.22Z"
            transform="translate(-535.34 -125.02)"
        />
        <path
            d="M544.92,135.33c.83-.28,1-.35,1.15-.38,8.5-1.46,14.2.85,18.94,9.21,10.8,19,23,37.17,34.65,55.66.94,1.5,2,2.9,3.51,5,5.6-8.93,10.87-17.37,16.18-25.77,8.84-14,17.67-28,26.62-41.88a5.06,5.06,0,0,1,3.31-2.18c3.9-.28,7.83-.11,12.3-.11-19.56,31-38.78,61.51-58.53,92.82Z"
            transform="translate(-535.34 -125.02)"
        />
        <path
            d="M586.93,209.78c-7.93,0-15.23.09-22.51-.09a5.53,5.53,0,0,1-4-1.87c-3.51-5.29-6.69-10.78-10.49-17,8.34,0,15.87-.1,23.38.12,1.07,0,2.37,1.41,3.09,2.49C579.86,198.59,583.16,203.88,586.93,209.78Z"
            transform="translate(-535.34 -125.02)"
        />
        <path
            d="M619.48,209.76c3.57-6,6.68-11.55,10.06-16.87a5.07,5.07,0,0,1,3.6-2c7.38-.19,14.76-.09,22.81-.09-3.63,6.22-6.91,11.94-10.33,17.58a3.35,3.35,0,0,1-2.43,1.32C635.49,209.8,627.79,209.76,619.48,209.76Z"
            transform="translate(-535.34 -125.02)"
        />
        <path
            d="M572.29,186.8c-8,0-15.06.11-22.15-.09a5.68,5.68,0,0,1-4-2.07c-3.61-5.24-6.93-10.69-10.84-16.81,8.24,0,15.74-.08,23.23.08a4.49,4.49,0,0,1,3.07,1.9C565.13,175.14,568.43,180.59,572.29,186.8Z"
            transform="translate(-535.34 -125.02)"
        />
        <path
            d="M633.49,186.8c4.17-6.46,7.67-12.11,11.47-17.56.64-.93,2.53-1.34,3.86-1.36,6.95-.13,13.9-.06,21.81-.06-4,6.32-7.58,12.19-11.42,17.89-.54.8-2.32,1-3.54,1C648.72,186.85,641.77,186.8,633.49,186.8Z"
            transform="translate(-535.34 -125.02)"
        />
    </svg>
);

export default ExperienceIcon03;

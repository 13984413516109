// raw KT data
import { SPACE_MARINE_ID } from "../../../constants/factions";

const killTeam = {
    id: SPACE_MARINE_ID,
    fireTeams: 1,
    fireTeamTypes: [
        {
            name: "Intercessor",
            units: ["Intercessor Sergeant", "Intercessor Warrior"]
        },
        {
            name: "Assault Intercessor",
            units: [
                "Assault Intercessor Sergeant",
                "Assault Intercessor Warrior"
            ]
        },
        {
            name: "Incursor",
            units: ["Incursor Sergeant", "Incursor Warrior"]
        },
        {
            name: "Infiltrator",
            units: ["Infiltrator Sergeant", "Infiltrator Warrior"]
        },
        {
            name: "Reiver",
            units: ["Reiver Sergeant", "Reiver Warrior"]
        },
        {
            name: "Heavy Intercessor",
            units: [
                "Heavy Intercessor Sergeant",
                "Heavy Intercessor Warrior",
                "Heavy Intercessor Heavy Gunner"
            ]
        },
        {
            name: "Tactical Marine",
            units: [
                "Tactical Marine Sergeant",
                "Tactical Marine Warrior",
                "Tactical Marine Gunner",
                "Tactical Marine Heavy Gunner"
            ]
        },
        {
            name: "Scout",
            units: [
                "Scout Sergeant",
                "Scout Sniper Sergeant",
                "Scout Warrior",
                "Scout Sniper Warrior",
                "Scout Heavy Gunner"
            ]
        },
        {
            name: "Deathwatch Veteran",
            units: [
                "Deathwatch Veteran Watch Sergeant",
                "Deathwatch Veteran Warrior",
                "Deathwatch Veteran Fighter",
                "Deathwatch Veteran Gunner",
                "Deathwatch Veteran Heavy Gunner"
            ]
        }
    ],
    operatives: [
        {
            name: "Intercessor Sergeant",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Auto bolt rifle",
                    "Bolt pistol",
                    "Thunder hammer",
                    "hand flamer",
                    "Plasma pistol",
                    "Stalker bolt rifle"
                ],
                [
                    "Chainsword",
                    "Fists",
                    "Power fist",
                    "Power weapon",
                    "Thunder hammer"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Intercessor Warrior",
            limit: 4,
            weapons: [
                ["Fists"],
                ["Auto bolt rifle", "Bolt rifle", "Stalker bolt rifle"]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Assault Intercessor Sergeant",
            limit: 1,
            weapons: [
                ["Hand flamer", "Heavy bolt pistol", "Plasma pistol"],
                ["Chainsword", "Power fist", "Power weapon", "Thunder hammer"]
            ],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Assault Intercessor Warrior",
            limit: 4,
            weapons: [["Heavy bolt pistol, Chainsword"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Incursor Sergeant",
            limit: 1,
            weapons: [["Occulus bolt carbine, Combat blade"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Incursor Warrior",
            limit: 4,
            weapons: [["Occulus bolt carbine, Combat blade"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Infiltrator Sergeant",
            limit: 1,
            weapons: [["Marksman bolt carbine, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Infiltrator Warrior",
            limit: 4,
            weapons: [["Marksman bolt carbine, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Reiver Sergeant",
            limit: 1,
            weapons: [
                ["Special issue bolt pistol"],
                [
                    "Bolt carbine, Combat knife",
                    "Bolt carbine, Fists",
                    "Combat knife"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Reiver Warrior",
            limit: 4,
            weapons: [
                ["Special issue bolt pistol"],
                ["Bolt carbine, Fists", "Combat knife"]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Heavy Intercessor Sergeant",
            limit: 1,
            weapons: [
                ["Fists"],
                [
                    "Executor bolt rifle",
                    "Heavy bolt rifle",
                    "Hellstorm bolt rifle"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Heavy Intercessor Warrior",
            limit: 3,
            weapons: [
                ["Fists"],
                [
                    "Executor bolt rifle",
                    "Heavy bolt rifle",
                    "Hellstorm bolt rifle"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Heavy Intercessor Heavy Gunner",
            limit: 1,
            weapons: [
                ["Fists"],
                [
                    "Executor heavy bolter",
                    "Heavy bolter",
                    "Hellstorm heavy bolter"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Tactical Marine Sergeant",
            limit: 1,
            weapons: [
                [
                    "Fists, Boltgun",
                    "Fists, Combi-flamer",
                    "Fists, Comb-grav",
                    "Fists, Combi-melta",
                    "Fists, Combi-plasma",
                    "Chainsword, Bolt pistol",
                    "Chainsword, Grav-pistol",
                    "Chainsword, Hand flamer",
                    "Chainsword, Inferno pistol",
                    "Chainsword, Plasma pistol",
                    "Power fist, Bolt pistol",
                    "Power fist, Grav-pistol",
                    "Power fist, Hand flamer",
                    "Power fist, Inferno pistol",
                    "Power fist, Plasma pistol",
                    "Power weapon, Bolt pistol",
                    "Power weapon, Grav-pistol",
                    "Power weapon, Hand flamer",
                    "Power weapon, Inferno pistol",
                    "Power weapon, Plasma pistol"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Tactical Marine Warrior",
            limit: 5,
            weapons: [["Boltgun, Fists"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Tactical Marine Gunner",
            limit: 1,
            weapons: [
                ["Fists"],
                ["Flamer", "Grav-gun", "Meltagun", "Plasma gun"]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Tactical Marine Heavy Gunner",
            limit: 1,
            weapons: [["Fists"], ["Heavy bolter", "Missile launcher"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Scout Sergeant",
            limit: 1,
            weapons: [
                [
                    "Astartes shotgun, Fists",
                    "Boltgun, Fists",
                    "Chainsword, Bolt pistol"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Scout Sniper Sergeant",
            limit: 1,
            weapons: [["Scout sniper rifle, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Scout Warrior",
            limit: 9,
            weapons: [
                [
                    "Astartes shotgun, Fists",
                    "Boltgun, Fists",
                    "Bolt pistol, Combat knife"
                ]
            ],
            specialisms: ["scout"]
        },
        {
            name: "Scout Sniper Warrior",
            limit: 3,
            weapons: [["Scout sniper rifle, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Scout Heavy Gunner",
            limit: 1,
            weapons: [["Fists"], ["Heavy bolter", "Missile launcher"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Deathwatch Veteran Watch Sergeant",
            limit: 1,
            weapons: [
                [
                    "Heavy thunder hammer",
                    "Lightning claws",
                    "Fists, Deathwatch boltgun",
                    "Fists, Deathwatch shotgun",
                    "Fists, Combi-flamer",
                    "Fists, Comb-grav",
                    "Fists, Combi-melta",
                    "Fists, Combi-plasma",
                    "Fists, Flamer",
                    "Fists, Grav-gun",
                    "Fists, Meltagun",
                    "Fists, Plasma gun",
                    "Fists, Stalker pattern boltgun",
                    "Fists, Storm bolter",
                    "Chainsword, Bolt pistol",
                    "Chainsword, Grav-pistol",
                    "Chainsword, Hand flamer",
                    "Chainsword, Inferno pistol",
                    "Chainsword, Plasma pistol",
                    "Chainsword, Storm shield",
                    "Lightning claw, Bolt pistol",
                    "Lightning claw, Grav-pistol",
                    "Lightning claw, Hand flamer",
                    "Lightning claw, Inferno pistol",
                    "Lightning claw, Plasma pistol",
                    "Lightning claw, Storm shield",
                    "Power fist, Bolt pistol",
                    "Power fist, Grav-pistol",
                    "Power fist, Hand flamer",
                    "Power fist, Inferno pistol",
                    "Power fist, Plasma pistol",
                    "Power fist, Storm shield",
                    "Power maul, Bolt pistol",
                    "Power maul, Grav-pistol",
                    "Power maul, Hand flamer",
                    "Power maul, Inferno pistol",
                    "Power maul, Plasma pistol",
                    "Power maul, Storm shield",
                    "Power weapon, Bolt pistol",
                    "Power weapon, Grav-pistol",
                    "Power weapon, Hand flamer",
                    "Power weapon, Inferno pistol",
                    "Power weapon, Plasma pistol",
                    "Power weapon, Storm shield",
                    "Thunder hammer, Bolt pistol",
                    "Thunder hammer, Grav-pistol",
                    "Thunder hammer, Hand flamer",
                    "Thunder hammer, Inferno pistol",
                    "Thunder hammer, Plasma pistol",
                    "Thunder hammer, Storm shield",
                    "Xenophase blade, Bolt pistol",
                    "Xenophase blade, Grav-pistol",
                    "Xenophase blade, Hand flamer",
                    "Xenophase blade, Inferno pistol",
                    "Xenophase blade, Plasma pistol",
                    "Xenophase blade, Storm shield"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Deathwatch Veteran Warrior",
            limit: 4,
            weapons: [
                [
                    "Deathwatch boltgun, Power weapon",
                    "Fists, Deathwatch shotgun",
                    "Fists, Stalker pattern boltgun",
                    "Fists, Storm bolter"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Deathwatch Veteran Fighter",
            limit: 1,
            weapons: [
                [
                    "Heavy thunder hammer",
                    "Lightning claws",
                    "Chainsword, Bolt pistol",
                    "Chainsword, Grav-pistol",
                    "Chainsword, Hand flamer",
                    "Chainsword, Inferno pistol",
                    "Chainsword, Plasma pistol",
                    "Chainsword, Storm shield",
                    "Lightning claw, Bolt pistol",
                    "Lightning claw, Grav-pistol",
                    "Lightning claw, Hand flamer",
                    "Lightning claw, Inferno pistol",
                    "Lightning claw, Plasma pistol",
                    "Lightning claw, Storm shield",
                    "Power fist, Bolt pistol",
                    "Power fist, Grav-pistol",
                    "Power fist, Hand flamer",
                    "Power fist, Inferno pistol",
                    "Power fist, Plasma pistol",
                    "Power fist, Storm shield",
                    "Power maul, Bolt pistol",
                    "Power maul, Grav-pistol",
                    "Power maul, Hand flamer",
                    "Power maul, Inferno pistol",
                    "Power maul, Plasma pistol",
                    "Power maul, Storm shield",
                    "Power weapon, Bolt pistol",
                    "Power weapon, Grav-pistol",
                    "Power weapon, Hand flamer",
                    "Power weapon, Inferno pistol",
                    "Power weapon, Plasma pistol",
                    "Power weapon, Storm shield",
                    "Thunder hammer, Bolt pistol",
                    "Thunder hammer, Grav-pistol",
                    "Thunder hammer, Hand flamer",
                    "Thunder hammer, Inferno pistol",
                    "Thunder hammer, Plasma pistol",
                    "Thunder hammer, Storm shield"
                ]
            ],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Deathwatch Veteran Gunner",
            limit: 1,
            weapons: [
                [
                    "Fists, Combi-flamer",
                    "Fists, Combi-grav",
                    "Fists, Combi-plasma",
                    "Fists, Flamer",
                    "Fists, Grav-gun",
                    "Fists, Meltagun",
                    "Fists, Plasma gun"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Deathwatch Veteran Heavy Gunner",
            limit: 1,
            weapons: [
                [
                    "Fists, Frag cannon",
                    "Fists, Heavy bolter",
                    "Fists, Heavy flamer",
                    "Fists, Infernus heavy bolter",
                    "Fists, Missile launcher"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        }
    ]
};

export default killTeam;

// names
// backgrounds etc...
import { NOVITIATE_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: NOVITIATE_ID,
    moniker: "Novitiate",
    restrictions: restrictions,
    battleHonours: [
        "Devout",
        "Pious",
        "Righteous Purpose",
        "Glare of Condemnation",
        "Determined",
        "Quick of Thought and Action"
    ],
    killTeamNames: [
        [
            "Penitents",
            "Cleansing Fire",
            "Purgators",
            "Chastisers",
            "Guardians",
            "Humble Sisters"
        ],
        [
            "Illuminatum",
            "Of Blood",
            "Of the Creed",
            "Of the Heroine Unbowed",
            "Redemptive",
            "Of Wrath"
        ]
    ],
    operativeNames: [
        [
            "Danica",
            "Constantia",
            "Eleanor",
            "Mariah",
            "Lisbett",
            "Kataryn",
            "Helenica",
            "Isadore",
            "Amelia",
            "Elyza",
            "Hyldeth"
        ],
        [
            "Serenitas",
            "Bellicos",
            "Sacristus",
            "N'bata",
            "Lucinias",
            "Honourifica",
            "Dasyrov",
            "Caritas",
            "Vengor",
            "Shengg",
            "Khazentia"
        ]
    ],
    history: [
        "Shrine Guardians",
        "Merciless",
        "Unproven",
        "Prefects of the Brill-abbots",
        "Eyes Opened to Wickedness",
        "Students of the Saints"
    ],
    baseOfOperations: [
        "Reconsecrated Shrine",
        "Invasion Cathedrum",
        "Under the Eyes of Saints",
        "Underground Imperial Cult",
        "Mausoleum",
        "Sororitas Rhinos"
    ],
    squadQuirk: [
        "Fanatical",
        "Vow of Silence",
        "Flagellants",
        "Serene",
        "Hymnal to Glory",
        "Ritualised Maintenance"
    ],
    strategicAssets: ["Incendiary Bombs", "Sanitorium", "Sanctum"],
    equipment: [
        {
            name: "Icon of Faith",
            cost: 2
        },
        {
            name: "Chaplet Ecclesiaticus",
            cost: 2
        },
        {
            name: "Adamantine-weave Surplice",
            cost: 2
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Auto-chastiser",
            cost: 1
        },
        {
            name: "Sainted Reliquae",
            cost: 2
        }
    ],
    rareEquipment: [
        {
            name: "Relic Gunsight",
            cost: 3
        },
        {
            name: "Blessed Blade",
            cost: 2
        },
        {
            name: "Blessed Incense",
            cost: 2
        },
        {
            name: "Inferno Rounds",
            cost: 3
        },
        {
            name: "Saint-touched Cloth",
            cost: 2
        },
        {
            name: "Proclamation Broadcaster",
            cost: 2
        }
    ],
    specOps: ["Purge the Unclean", "Reclamation Sweep"]
};

export default info;

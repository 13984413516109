// raw KT data
import { WYRMBLADE_ID } from "../../../constants/factions";

const killTeam = {
    id: WYRMBLADE_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Neophyte Leader",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Autogun, Gun Butt",
                    "Shotgun, Gun Butt",
                    "Master-crafted Autopistol, Chainsword",
                    "Master-crafted Autopistol, Power Maul",
                    "Master-crafted Autopistol, Power Pick",
                    "Bolt Pistol, Chainsword",
                    "Bolt Pistol, Power Maul",
                    "Bolt Pistol, Power Pick",
                    "Web Pistol, Chainsword",
                    "Web Pistol, Power Maul",
                    "Web Pistol, Power Pick"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Kelermorph",
            limit: 1,
            weapons: [["Liberator autostubs, Kelermorph knife"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Locus",
            limit: 1,
            weapons: [["Barbed tail, Locus blades"]],
            specialisms: ["combat"]
        },
        {
            name: "Neophyte Brood-adept",
            limit: 13,
            weapons: [["Gun Butt, Autogun", "Gun Butt, Shotgun"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Neophyte Gunner equipped with: Gun Butt, Flamer",
            limit: 1,
            weapons: [["Gun Butt, Flamer"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Neophyte Gunner equipped with: Gun Butt, Grenade Launcher",
            limit: 1,
            weapons: [["Gun Butt, Grenade Launcher"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Neophyte Gunner equipped with: Gun Butt, Webber",
            limit: 1,
            weapons: [["Gun Butt, Webber"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Neophyte Heavy Gunner equipped with: Gun Butt, Heavy Stubber",
            limit: 1,
            weapons: [["Gun Butt, Heavy Stubber"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Neophyte Heavy Gunner equipped with: Gun Butt, Mining Laser",
            limit: 1,
            weapons: [["Gun Butt, Mining Laser"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Neophyte Heavy Gunner equipped with: Gun Butt, Seismic Cannon",
            limit: 1,
            weapons: [["Gun Butt, Seismic Cannon"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Neophyte Icon Bearer",
            limit: 1,
            weapons: [["Gun Butt, Autogun", "Gun Butt, Shotgun"]],
            specialisms: ["staunch"]
        },
        {
            name: "Sanctus Sniper",
            limit: 1,
            weapons: [["Sanctus Sniper rifle, Fists"]],
            specialisms: ["marksman"]
        },
        {
            name: "Sanctus Talon",
            limit: 1,
            weapons: [["Sanctus bio-dagger"]],
            specialisms: ["combat", "scout"]
        }
    ]
};

export default killTeam;

import styled from "styled-components";
import theme from "./theme";

const StyledOperative = styled.div`
    background: ${theme.colors.offWhite};
    margin-top: 1em;

    .spec-ops--hidden {
        display: none;
    }

    .header {
        background: ${theme.colors.black};
        color: ${theme.colors.white};
        padding: 0.5em 1rem 0.4em;
        font-family: ${theme.fonts.header};
        text-transform: uppercase;
        font-size: 0.9em;
    }

    .main {
        font-size: 0.8em;
        border-left: solid 1px ${theme.colors.grey};
        border-right: solid 1px ${theme.colors.grey};
    }
    .row {
        padding: 0.4em 1rem;
    }
    label {
        display: block;
        color: ${theme.colors.orange};
        font-family: ${theme.fonts.header};
        text-transform: uppercase;
        font-size: 0.9em;
        letter-spacing: 0.03em;
    }

    .weapons {
        .weapon-single {
            font-size: 1.1em;
            background: none;
            border: none;
            width: 100%;
            padding: 0.4em;
            border-top: solid 1px ${theme.colors.orange};
            color: ${theme.colors.black};
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;

            &.weapon--noalt {
                border-top: 0;
            }
        }

        &.weapons--kill-team {
            background: ${theme.colors.altOffWhite};
        }

        select.weapon-single {
            padding-left: 0;
        }
    }

    .weapon-select__wrap {
        position: relative;

        &::after {
            position: absolute;
            right: 0;
            top: 0.5em;
            content: "▾";
            font-weight: 700;
            font-size: 1.2em;
            // content: "﹀";
            // content: "︾";
        }

        &:first-child {
            select {
                border-top: none;
            }
        }

        select:focus {
            outline: none;
        }
    }

    .footer {
        background: ${theme.colors.grey};
        text-align: right;
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            border: none;
            background: transparent;
            font-size: 0.8em;
            text-transform: uppercase;
            padding: 0.4em 0 0.4em 1em;
            font-family: ${theme.fonts.body};
            color: ${theme.colors.darkGrey};
            font-weight: 700;

            &.footer__button-kt {
                padding-left: 0;
                display: flex;
                align-items: center;

                &::before {
                    font-size: 1.4em;
                    content: "+";
                    padding-right: 0.2em;
                    color: ${theme.colors.orange};
                }
            }

            &.footer__button-kt--remove {
                &::before {
                    content: "-";
                }
            }
        }
    }

    select {
        width: 100%;
        background: none;
        border: none;
        font-family: ${theme.fonts.body};
        // letter-spacing: 0.01em;
        font-size: 1.1em;
        padding-right: 0.3em;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        &:focus {
            outline: none;
        }
    }

    .equipment__select-wrap {
        position: relative;

        &::after {
            position: absolute;
            right: 0;
            top: 0.9em;
            content: "▾";
            font-weight: 700;
            font-size: 1.2em;
            // content: "﹀";
            // content: "︾";
        }
    }

    .row--list {
        select {
            margin-top: 0.5em;
            padding: 0.3em 0;
            border-top: solid 2px ${theme.colors.orange};
            font-size: 1.1em;
            line-height: 1.6em;
            font-weight: 700;
            letter-spacing: 0;
            color: ${theme.colors.black};
        }

        .list-items > * {
            display: flex;
            justify-content: space-between;
            padding: 0.25em 0;
            font-size: 1.1em;

            border-top: solid 1px ${theme.colors.grey};

            &:first-child {
                border-top: none;
            }
        }

        button {
            background: none;
            border: solid 2px #888;
            color: #888;
            font-family: ${theme.fonts.header};
            text-transform: uppercase;

            &:hover {
                border-color: ${theme.colors.darkGrey};
                color: ${theme.colors.darkGrey};
            }
        }
    }
`;

export default StyledOperative;

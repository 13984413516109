import { PHOBOS_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const PhobosController = {
    id: PHOBOS_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length < 6) {
            passed = false;
            messages.push("Must include 6 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Infiltrator Sergeant" ||
                    operative.unit === "Incursor Sergeant" ||
                    operative.unit === "Reiver Sergeant"
                );
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Leader");
        }

        // only 1 of these units
        [
            "Infiltrator Commsman",
            "Infiltrator Helix Adept",
            "Infiltrator Saboteur",
            "Infiltrator Veteran",
            "Infiltrator Voxbreaker",
            "Incursor Marksman",
            "Incursor Mineflayer"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}.`);
            }
        });

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default PhobosController;

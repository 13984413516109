import { EXACTION_SQUAD } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const ExactionSquadController = {
    id: EXACTION_SQUAD,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length < 11) {
            passed = false;
            messages.push("Must include 11 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Arbites Proctor-exactant";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Arbites Proctor-exactant");
        }

        // only 1 of these units
        [
            "Arbites Castigator",
            "Arbites Chirurgant",
            "Arbites Leashmaster",
            "Arbites Malocator",
            "Arbites Marksman",
            "Arbites Revelatum",
            "Arbites Vox-signifier",
            "R-VR Cyber-mastiff"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        // max 2 darklight weapons
        if (
            operativesInKillTeam.filter((operative) => {
                return "Arbites Gunner" === operative.unit;
            }).length > 2
        ) {
            passed = false;
            messages.push(`Maximum of 2 Arbites Gunners`);
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return "Arbites Subductor" === operative.unit;
            }).length > 4
        ) {
            passed = false;
            messages.push(`Maximum of 4 Arbites Subductors`);
        }

        return {
            passed,
            messages
        };
    },

    // randomiseTeamName: (names) => {
    //     const first = names[0][Math.floor(Math.random() * names[0].length)];
    //     const second = names[1][Math.floor(Math.random() * names[1].length)];

    //     return `${first} ${second}`;
    // },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default ExactionSquadController;

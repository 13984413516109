// names
// backgrounds etc...
import { HIEROTEK_CIRCLE_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: HIEROTEK_CIRCLE_ID,
    moniker: "Heirotek Circle",
    restrictions: restrictions,
    battleHonours: [
        "Controlling",
        "Ingenious",
        "Collector",
        "Enduring",
        "Revenant",
        "Unrelenting"
    ],
    killTeamNames: [
        "Cryptoretinue of [Name of Cryptek]",
        "Cryptocoterie of [Name of Cryptek]",
        "Circle of [Name of Cryptek]",
        "Court of [Name of Cryptek]",
        "Retinue of [Name of Cryptek]",
        "Rivalpurge Phalanx of [Name of Cryptek]",
        "[Name of Cryptek]'s Acquisition Thralls",
        "[Name of Cryptek]'s Select",
        "[Name of Cryptek]'s Artefact Collection Phalanx",
        "[Name of Cryptek]'s Assassination Phalanx",
        "[Name of Cryptek]'s Chosen Circle",
        "[Name of Cryptek]'s Glorifiers"
    ],
    operativeNames: [
        [
            "Ankhep",
            "Tamonhak",
            "Eknotath",
            "Khotek",
            "Thanatar",
            "Amhut",
            "Karok",
            "Zan-Tep",
            "Unakh",
            "Khophec",
            "Tzantath",
            "Tahar",
            "Imonekh",
            "Trazat",
            "Xeoptar",
            "Hamanet",
            "Oberek",
            "Banatur",
            "Ahmnok",
            "Kophesh",
            "Teznet",
            "Odakhar",
            "Kythok",
            "Eknothet",
            "Anubitar",
            "Anokh",
            "Thotep",
            "Anhutek",
            "Ikhatar",
            "Thotmek",
            "Ramatek",
            "Homanat",
            "Taknophet",
            "Makhret",
            "Zanatek",
            ""
        ],
        [
            "the Unliving",
            "the Gilded",
            "the Great",
            "the Exalted",
            "the Loyal",
            "the Cruel",
            "the Storm's Eye",
            "the Bloodied",
            "the Mighty",
            "the Relentless",
            "the Unforgiving",
            "the Merciless",
            "the Glorious",
            "the Devoted",
            "the Victorious",
            "the Destroyer",
            "the Shrouded",
            "the Flenser",
            "the Unstoppable",
            "the Beheader",
            "the Impaler",
            "the Magnificent",
            "the Illuminated",
            "the Executioner",
            "the Phaeron's Hand",
            "of the Eternal Gaze",
            "the Gatekeeper",
            "the All-seeing",
            "the All-knowing",
            "the Starwalker",
            "the Starkiller",
            "the Lifetaker",
            "the Godbreaker",
            "the Torchbearer",
            "the Stormbringer",
            "the Colossus"
        ]
    ],
    history: [
        "Domination Force",
        "The Phaeron's Chosen",
        "Harbingers",
        "Antiquaries",
        "Unscrupulous Assassins",
        "Underhanded Schemers"
    ],
    baseOfOperations: [
        "Between the Dimensions",
        "Great Pyramid",
        "Ruined Enemy Temple",
        "Personal Collection",
        "Beneath the Surface",
        "Fast Starship"
    ],
    squadQuirk: [
        "Wreathed in Power",
        "Deadly Automata",
        "Dark Sentience",
        "Ether-flux",
        "Arrogant Hunters",
        "Deranged"
    ],
    strategicAssets: [
        "Repair Scarabs",
        "Tesseract Cube",
        "Dimensional Gateway"
    ],
    equipment: [
        {
            name: "Phase Oculars",
            cost: 2
        },
        {
            name: "Hyperphase Blade",
            cost: 2
        },
        {
            name: "Tesla Weave",
            cost: 2
        },
        {
            name: "Arcshock Projector",
            cost: 1
        },
        {
            name: "Phase Shifter",
            cost: 3
        },
        {
            name: "Devourer Nanoscarabs",
            cost: 3
        },
        {
            name: "Quantum Reanimytes",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Plasma Crystal",
            cost: 2
        },
        {
            name: "Resurrection Beam",
            cost: 2
        },
        {
            name: "Tech-tendrils",
            cost: 3
        },
        {
            name: "Rapid Reanimatrix",
            cost: 1
        },
        {
            name: "Temporic Orb",
            cost: 3
        },
        {
            name: "Hunter Scarab",
            cost: 3
        }
    ],
    specOps: ["Excavate Nexus", "Field Study"]
};

export default info;

import React, { useContext } from "react";
import StyledFactionValidation from "../styles/StyledFactionValidation";
import AppStateContext from "../context";
import { getFactionData } from "../controllers/DataController";

const FactionValidation = (props) => {
    const { appState } = useContext(AppStateContext);
    const list = appState.lists.byId[appState.activeList];

    const factionData = getFactionData(list.faction);

    const validationMessages =
        factionData.controller && factionData.controller.validator
            ? factionData.controller.validator(list)
            : null;

    return (
        <StyledFactionValidation>
            <div className="title">
                {factionData.info.moniker} Kill Team{" "}
                {list.moniker && <span>{list.moniker}</span>}
            </div>
            {/* <div className="info">{factionData.info.restrictions()}</div> */}

            {validationMessages && !validationMessages.passed && (
                <div className="validation-messages">
                    <span>NOT VALID:</span>
                    <ul>
                        {validationMessages.messages.map((message, i) => (
                            <li
                                className="validation-message"
                                key={`validationMessage-${i}`}
                            >
                                {message}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </StyledFactionValidation>
    );
};

export default FactionValidation;

import React, { useContext } from "react";
import AppStateContext from "../context";
import StyledSpecOps from "../styles/StyledSpecOps";
import StyledFactionValidation from "../styles/StyledFactionValidation";
import {
    getFactionData,
    getStrategicAssetOptions,
    getStashOptions,
    getStashItemData,
    getSpecOpsOptions
} from "../controllers/DataController";
import SelectText from "./SelectText";
import RandomiseIcon from "./icons/RandomiseIcon";

const PageSpecOps = (props) => {
    const { appState, dispatch } = useContext(AppStateContext);
    const list = appState.lists.byId[appState.activeList];
    const factionData = getFactionData(list.faction);

    const factionEquipment = getStashOptions(factionData.id).filter(
        (stash) => stash.type === "equipment"
    );

    const factionRareEquipment = getStashOptions(factionData.id).filter(
        (stash) => stash.type === "rareEquipment"
    );

    const update = (value, key) => {
        /*
        moniker: "",
        baseOfOperations: "",
        history: "",
        squadQuirk: "",
        strategicAssets: [],
        stash: [],
        specOpsLog: [],
        requisitionPoints: 0,
        assetCapacity: 0,
        notes: ""
        */

        dispatch({
            type: "UPDATE_SPEC_OPS",
            payload: {
                key: key,
                value: value
            }
        });
    };

    return (
        <StyledSpecOps>
            <StyledFactionValidation>
                <div className="title">
                    {factionData.info.moniker} Kill Team{" "}
                    {list.moniker && <span>{list.moniker}</span>}
                </div>
            </StyledFactionValidation>
            <div>
                <div className="row row--inline">
                    <label>Kill Team Name:</label>

                    <div className="team-name">
                        <input
                            placeholder="Kill Team name..."
                            value={list.moniker}
                            onChange={(event) => {
                                update(event.target.value, "moniker");
                            }}
                        />

                        {factionData.controller.randomiseTeamName && (
                            <button
                                onClick={(event) => {
                                    update(
                                        factionData.controller.randomiseTeamName(
                                            factionData.info.killTeamNames
                                        ),
                                        "moniker"
                                    );
                                }}
                                className="team-name__icon"
                            >
                                <RandomiseIcon />
                            </button>
                        )}
                    </div>
                </div>
                <div className="row row--inline">
                    <label>Base of Operations:</label>

                    <SelectText
                        metric={"baseOfOperations"}
                        text={"Base of Operations"}
                    />
                </div>

                <div className="row row--inline">
                    <label>History:</label>

                    <SelectText
                        metric={"history"}
                        text={"History"}
                        odd={true}
                    />
                </div>

                <div className="row row--inline">
                    <label>Squad Quirk:</label>

                    <SelectText metric={"squadQuirk"} text={"Squad Quirk"} />
                </div>

                <div className="row row--list">
                    <label>Strategic Assets:</label>
                    <div className="list-items">
                        {list.strategicAssets.map((item, index) => {
                            return (
                                <div key={index}>
                                    <span>{item}</span>
                                    <button
                                        onClick={() => {
                                            const filteredArr =
                                                list.strategicAssets;

                                            for (
                                                let index = 0;
                                                index < filteredArr.length;
                                                index++
                                            ) {
                                                const element =
                                                    filteredArr[index];

                                                if (element === item) {
                                                    filteredArr.splice(
                                                        index,
                                                        1
                                                    );

                                                    break;
                                                }
                                            }

                                            update(
                                                filteredArr,
                                                "strategicAssets"
                                            );
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            );
                        })}
                    </div>

                    <div className="row__select-wrap">
                        <select
                            value="Add Strategic Asset..."
                            onChange={(event) => {
                                const newAssets = list.strategicAssets;

                                if (newAssets.includes(event.target.value)) {
                                    return;
                                }

                                newAssets.push(event.target.value);

                                dispatch({
                                    type: "UPDATE_SPEC_OPS",
                                    payload: {
                                        key: "strategicAssets",
                                        value: newAssets
                                    }
                                });
                            }}
                        >
                            <option default>Add Strategic Asset...</option>
                            {getStrategicAssetOptions(factionData.id).map(
                                (asset) => {
                                    return <option key={asset}>{asset}</option>;
                                }
                            )}
                        </select>
                    </div>
                </div>

                <div className="row row--list">
                    <label>Stash:</label>
                    <div className="list-items">
                        {list.stash.map((item, index) => {
                            const stashItemObj = getStashItemData(
                                item,
                                factionData.id
                            );

                            return (
                                <div key={index}>
                                    <span>
                                        {stashItemObj.name}{" "}
                                        <strong>[{stashItemObj.cost}EP]</strong>
                                    </span>
                                    <button
                                        onClick={() => {
                                            const filteredArr = list.stash;

                                            for (
                                                let index = 0;
                                                index < filteredArr.length;
                                                index++
                                            ) {
                                                const element =
                                                    filteredArr[index];

                                                if (element === item) {
                                                    filteredArr.splice(
                                                        index,
                                                        1
                                                    );

                                                    break;
                                                }
                                            }

                                            update(filteredArr, "stash");
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            );
                        })}
                    </div>

                    <div className="row__select-wrap">
                        <select
                            value="Add equipment..."
                            onChange={(event) => {
                                const newAssets = list.stash;
                                newAssets.push(event.target.value);

                                dispatch({
                                    type: "UPDATE_SPEC_OPS",
                                    payload: {
                                        key: "stash",
                                        value: newAssets
                                    }
                                });
                            }}
                        >
                            <option default>Add equipment...</option>
                            {/* {getStashOptions(factionData.id).map((stash) => {
                                return (
                                    <option value={stash.name} key={stash.name}>
                                        {stash.name} [{stash.cost}EP]
                                    </option>
                                );
                            })} */}

                            {factionEquipment.length > 0 && (
                                <>
                                    <option disabled>-- Equipment --</option>

                                    {factionEquipment.map((stash) => {
                                        return (
                                            <option
                                                value={stash.name}
                                                key={stash.name}
                                            >
                                                {stash.name} [{stash.cost}
                                                EP]
                                            </option>
                                        );
                                    })}
                                </>
                            )}

                            {factionRareEquipment.length > 0 && (
                                <>
                                    <option disabled>
                                        -- Rare Equipment --
                                    </option>

                                    {factionRareEquipment.map((stash) => {
                                        return (
                                            <option
                                                value={stash.name}
                                                key={stash.name}
                                            >
                                                {stash.name} [{stash.cost}
                                                EP]
                                            </option>
                                        );
                                    })}
                                </>
                            )}
                        </select>
                    </div>
                </div>

                <div className="row row--list">
                    <label>Spec Ops Log:</label>
                    <div className="list-items">
                        {list.specOpsLog.map((item, index) => {
                            return (
                                <div key={index}>
                                    <span>{item}</span>
                                    <button
                                        onClick={() => {
                                            const filteredArr = list.specOpsLog;

                                            for (
                                                let index = 0;
                                                index < filteredArr.length;
                                                index++
                                            ) {
                                                const element =
                                                    filteredArr[index];

                                                if (element === item) {
                                                    filteredArr.splice(
                                                        index,
                                                        1
                                                    );

                                                    break;
                                                }
                                            }

                                            update(filteredArr, "specOpsLog");
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            );
                        })}
                    </div>

                    <div className="row__select-wrap">
                        <select
                            value="Add new item..."
                            onChange={(event) => {
                                const newAssets = list.specOpsLog;
                                newAssets.push(event.target.value);

                                dispatch({
                                    type: "UPDATE_SPEC_OPS",
                                    payload: {
                                        key: "specOpsLog",
                                        value: newAssets
                                    }
                                });
                            }}
                        >
                            <option default>Add new item...</option>
                            {getSpecOpsOptions(factionData.id).map((asset) => {
                                return <option key={asset}>{asset}</option>;
                            })}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="counters">
                        <div className="counter">
                            <label>Requisition Points:</label>
                            <div>
                                <button
                                    onClick={() => {
                                        update(
                                            list.requisitionPoints === 0
                                                ? 0
                                                : list.requisitionPoints - 1,
                                            "requisitionPoints"
                                        );
                                    }}
                                >
                                    -
                                </button>
                                <input
                                    disabled
                                    value={list.requisitionPoints}
                                    readOnly
                                    type="number"
                                />
                                <button
                                    onClick={() => {
                                        update(
                                            list.requisitionPoints + 1,
                                            "requisitionPoints"
                                        );
                                    }}
                                >
                                    +
                                </button>
                            </div>
                        </div>

                        <div className="counter">
                            <label>Asset Capacity:</label>
                            <div>
                                <button
                                    onClick={() => {
                                        update(
                                            list.assetCapacity === 0
                                                ? 0
                                                : list.assetCapacity - 1,
                                            "assetCapacity"
                                        );
                                    }}
                                >
                                    -
                                </button>
                                <input
                                    disabled
                                    value={list.assetCapacity}
                                    readOnly
                                    type="number"
                                />
                                <button
                                    onClick={() => {
                                        update(
                                            list.assetCapacity + 1,
                                            "assetCapacity"
                                        );
                                    }}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <label>Notes:</label>
                    <textarea
                        onChange={(event) => {
                            update(event.target.value, "notes");
                        }}
                        placeholder="Any additional notes..."
                        value={list.notes}
                    ></textarea>
                </div>
            </div>
        </StyledSpecOps>
    );
};

export default PageSpecOps;

import React, { useReducer, useEffect } from "react";
import AppStateContext from "../context";
import reducer from "../reducer";
import { setStoredData } from "../controllers/UserDataController";
import nullState from "../nulls/nullState";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import StyledAppWrap from "../styles/StyledAppWrap";
import Header from "./Header";
import theme from "../styles/theme";

// pages
import {
    LANDING_PAGE,
    ROSTER_PAGE,
    KILL_TEAM_PAGE,
    SPEC_OPS_PAGE,
    EXPORT_PAGE
} from "../constants/pages";
import PageLanding from "./PageLanding";
import PageRoster from "./PageRoster";
import PageKillTeam from "./PageKillTeam";
import PageSpecOps from "./PageSpecOps";
import PageExport from "./PageExport";

const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
        font-size: inherit;
        font-weight: normal;
    }

    *, *:before, *:after {
        box-sizing: border-box;
    }

    button {
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    input {
        &:focus,
        &:active {
            outline: none;
        }
    }
`;

const App = () => {
    const [appState, dispatch] = useReducer(reducer, nullState);

    useEffect(() => {
        dispatch({
            type: "UPDATE_INITIAL_STATE"
        });

        return;
    }, []);

    useEffect(() => {
        setStoredData(appState);

        return;
    }, [appState]);

    return (
        <ThemeProvider theme={theme}>
            <AppStateContext.Provider value={{ appState, dispatch }}>
                <StyledAppWrap>
                    <Header />
                    {(() => {
                        switch (appState.activePage) {
                            case LANDING_PAGE:
                                return <PageLanding />;

                            case ROSTER_PAGE:
                                return <PageRoster />;

                            case KILL_TEAM_PAGE:
                                return <PageKillTeam />;

                            case SPEC_OPS_PAGE:
                                return <PageSpecOps />;

                            case EXPORT_PAGE:
                                return <PageExport />;

                            // case TAC_OPS_PAGE:
                            //     return <PageTacOps />;

                            default:
                                return null;
                        }
                    })()}
                </StyledAppWrap>
                <GlobalStyle />
            </AppStateContext.Provider>
        </ThemeProvider>
    );
};

export default App;

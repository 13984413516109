import React from "react";

const RandomiseIcon = props => (
    <svg
        fill={props.fill ? props.fill : "#000"}
        id="ktcr_randomise"
        viewBox="0 0 980 873.66"
    >
        <path
            d="M0,573.46c10.5,0.04,20.99,0.12,31.49,0.12c49.99,0.01,99.98-0.01,149.97,0.05c3.17,0,5.5-0.66,7.8-3.18
            C314.51,433.24,439.86,296.1,565.12,158.9c2.34-2.57,4.69-3.53,8.15-3.52c72.32,0.1,144.64,0.08,216.96,0.08c1.99,0,3.98,0,6.64,0
            c0-51.83,0-103.49,0-155.14c0.36-0.11,0.72-0.22,1.08-0.32c6.39,8.21,12.79,16.43,19.18,24.64
            c53.62,68.88,107.24,137.75,160.87,206.62c0.6,0.78,1.32,1.47,1.99,2.2c0,0.33,0,0.67,0,1c-9.47,10.49-19.02,20.9-28.39,31.47
            c-50.2,56.59-100.35,113.23-150.53,169.84c-0.99,1.12-2.04,2.19-3.73,4c0-47.06,0-93.34,0-140.13c-5.13-0.31-9.71-0.68-14.29-0.84
            c-54.12-1.95-108.23-3.91-162.36-5.65c-2-0.06-4.62,1.58-6.02,3.22c-29.94,34.91-59.74,69.93-89.57,104.93
            c-86.99,102.09-173.97,204.19-261.06,306.19c-1.47,1.73-4.37,3.17-6.61,3.17c-84.65,0.16-169.31,0.14-253.96,0.12
            c-1.15,0-2.31-0.21-3.46-0.32C0,664.79,0,619.13,0,573.46z"
        />
        <path
            d="M980,659.46c-1.56,1.42-3.26,2.72-4.68,4.27c-62.25,68.41-124.47,136.83-186.7,205.26c-1.22,1.34-2.48,2.64-4.38,4.67
            c0-52.37,0-103.89,0-156.28c-2.57,0-4.64,0-6.71,0c-70.16,0-140.33-0.03-210.49,0.08c-3.67,0.01-6.09-1.06-8.53-3.81
            c-34.93-39.41-69.98-78.7-105-118.03c-0.66-0.74-1.28-1.51-2.31-2.73c42.6-38.82,85.14-77.59,127.41-116.1
            c3.48,3.18,6.95,6,10.01,9.2c20.6,21.58,41.06,43.28,61.73,64.79c1.63,1.69,4.43,3.19,6.69,3.2c43,0.19,85.99,0.14,128.99,0.12
            c1.3,0,2.59-0.21,4.38-0.36c0-25.68,0-51.1,0-78.15c63.91,61.64,126.76,122.25,189.6,182.86C980,658.79,980,659.13,980,659.46z"
        />
        <path
            d="M0,155.46c62.63,0.03,125.27,0.05,187.9,0.08c27.49,0.01,54.97-0.05,82.46,0.1c2.06,0.01,4.74,0.82,6.06,2.25
            c35.56,38.35,70.98,76.83,106.42,115.3c0.22,0.24,0.36,0.56,0.94,1.46c-40.65,34.2-81.37,68.45-122.42,102.99
            c-1.91-2.02-3.72-3.91-5.51-5.83c-15.81-16.92-31.66-33.79-47.36-50.82c-2.35-2.55-4.68-3.59-8.16-3.56
            c-65.13,0.51-130.26,0.91-195.38,1.31c-1.65,0.01-3.31-0.18-4.96-0.27C0,264.13,0,209.79,0,155.46z"
        />
    </svg>
);

export default RandomiseIcon;

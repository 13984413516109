// raw KT data
import { ECCLESIARCHY_ID } from "../../../constants/factions";

const killTeam = {
    id: ECCLESIARCHY_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Battle Sister",
            units: [
                "Battle Sister Superior",
                "Battle Sister Warrior",
                "Battle Sister Icon Bearer",
                "Battle Sister Gunner",
                "Battle Sister Heavy Gunner"
            ]
        },
        {
            name: "Repentia",
            units: ["Repentia Superior", "Sister Repentia"]
        },
        {
            name: "Arco-flagellant",
            units: ["Arco-flagellant"]
        }
    ],
    operatives: [
        {
            name: "Battle Sister Superior",
            limit: 1,
            leader: true,
            weapons: [
                ["Chainsword", "Gun butt", "Power maul", "Power weapon"],
                [
                    "Bolt pistol",
                    "Boltgun",
                    "Combi-melta",
                    "Combi-plasma",
                    "Condemnor boltgun",
                    "Inferno pistol",
                    "Ministorum combi-flamer",
                    "Ministorum hand flamer",
                    "Plasma pistol"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Battle Sister Warrior",
            limit: 5,
            weapons: [["Boltgun, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Battle Sister Icon Bearer",
            limit: 5,
            weapons: [["Boltgun, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Battle Sister Gunner",
            limit: 1,
            weapons: [
                ["Gun butt"],
                ["Meltagun", "Ministorum flamer", "Storm bolter"]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Battle Sister Heavy Gunner",
            limit: 1,
            weapons: [
                ["Gun butt"],
                ["Heavy bolter", "Ministorum heavy flamer"]
            ],
            specialisms: ["staunch", "marksman"]
        },
        //
        {
            name: "Repentia Superior",
            limit: 1,
            leader: true,
            weapons: [["Neural whips, Neural whips"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Sister Repentia",
            limit: 5,
            weapons: [["Penitent eviscerator"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Arco-flagellant",
            limit: 5,
            weapons: [["Arco-flails"]],
            specialisms: ["combat", "scout"]
        }
    ]
};

export default killTeam;

// names
// backgrounds etc...
import { GELLERPOX_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: GELLERPOX_ID,
    moniker: "Gellerpox Infected",
    restrictions: restrictions,
    battleHonours: [
        "Repulsive Resilience",
        "Obstinate Gifts",
        "Twisted Talon",
        "Fly Swarm",
        "Repugnant Stench",
        "Freakish Follower"
    ],
    // killTeamNames: [
    //     [
    //
    //     ],
    //     [
    //
    //     ]
    // ],
    operativeNames: [
        [
            "Blorgh",
            "Gulvog",
            "Hurrgh",
            "Vomex",
            "Sloppit",
            "Rancyk",
            "Foulefang",
            "Rotgut",
            "Murrgh",
            "Festur",
            "Glorp",
            "Bolhargh",
            "Pestrus",
            "Gruu",
            "Retchus",
            "Sputus",
            "Skritter",
            "Struggh",
            "Glurk",
            "Molg",
            "Thraxxus",
            "Byle",
            "Drolgh",
            "Ugholg",
            "Glottus",
            "Vulgh",
            "Unctum",
            "Wythergutt",
            "Olghug",
            "Vlorg",
            "Festrum",
            "Fylthing",
            "Sputtergrug",
            "Ghweeze",
            "Rancus",
            "Unglor"
        ],
        [
            "Sputterlung",
            "The Seep",
            "Foulerott",
            "Bubotus",
            "The Lurker",
            "Fylthspew",
            "The Vile",
            "Glitchbringer",
            "Repugnos",
            "Horrogh",
            "Foulspew",
            "Statikk",
            "Darkynskreen",
            "The Biter",
            "Mouldguts",
            "The Rotting",
            "The Bloated",
            "The Sevenblessed",
            "Eurgh",
            "Vomithrax",
            "The Foetid",
            "The Clammy",
            "Dankspew",
            "Rotgums",
            "Manchewer",
            "The Reek",
            "The Spike",
            "Nausus",
            "Noxus",
            "The Consumptor",
            "Sparktooth",
            "Burntwyre",
            "The Unclean",
            "The Foulespawned",
            "Shamblerot",
            "Revultus"
        ]
    ],
    history: [
        "Vulgrar's Crew",
        "Care Corrupted",
        "From Dark Dreams",
        "The Neglected",
        "Sudden Outbreak",
        "From the Pits"
    ],
    baseOfOperations: [
        "Stolen Shuttle",
        "Plague Ship",
        "It Came From the Vents",
        "From the Beyond",
        "Festerhole",
        "Tainted Shrine"
    ],
    squadQuirk: [
        "Gruesome Glee",
        "Nightmare Shroud",
        "Prey Upon The Weak",
        "Tech-hunger",
        "Foul Stench",
        "Droning Chant"
    ],
    strategicAssets: [
        "Vermin Nest",
        "Possessed Cogitator",
        "Pulsating Plaguenode"
    ],
    equipment: [
        {
            name: "Cursemite (first)",
            cost: 3
        },
        {
            name: "Cursemite",
            cost: 2
        },
        {
            name: "Eyesting Swarm (first)",
            cost: 3
        },
        {
            name: "Eyesting Swarm",
            cost: 2
        },
        {
            name: "Sludge-grub (first)",
            cost: 3
        },
        {
            name: "Sludge-grub",
            cost: 2
        }
    ],
    rareEquipment: [
        {
            name: "Vermin Infestation",
            cost: 1
        },
        {
            name: "Toxic Pus",
            cost: 1
        },
        {
            name: "Unreal Aura (NIGHTMARE HULK)",
            cost: 2
        },
        {
            name: "Unreal Aura",
            cost: 1
        },
        {
            name: "Scrapcode Emanator",
            cost: 2
        },
        {
            name: "Flesh Hunger (NIGHTMARE HULK)",
            cost: 3
        },
        {
            name: "Flesh Hunger",
            cost: 1
        },
        {
            name: "Mutoid Pheromones",
            cost: 1
        }
    ],
    specOps: ["Spread the Pox", "Pestilence Ex Machina"]
};

export default info;

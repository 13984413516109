// raw KT data
import { FELLGORE_RAVAGERS } from "../../../constants/factions";

const killTeam = {
    id: FELLGORE_RAVAGERS,
    fireTeams: false,
    operatives: [
        {
            name: "Fellgor Ironhorn",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Corrupter pistol, Corrupted chainsword",
                    "Plasma pistol, Bludgeon"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Fellgor Deathknell",
            limit: 1,
            weapons: [["Autopistol, bludgeon"]],
            specialisms: ["staunch"]
        },
        {
            name: "Fellgor Fluxbray",
            limit: 1,
            weapons: [["Triple cleavers"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Fellgor Gnarlscar",
            limit: 1,
            weapons: [["Autopistol, bionic fist"]],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Fellgor Gorehorn",
            limit: 1,
            weapons: [["Autopistol, skullcleaver"]],
            specialisms: ["combat"]
        },
        {
            name: "Fellgor Herd-goad",
            limit: 1,
            weapons: [["Autopistol, crackthorn whip"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Fellgor Mangler",
            limit: 1,
            weapons: [["Vicious claws"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Fellgor Shaman",
            limit: 1,
            weapons: [["Autopistol, braystave"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Fellgor Toxhorn",
            limit: 1,
            weapons: [["Autopistol, cleaver"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Fellgor Vandal",
            limit: 1,
            weapons: [["Mancrusher"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Fellgor Warrior",
            weapons: [["Autopistol, bludgeon", "Autopistol, cleaver"]],
            specialisms: ["combat", "scout"]
        }
    ]
};

export default killTeam;

// names
// backgrounds etc...
import { WARPCOVEN_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: WARPCOVEN_ID,
    moniker: "Warpcoven",
    restrictions: restrictions,
    battleHonours: {
        sorcerer: ["Boon", "Studious", "Rubric Affinity"],
        rubricMarine: ["Warded", "Unwavering", "Automata"],
        tzaangor: ["Avian Mobility", "Mutation", "Savage"]
    },
    killTeamNames: [
        ["Infernal", "Sckolasticar", "Coils", "Serpents", "Coven", "Ghosts"],
        ["Sons", "Infernum", "Magicka", "of Magnus", "of Fire", "Corvidiads"]
    ],
    operativeNames: [
        [
            "Phosis",
            "Amonhep",
            "Basteq",
            "Apophitar",
            "Thotek",
            "Kalophis",
            "Phael",
            "Imhoden",
            "Ankhu",
            "Naratt",
            "Kahotep",
            "Khadeth",
            "Revuel",
            "Tolbek",
            "Onoris",
            "Gilameht",
            "Amon",
            "Sanakht",
            "Sektoth",
            "Az'mekh",
            "Zah'mat",
            "Ultak'h",
            "Daedophet",
            "Azhek",
            "Azhtar",
            "Iskandar",
            "Mhotep",
            "Hedara",
            "Amarhotep",
            "Hakor",
            "Myrakh",
            "Aarthrat",
            "Thotmas",
            "Merhet",
            "Kasaikrah",
            "Kahzmut"
        ],
        [
            "T'kor",
            "Basth",
            "Takar",
            "Kallisar",
            "Rhan",
            "P'Tra",
            "Manahkmor",
            "Shen",
            "Apophontar",
            "H'Kett",
            "Maat",
            "Anen",
            "Khayon",
            "Manutec",
            "Qu'rastis",
            "Uthizzar",
            "Toron",
            "Daast",
            "Sum",
            "Qezremah",
            "Arvida",
            "Temekh",
            "Aktera",
            "Bekti",
            "Metenh",
            "Thothmet",
            "Pashet",
            "Kashtoh",
            "Harpakrut",
            "Amen'ket",
            "Ammon",
            "Z'kar",
            "Kh'nem",
            "As'rahk",
            "Hata'kah",
            "Takham"
        ]
    ],
    history: [
        "Heralds of Madness",
        "Seekers After Sorcery",
        "Arch-coven",
        "Relentless Destroyers",
        "Warriors Out of Time",
        "Daemonologists"
    ],
    baseOfOperations: [
        "Cursed Moon",
        "Warp Gate",
        "Forbidden Library",
        "Corrupted Asylum",
        "Empyric Sub-realm",
        "Ancient Ruin"
    ],
    squadQuirk: [
        "Aetheric Coronae",
        "One Will",
        "Single-minded",
        "Ancient Dust",
        "Superior",
        "Ghosts of the Warp"
    ],
    strategicAssets: ["Rebinding Altar", "Divine Orrery", "Herdstone"],
    equipment: [
        {
            name: "Gargoyle Bayonet",
            cost: 2
        },
        {
            name: "High Capacity Magazine (Autopistol)",
            cost: 1
        },
        {
            name: "High Capacity Magazine",
            cost: 3
        },
        {
            name: "Occult Talisman",
            cost: 2
        },
        {
            name: "Sorcerous Scroll",
            cost: 4
        },
        {
            name: "Arcane Robes",
            cost: 2
        },
        {
            name: "Ensorcelled Rounds",
            cost: 2
        },
        {
            name: "Gilded Horns",
            cost: 1
        }
    ],
    rareEquipment: [
        {
            name: "Oculus (Autopistol)",
            cost: 1
        },
        {
            name: "Oculus (Inferno Pistol)",
            cost: 2
        },
        {
            name: "Animate Avians",
            cost: 3
        },
        {
            name: "Witchfire Mantle",
            cost: 3
        },
        {
            name: "Aeonglass",
            cost: 3
        },
        {
            name: "Fluxhelm",
            cost: 3
        },
        {
            name: "Changeblade",
            cost: 3
        }
    ],
    specOps: ["Labyrinthine Plans", "Arcane Artefact"]
};

export default info;

// names
// backgrounds etc...
import { WYRMBLADE_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: WYRMBLADE_ID,
    moniker: "Wyrmblade",
    restrictions: restrictions,
    battleHonours: [
        "Scurry",
        "Elusive",
        "Mercurial",
        "Prowler",
        "Stalker",
        "Clandestine"
    ],
    killTeamNames: [
        [
            "The Enlightened",
            "The Patient",
            "Genekin",
            "The Hidden",
            "Proselytisers",
            "Sire's Martyrs'"
        ],
        [
            "Coil of Redemption",
            "Hierobrood",
            "From Below",
            "of the True Faith",
            "Shadowcell",
            "Most Humble"
        ]
    ],
    operativeNames: [
        [
            "Gannar",
            "Dhraz",
            "Yohrick",
            "Kol",
            "Hastun",
            "Sayben",
            "Hollan",
            "Narek",
            "Rauss",
            "Basc",
            "Davon",
            "Zask",
            "Nasser",
            "Seimon",
            "Jacobiah",
            "Skir",
            "Ghaskin",
            "Foyle",
            "Kreen",
            "Judh",
            "Mordecai",
            "Isaak",
            "Michon",
            "Jerec",
            "Aldren",
            "Madrax",
            "Vyrion",
            "Hollun",
            "Steen",
            "Pike",
            "Mallick",
            "Groust",
            "Eldric",
            "Yorl",
            "Xandus",
            "Crasker"
        ],
        [
            "Druchmann",
            "Kreel",
            "Desh",
            "Cavorla",
            "Krauss",
            "Gardlinger",
            "Zorbech",
            "Stennvar",
            "Varnway",
            "Starn",
            "Baumgart",
            "Drisso",
            "Sammer",
            "Helm",
            "Tarnright",
            "Valka",
            "Kelbrech",
            "Kheiser",
            "Madrach",
            "Venner",
            "Novek",
            "Svodnor",
            "Black",
            "Barchus",
            "Matterzhek",
            "Onderghast",
            "Trace",
            "Lhaska",
            "Rezzekh",
            "Carleon",
            "Drevender",
            "Seifer",
            "Vreel",
            "Xyben",
            "Gorl",
            "Arnalt"
        ]
    ],
    history: [
        "Savenger Cell",
        "Tunnel Skulkers",
        "Iconoclast Cell",
        "Snatcher Cell",
        "Snare Setters",
        "Veterans of the First Cycle"
    ],
    baseOfOperations: [
        "Goliath Trucks",
        "Dirtcycles",
        "Abandoned Mine Annex",
        "Tectonic Fragdrill",
        "Beneath the Oppressors' Noses",
        "Macro Sewer Network"
    ],
    squadQuirk: [
        "Deep-dwellers",
        "Rabid Evangelists",
        "Creeping Killers",
        "Blessed",
        "Twisted Physiologies",
        "Inhuman Cruelty"
    ],
    strategicAssets: [
        "Alchemical Cryo-dispensary",
        "Wyrmbore Network",
        "Insurgent Dead Drop"
    ],
    equipment: [
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Blasting Charge",
            cost: 3
        },
        {
            name: "Flash Visor",
            cost: 1
        },
        {
            name: "Spotlight",
            cost: 3
        },
        {
            name: "Cult Talisman",
            cost: 2
        },
        {
            name: "Climbing Equipment",
            cost: 1
        },
        {
            name: "Cult Knife",
            cost: 1
        }
    ],
    rareEquipment: [
        {
            name: "Splinterwyrm Knife",
            cost: 2
        },
        {
            name: "Gene-brew",
            cost: 2
        },
        {
            name: "Ichor-infused Ammunition (Heavy Stubber)",
            cost: 2
        },
        {
            name: "Ichor-infused Ammunition",
            cost: 1
        },
        {
            name: "Shadowleap Cloak",
            cost: 2
        },
        {
            name: "Sire-blessed Icon",
            cost: 3
        },
        {
            name: "Neural Shroud",
            cost: 3
        }
    ],
    specOps: ["Wyrmblade Assassination", "Clandestine Warfare"]
};

export default info;

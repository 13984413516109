// names
// backgrounds etc...
import { KOMMANDO_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: KOMMANDO_ID,
    moniker: "Kommando",
    restrictions: restrictions,
    battleHonours: [
        "Skinna",
        "Irritable",
        "Destructive",
        "Shifty",
        "Thievin' Git",
        "Ambusher"
    ],
    killTeamNames: [
        ["Sneaky", "Bad", "Snazzy", "Red", "Brutal", "Kunnin'"],
        ["Ladz", "Gits", "Jokers", "Mob", "Boyz", "Krew"]
    ],
    operativeNames: [
        [
            "Urzog",
            "Snikrat",
            "Krogskull",
            "Gorgrok",
            "Droknog",
            "Grodd",
            "Zogwarp",
            "Gitzog",
            "Ruggat",
            "Zargruk",
            "Stugbrog",
            "Snarkrat",
            "Zagblag",
            "Bokgrobber",
            "Zarknutz",
            "Dhakadak",
            "Nargrunt",
            "Farksnot",
            "Gharagh",
            "Urlakk",
            "Zogger",
            "Slazbag",
            "Squigface",
            "Ugul",
            "Tuska",
            "Nakboz",
            "Skarzot",
            "Kroggler",
            "Grukk",
            "Fragbad",
            "Traknug",
            "Grizgutz",
            "Shrokbog",
            "Kraznag",
            "Gragnatz",
            "Blokrotz"
        ],
        [
            "Drakka",
            "Grug",
            "Gitstompa",
            "Skullcrusha",
            "Facekrumpa",
            "the 'Ard",
            "Grot Kicker",
            "da Shiv",
            "(no uwa bit)",
            "Blaktoof",
            "da Hammer",
            "Ghazbhag",
            "Steelfang",
            "Daggafist",
            "Squigbiter",
            "da Stompy",
            "da Facegrinda",
            "Loudgob",
            "Facebiter",
            "da Maniak",
            "Steelbootz",
            "Ripblade",
            "'Umiechewa",
            "Ironboot",
            "Flame Spitta",
            "Wurldkilla",
            "Stompkrumpa",
            "Spleenrippa",
            "Bigfangz",
            "Badfang",
            "Snotkicka",
            "Brewguzzla",
            "Bonesplitta",
            "'Eadkrakka",
            "Madeye",
            "Trakeye"
        ]
    ],
    history: [
        "Da Boss' Fist",
        "Slinkerz",
        "From the Wildz",
        "Opportunists",
        "Experimunnt Numba Three",
        "Out for Blood"
    ],
    baseOfOperations: [
        "Submersible",
        "Chinork",
        "Squig-riddled Cave",
        "Looted Bunka",
        "Krawlwayz",
        "Old Fort"
    ],
    squadQuirk: [
        "Stolen Stealth",
        "Skarboyz",
        "Looters",
        "Un-Orky",
        "Bomboyz",
        "Rippaz"
    ],
    strategicAssets: ["Territorial Glyphs", "Loot Hoard", "Explosives Stash"],
    equipment: [
        {
            name: "Choppa",
            cost: 2
        },
        {
            name: "Slugga",
            cost: 2
        },
        {
            name: "Harpoon",
            cost: 3
        },
        {
            name: "Sledgehammer",
            cost: 3
        },
        {
            name: "Smoke Grenade",
            cost: 3
        },
        {
            name: "Stun Grenade",
            cost: 2
        },
        {
            name: "Stikkbomb",
            cost: 2
        },
        {
            name: "Dynamite",
            cost: 4
        },
        {
            name: "Climbing Rope",
            cost: 1
        }
    ],
    rareEquipment: [
        {
            name: "Shiny Slugz (on Slugga)",
            cost: 2
        },
        {
            name: "Shiny Slugz",
            cost: 3
        },
        {
            name: "Mork's Eyeball (on Slugga)",
            cost: 1
        },
        {
            name: "Mork's Eyeball",
            cost: 3
        },
        {
            name: "Devil's Whispa",
            cost: 2
        },
        {
            name: "Skraga's Choppa",
            cost: 2
        },
        {
            name: "Fungal Brew",
            cost: 2
        },
        {
            name: "Klever Kap",
            cost: 2
        }
    ],
    specOps: ["Blitz", "Rampant Destruction"]
};

export default info;

// raw KT data
import { HUNTER_CADRE_ID } from "../../../constants/factions";

const killTeam = {
    id: HUNTER_CADRE_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Fire Warrior",
            units: [
                "Fire Warrior Shas'ui",
                "Fire Warrior Shas'la",
                //
                "MV1 Gun Drone",
                "MV4 Shield Drone",
                "MV7 Marker Drone",
                "MV36 Guardian Drone",
                "DS8 Tactical Support Turret"
            ]
        },
        {
            name: "Pathfinder",
            units: [
                "Pathfinder Shas'ui",
                "Pathfinder Shas'la",
                "Pathfinder Heavy Gunner",
                //
                "MV1 Gun Drone",
                "MV4 Shield Drone",
                "MV7 Marker Drone",
                "MB3 Recon Drone",
                "MV31 Pulse Accelerator Drone",
                "MV33 Grav-inhibitor Drone"
            ]
        },
        {
            name: "Stealth Battlesuit",
            units: [
                "Stealth Battlesuit Shas'vre",
                "Stealth Battlesuit Shas'ui",
                //
                "MV1 Gun Drone",
                "MV4 Shield Drone",
                "MV7 Marker Drone"
            ]
        }
    ],
    operatives: [
        {
            name: "Fire Warrior Shas'ui",
            limit: 1,
            leader: true,
            weapons: [
                ["Gun butt"],
                ["Pulse blaster", "Pulse carbine", "Pulse rifle"]
            ],
            specialisms: ["marksman"]
        },
        {
            name: "Fire Warrior Shas'la",
            limit: 6,
            weapons: [
                ["Gun butt"],
                ["Pulse blaster", "Pulse carbine", "Pulse rifle"]
            ],
            specialisms: ["marksman"]
        },
        //
        {
            name: "Pathfinder Shas'ui",
            limit: 1,
            leader: true,
            weapons: [["Pulse carbine, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Pathfinder Shas'la",
            limit: 6,
            weapons: [["Pulse carbine, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Pathfinder Heavy Gunner",
            limit: 1,
            weapons: [["Gun butt"], ["Ion rifle", "Rail rifle"]],
            specialisms: ["marksman", "scout"]
        },
        //
        {
            name: "Stealth Battlesuit Shas'vre",
            limit: 1,
            leader: true,
            weapons: [["Fists"], ["Fusion blaster", "Burst cannon"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Stealth Battlesuit Shas'ui",
            limit: 3,
            weapons: [["Fists"], ["Fusion blaster", "Burst cannon"]],
            specialisms: ["marksman", "scout"]
        },

        // DRONES
        {
            name: "MV1 Gun Drone",
            limit: 1,
            weapons: [["Twin pulse carbine, Ram"]],
            specialisms: ["marksman"]
        },
        {
            name: "MV4 Shield Drone",
            limit: 1,
            weapons: [["Ram"]],
            specialisms: ["staunch"]
        },
        {
            name: "MV7 Marker Drone",
            limit: 1,
            weapons: [["Ram"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "MB3 Recon Drone",
            limit: 1,
            weapons: [["Burst cannon, Ram"]],
            specialisms: ["staunch", "marksman", "scout"]
        },
        {
            name: "MV31 Pulse Accelerator Drone",
            limit: 1,
            weapons: [["Ram"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "MV33 Grav-inhibitor Drone",
            limit: 1,
            weapons: [["Ram"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "MV36 Guardian Drone",
            limit: 1,
            weapons: [["Ram"]],
            specialisms: ["staunch"]
        },
        {
            name: "DS8 Tactical Support Turret",
            limit: 1,
            weapons: [["Missile pod, Smart missile system"]],
            specialisms: ["staunch", "marksman"]
        }
    ]
};

export default killTeam;

import { HIEROTEK_CIRCLE_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const HierotekCircleController = {
    id: HIEROTEK_CIRCLE_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length !== 8) {
            passed = false;
            messages.push("Must include 8 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Chronomancer" ||
                    operative.unit === "Psychomancer" ||
                    operative.unit === "Technomancer"
                );
            }).length !== 1
        ) {
            passed = false;
            messages.push(
                "Must include 1 Cryptek (Chronomancer or Psychomancer or Technomancer)"
            );
        }

        // only 1 of these units
        [
            "Plasmacyte Accelerator",
            "Plasmacyte Reanimator",
            "Apprentek",
            "Immortal Despotek"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}.`);
            }
        });

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];

        return `${first}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default HierotekCircleController;

const restrictions = () => {
    return (
        <div>
            <p>
                A <strong className="faction">WARPCOVEN</strong> kill team
                consists of:
            </p>
            <ul>
                <li>
                    1 <strong>SORCERER</strong>
                </li>
                <li>
                    10 <strong className="faction">WARPCOVEN</strong> operatives
                    selected from the following list (
                    <strong>ARCANA ASTARTES</strong> operatives count as two
                    selections):
                    <ul>
                        <li>
                            <strong>SORCERER</strong>
                        </li>
                        <li>
                            <strong>RUBRIC MARINE GUNNER</strong>
                        </li>
                        <li>
                            <strong>RUBRIC MARINE ICON BEARER</strong>
                        </li>
                        <li>
                            <strong>RUBRIC MARINE WARRIOR</strong>
                        </li>
                        <li>
                            <strong>TZAANGOR CHAMPION</strong>
                        </li>
                        <li>
                            <strong>TZAANGOR FIGHTER</strong>
                        </li>
                        <li>
                            <strong>TZAANGOR HORN BEARER</strong>
                        </li>
                        <li>
                            <strong>TZAANGOR ICON BEARER</strong>
                        </li>
                    </ul>
                </li>
            </ul>

            {/* prettier-ignore */}
            <p>Other than <strong>SORCERER</strong>, <strong>RUBCRIC MARINE WARRIOR</strong> and <strong>TZAANGOR FIGHTER</strong> operatives, your kill team can only include each operative above once.</p>

            {/* prettier-ignore */}
            <p>This kill team can include up to three <strong>SORCERER</strong> operatives.</p>

            {/* prettier-ignore */}
            <p>You cannot select a second <strong>GUNNER</strong> operative until your kill team includes four <strong>RUBRIC MARINE</strong> operatives.</p>
        </div>
    );
};

export default restrictions;

const theme = {
    maxWidth: "40em",

    fonts: {
        header: "'gunplay', sans-serif",
        body: "source-sans-3, sans-serif"
    },

    colors: {
        white: "#fff",
        black: "#000",
        orange: "#Ca4e19",
        red: "#a81b00",
        lightOrange: "#fdccbc",
        offWhite: "#efefef",
        altOffWhite: "#E3E3E3",
        // grey: "#CBCBCB",
        grey: "#CBCBCB",
        lightGrey: "#ddd",
        darkGrey: "#2E2E2E"
    }
};

export default theme;

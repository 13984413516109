// names
// backgrounds etc...
import { STARSTRIDER_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: STARSTRIDER_ID,
    moniker: "Elucidian Starstriders",
    restrictions: restrictions,
    battleHonours: [
        "Loyal to House Vhane",
        "Close-Quarters Composure",
        "Voidmaster's Commendation",
        "Discretionary",
        "Veteran of the Frontier",
        "Well Prepared"
    ],
    killTeamNames: [
        [
            "Von Klemprine",
            "Jesperest",
            "Greigan",
            "The Grey Weaver's",
            "The Scarlet Baron's",
            "Honorean",
            "Sapphire Broker's",
            "Scepterin's",
            "Transmercator's",
            "Caveat Pactor's",
            "The Golden Mean's",
            "Aquila of Spite's"
        ],
        [
            "Scrutineers",
            "Final Negotiation",
            "Acquisitors",
            "Diligents",
            "Transactors",
            "Covenant Wards",
            "Warrantguard",
            "Dealbreakers",
            "Diplomats-Martial",
            "Void Escorts",
            "Reclamators",
            "Companions Astral"
        ]
    ],
    operativeNames: [
        [
            "Delarique",
            "Jan",
            "Kaerlhu",
            "Milosh",
            "Elecia",
            "Vuleth",
            "Fihrenika",
            "Dekkon",
            "Saorise",
            "Jeinus",
            "Neyam",
            "Mordekei",
            "Sie",
            "hadn",
            "Dragalos",
            "Konstanza",
            "Nylus",
            "Ingnacia",
            "Tobasin",
            "Eyva",
            "Inigo",
            "Temusin",
            "Hektor",
            "Trenna",
            "Greigan",
            "Ani-Quor",
            "Godwyn",
            "Mharean",
            "Strostan",
            "Verasheen",
            "Thaddeus",
            "Yvanna",
            "Poltrek",
            "Sine va Rethe",
            "Johannes",
            "Khorvasta",
            "Nestor"
        ],
        [
            "Draikh",
            "Argevan",
            "Holpstein",
            "Tellanarosea",
            "Hu",
            "<none known>",
            "Greist-Varrak",
            "Of House Philo",
            "Borodin",
            "Sulymanya",
            "Vhorne",
            "Rey Garanoza",
            "Parnevue",
            "Of House Larnor",
            "ven Jastobol",
            "Threce",
            "Mottano di Vega",
            "Du Languille",
            "Romalla",
            "Tresskus",
            "Of House Di'ma'qetch ",
            "Varonius",
            "Ghebli",
            "Svort",
            "Felicidine",
            "Al'Mazia",
            "Quarmokk",
            "Vatham",
            "Of House Kran",
            "Aseer'nahal",
            "Dammas",
            "Zhufornix",
            "na Khal",
            "Serjin",
            "of the Thelcid Dynasty",
            "Whorn"
        ]
    ],
    history: [
        "Starstrider Survivors",
        "Mercantile Fugitives",
        "Cartogrammatists",
        "Darkened Eyes and Hollowed Souls",
        "Criminal Contacts",
        "Archeobrokers"
    ],
    baseOfOperations: [
        "Aquila Lander",
        "Archeotech Port-Key",
        "Boarding Craft",
        "Luxury on the Move",
        "Truehawk II",
        "Traded Transports"
    ],
    squadQuirk: [
        "Fresh Trail",
        "Dealmakers",
        "Xenos Traders",
        "Pious",
        "Arrogance of Position",
        "Covetous Nature"
    ],
    strategicAssets: [
        "Envoy Strategium",
        "Fleet Uplink Booster",
        "Family Chronicle"
    ],
    equipment: [
        {
            name: "Flash Visor",
            cost: 1
        },
        {
            name: "Carapace Armour",
            cost: 2
        },
        {
            name: "Hot-shot Capacitor Pack",
            cost: 2
        },
        {
            name: "Concussion Grenade",
            cost: 2
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Archeotech Grenade",
            cost: 4
        },
        {
            name: "Decksweeper",
            cost: 3
        },
        {
            name: "Medicae Servo Unit",
            cost: 2
        },
        {
            name: "Aggression Serum",
            cost: 2
        },
        {
            name: "Relic Voltagheist Generator",
            cost: 4
        },
        {
            name: "Support Uplink",
            cost: 1
        }
    ],
    specOps: ["Eliminate Obstacle", "Glory and Profit"]
};

export default info;

// names
// backgrounds etc...
import { HUNTER_CADRE_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: HUNTER_CADRE_ID,
    moniker: "Hunter Cadre",
    restrictions: restrictions,
    equipment: [
        {
            name: "Markerlight",
            cost: 2
        },
        {
            name: "Photon Grenade",
            cost: 2
        },
        {
            name: "Target Lock",
            cost: 3
        },
        {
            name: "Holographic Readout",
            cost: 2
        },
        {
            name: "Stimulant Injector",
            cost: 3
        }
    ]
};

export default info;

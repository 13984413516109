import { IMPERIAL_GUARD_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const ImperialGuardController = {
    id: IMPERIAL_GUARD_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Guardsman Sergeant" ||
                    operative.unit === "Tempestor"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push("Must only include 1 Guardsman Sergeant/Tempestor");
        }

        // ONLY SINGLE OPERATIVES IN KILL TEAM (not fire teams)
        [
            "Guardsman Comms",
            "Guardsman Gunner equipped with: Bayonet, Flamer",
            "Guardsman Gunner equipped with: Bayonet, Grenade launcher",
            "Guardsman Gunner equipped with: Bayonet, Meltagun",
            "Guardsman Gunner equipped with: Bayonet, Plasma gun",
            "Guardsman Gunner equipped with: Bayonet, Sniper rifle",
            //
            "Tempestus Scion Comms",
            "Tempestus Scion Gunner equipped with: Gun butt, Flamer",
            "Tempestus Scion Gunner equipped with: Gun butt, Grenade launcher",
            "Tempestus Scion Gunner equipped with: Gun butt, Hot-shot volley gun",
            "Tempestus Scion Gunner equipped with: Gun butt, Meltagun",
            "Tempestus Scion Gunner equipped with: Gun butt, Plasma gun"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit} in KILL TEAM`);
            }
        });

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Guardsman") {
                if (operativesInFireTeams[index].length !== 7) {
                    passed = false;
                    messages.push(
                        "Guardsman Fire Team must include 7 operatives"
                    );
                }

                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit === "Guardsman Comms" ||
                            operative.unit ===
                                "Guardsman Gunner equipped with: Bayonet, Flamer" ||
                            operative.unit ===
                                "Guardsman Gunner equipped with: Bayonet, Grenade launcher" ||
                            operative.unit ===
                                "Guardsman Gunner equipped with: Bayonet, Meltagun" ||
                            operative.unit ===
                                "Guardsman Gunner equipped with: Bayonet, Plasma gun" ||
                            operative.unit ===
                                "Guardsman Gunner equipped with: Bayonet, Sniper rifle"
                        );
                    }).length > 3
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 3 Gunner/Comms operatives in Guardsman fire team`
                    );
                }
            }

            if (fireTeam === "Tempestus Scion") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Tempestus Scion Fire Team must include 5 operatives"
                    );
                }

                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit === "Tempestus Scion Comms" ||
                            operative.unit ===
                                "Tempestus Scion Gunner equipped with: Gun butt, Flamer" ||
                            operative.unit ===
                                "Tempestus Scion Gunner equipped with: Gun butt, Grenade launcher" ||
                            operative.unit ===
                                "Tempestus Scion Gunner equipped with: Gun butt, Hot-shot volley gun" ||
                            operative.unit ===
                                "Tempestus Scion Gunner equipped with: Gun butt, Meltagun" ||
                            operative.unit ===
                                "Tempestus Scion Gunner equipped with: Gun butt, Plasma gun"
                        );
                    }).length > 2
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 2 Gunner/Comms operatives in Tempestus Scion fire team`
                    );
                }
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default ImperialGuardController;

// names
// backgrounds etc...
import { TALONS_OF_THE_EMPEROR_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: TALONS_OF_THE_EMPEROR_ID,
    moniker: "Talons of the Emperor",
    restrictions: restrictions,
    equipment: [
        {
            name: "Oath Parchment",
            cost: 3
        },
        {
            name: "Tanglefoot Grenade",
            cost: 3
        },
        {
            name: "Misericordia",
            cost: 2
        },
        {
            name: "Psyk-out Grenade",
            cost: 2
        },
        {
            name: "Vratine Faceplate",
            cost: 2
        }
    ]
};

export default info;

// raw KT data
import { COMMORRITE_ID } from "../../../constants/factions";

const killTeam = {
    id: COMMORRITE_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Kabalite",
            units: [
                "Sybarite",
                "Kabalite Warrior",
                "Kabalite Gunner",
                "Kabalite Heavy Gunner"
            ]
        },
        {
            name: "Wych",
            units: ["Hekatrix", "Wych Warrior", "Wych Fighter"]
        }
    ],
    operatives: [
        {
            name: "Sybarite",
            limit: 1,
            leader: true,
            weapons: [
                ["Blast pistol", "Splinter pistol", "Splinter rifle"],
                ["Agoniser", "Array of blades", "Power weapon"]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Kabalite Warrior",
            limit: 5,
            weapons: [["Splinter rifle, Array of blades"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kabalite Gunner",
            limit: 1,
            weapons: [["Blaster", "Shredder"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kabalite Heavy Gunner",
            limit: 1,
            weapons: [["Dark lance", "Splinter cannon"]],
            specialisms: ["marksman", "scout"]
        },
        //
        {
            name: "Hekatrix",
            limit: 1,
            leader: true,
            weapons: [
                ["Blast pistol", "Splinter pistol"],
                ["Agoniser", "Hekatarii", "Power weapon"]
            ],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Wych Warrior",
            limit: 5,
            weapons: [["Splinter pistol, Hekatarii blade"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Wych Fighter",
            limit: 2,
            weapons: [["Hydra gauntlets", "Razorflails", "Shardnet, Implaer"]],
            specialisms: ["combat", "scout"]
        }
    ]
};

export default killTeam;

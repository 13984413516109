const restrictions = () => {
    return (
        <div>
            <p>
                A <strong className="faction">VETERAN GAURDSMAN</strong> kill
                team consists of 10{" "}
                <strong className="faction">VETERAN GAURDSMAN</strong>{" "}
                operatives and 1 Ancillary Support option.
            </p>
            <p>
                10 <strong className="faction">VETERAN GAURDSMAN</strong>{" "}
                operatives are selected as follows:
            </p>
            <ul>
                <li>
                    1 <strong>SERGEANT VETERAN</strong>
                </li>
                <li>
                    9 <strong className="faction">VETERAN GAURDSMAN</strong>{" "}
                    operatives selected from the following list:
                    <ul>
                        <li>
                            <strong>TROOPER VETERAN</strong>
                        </li>
                        <li>
                            <strong>CONFIDANT VETERAN</strong>
                        </li>
                        <li>
                            <strong>ZEALOT VETERAN</strong>
                        </li>
                        <li>
                            <strong>MEDIC VETERAN</strong>
                        </li>
                        <li>
                            <strong>COMMS VETERAN</strong>
                        </li>
                        <li>
                            <strong>DEMOLITION VETERAN</strong>
                        </li>
                        <li>
                            <strong>BRUISER VETERAN</strong>
                        </li>
                        <li>
                            <strong>HARDENED VETERAN</strong>
                        </li>
                        <li>
                            <strong>SNIPER VETERAN</strong>
                        </li>
                        <li>
                            <strong>SPOTTER VETERAN</strong>
                        </li>
                        <li>
                            <strong>GUNNER VETERAN</strong> equipped with a
                            bayonet and grenade launcher
                        </li>
                        <li>
                            <strong>GUNNER VETERAN</strong> equipped with a
                            bayonet and meltagun
                        </li>
                        <li>
                            <strong>GUNNER VETERAN</strong> equipped with a
                            bayonet and plasma gun
                        </li>
                        <li>
                            <strong>GUNNER VETERAN</strong> equipped with a
                            bayonet and flamer
                        </li>
                    </ul>
                </li>
            </ul>

            <p>
                <strong>TROOPER VETERAN</strong> operatives can be selected up
                to nine times, and each other operative above can selected once.
            </p>
        </div>
    );
};

export default restrictions;

// names
// backgrounds etc...
import { IMPERIAL_NAVY_BREACHERS_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: IMPERIAL_NAVY_BREACHERS_ID,
    moniker: "Imperial Navy Breachers",
    restrictions: restrictions,
    battleHonours: [
        "Gutsy",
        "Assured",
        "Deckhand",
        "Brusier",
        "Scrapper",
        "Gallant"
    ],
    // killTeamNames: [
    //     [
    //         "Players of the",
    //         "Swords of the",
    //         "Ensemble of the",
    //         "Spears of the",
    //         "Cast of the",
    //         "Band of the",
    //         "Company of the",
    //         "Assembly of the",
    //         "Coterie of the",
    //         "Kiss of the",
    //         "Symphony of the",
    //         "Harmony of the"
    //     ],
    //     [
    //         "Glittering Tide",
    //         "Jape Most Dark",
    //         "Crimson Sun",
    //         "Swooping Falcon",
    //         "Never-ending Dream",
    //         "Shimmering Prism",
    //         "Crystal Stage",
    //         "Healer's Lament",
    //         "Azure Moon",
    //         "Crone's Talon",
    //         "End of Days",
    //         "Blinding Serpent"
    //     ]
    // ],
    operativeNames: [
        [
            "Sheng",
            "Jonah",
            "Otwin",
            "Irma",
            "Romana",
            "'Legs'",
            "Kyrus",
            "Asa",
            "Zippora",
            "Ebram",
            "Jedidi",
            "Lepus",
            "Abiah",
            "Namah",
            "Redga",
            "Medega",
            "Kaalsan",
            "Birkis",
            "Siduri",
            "Netis",
            "Dadan",
            "Aces'",
            "Kherith",
            "Gunde",
            "'Lofty'",
            "Eben",
            "Melita",
            "Eudie",
            "Bilah",
            "Jeshai",
            "Kairis",
            "Edreo",
            "'Shifty'",
            "Sai",
            "Paj",
            "Ougum"
        ],
        [
            "'the Shiv'",
            "Lang",
            "Kline",
            "Sher",
            "Sao",
            "Pymer",
            "Vynall",
            "Koiba",
            "'the Duchess'",
            "Kraal",
            "Heu",
            "Bhorr",
            "Annac",
            "Piaq",
            "'Smasher'",
            "Farz",
            "Nazill",
            "Vrax",
            "Khou",
            "Bhroil",
            "Dimt",
            "Usaga",
            "Dayev",
            "'the Slab'",
            "Botsoe",
            "Lannaq",
            "Salar",
            "Krann",
            "Nias",
            "'the Fist'",
            "Gulav",
            "Akav",
            "Azgir",
            "Gatu",
            "Zuga",
            "'the Blessed'"
        ]
    ],
    history: [
        "Improvisers",
        "Grizzled Veterans",
        "Zealots",
        "Superstitious",
        "Humourless Killers",
        "Diehards"
    ],
    baseOfOperations: [
        "Barracks",
        "Secure Chamber",
        "Boarding Craft",
        "Escape Pod",
        "Servohaulers",
        "Hangar Bay"
    ],
    squadQuirk: [
        "Brawn over Brains",
        "Envelop",
        "Xenos Hunters",
        "Scorched Deck",
        "Secure and Control",
        "Brains over Brawn"
    ],
    strategicAssets: ["Colours", "Void Augur", "Expanded Supply Bay"],
    equipment: [
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Stun Grenade",
            cost: 2
        },
        {
            name: "Rebreather",
            cost: 1
        },
        {
            name: "Slugs",
            cost: 1
        },
        {
            name: "Stimm",
            cost: 1
        },
        {
            name: "System Override Device",
            cost: 2
        }
    ],
    rareEquipment: [
        {
            name: "Relic Cutlass",
            cost: 1
        },
        {
            name: "Navis Cuirass",
            cost: 2
        },
        {
            name: "Enhanced Energy Cell",
            cost: 2
        },
        {
            name: "Remote Access Device",
            cost: 2
        },
        {
            name: "Auspicator",
            cost: 3
        },
        {
            name: "Auto-medicae Unit",
            cost: 1
        }
    ],
    specOps: ["Breach, Clear and Secure", "Scorched Deck"]
};

export default info;

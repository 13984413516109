// raw KT data
import { VOIDSCARRED_ID } from "../../../constants/factions";

const killTeam = {
    id: VOIDSCARRED_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Voidscarred Felarch",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Shuriken rifle, Shuriken pistol, Power weapon",
                    "Neuro disruptor, Power weapon"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Voidscarred Warrior",
            limit: 8,
            weapons: [
                [
                    "Shuriken pistol, Power weapon",
                    "Shuriken rifle, Shuriken pistol, fists"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Voidscarred Gunner",
            limit: 1,
            weapons: [
                [
                    "Shuriken pistol, Fists, Blaster",
                    "Shuriken pistol, Fists, Shredder"
                ]
            ],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Voidscarred Heavy Gunner",
            limit: 1,
            weapons: [
                [
                    "Shuriken pistol, Fists, Shuriken cannon",
                    "Shuriken pistol, Fists, Wraithcannon"
                ]
            ],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Voidscarred Starstorm Duellist",
            limit: 1,
            weapons: [["Fusion pistol, Shuriken pistol, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Voidscarred Kurnite Hunter",
            limit: 1,
            weapons: [["Shuriken pistol, Power weapon"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Voidscarred Shade Runner",
            limit: 1,
            weapons: [["Shuriken pistol, Throwing blades, Hekatarii blades"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Voidscarred Kurnathi",
            limit: 1,
            weapons: [["Shuriken pistol, Dual power weapons"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Voidscarred Fate Dealer",
            limit: 1,
            weapons: [["Ranger long rifle, Shuriken pistol, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Voidscarred Way Seeker",
            limit: 1,
            weapons: [["Shuriken pistol, Witch staff"]],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Voidscarred Soul Weaver",
            limit: 1,
            weapons: [["Shuriken pistol, Power weapon"]],
            specialisms: ["combat", "scout"]
        }
    ]
};

export default killTeam;

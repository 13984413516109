// names
// backgrounds etc...
import { GREENSKIN_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: GREENSKIN_ID,
    moniker: "Greenskin",
    restrictions: restrictions,
    equipment: [
        {
            name: "Stikkbomb",
            cost: 2
        },
        {
            name: "'eavy Armour",
            cost: 2
        },
        {
            name: "Bosspole",
            cost: 3
        },
        {
            name: "Drum Mag",
            cost: 2
        },
        {
            name: "Targetin' Fing",
            cost: 3
        },
        {
            name: "Cuttin' Nozzle",
            cost: 2
        },
        {
            name: "Kustom Force Field",
            cost: 3
        }
    ]
};

export default info;

// raw KT data
import { KASRKIN_ID } from "../../../constants/factions";

const killTeam = {
    id: KASRKIN_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Kasrkin Sergeant",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Bolt pistol, Power weapon",
                    "Hot-shot lasgun, Gun butt",
                    "Hot-shot laspistol, Power weapon",
                    "Plasma pistol, Chainsword"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Kasrkin Combat Medic",
            limit: 1,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Kasrkin Demo-trooper",
            limit: 1,
            weapons: [["Hot-shot laspistol, Melta mine, Gun butt"]],
            specialisms: ["staunch"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Flamer",
            limit: 1,
            weapons: [["Gun butt, Flamer"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Grenade launcher",
            limit: 1,
            weapons: [["Gun butt, Grenade launcher"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Hot-shot volley gun",
            limit: 1,
            weapons: [["Gun butt, Hot-shot volley gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Meltagun",
            limit: 1,
            weapons: [["Gun butt, Meltagun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Plasma gun",
            limit: 1,
            weapons: [["Gun butt, Plasma gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Recon-trooper",
            limit: 1,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kasrkin Sharpshooter",
            limit: 1,
            weapons: [["Hot-shot marksman rifle, Gun butt"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Trooper",
            limit: 9,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kasrkin Vox-trooper",
            limit: 1,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["staunch", "scout"]
        }
    ]
};

export default killTeam;

import factionsData from "../data/factions/factionsData";
import specialisms from "../data/specialisms";
import battleScars from "../data/battleScars";
import strategicAssets from "../data/strategicAssets";
import rareEquipment from "../data/rareEquipment";
import specOps from "../data/specOps";
import { getObjectValues } from "./HelpersController";
import { WARPCOVEN_ID } from "../constants/factions";

// FACTIONS
export function getFactions() {
    return factionsData.sort((a, b) =>
        a.info.moniker.localeCompare(b.info.moniker)
    );
}

export function getFactionData(factionId) {
    return factionsData.find((factionData) => factionData.id === factionId);
}

export function getOperativeFactionData(factionId, operativeName) {
    return getFactionData(factionId).killTeam.operatives.find(
        (operative) => operative.name === operativeName
    );
}

export function getAvailableBattleHonours(factionId, operative) {
    const specialism = operative.specialism;
    const factionBattleHonours = getFactionData(factionId).info.battleHonours;

    // WARPCOVEN SPECIFIC
    if (factionId === WARPCOVEN_ID) {
        if (operative.unit === "Sorcerer") {
            return specialisms[specialism].concat(
                factionBattleHonours.sorcerer
            );
        } else if (
            operative.unit === "Rubric Marine Gunner" ||
            operative.unit === "Rubric Marine Icon Bearer" ||
            operative.unit === "Rubric Marine Warrior"
        ) {
            return specialisms[specialism].concat(
                factionBattleHonours.rubricMarine
            );
        } else if (
            operative.unit === "Tzaangor Champion" ||
            operative.unit === "Tzaangor Fighter" ||
            operative.unit === "Tzaangor Horn Bearer" ||
            operative.unit === "Tzaangor Icon Bearer"
        ) {
            return specialisms[specialism].concat(
                factionBattleHonours.tzaangor
            );
        }

        return specialisms[specialism];
    }

    return specialisms[specialism].concat(factionBattleHonours);
}

export function getBattleScars() {
    return battleScars;
}

export function getFactionName(factionId) {
    return factionsData.find((faction) => {
        return faction.id === factionId;
    }).info.moniker;
}

export function getStrategicAssetOptions(factionId) {
    const factionData = getFactionData(factionId);
    const factionAssets = factionData.info.strategicAssets
        ? factionData.info.strategicAssets
        : [];

    return factionAssets.concat(strategicAssets);
}

export function getStashOptions(factionId) {
    const factionData = getFactionData(factionId);

    const allEquipment = [];

    if (factionData.info.equipment) {
        factionData.info.equipment.forEach((item) => {
            allEquipment.push({ ...item, type: "equipment" });
        });
    }

    if (factionData.info.rareEquipment) {
        factionData.info.rareEquipment.forEach((item) => {
            allEquipment.push({ ...item, type: "rareEquipment" });
        });
    }

    rareEquipment.forEach((item) => {
        allEquipment.push({ ...item, type: "rareEquipment" });
    });

    return allEquipment;
}

export function getStashItemData(item, factionId) {
    const stashOptions = getStashOptions(factionId);

    return stashOptions.find((option) => {
        return option.name === item;
    });
}

export function getSpecOpsOptions(factionId) {
    const factionData = getFactionData(factionId);
    const factionAssets = factionData.info.specOps
        ? factionData.info.specOps
        : [];

    return factionAssets.concat(specOps);
}

export function getTotalEquipmentCost(operatives, factionId) {
    const stash = getStashOptions(factionId);

    const allEquipment = [];

    getObjectValues(operatives).forEach((operative) => {
        allEquipment.push(operative.equipment);
    });

    const allEquipmentFlattened = [].concat(...allEquipment);

    let totalCost = 0;

    allEquipmentFlattened.forEach((item) => {
        const itemObj = stash.find((singleStash) => {
            return singleStash.name === item;
        });

        totalCost += itemObj.cost;
    });

    return totalCost;
}

export function createExportObj(appState, playerName) {
    const list = appState.lists.byId[appState.activeList];
    const faction = getFactionData(list.faction);
    const operatives = getObjectValues(list.roster.byId);

    return {
        playerName,
        faction: faction.info.moniker,
        operatives,
        list
    };
}

import { CADRE_MERCENARY_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const CadreMercenaryController = {
    id: CADRE_MERCENARY_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Krootox";
            }).length > 0 &&
            operativesInKillTeam.length > 12
        ) {
            passed = false;
            messages.push(
                "Cadre Mercenary Fire Team cannot include more than 12 operatives"
            );
        } else if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Krootox";
            }).length > 0 &&
            operativesInKillTeam.length < 12
        ) {
            passed = false;
            messages.push(
                "Cadre Mercenary Fire Team with Krootox must include 12 operatives"
            );
        } else if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Krootox";
            }).length === 0 &&
            operativesInKillTeam.length !== 13
        ) {
            passed = false;
            messages.push(
                "Cadre Mercenary Fire Team must include 13 operatives"
            );
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Kroot Carnivore Leader";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Kroot Carnivore Leader");
        }

        // only 1 of non boy units
        if (
            operativesInKillTeam.filter((operative) => {
                return "Krootox" === operative.unit;
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 Krootox operative`);
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return "Kroot Hound" === operative.unit;
            }).length > 4
        ) {
            passed = false;
            messages.push(`Maximum of 4 Kroot Hound operative`);
        }

        return {
            passed,
            messages
        };
    }
};

export default CadreMercenaryController;

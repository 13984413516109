const LANDING_PAGE = "landingPage";
const ROSTER_PAGE = "rosterPage";
const KILL_TEAM_PAGE = "killTeamPage";
const SPEC_OPS_PAGE = "specOpsPage";
const EXPORT_PAGE = "exportPage";

export {
    LANDING_PAGE,
    ROSTER_PAGE,
    KILL_TEAM_PAGE,
    SPEC_OPS_PAGE,
    EXPORT_PAGE
};

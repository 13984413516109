import styled from "styled-components";
import theme from "./theme";

const AppWrap = styled.div`
    position: relative;
    font-family: ${theme.fonts.body};
    font-weight: 300;
    font-style: normal;
    max-width: ${theme.maxWidth};
    margin: 0 auto;
    padding: 0 0 2em;
`;

export default AppWrap;

import { GREY_KNIGHT_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const GreyKnightController = {
    id: GREY_KNIGHT_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        // if (operativesInKillTeam.length < 5) {
        //     passed = false;
        //     messages.push("Must include 5 operatives");
        // }

        // balance dataslate Q2 2022
        if (operativesInKillTeam.length < 6) {
            passed = false;
            messages.push("Must include 6 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Grey Knight Justicar";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Grey Knight Justicar");
        }

        // only 1 of gunner units
        if (
            operativesInKillTeam.filter((operative) => {
                return "Grey Knight Gunner" === operative.unit;
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 Grey Knight Gunner operative`);
        }

        return {
            passed,
            messages
        };
    }
};

export default GreyKnightController;

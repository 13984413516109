// raw KT data
import { CRAFTWORLD_ID } from "../../../constants/factions";

const killTeam = {
    id: CRAFTWORLD_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Guardian Defender",
            units: [
                "Guardian Defender Leader",
                "Guardian Defender Warrior",
                "Guardian Defender Heavy Gunner",
                "Guardian Defender Heavy Weapon Platform"
            ]
        },
        {
            name: "Storm Guardian",
            units: [
                "Storm Guardian Leader",
                "Storm Guardian Warrior",
                "Storm Guardian Gunner"
            ]
        },
        {
            name: "Ranger",
            units: ["Ranger Leader", "Ranger Warrior"]
        },
        {
            name: "Dire Avenger",
            units: ["Dire Avenger Exarch", "Dire Avenger Warrior"]
        }
    ],
    operatives: [
        {
            name: "Guardian Defender Leader",
            limit: 1,
            leader: true,
            weapons: [["Shuriken catapult, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Guardian Defender Warrior",
            limit: 5,
            weapons: [["Shuriken catapult, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Guardian Defender Heavy Gunner",
            limit: 1,
            weapons: [["Shuriken catapult, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Guardian Defender Heavy Weapon Platform",
            limit: 1,
            weapons: [
                [
                    "Aeldari missile launcher",
                    "Bright lance",
                    "Scatter laser",
                    "Shuriken cannon",
                    "Starcannon"
                ]
            ],
            specialisms: []
        },
        //
        {
            name: "Storm Guardian Leader",
            limit: 1,
            leader: true,
            weapons: [["Shuriken pistol, Storm Guardian blades"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Storm Guardian Warrior",
            limit: 5,
            weapons: [["Shuriken pistol, Storm Guardian blades"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Storm Guardian Gunner",
            limit: 1,
            weapons: [["Fists, Flamer", "Fists, Fusion gun"]],
            specialisms: ["marksman", "scout"]
        },
        //
        {
            name: "Ranger Leader",
            limit: 1,
            leader: true,
            weapons: [["Ranger long rifle, Shuriken pistol, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Ranger Warrior",
            limit: 4,
            weapons: [["Ranger long rifle, Shuriken pistol, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        //
        {
            name: "Dire Avenger Exarch",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Twin avenger shuriken catapult",
                    "Avenger shuriken catapult, Fists",
                    "Diresword, Shimmershield",
                    "Diresword, Shuriken pistol",
                    "Power weapon, Shimmershield",
                    "Power weapon, Shuriken pistol"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Dire Avenger Warrior",
            limit: 4,
            weapons: [["Avenger shuriken catapult, Fists"]],
            specialisms: ["marksman", "scout"]
        }
    ]
};

export default killTeam;

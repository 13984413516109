// raw KT data
import { BROOD_COVEN_ID } from "../../../constants/factions";

const killTeam = {
    id: BROOD_COVEN_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Neophyte Hybrid",
            units: [
                "Neophyte Hybrid Leader",
                "Neophyte Hybrid Trooper",
                "Neophyte Hybrid Gunner equipped with: Gun butt, Flamer",
                "Neophyte Hybrid Gunner equipped with: Gun butt, Grenade launcher",
                "Neophyte Hybrid Gunner equipped with: Gun butt, Webber",
                "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Heavy stubber",
                "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Mining laser",
                "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Seismic cannon",
                "Neophyte Hybrid Icon Bearer"
            ]
        },
        {
            name: "Acolyte Hybrid",
            units: [
                "Acolyte Hybrid Leader",
                "Acolyte Hybrid Trooper",
                "Acolyte Hybrid Gunner",
                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock cutter",
                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock drill",
                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock saw",
                "Acolyte Hybrid Icon Bearer"
            ]
        },
        {
            name: "Hybrid Metamorph",
            units: [
                "Hybrid Metamorph Leader",
                "Hybrid Metamorph Fighter",
                "Hybrid Metamorph Gunner",
                "Hybrid Metamorph Icon Bearer"
            ]
        }
    ],
    operatives: [
        {
            name: "Neophyte Hybrid Leader",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Autogun, Gun butt",
                    "Shotgun, Gun butt",
                    "Chainsword, Autopistol",
                    "Chainsword, Bolt pistol",
                    "Chainsword, Web pistol",
                    "Power maul, Autopistol",
                    "Power maul, Bolt pistol",
                    "Power maul, Web pistol",
                    "Power pick, Autopistol",
                    "Power pick, Bolt pistol",
                    "Power pick, Web pistol"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Neophyte Hybrid Trooper",
            limit: 7,
            weapons: [["Autogun, Shotgun, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Neophyte Hybrid Gunner equipped with: Gun butt, Flamer",
            limit: 2,
            weapons: [["Gun butt, Flamer"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Neophyte Hybrid Gunner equipped with: Gun butt, Grenade launcher",
            limit: 2,
            weapons: [["Gun butt, Grenade launcher"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Neophyte Hybrid Gunner equipped with: Gun butt, Webber",
            limit: 2,
            weapons: [["Gun butt, Webber"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Heavy stubber",
            limit: 1,
            weapons: [["Gun butt, Heavy stubber"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Mining laser",
            limit: 1,
            weapons: [["Gun butt, Mining laser"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Seismic cannon",
            limit: 1,
            weapons: [["Gun butt, Seismic cannon"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Neophyte Hybrid Icon Bearer",
            limit: 1,
            weapons: [["Autogun, Shotgun, Gun butt"]],
            specialisms: ["staunch"]
        },
        //
        {
            name: "Acolyte Hybrid Leader",
            limit: 1,
            leader: true,
            weapons: [
                ["Autopistol", "Hand flamer", "Lash whip"],
                ["Cult bonesword and Claw", "Cult knife and Claw"]
            ],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Acolyte Hybrid Trooper",
            limit: 5,
            weapons: [["Autopistol, Cult knife and claw"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Acolyte Hybrid Gunner",
            limit: 2,
            weapons: [["Hand flamer, Cult knife and Claw"]],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock cutter",
            limit: 2,
            weapons: [["Autopistol, Heavy rock cutter"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock drill",
            limit: 2,
            weapons: [["Autopistol, Heavy rock drill"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock saw",
            limit: 2,
            weapons: [["Autopistol, Heavy rock saw"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Acolyte Hybrid Icon Bearer",
            limit: 1,
            weapons: [["Autopistol, Cult knife and claw"]],
            specialisms: ["staunch"]
        },
        //
        {
            name: "Hybrid Metamorph Leader",
            limit: 1,
            leader: true,
            weapons: [
                ["Autopistol", "Hand flamer"],
                [
                    "Cult bonesword and Metamorph mutations",
                    "Metamorph mutations"
                ]
            ],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Hybrid Metamorph Fighter",
            limit: 5,
            weapons: [["Autopistol, Metamorph mutations"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Hybrid Metamorph Gunner",
            limit: 2,
            weapons: [["Hand flamer, Metamorph mutations"]],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Hybrid Metamorph Icon Bearer",
            limit: 1,
            weapons: [["Autopistol, Metamorph mutations"]],
            specialisms: ["staunch"]
        }
    ]
};

export default killTeam;

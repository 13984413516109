// names
// backgrounds etc...
import { VETERAN_GUARDSMAN_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: VETERAN_GUARDSMAN_ID,
    moniker: "Veteran Guardsman",
    restrictions: restrictions,
    battleHonours: [
        "Capable Under Fire",
        "Brash",
        "Siege Specialist",
        "Grit and Determination",
        "Decorated Veteran",
        "Final Litany"
    ],
    killTeamNames: [
        [
            "Faithful",
            "Death Dealers",
            "Raiders",
            "Sappers",
            "Phantoms",
            "Tunnelers"
        ],
        [
            "Rangers",
            "Survivors",
            "Last Chancers",
            "Bluebloods",
            "Firestarters",
            "Ferals"
        ]
    ],
    operativeNames: [
        // CADIAN
        "Jens",
        "Karsk",
        "Hekler",
        "Reeve",
        "Pavlo",
        "Hektor",
        "Nils",
        "Thenmann",
        "Kyser",
        "Erlen",
        "Raphe",
        "Creed",
        "Lasko",
        "Ackerman",
        "Mattias",
        "Mortens",
        "Dansk",
        "Feodor",
        "Tomas",
        "Kolson",
        "Vance",
        "Pask",
        "Niems",
        "Gryf",
        "Willem",
        "Sonnen",
        "Ekhter",
        "Farestein",
        "Dekker",
        "Graf",
        "Arvans",
        "Viers",
        "Kolm",
        "Bask",
        "Vesker",
        "Pavlo",

        // CATACHAN
        "Gunnarsen",
        "Harker",
        "Grytt",
        "'Snake' Vandien",
        "Greiss",
        "Stryker",
        "Storm",
        "Lance",
        "'Crazy' Winters",
        "Cage",
        "Hammer",
        "Cobra",
        "'Hardhead' Jackson",
        "'Stonefist' Kage",
        "'Boss' Stransky",
        "'Bomber' Johnson",
        "Jacksen",
        "Marshall",
        "'Fangs' Lorson",
        "'Cold-eye' McKay",
        "Wolf",
        "'Shiv' Frost",
        "Brent",
        "'Ironheart' McKillen",
        "'Killer' Crowe",
        "'Wildman' Weiss",
        "'Ice' Creek",
        "Dane",
        "Steeljaw",
        "Dransky",
        "'Slim' Hasker",
        "Mason",
        "Hawks",
        "Axel",
        "Carver",
        "Payne",

        // DEATH KORPS
        "Kraust",
        "Transk",
        "Vern",
        "Lensk",
        "Thraust",
        "Junden",
        "Starnerr",
        "Gershtak",
        "Jent",
        "Rausch",
        "Jurten",
        "Arnim",
        "Kagorik",
        "Voorscht",
        "Trymon",
        "Stagler",
        "Tyborc",
        "Kulth",
        "Kraevahn",
        "Garric",
        "Karis",
        "Venner",
        "Keist",
        "Bozanko",
        "Horrst",
        "Jonkar",
        "Kabil",
        "Varpok",
        "Hebiz",
        "Vanchok",
        "Valbek",
        "Adnan",
        "Pajk",
        "Dovor",
        "Guanat",
        "Kyuras",

        // TALLARN
        "Alem",
        "Hallain",
        "Laskair",
        "Nathal",
        "Altarem",
        "Mohal",
        "Dharem",
        "Hasman",
        "Sonnam",
        "Ushmet",
        "Shadnan",
        "Asphret",
        "Ushad",
        "Tal'hasen",
        "Ashmyr",
        "Shashlem",
        "Haseed",
        "Ushar",
        "Raheim",
        "Dassed",
        "Sahleim",
        "Alhret",
        "Khaleeth",
        "Eshmet",
        "Ul'shalem",
        "Talannar",
        "Al'phareim",
        "El'shan",
        "Khabir",
        "Khalym",
        "'Swiftrider'",
        "'Dunestrider'",
        "'Hawkseye'",
        "'Sand",
        "Devil'",
        "'the",
        "Mirage",
        "'the",
        "Hunter'"
    ],
    history: [
        "Best of the Best",
        "Sworn to Vengeance",
        "Infamous",
        "Ghosts",
        "A Second Chance",
        "Propaganda Assets"
    ],
    baseOfOperations: [
        "Command Tank",
        "Command Bunker",
        "Hidden Cave",
        "Ruined Enforcers' Outpost",
        "Forgotten Shrine",
        "Trench Section"
    ],
    squadQuirk: [
        "Unspeaking",
        "Fanatics",
        "The Mourned",
        "Willful Improvisation",
        "Powerful Patron",
        "By the Book"
    ],
    strategicAssets: [
        "Preliminary Bombardment",
        "Munition Supply Lines",
        "Expanded Engineering Bay"
    ],
    equipment: [
        {
            name: "Hot-shot Capacitor Pack",
            cost: 2
        },
        {
            name: "Rosary",
            cost: 2
        },
        {
            name: "Hand Axe",
            cost: 2
        },
        {
            name: "Trench Shovel",
            cost: 2
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Chronometer",
            cost: 3
        },
        {
            name: "Topographical Chart",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Relic Sidearm",
            cost: 2
        },
        {
            name: "Prized Sabre",
            cost: 1
        },
        {
            name: "Vid-capture Relay",
            cost: 2
        },
        {
            name: "Litany of Sacrifice",
            cost: 3
        },
        {
            name: "Regimental Heirloom",
            cost: 2
        },
        {
            name: "Diagnosticator",
            cost: 2
        }
    ],
    specOps: ["Victory or Death", "Grand Offensive"]
};

export default info;

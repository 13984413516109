import React, { useContext } from "react";
import AppStateContext from "../context";
import StyledOperative from "../styles/StyledOperative";
import {
    getOperativeFactionData,
    getStashOptions,
    getStashItemData
} from "../controllers/DataController";
import OperativeSpecOps from "./OperativeSpecOps";
import { KILL_TEAM_PAGE } from "../constants/pages";

const Operative = (props) => {
    const { appState, dispatch } = useContext(AppStateContext);
    const operative =
        appState.lists.byId[appState.activeList].roster.byId[props.id];
    const unitName = operative.unit;
    const factionId = operative.faction;
    const operativeFactionData = getOperativeFactionData(factionId, unitName);
    const listStash = appState.lists.byId[appState.activeList].stash;

    const factionEquipment = getStashOptions(factionId).filter(
        (stash) => stash.type === "equipment"
    );

    const factionRareEquipment = getStashOptions(factionId).filter(
        (stash) => stash.type === "rareEquipment"
    );

    return (
        <StyledOperative>
            <div className="header">
                <span>{unitName}</span>
            </div>

            <div className="main">
                {/* WEAPONS */}
                {operativeFactionData.weapons[0] &&
                    operativeFactionData.weapons[0].length > 0 && (
                        <div
                            className={
                                appState.activePage === KILL_TEAM_PAGE
                                    ? "row weapons weapons--kill-team"
                                    : "row weapons"
                            }
                        >
                            <label>Weapons:</label>
                            <div>
                                {operativeFactionData.weapons.map(
                                    (options, index) => {
                                        if (options.length === 1) {
                                            return (
                                                <div
                                                    key={`weaponsKey-${index}`}
                                                    className="weapon-single weapon--noalt"
                                                >
                                                    {options[0]}
                                                </div>
                                            );
                                        }

                                        return (
                                            <div
                                                key={`weaponsKey-${index}`}
                                                className="weapon-select__wrap"
                                            >
                                                <select
                                                    value={
                                                        operative.weapons[index]
                                                    }
                                                    className="weapon-single"
                                                    onChange={(event) => {
                                                        const newOperativeObj =
                                                            operative;
                                                        newOperativeObj.weapons[
                                                            index
                                                        ] = event.target.value;

                                                        dispatch({
                                                            type: "UPDATE_OPERATIVE",
                                                            payload: {
                                                                operative:
                                                                    newOperativeObj,
                                                                operativeId:
                                                                    props.id
                                                            }
                                                        });
                                                    }}
                                                >
                                                    {options.map(
                                                        (option, index) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        option
                                                                    }
                                                                    key={option}
                                                                >
                                                                    {option}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    )}

                {/* EQUIPMENT */}
                {appState.activePage === KILL_TEAM_PAGE && (
                    <div className="row row--list">
                        <label>Equipment:</label>
                        <div className="list-items">
                            {operative.equipment.map((item, index) => {
                                // get the equipment cost
                                const stashItemObj = getStashItemData(
                                    item,
                                    factionId
                                );

                                return (
                                    <div key={index}>
                                        <span>
                                            {item}{" "}
                                            <strong>
                                                [{stashItemObj.cost}EP]
                                            </strong>
                                        </span>
                                        <button
                                            onClick={() => {
                                                for (
                                                    let i = 0;
                                                    i <
                                                    operative.equipment.length;
                                                    i++
                                                ) {
                                                    const element =
                                                        operative.equipment[i];

                                                    if (element === item) {
                                                        operative.equipment.splice(
                                                            i,
                                                            1
                                                        );

                                                        break;
                                                    }
                                                }

                                                // const newOperativeObj =
                                                //     operative;

                                                // newOperativeObj.equipment =
                                                //     filteredArr;

                                                dispatch({
                                                    type: "UPDATE_OPERATIVE",
                                                    payload: {
                                                        operativeId: props.id,
                                                        operative
                                                        // newOperativeObj
                                                    }
                                                });
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="equipment__select-wrap">
                            <select
                                value="Add equipment..."
                                onChange={(event) => {
                                    // const newOperativeObj = operative;
                                    // newOperativeObj.equipment.push(
                                    operative.equipment.push(
                                        event.target.value
                                    );

                                    dispatch({
                                        type: "UPDATE_OPERATIVE",
                                        payload: {
                                            operativeId: props.id,
                                            // operative: newOperativeObj
                                            operative
                                        }
                                    });
                                }}
                            >
                                <option default>Add equipment...</option>

                                {listStash.length > 0 && (
                                    <>
                                        <option disabled>
                                            -- Equipment from Stash --
                                        </option>

                                        {listStash.map((stash) => {
                                            const singleItem = getStashOptions(
                                                factionId
                                            ).find(
                                                (stashItem) =>
                                                    stashItem.name === stash
                                            );

                                            return (
                                                <option
                                                    value={singleItem.name}
                                                    key={singleItem.name}
                                                >
                                                    {singleItem.name} [
                                                    {singleItem.cost}EP]
                                                </option>
                                            );
                                        })}
                                    </>
                                )}

                                {factionEquipment.length > 0 && (
                                    <>
                                        <option disabled>
                                            -- Equipment --
                                        </option>

                                        {factionEquipment.map((stash) => {
                                            return (
                                                <option
                                                    value={stash.name}
                                                    key={stash.name}
                                                >
                                                    {stash.name} [{stash.cost}
                                                    EP]
                                                </option>
                                            );
                                        })}
                                    </>
                                )}

                                {factionRareEquipment.length > 0 && (
                                    <>
                                        <option disabled>
                                            -- Rare Equipment --
                                        </option>

                                        {factionRareEquipment.map((stash) => {
                                            return (
                                                <option
                                                    value={stash.name}
                                                    key={stash.name}
                                                >
                                                    {stash.name} [{stash.cost}
                                                    EP]
                                                </option>
                                            );
                                        })}
                                    </>
                                )}

                                {/* {getStashOptions(factionId).map((stash) => {
                                    return (
                                        <option
                                            value={stash.name}
                                            key={stash.name}
                                        >
                                            {stash.name} [{stash.cost}EP]
                                        </option>
                                    );
                                })} */}
                            </select>
                        </div>
                    </div>
                )}

                {/* SPEC OPS */}
                <div
                    className={
                        operative.showSpecOps
                            ? "spec-ops"
                            : "spec-ops spec-ops--hidden"
                    }
                >
                    <OperativeSpecOps operative={operative} id={props.id} />
                </div>
            </div>

            <div className="footer">
                <div>
                    <button
                        className={
                            operative.inKillTeam
                                ? "footer__button-kt footer__button-kt--remove"
                                : "footer__button-kt footer__button-kt--add"
                        }
                        onClick={() => {
                            const newOperativeObj = operative;
                            newOperativeObj.inKillTeam = !operative.inKillTeam;

                            dispatch({
                                type: "UPDATE_OPERATIVE",
                                payload: {
                                    operative: newOperativeObj,
                                    operativeId: props.id
                                }
                            });
                        }}
                    >
                        {operative.inKillTeam ? "Remove from" : "Add to"} Kill
                        Team
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => {
                            const newOperativeObj = operative;
                            newOperativeObj.showSpecOps =
                                !operative.showSpecOps;

                            dispatch({
                                type: "UPDATE_OPERATIVE",
                                payload: {
                                    operative: newOperativeObj,
                                    operativeId: props.id
                                }
                            });
                        }}
                    >
                        {operative.showSpecOps ? "Hide" : "Show"} Spec Ops
                    </button>
                    <button
                        onClick={() => {
                            dispatch({
                                type: "REMOVE_OPERATIVE",
                                payload: {
                                    id: props.id
                                }
                            });
                        }}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </StyledOperative>
    );
};

export default Operative;

// names
// backgrounds etc...
import { HANDS_OF_THE_ARCHON } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: HANDS_OF_THE_ARCHON,
    moniker: "Hands of the Archon",
    restrictions: restrictions,
    battleHonours: [
        "Epicurean of Suffering",
        "Pain Artist",
        "Shadow Hunter",
        "Spire Stalker",
        "Conniving",
        "Patient Predator"
    ],
    killTeamNames: [
        ["Splintered", "Twisted", "Inexorable", "Unsated", "Void", "Shadowed"],
        ["Rapture", "Vein", "Fang", "Blade", "Gauntlet", "Talon"]
    ],
    operativeNames: [
        [
            "Anarkyss",
            "Veth'va",
            "Mayator",
            "Quaez",
            "Daisan",
            "Bekliel",
            "Orvak",
            "Narlek",
            "Monsatos",
            "Vivithrax",
            "Drevakh",
            "Kyzarkh",
            "Thresyn",
            "Shylas",
            "Lythric",
            "Kylos",
            "Theskril",
            "Skythe",
            "Akkhar",
            "Kharsac",
            "Nyktos",
            "Grevyth",
            "Thraed",
            "Sykil",
            "Khaeyl",
            "Madrax",
            "Akhirion",
            "Vypus",
            "Ethriliac",
            "Kheraes",
            "lyshak",
            "Khepres",
            "Eldoriac",
            "Vrekkus",
            "Thayd",
            "Xurul"
        ],
        [
            "Sar'sel",
            "Vorpex",
            "Kreen",
            "the Bloodbreather",
            "Maestros",
            "Gaarsus",
            "Ehthrek",
            "Ghorghast",
            "Ignyss",
            "Mohrkhar",
            "Thresk",
            "Scaur",
            "the Pale",
            "Khadylus",
            "Phrel",
            "Vulkyriax",
            "Nul",
            "the Flenser",
            "Poisonblade",
            "Barbtongue",
            "Xesh",
            "the Ravening",
            "Draeven",
            "of the Obsidian Needle",
            "Vhrex",
            "Kaghmyr",
            "Thrail",
            "Flickerblade",
            "Xosh",
            "the Bleak",
            "Neverbreath",
            "Skahyl",
            "Verkosian",
            "Ulthurian",
            "Menesh",
            "the Cruel"
        ]
    ],
    history: [
        "Trueborn",
        "Ascendant Murderers",
        "Enemies of My Enemy",
        "Parasitic Compulsion",
        "One Shall Stand",
        "Desolators"
    ],
    baseOfOperations: [
        "Blade-prowed Skycraft",
        "Charnel Den",
        "Slavebringer",
        "Shadowed Sub-realm",
        "Enslaved Thralls",
        "Baited Trap"
    ],
    squadQuirk: [
        "Sinful Focus",
        "Swift and Deadly",
        "Reckless",
        "Territorial",
        "Trophy Hunters",
        "Consummate Provocateurs"
    ],
    strategicAssets: [
        "Shrine of Suffering",
        "Pain-adept Laboratory",
        "Toxin Cryo-distillery"
    ],
    equipment: [
        {
            name: "Chain Snare",
            cost: 1
        },
        {
            name: "Wicked Blade",
            cost: 1
        },
        {
            name: "Toxin Coating (Array of Blades)",
            cost: 1
        },
        {
            name: "Toxin Coating",
            cost: 2
        },
        {
            name: "Refined Poison (Splinter Pistol)",
            cost: 2
        },
        {
            name: "Refined Poison",
            cost: 3
        },
        {
            name: "Plasma Grenade",
            cost: 3
        },
        {
            name: "Phantasm Grenade Launcher",
            cost: 3
        },
        {
            name: "Kabalite Banner",
            cost: 2
        }
    ],
    rareEquipment: [
        {
            name: "Ghostplate Armour",
            cost: 2
        },
        {
            name: "Tormented Soulstone",
            cost: 2
        },
        {
            name: "Clone Field",
            cost: 3
        },
        {
            name: "Pain Casket",
            cost: 2
        },
        {
            name: "Living Trophy",
            cost: 3
        },
        {
            name: "Soul-scent Barb",
            cost: 2
        }
    ],
    specOps: ["Slave Raid", "Remove Rival"]
};

export default info;

const KOMMANDO_ID = "KOMMANDO";
const VETERAN_GUARDSMAN_ID = "VETERANGUARDSMAN";
const HUNTER_CLADE_ID = "HUNTERCLADE";
const WARPCOVEN_ID = "WARPCOVEN";
const NOVITIATE_ID = "NOVITIATE";
const PATHFINDER_ID = "PATHFINDER";

const SPACE_MARINE_ID = "SPACEMARINE";
const GREY_KNIGHT_ID = "GREYKNIGHT";
const IMPERIAL_GUARD_ID = "IMPERIALGUARD";
const FORGE_WORLD_ID = "FORGEWORLD";
const ECCLESIARCHY_ID = "ECCLESIARCHY";
const TALONS_OF_THE_EMPEROR_ID = "TALONSOFTHEEMPEROR";
const TRAITOR_SPACE_MARINE_ID = "TRAITORSPACEMARINE";
const DEATH_GUARD_ID = "DEATHGUARD";
const THOUSAND_SONS_ID = "THOUSANDSONS";
const CHAOS_DAEMON_ID = "CHAOSDAEMON";
const CRAFTWORLD_ID = "CRAFTWORLD";
const COMMORRITE_ID = "COMMORRITE";
const TROUPE_ID = "TROUPE";
const GREENSKIN_ID = "GREENSKIN";
const TOMB_WORLD_ID = "TOMBWORLD";
const HUNTER_CADRE_ID = "HUNTERCADRE";
const CADRE_MERCENARY_ID = "CADREMERCENARY";
const HIVE_FLEET_ID = "HIVEFLEET";
const BROOD_COVEN_ID = "BROODCOVEN";
const WYRMBLADE_ID = "WYRMBLADE";
const VOID_DANCER_ID = "VOIDDANCER";
const VOIDSCARRED_ID = "CORSAIRVOIDSCARRED";
const LEGIONARY_ID = "THELEGIONARY";
const PHOBOS_ID = "PHOBOSSTRIKETEAM";
const BLOODED_ID = "BLOODED";
const STARSTRIDER_ID = "STARSTRIDER";
const GELLERPOX_ID = "GELLERPOX";
const IMPERIAL_NAVY_BREACHERS_ID = "IMPERIALNAVYBREACHERS";
const FARSTALKER_ID = "FARSTALKER";
const INTERCESSOR_SQUAD_ID = "INTERCESSORSQUAD";
const KASRKIN_ID = "KASRKIN";
const HIEROTEK_CIRCLE_ID = "HEIROTEKCIRCLE";
const HANDS_OF_THE_ARCHON = "HANDSOFTHEARCHON";
const EXACTION_SQUAD = "EXACTIONSQUAD";
const HEARTHKYN_SALVAGERS = "HEARTHKYNSALVAGERS";
const FELLGORE_RAVAGERS = "FELLGORERAVAGERS";
const INQUISITORIAL_AGENT = "INQUISITORIALAGENT";
const CHAOS_CULT = "CHAOSCULT";

export {
    KOMMANDO_ID,
    SPACE_MARINE_ID,
    VETERAN_GUARDSMAN_ID,
    HUNTER_CLADE_ID,
    WARPCOVEN_ID,
    GREY_KNIGHT_ID,
    IMPERIAL_GUARD_ID,
    FORGE_WORLD_ID,
    ECCLESIARCHY_ID,
    TALONS_OF_THE_EMPEROR_ID,
    TRAITOR_SPACE_MARINE_ID,
    DEATH_GUARD_ID,
    THOUSAND_SONS_ID,
    CHAOS_DAEMON_ID,
    CRAFTWORLD_ID,
    COMMORRITE_ID,
    TROUPE_ID,
    GREENSKIN_ID,
    TOMB_WORLD_ID,
    HUNTER_CADRE_ID,
    CADRE_MERCENARY_ID,
    HIVE_FLEET_ID,
    BROOD_COVEN_ID,
    NOVITIATE_ID,
    PATHFINDER_ID,
    WYRMBLADE_ID,
    VOID_DANCER_ID,
    VOIDSCARRED_ID,
    LEGIONARY_ID,
    PHOBOS_ID,
    BLOODED_ID,
    IMPERIAL_NAVY_BREACHERS_ID,
    FARSTALKER_ID,
    STARSTRIDER_ID,
    GELLERPOX_ID,
    INTERCESSOR_SQUAD_ID,
    KASRKIN_ID,
    HIEROTEK_CIRCLE_ID,
    HANDS_OF_THE_ARCHON,
    EXACTION_SQUAD,
    HEARTHKYN_SALVAGERS,
    FELLGORE_RAVAGERS,
    INQUISITORIAL_AGENT,
    CHAOS_CULT
};

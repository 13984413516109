const isiOS =
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
const isChrome = /CriOS/i.test(navigator.userAgent);

const openForiOS = async blob => {
  if (isChrome) {
    const url = await createFileReaderURL(blob);
    window.open(url);
  } else {
    const url = URL.createObjectURL(blob);
    window.open(url, "_parent");
  }
};

const createFileReaderURL = blob =>
  new Promise(res => {
    const reader = new FileReader();
    reader.onload = () => {
      res(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export { isiOS, openForiOS };

import { CRAFTWORLD_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const CraftworldController = {
    id: CRAFTWORLD_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains up to 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Guardian Defender Leader" ||
                    operative.unit === "Storm Guardian Leader" ||
                    operative.unit === "Ranger Leader" ||
                    operative.unit === "Dire Avenger Exarch"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Maximum of 1 Guardian Defender Leader/Storm Guardian Leader/Ranger Leader/Dire Avenger Exarch"
            );
        }

        // singles for kill team
        [
            "Guardian Defender Heavy Gunner",
            "Guardian Defender Heavy Weapon Platform"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    "Guardian Defender Heavy Weapon Platform" === operative.unit
                );
            }).length > 0 &&
            operativesInKillTeam.filter((operative) => {
                return "Guardian Defender Heavy Gunner" === operative.unit;
            }).length < 1
        ) {
            passed = false;
            messages.push(
                "Guardian Defender Fire Team with a Guardian Defender Heavy Weapon Platform must include a Guardian Defender Heavy Gunner"
            );
        }

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Guardian Defender") {
                // if (operativesInFireTeams[index].length !== 5) {
                //     passed = false;
                //     messages.push(
                //         "Guardian Defender Fire Team must include 5 operatives"
                //     );
                // }

                // balance dataslate Q2 2022
                if (operativesInFireTeams[index].length !== 6) {
                    passed = false;
                    messages.push(
                        "Guardian Defender Fire Team must include 6 operatives"
                    );
                }
            }

            //
            if (fireTeam === "Storm Guardian") {
                // if (operativesInFireTeams[index].length !== 5) {
                //     passed = false;
                //     messages.push(
                //         "Storm Guardian Fire Team must include 5 operatives"
                //     );
                // }

                // balance dataslate Q2 2022
                if (operativesInFireTeams[index].length !== 6) {
                    passed = false;
                    messages.push(
                        "Storm Guardian Fire Team must include 6 operatives"
                    );
                }

                // singles for kill team
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return "Storm Guardian Gunner" === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 1 Storm Guardian Gunner per fire team`
                    );
                }
            }

            //
            if (fireTeam === "Ranger") {
                // if (operativesInFireTeams[index].length !== 4) {
                //     passed = false;
                //     messages.push("Ranger Fire Team must include 4 operatives");
                // }

                // balance dataslate Q2 2022
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push("Ranger Fire Team must include 5 operatives");
                }
            }

            //
            if (fireTeam === "Dire Avenger") {
                // if (operativesInFireTeams[index].length !== 4) {
                //     passed = false;
                //     messages.push(
                //         "Dire Avenger Fire Team must include 4 operatives"
                //     );
                // }

                // balance dataslate Q2 2022
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Dire Avenger Fire Team must include 5 operatives"
                    );
                }
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default CraftworldController;

const restrictions = () => {
    return (
        <div>
            <p>
                A <strong className="faction">SPACE MARINE</strong> kill team
                consists of one fire team selected from the following list:
            </p>
            <ul>
                <li>
                    <strong>INTERCESSOR</strong>
                </li>
                <li>
                    <strong>ASSAULT INTERCESSOR</strong>
                </li>
                <li>
                    <strong>INCURSOR</strong>
                </li>
                <li>
                    <strong>INFILTRATOR</strong>
                </li>
                <li>
                    <strong>REIVER</strong>
                </li>
                <li>
                    <strong>HEAVY INTERCESSOR</strong>
                </li>
                <li>
                    <strong>TACTICAL MARINE</strong>
                </li>
                <li>
                    <strong>SCOUT</strong>
                </li>
                <li>
                    <strong>DEATHWATCH VETERAN</strong>
                </li>
            </ul>

            {/* prettier-ignore */}
            <p>Operatives in <strong>TACTICAL MARINE</strong> and <strong>SCOUT</strong> fire teams cannot have the <strong>DEATHWATCH</strong> selectable keyword (and therefore cannot be selected for <strong>DEATHWATCH</strong> kill teams). Note, however that <strong>DEATHWATCH VETERANS</strong> are only available to <strong>DEATHWATCH</strong> kill teams.</p>

            <div className="fire-teams">
                <div className="fire-team">
                    <h3>Intercessor fire team</h3>

                    {/* prettier-ignore */}
                    <p>An <strong>INTERCESSOR</strong> fire team consists of:</p>

                    <ul>
                        <li>
                            <strong>1 INTERCESSOR SERGEANT</strong>
                        </li>
                        <li>
                            <strong>4 INTERCESSOR WARRIOR</strong>
                        </li>
                    </ul>
                </div>

                <div className="fire-team">
                    <h3>Assault Intercessor fire team</h3>

                    {/* prettier-ignore */}
                    <p>An <strong>ASSAULT INTERCESSOR</strong> fire team consists of:</p>

                    <ul>
                        <li>
                            <strong>1 ASSAULT INTERCESSOR SERGEANT</strong>
                        </li>
                        <li>
                            <strong>4 ASSAULT INTERCESSOR WARRIOR</strong>
                        </li>
                    </ul>
                </div>

                <div className="fire-team">
                    <h3>INCURSOR fire team</h3>

                    {/* prettier-ignore */}
                    <p>An <strong>INCURSOR</strong> fire team consists of:</p>

                    <ul>
                        <li>
                            <strong>1 INCURSOR SERGEANT</strong>
                        </li>
                        <li>
                            <strong>4 INCURSOR WARRIOR</strong>
                        </li>
                    </ul>
                </div>

                <div className="fire-team">
                    <h3>INFILTRATOR fire team</h3>

                    {/* prettier-ignore */}
                    <p>An <strong>INFILTRATOR</strong> fire team consists of:</p>

                    <ul>
                        <li>
                            <strong>1 INFILTRATOR SERGEANT</strong>
                        </li>
                        <li>
                            <strong>4 INFILTRATOR WARRIOR</strong>
                        </li>
                    </ul>
                </div>

                <div className="fire-team">
                    <h3>REIVER fire team</h3>

                    {/* prettier-ignore */}
                    <p>An <strong>REIVER</strong> fire team consists of:</p>

                    <ul>
                        <li>
                            <strong>1 REIVER SERGEANT</strong>
                        </li>
                        <li>
                            <strong>4 REIVER WARRIOR</strong>
                        </li>
                    </ul>
                </div>

                <div className="fire-team">
                    <h3>HEAVY Intercessor fire team</h3>

                    {/* prettier-ignore */}
                    <p>An <strong>HEAVY INTERCESSOR</strong> fire team consists of:</p>

                    <ul>
                        <li>
                            <strong>1 HEAVY INTERCESSOR SERGEANT</strong>
                        </li>
                        <li>
                            <strong>4 HEAVY INTERCESSOR WARRIOR</strong>
                        </li>
                    </ul>
                </div>

                <div className="fire-team">
                    <h3>TACTICAL MARINE fire team</h3>

                    {/* prettier-ignore */}
                    <p>An <strong>TACTICAL MARINE</strong> fire team consists of:</p>

                    <ul>
                        <li>
                            <strong>1 TACTICAL MARINE SERGEANT</strong>
                        </li>
                        <li>
                            <strong>5 TACTICAL MARINE</strong> operatives from
                            the following list:
                            <ul>
                                <li>
                                    <strong>TACTICAL MARINE WARRIOR</strong>
                                </li>
                                <li>
                                    <strong>TACTICAL MARINE GUNNER</strong>
                                </li>
                                <li>
                                    <strong>
                                        TACTICAL MARINE HEAVY GUNNER
                                    </strong>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <p>
                        Other than <strong>TACTICAL MARINE WARRIOR</strong>{" "}
                        operatives, your kill team can only include each
                        operative above once.
                    </p>
                </div>

                <div className="fire-team">
                    <h3>SCOUT fire team</h3>

                    {/* prettier-ignore */}
                    <p>A <strong>SCOUT</strong> fire team consists of 1 operative from the following list:</p>

                    <ul>
                        <li>
                            <strong>1 SCOUT</strong> operatives from the
                            following list:
                            <ul>
                                <li>
                                    <strong>SCOUT SERGEANT</strong>
                                </li>
                                <li>
                                    <strong>SCOUT SNIPER SERGEANT</strong>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>9 SCOUT</strong> operatives from the
                            following list:
                            <ul>
                                <li>
                                    <strong>SCOUT WARRIOR</strong>
                                </li>
                                <li>
                                    <strong>SCOUT GUNNER</strong>
                                </li>
                                <li>
                                    <strong>SCOUT HEAVY GUNNER</strong>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <p>
                        Other than <strong>SCOUT WARRIOR</strong> operatives,
                        your kill team can only include each operative above
                        once.
                    </p>
                </div>

                <div className="fire-team">
                    <h3>DEATHWATCH fire team</h3>

                    {/* prettier-ignore */}
                    <p>A <strong>DEATHWATCH</strong> fire team consists of:</p>

                    <ul>
                        <li>
                            <strong>1 DEATHWATCH WATCH SERGEANT</strong>
                        </li>
                        <li>
                            4 <strong>DEATHWATCH VETERAN</strong> operatives
                            from the following list:
                            <ul>
                                <li>
                                    <strong>DEATHWATCH VETERAN WARRIOR</strong>
                                </li>
                                <li>
                                    <strong>DEATHWATCH VETERAN FIGHTER</strong>
                                </li>
                                <li>
                                    <strong>DEATHWATCH VETERAN GUNNER</strong>
                                </li>
                                <li>
                                    <strong>
                                        DEATHWATCH VETERAN HEAVY GUNNER
                                    </strong>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    {/* prettier-ignore */}
                    <p>Other than <strong>DEATHWATCH VETERAN WARRIOR</strong> operatives, your kill team can only include each operative above once.</p>
                </div>
            </div>
        </div>
    );
};

export default restrictions;

import { BLOODED_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const BloodedController = {
    id: BLOODED_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        let count = 0;

        operativesInKillTeam.forEach((operative) => {
            if (
                operative.unit === "Traitor Enforcer" ||
                operative.unit === "Traitor Ogryn"
            ) {
                count += 2;
            } else {
                count++;
            }
        });

        if (count !== 14) {
            passed = false;
            messages.push(
                "Must include 14 selections. TRAITOR ENFORCER and TRAITOR OGRYN operatives count as 2 selections"
            );
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Traitor Chieftain";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Traitor Chieftain");
        }

        // max 2 gunners
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit ===
                        "Traitor Gunner equipped with: Bayonet, Flamer" ||
                    operative.unit ===
                        "Traitor Gunner equipped with: Bayonet, Grenade Launcher" ||
                    operative.unit ===
                        "Traitor Gunner equipped with: Bayonet, Meltagun" ||
                    operative.unit ===
                        "Traitor Gunner equipped with: Bayonet, Plasma gun"
                );
            }).length > 2
        ) {
            passed = false;
            messages.push(`Maximum of 2 TRAITOR GUNNER operatives`);
        }

        // one of units
        [
            "Traitor Brimstone Grenadier",
            "Traitor Butcher",
            "Traitor Commsman",
            "Traitor Corpseman",
            "Traitor Enforcer",
            "Traitor Flenser",
            "Traitor Gunner equipped with: Bayonet, Flamer",
            "Traitor Gunner equipped with: Bayonet, Grenade Launcher",
            "Traitor Gunner equipped with: Bayonet, Meltagun",
            "Traitor Gunner equipped with: Bayonet, Plasma gun",
            "Traitor Ogryn",
            "Traitor Sharpshooter",
            "Traitor Thug",
            "Traitor Trench Sweeper"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit} operative`);
            }
        });

        // max 9 units from list 1
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit ===
                        "Traitor Gunner equipped with: Bayonet, Flamer" ||
                    operative.unit ===
                        "Traitor Gunner equipped with: Bayonet, Grenade Launcher" ||
                    operative.unit ===
                        "Traitor Gunner equipped with: Bayonet, Meltagun" ||
                    operative.unit ===
                        "Traitor Gunner equipped with: Bayonet, Plasma gun" ||
                    operative.unit === "Traitor Brimstone Grenadier" ||
                    operative.unit === "Traitor Butcher" ||
                    operative.unit === "Traitor Commsman" ||
                    operative.unit === "Traitor Corpseman" ||
                    operative.unit === "Traitor Flenser" ||
                    operative.unit === "Traitor Sharpshooter" ||
                    operative.unit === "Traitor Thug" ||
                    operative.unit === "Traitor Trench Sweeper"
                );
            }).length > 9
        ) {
            passed = false;
            messages.push(
                `Maximum of 9 BLOODED operatives (not including TRAITOR TROOPER, TRAITOR OGRYN and TRAITOR ENFORCER units)`
            );
        }

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default BloodedController;

// names
// backgrounds etc...
import { IMPERIAL_GUARD_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: IMPERIAL_GUARD_ID,
    moniker: "Imperial Guard",
    restrictions: restrictions,
    equipment: [
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 2
        },
        {
            name: "Medkit",
            cost: 2
        },
        {
            name: "Carapace Armour",
            cost: 3
        },
        {
            name: "Scion Blade",
            cost: 1
        },
        {
            name: "Regimental Standard",
            cost: 3
        }
    ]
};

export default info;

import React, { useContext } from "react";
import StyledRosterAndKillTeam from "../styles/StyledRosterAndKillTeam";
import StyledFactionValidation from "../styles/StyledFactionValidation";
import AppStateContext from "../context";
import StyledSelectInput from "../styles/StyledSelectInput";
import { getFactionData } from "../controllers/DataController";
import Operative from "./Operative";

const PageRoster = (props) => {
    const { appState, dispatch } = useContext(AppStateContext);
    const list = appState.lists.byId[appState.activeList];
    const operatives = list.roster.allIds;
    const { killTeam, id, info } = getFactionData(list.faction);

    return (
        <StyledRosterAndKillTeam>
            <StyledFactionValidation>
                <div className="title">
                    {info.moniker} Kill Team{" "}
                    {list.moniker && <span>{list.moniker}</span>}
                </div>
            </StyledFactionValidation>
            {killTeam.fireTeams ? (
                [...Array(killTeam.fireTeams)].map((val, index) => (
                    <div key={`fireteam-${index}`} className="fire-team">
                        <div className="select__fireteam-inner">
                            <select
                                className="select__fireteam"
                                value={list.fireTeams[index]}
                                onChange={(event) => {
                                    dispatch({
                                        type: "SET_FIRETEAM",
                                        payload: {
                                            index: index,
                                            team: event.target.value
                                        }
                                    });
                                }}
                            >
                                <option value="default" disabled>
                                    Select a Fire Team...
                                </option>
                                {killTeam.fireTeamTypes.map((team) => {
                                    return (
                                        <option
                                            key={team.name}
                                            value={team.name}
                                        >
                                            {team.name} Fire Team
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        {list.fireTeams[index] !== "default" && (
                            <>
                                <div className="operatives">
                                    {operatives.length > 0 &&
                                        operatives
                                            .filter((operativeId) => {
                                                return (
                                                    list.roster.byId[
                                                        operativeId
                                                    ].fireTeam === index
                                                );
                                            })
                                            .map((operativeId) => (
                                                <Operative
                                                    key={operativeId}
                                                    id={operativeId}
                                                    fireTeam={index}
                                                />
                                            ))}
                                </div>
                                <div className="add-operative__wrap">
                                    <label>Add Operative to Fire Team:</label>

                                    <div className="select__operative-inner">
                                        <select
                                            className="select__operative"
                                            value="default"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "ADD_OPERATIVE",
                                                    payload: {
                                                        operativeName:
                                                            event.target.value,
                                                        factionId: id,
                                                        fireTeam: index
                                                    }
                                                });
                                            }}
                                        >
                                            <option value="default" disabled>
                                                Select an Operative...
                                            </option>
                                            {killTeam.fireTeamTypes
                                                .find((fireTeam) => {
                                                    return (
                                                        fireTeam.name ===
                                                        list.fireTeams[index]
                                                    );
                                                })
                                                .units.map((operative) => {
                                                    return (
                                                        <option
                                                            key={operative}
                                                            value={operative}
                                                        >
                                                            {operative}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))
            ) : (
                <>
                    <div className="operatives">
                        {operatives.length > 0 &&
                            operatives.map((operativeId) => (
                                <Operative key={operativeId} id={operativeId} />
                            ))}
                    </div>
                    <div className="add-operative__wrap">
                        <label>Add Operatives:</label>
                        <StyledSelectInput>
                            <div className="select-input__wrap">
                                <select
                                    value="default"
                                    onChange={(event) => {
                                        dispatch({
                                            type: "ADD_OPERATIVE",
                                            payload: {
                                                operativeName:
                                                    event.target.value,
                                                factionId: id
                                            }
                                        });
                                    }}
                                >
                                    <option value="default" disabled>
                                        Select an Operative...
                                    </option>
                                    {killTeam.operatives.map((operative) => {
                                        return (
                                            <option
                                                key={operative.name}
                                                value={operative.name}
                                            >
                                                {operative.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </StyledSelectInput>
                    </div>
                </>
            )}
        </StyledRosterAndKillTeam>
    );
};

export default PageRoster;

// names
// backgrounds etc...
import { HUNTER_CLADE_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: HUNTER_CLADE_ID,
    moniker: "Hunter Clade",
    restrictions: restrictions,
    battleHonours: [
        "Fixated",
        "Faith in the Machine",
        "Blessed Components",
        "Enhanced Bionics",
        "Consecrated Cybernetics",
        "Autonomic Cortex"
    ],
    killTeamNames: [
        "Rust",
        "Cog",
        "Night",
        "Purgation",
        "Nemesis",
        "Circuit",
        "Ghosts",
        "Hackers",
        "Stalkers",
        "Hunters",
        "Linkage",
        "Purgers",
        "00183",
        "55332",
        "93845",
        "12985",
        "03845",
        "76427",
        "Resolution Zero",
        "Cog-Circuit-Theta 2-7",
        "Nullifier Axiom",
        "Circuit Prima",
        "Nemesis Linkage 8-2-4",
        "Actuator Alpha"
    ],
    operativeNames: [
        [
            "Lho",
            "Sy-gex",
            "Protos-Reductus",
            "09",
            "Mu",
            "8-",

            "Rhy",
            "Dos",
            "Exitor-Dho",
            "Delpha",
            "Decima",
            "Zhu"
        ],
        [
            "-XXVII",
            "-6e20F",
            "-089",
            "323Mk12",
            "-998/56c",
            "-1111",

            "Gedd-38f",
            "-511",
            "-888.88",
            "-66.75/Mk98",
            "-1010",
            "-0.44/K"
        ]
    ],
    history: [
        "Explorator Team",
        "Archeotech Hunters",
        "Campaign Veterans",
        "Titan Guards",
        "Infiltrator Team",
        "Rad-zone Corps"
    ],
    baseOfOperations: [
        "Armoury-vault",
        "Forge-temple",
        "Servitor Repair-works",
        "Cogitator Shrine",
        "Archaeopter Wing",
        "Skorpius Duneriders"
    ],
    squadQuirk: [
        "Acquisitive",
        "Requiring Re-sanctification",
        "Pursuing Auto-perfection",
        "Mercilessly Decisive",
        "Secretive",
        "Beyond the Crux Mechanicus"
    ],
    strategicAssets: [
        "Electromagnetic Spectra-storm",
        "Repair Repository",
        "Data Augur"
    ],
    equipment: [
        {
            name: "Uplinked Vid-feed",
            cost: 1
        },
        {
            name: "Servo-skull",
            cost: 2
        },
        {
            name: "Mechadendrites",
            cost: 2
        },
        {
            name: "Cense Bearer",
            cost: 2
        },
        {
            name: "Command Uplink",
            cost: 4
        },
        {
            name: "Enriched Rounds",
            cost: 2
        },
        {
            name: "Optimised Gait",
            cost: 1
        },
        {
            name: "Refractor Field",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Thorium Rounds",
            cost: 2
        },
        {
            name: "Technodermic Weave",
            cost: 3
        },
        {
            name: "Autorepair Appendage",
            cost: 2
        },
        {
            name: "Amplified Data Emitter",
            cost: 3
        },
        {
            name: "Omnispectral Analyser",
            cost: 3
        },
        {
            name: "Arc Grenade",
            cost: 3
        }
    ],
    specOps: ["Dauntless Pursuit", "Expeditious Assassinations"]
};

export default info;

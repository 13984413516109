import React from "react";

const ExperienceIcon01 = () => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 116.62 102.16"
    >
        <path
            d="M243.16,197c-15.08-23.95-30-47.6-45.21-71.77h90.44C273.27,149.25,258.31,173,243.16,197Z"
            transform="translate(-184.86 -125.27)"
        />
        <path
            d="M184.86,134.92c4.59,0,8.38-.27,12.09.14a6.51,6.51,0,0,1,4.05,2.88c12.66,20,25.19,40.13,37.77,60.2,1.29,2.06,2.73,4,4.48,6.61l21.33-33.95c6.92-11,13.75-22.06,20.86-32.93a7.91,7.91,0,0,1,5.07-2.83c3.25-.45,6.61-.12,11-.12l-58.32,92.51Z"
            transform="translate(-184.86 -125.27)"
        />
    </svg>
);

export default ExperienceIcon01;

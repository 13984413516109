import React, { useContext } from "react";
import AppStateContext from "../context";
import { getFactionName } from "../controllers/DataController";
import StyledUserLists from "../styles/StyledUserLists";

const UserLists = (props) => {
    const { appState, dispatch } = useContext(AppStateContext);

    return (
        <StyledUserLists>
            <span className="lists__header">My Lists</span>
            {appState.lists.allIds.map((listId) => {
                return (
                    <div className="lists__item" key={listId}>
                        <span className="list__info">
                            <span className="list__faction">
                                {getFactionName(
                                    appState.lists.byId[listId].faction
                                )}
                            </span>
                            <span className="list__name">
                                {appState.lists.byId[listId].moniker}
                            </span>
                        </span>
                        <button
                            onClick={() =>
                                dispatch({
                                    type: "REMOVE_LIST",
                                    payload: { listId: listId }
                                })
                            }
                            className="list__delete"
                        >
                            Delete
                        </button>
                        <button
                            onClick={() =>
                                dispatch({
                                    type: "LOAD_LIST",
                                    payload: { listId: listId }
                                })
                            }
                            className="list__load"
                        >
                            Load
                        </button>
                    </div>
                );
            })}
        </StyledUserLists>
    );
};

export default UserLists;

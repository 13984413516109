// raw KT data
import { TOMB_WORLD_ID } from "../../../constants/factions";

const killTeam = {
    id: TOMB_WORLD_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Necron Warrior",
            units: ["Necron Warrior"]
        },
        {
            name: "Immortal",
            units: ["Immortal Leader", "Immortal Warrior"]
        },
        {
            name: "Flayed One",
            units: ["Flayed One Leader", "Flayed One Warrior"]
        },
        {
            name: "Deathmark",
            units: ["Deathmark Leader", "Deathmark Warrior"]
        }
    ],
    operatives: [
        {
            name: "Necron Warrior",
            limit: 5,
            weapons: [["Gauss flayer, Gauss reaper, Bayonet"]],
            specialisms: ["staunch", "marksman"]
        },
        //
        {
            name: "Immortal Leader",
            limit: 1,
            leader: true,
            weapons: [["Gauss blaster", "Tesla carbine"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Immortal Warrior",
            limit: 4,
            weapons: [["Gauss blaster", "Tesla carbine"]],
            specialisms: ["staunch", "marksman"]
        },
        //
        {
            name: "Flayed One Leader",
            limit: 1,
            leader: true,
            weapons: [["Flayer claws"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Flayed One Warrior",
            limit: 5,
            weapons: [["Flayer claws"]],
            specialisms: ["combat", "scout"]
        },
        //
        {
            name: "Deathmark Leader",
            limit: 1,
            leader: true,
            weapons: [["Synaptic disintegrator, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Deathmark Warrior",
            limit: 4,
            weapons: [["Synaptic disintegrator, Fists"]],
            specialisms: ["marksman", "scout"]
        }
    ]
};

export default killTeam;

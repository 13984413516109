import styled from "styled-components";
import theme from "./theme";

const StyledHeader = styled.div`
    width: 100%;
    height: 100%;

    .tabs {
        ul {
            list-style: none;
            padding: 0;
            display: flex;
        }

        li {
            width: 25%;

            &:first-child {
                button {
                    border-left: solid 3px ${theme.colors.orange};
                }
            }
        }

        button {
            width: 100%;
            padding: 0.6em;
            border: solid 3px ${theme.colors.orange};
            border-left: none;
            background: ${theme.colors.white};
            font-family: ${theme.fonts.header};
            text-transform: uppercase;
            color: ${theme.colors.orange};
        }

        .active {
            background: ${theme.colors.orange};
            color: ${theme.colors.white};
        }
    }

    h1 {
        margin-top: 0.5em;
        display: inline-block;
        text-indent: -9999px;
        width: 10.6em;
        height: 4.5em;
        // background: red;
        background-image: url("/assets/images/killteam-logo-2.jpg");
        background-size: contain;
        background-repeat: no-repeat;
    }

    .load-new-button {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 2.5em;
        border: solid 2px ${theme.colors.black};
        padding: 0.4em 1em;
        background: none;
        border-radius: 0;
        text-transform: uppercase;
        font-family: ${theme.fonts.header};
        color: ${theme.colors.black};
        letter-spacing: 0.04em;
        font-size: 0.7em;
        transition: all 0.3s ease;

        &:hover {
            background: ${theme.colors.darkGrey};
            color: ${theme.colors.white};
        }
    }

    @media (min-width: 400px) {
        text-align: center;
    }
`;

export default StyledHeader;

import { GELLERPOX_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const GellerpoxController = {
    id: GELLERPOX_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // only 1 of these units
        [
            "Vulgrar Thrice-cursed",
            "Bloatspawn",
            "Fleshscreamer",
            "Lumberghast",
            "Gellerpox Mutant equipped with: Frag grenade, Heavy axe"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length !== 1
            ) {
                passed = false;
                messages.push(`Must include 1 ${unit}`);
            }
        });

        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    "Gellerpox Mutant equipped with: Frag grenade, Improvised weapon, Mutated limb" ===
                    operative.unit
                );
            }).length !== 3
        ) {
            passed = false;
            messages.push(
                `Must include 2 Gellerpox Mutants equipped with: Frag grenade, Improvised weapon, Mutated limb`
            );
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return "Glitchling" === operative.unit;
            }).length !== 4
        ) {
            passed = false;
            messages.push(`Must include 4 Glitchlings.`);
        }

        return {
            passed,
            messages
        };
    },

    // randomiseTeamName: (names) => {
    //     const first = names[0][Math.floor(Math.random() * names[0].length)];
    //     const second = names[1][Math.floor(Math.random() * names[1].length)];

    //     return `${first} ${second}`;
    // },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default GellerpoxController;

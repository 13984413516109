import styled from "styled-components";
import theme from "./theme";

const StyledSelectText = styled.div`
    .select-text__wrap {
        min-height: 1.4em;
        position: relative;

        &.select-text__wrap--odd {
            input {
                background: ${theme.colors.altOffWhite};
            }
        }

        select {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: none;
            padding-right: 2em;
            cursor: pointer;
            border-radius: 0;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
        }

        input {
            width: calc(100% - 2em);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            text-align: right;
            border-bottom: dotted 1px ${theme.colors.grey};
            background: ${theme.colors.offWhite};

            &.no-options {
                width: 100%;
            }
        }

        .select-text__inner {
            position: relative;

            &::after {
                position: absolute;
                right: 0;
                top: -0.2em;
                content: "▾";
                font-weight: 700;
                font-size: 1.2em;
                // content: "﹀";
                // content: "︾";
            }
        }
    }
`;

export default StyledSelectText;

import React from "react";

const ExperienceIcon02 = () => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 135.33 102.11"
    >
        <path
            d="M418.42,197l-45.33-71.74H463.6Z"
            transform="translate(-350.75 -125.29)"
        />
        <path
            d="M418.56,204.41c8.93-14,17.54-27.43,26.08-40.9,4.93-7.79,9.89-15.56,14.57-23.49,2.15-3.66,4.64-5.66,9.09-5,2.42.38,4.95.07,8.28.07l-58.17,92.27L360.2,135.16c4.79,0,9-.19,13.15.13,1,.08,2.15,1.65,2.87,2.77q20.1,31.66,40.13,63.38C416.87,202.26,417.51,203,418.56,204.41Z"
            transform="translate(-350.75 -125.29)"
        />
        <path
            d="M388,187.09c-7.92,0-15,.13-22.14-.11a6.26,6.26,0,0,1-4.41-2.27c-3.6-5.28-6.85-10.82-10.73-17.08,8.31,0,16-.06,23.68.09.89,0,2,1.08,2.59,1.95C380.6,175.18,384.05,180.77,388,187.09Z"
            transform="translate(-350.75 -125.29)"
        />
        <path
            d="M486.08,167.6c-3.93,6.32-7.18,11.85-10.79,17.13a6.26,6.26,0,0,1-4.41,2.25c-7.1.24-14.21.11-22.15.11,4.11-6.41,7.84-12.35,11.74-18.16a4,4,0,0,1,2.91-1.27C470.64,167.55,477.89,167.6,486.08,167.6Z"
            transform="translate(-350.75 -125.29)"
        />
    </svg>
);

export default ExperienceIcon02;

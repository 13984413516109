import styled from "styled-components";
import theme from "./theme";

const StyledSelectInput = styled.div`
    .select-input__wrap {
        display: block;
        position: relative;
        border: solid 2px ${theme.colors.black};

        &::after {
            position: absolute;
            right: 0.5em;
            top: 0.35em;
            content: "▾";
            font-weight: 700;
            font-size: 1.2em;
            // content: "﹀";
            // content: "︾";
        }
    }

    select {
        display: block;
        font-size: 16px;
        font-family: ${theme.fonts.body};
        line-height: 1.4em;
        color: ${theme.colors.black};
        padding: 0.6em 1.4em 0.5em 0.8em;
        width: 100%;
        max-width: 100%;
        margin: 0;
        border: 0;
        box-shadow: 0;
        border-radius: 0;

        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        &::-ms-expand {
            display: none;
        }

        &:hover {
        }

        &:focus {
            outline: none;
        }

        option {
            font-weight: normal;
        }
    }
`;

export default StyledSelectInput;

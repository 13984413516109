import { IMPERIAL_NAVY_BREACHERS_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const BreachersController = {
    id: IMPERIAL_NAVY_BREACHERS_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length < 11) {
            passed = false;
            messages.push("Must include 11 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Navis Sergeant-at-arms";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Navis Sergeant-at-arms");
        }

        // only 1 of these units
        [
            "Navis Axejack",
            "Navis C.A.T Unit",
            "Navis Endurant",
            "Navis Gheistskull",
            "Navis Grenadier",
            "Navis Gunner equipped with: Navis las-volley, Gun butt",
            "Navis Gunner equipped with: Meltagun, Gun butt",
            "Navis Gunner equipped with: Plasma gun, Gun butt",
            "Navis Hatchcutter",
            "Navis Surveyor",
            "Navis Void-jammer"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}.`);
            }
        });

        // max 2 gunners
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit ===
                        "Navis Gunner equipped with: Navis las-volley, Gun butt" ||
                    operative.unit ===
                        "Navis Gunner equipped with: Meltagun, Gun butt" ||
                    operative.unit ===
                        "Navis Gunner equipped with: Plasma gun, Gun butt"
                );
            }).length > 2
        ) {
            passed = false;
            messages.push(
                `Maximum of 2 NAVIS GUNNER operatives per Kill Team.`
            );
        }

        // only pets if their masters are there

        // units present in KT
        const voidJammer =
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Navis Void-jammer";
            }).length === 1;

        const gheistskull =
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Navis Gheistskull";
            }).length === 1;

        const surveyor =
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Navis Surveyor";
            }).length === 1;

        const catUnit =
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Navis C.A.T. Unit";
            }).length === 1;

        if (gheistskull && !voidJammer) {
            passed = false;
            messages.push(
                `Can only include a NAVIS GHEISTSKULL operative if it also includes a NAVIS VOID-JAMMER operative`
            );
        }

        if (catUnit && !surveyor) {
            passed = false;
            messages.push(
                `Can only include a NAVIS C.A.T UNIT operative if it also includes a NAVIS SURVEYOR operative`
            );
        }

        return {
            passed,
            messages
        };
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default BreachersController;

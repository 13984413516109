// names
// backgrounds etc...
import { HEARTHKYN_SALVAGERS } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: HEARTHKYN_SALVAGERS,
    moniker: "Hearthkyn Salvagers",
    restrictions: restrictions,
    battleHonours: [
        "Rugged Survivalist",
        "Stubborn",
        "Begrudging",
        "Inveterate Toughness",
        "Void-dredger",
        "Honoured"
    ],
    killTeamNames: [
        [
            "The Wreck Scrapers",
            "The Relic Hunters",
            "The Beast Purgers",
            "The Hulk Bravers",
            "The Warp Divers",
            "The Avengers of Vymm Ransa"
        ]
    ],
    operativeNames: [
        [
            "Kôrv",
            "Krynn",
            "Ükyr",
            "Yôht",
            "Vykât",
            "Nârunn",
            "Hâk",
            "Võrk",
            "Yahp",
            "Tôryk",
            "Vânn",
            "Ôkhav",
            "Hêtynn",
            "Annyk",
            "Yuka",
            "Pykô",
            "Vâk",
            "Ûtann",
            "Okkâ",
            "Vynn",
            "Êttok",
            "Niyak",
            "Drûkh",
            "Okyp",
            "Ûnnvyr",
            "Kottak",
            "Adunn",
            "Hyvôr",
            "Akkyt",
            "Tôk",
            "Yhêl",
            "Ynnôk",
            "Hâvyr",
            "Pyûk",
            "Pûnnak",
            "Aktol",
            "Pôkhamm",
            "Ymmâ",
            "Kirv",
            "Vymm",
            "Vêyha",
            "Êkhya",
            "Ôrumm",
            "Dêki",
            "Yvâ",
            "Mûrym",
            "Úmyk",
            "Ryûk",
            "Simmka",
            "Êkyr",
            "Hâluk",
            "Vûkha",
            "Kylû",
            "Vyhâ",
            "Kimm",
            "Piya",
            "Ammuk",
            "Hêykha",
            "Kâhyrm",
            "Îyka",
            "Pôyuk",
            "Ymmi",
            "Pêmm",
            "Yrêk",
            "Viya",
            "Êyv",
            "Vêmmha",
            "Pôlyk",
            "Ymmê",
            "Thap",
            "Mêmm",
            "Kvyma"
        ],
        [
            "Thuürk",
            "Grât",
            "Hypêk",
            "Trânna",
            "Krônn",
            "Hegêm",
            "Ymyk",
            "Conglahr",
            "Ûrann",
            "Regyl",
            "Sôldyk",
            "Hyrthka",
            "Farstrydd",
            "Dêlvyk",
            "Cthynn",
            "Gylûr",
            "Strykk",
            "Rokhewyr",
            "Stârflyt",
            "lyrnwêrke",
            "Thûndryk",
            "Sêykr",
            "Skêyfyr",
            "Wârspeke",
            "Vôtyk",
            "Kynn",
            "Gâard",
            "Keen",
            "Eye",
            "Stoyk",
            "Star",
            "Seeker",
            "the Wise",
            "the Unbroken",
            "the Stern",
            "the Lucky",
            "the Swift",
            "the Strong",
            "Anhad",
            "Hûri",
            "Rânsa",
            "Pêrya",
            "Ônas",
            "Emônyr",
            "Myrr",
            "Côn",
            "Sûrta",
            "Úlaty",
            "Skårstreng",
            "Kêynshot",
            "Sturmm",
            "Dûtyk",
            "Fyrgrîte",
            "Vydsêke",
            "Wôlbrokke",
            "Nytgâard",
            "Flytstêp",
            "Hârvyr",
            "Blôhdfyr",
            "Sêyr",
            "lyrnstêde",
            "Deyprôkh",
            "Faenwrôght",
            "Änstyr",
            "Styrdâh",
            "Fyrfist",
            "Beast",
            "Slayer",
            "Ork Bane",
            "the Stoic",
            "the Skilled",
            "the Strong",
            "the Intrepid",
            "the Unyielding",
            "the Fierce"
        ]
    ],
    history: [
        "Mercenaries",
        "Wreck-wisened",
        "Broken League",
        "Van of the Prospect",
        "Former Hernkyn",
        "Rising Kindred"
    ],
    baseOfOperations: [
        "Hekaton Land Fortresses",
        "H3-C Recon Salvage Shelter Pack",
        "Comet-class Scout Ship",
        "'We Need Only What We Carry, We Carry Only What We Need'",
        "Sagitaur Squadron",
        "Hernkyn Hideaway"
    ],
    squadQuirk: [
        "Sailed to Far-space",
        "Grudgebrand",
        "Adventurers",
        "'That's not Kin'",
        "Treasure Seekers",
        "'The Void is in Our Veins'"
    ],
    strategicAssets: [
        "Supply Hold",
        "Enhanced Pan Spectral Scanner",
        "Excavation Machinery"
    ],
    equipment: [
        {
            name: "Gravitic Concussion Grenade",
            cost: 2
        },
        {
            name: "Plasma Knife",
            cost: 1
        },
        {
            name: "Climbing Equipment",
            cost: 1
        },
        {
            name: "Weavefield Crest",
            cost: 3
        },
        {
            name: "Excavation Tool",
            cost: 2
        }
    ],
    rareEquipment: [
        {
            name: "A Grudge Declared",
            cost: 3
        },
        {
            name: "Darkstar Weapon",
            cost: 2
        },
        {
            name: "Relic Plate",
            cost: 3
        },
        {
            name: "Right of Claim",
            cost: 3
        },
        {
            name: "Grav-lift Device",
            cost: 2
        },
        {
            name: "Ion Expediter",
            cost: 2
        }
    ],
    specOps: ["Recover Kin-tech", "Grudgeband"]
};

export default info;

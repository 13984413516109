// raw KT data
import { FARSTALKER_ID } from "../../../constants/factions";

const killTeam = {
    id: FARSTALKER_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Kroot Kill-broker",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Kroot rifle, Blade",
                    "Pulse Carbine, Blade",
                    "Pulse Rifle, Blade"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Kroot Bow-hunter",
            limit: 1,
            weapons: [["Accelerator bow, Blade"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kroot Cold-blood",
            limit: 1,
            weapons: [["Kroot Rifle, Blade"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Kroot Cut-skin",
            limit: 1,
            weapons: [["Cut-skin's blades"]],
            specialisms: ["combat"]
        },
        {
            name: "Kroot Heavy Gunner",
            limit: 1,
            weapons: [["Dvorgite skinner, Blade", "Londaxi tribalest, blade"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kroot Hound",
            limit: 2,
            weapons: [["Ripping fangs"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Kroot Long-sight",
            limit: 1,
            weapons: [["Kroot hunting rifle, Blade"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kroot Pistoler",
            limit: 1,
            weapons: [["Dual Kroot pistols, Blade"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kroot Stalker",
            limit: 1,
            weapons: [["Kroot scattergun, Stalker's blade"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Kroot Tracker",
            limit: 1,
            weapons: [["Kroot rifle, Blade"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kroot Warrior",
            limit: 10,
            weapons: [["Kroot rifle, blade", "Kroot scattergun, Blade"]],
            specialisms: ["combat", "marksman", "scout"]
        }
    ]
};

export default killTeam;

// raw KT data
import { VETERAN_GUARDSMAN_ID } from "../../../constants/factions";

const killTeam = {
    id: VETERAN_GUARDSMAN_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Sergeant Veteran",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Boltgun, Bayonet",
                    "Bolt pistol, Chainsword",
                    "Bolt Pistol, Power weapon",
                    "Laspistol, Chainsword",
                    "Laspistol, Power weapon",
                    "Plasma pistol, Chainsword",
                    "Plasma pistol, Power weapon"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Trooper Veteran",
            limit: 9,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Confidant Veteran",
            limit: 1,
            weapons: [
                [
                    "Boltgun, Bayonet",
                    "Lasgun, Bayonet",
                    "Bolt pistol, Chainsword",
                    "Laspistol, Chainsword"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Zealot Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["staunch"]
        },
        {
            name: "Medic Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Comms Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Demolition Veteran",
            limit: 1,
            weapons: [["Lasgun, Remote mine, Bayonet"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Bruiser Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet, Trench club"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Hardened Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet, Bionic arm"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Sniper Veteran",
            limit: 1,
            weapons: [["Long-las, Bayonet"]],
            specialisms: ["marksman"]
        },
        {
            name: "Spotter Veteran",
            limit: 1,
            weapons: [["Lasgun, Mortar barrage, Bayonet"]],
            specialisms: ["scout"]
        },
        {
            name: "Gunner Veteran - equipped with a bayonet and grenade launcher",
            limit: 1,
            weapons: [["Bayonet, Grenade Launcher"]],
            specialisms: ["marksman"]
        },
        {
            name: "Gunner Veteran - equipped with a bayonet and meltagun",
            limit: 1,
            weapons: [["Bayonet, Meltagun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Gunner Veteran - equipped with a bayonet and plasma gun",
            limit: 1,
            weapons: [["Bayonet, Plasma gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Gunner Veteran - equipped with a bayonet and flamer",
            limit: 1,
            weapons: [["Bayonet, Flamer"]],
            specialisms: ["marksman"]
        }
    ],
    ancilliarySupport: ["4 TROOPER VETERANS", "Tactical Assets"],
    tacticalAssets: [
        "Artillery Barrage",
        "Marked Air Strike",
        "Guided Missile",
        "Strafing Run"
    ]
};

export default killTeam;

import { LANDING_PAGE } from "../constants/pages";

const nullState = {
    version: 2,
    activePage: LANDING_PAGE,
    activeList: null,
    fresh: true,
    lists: {
        byId: {},
        allIds: []
    }
};

export default nullState;

// raw KT data
import { HEARTHKYN_SALVAGERS } from "../../../constants/factions";

const killTeam = {
    id: HEARTHKYN_SALVAGERS,
    fireTeams: false,
    operatives: [
        {
            name: "Hearthkyn Theyn",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Autoch-pattern bolt pistol",
                    "Autoch-pattern bolter",
                    "Bolt revolver",
                    "EtaCarn plasma pistol",
                    "Ion blaster",
                    "Ion pistol"
                ],
                ["Concussion gauntlet", "Plasme axe", "Plasma sqord"]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Hearthkyn Dôzr",
            limit: 1,
            weapons: [["Autoch-pattern bolt pistol, Concussion knux"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Hearthkyn Field Medic",
            limit: 1,
            weapons: [["Bolt revolver, Plasma knife"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Hearthkyn Grenadier",
            limit: 1,
            weapons: [["Autoch-pattern bolt pistol, C8 HX charge, Fists"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Hearthkyn Gunner",
            limit: 3,
            weapons: [
                [
                    "EtaCarn plasma beamer, fists",
                    "HYLas auto rifle, fists",
                    "HYLas rotary cannon, fists",
                    "L7 missile launcher, fists",
                    "Magna rail rifle, fists"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman", "scout"]
        },
        {
            name: "Hearthkyn Jump Pack Warrior",
            limit: 1,
            weapons: [["Autoch-pattern bolt pistol, Plamsa axe"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Hearthkyn Kinlynk",
            limit: 1,
            weapons: [["Autoch-pattern bolter, fists", "Ion blaster, fists"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Hearthkyn Kognitâar",
            limit: 1,
            weapons: [["Autoch-pattern bolter, fists", "Ion blaster, fists"]],
            specialisms: ["staunch"]
        },
        {
            name: "Hearthkyn Lokâtr",
            limit: 1,
            weapons: [["Autoch-pattern bolter, fists", "Ion blaster, fists"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Hearthkyn Lugger",
            limit: 1,
            weapons: [["Autoch-pattern bolter, fists", "Ion blaster, fists"]],
            specialisms: ["staunch"]
        },
        {
            name: "Hearthkyn Warrior",
            weapons: [["Autoch-pattern bolter, fists", "Ion blaster, fists"]],
            specialisms: ["staunch", "marksman"]
        }
    ]
};

export default killTeam;

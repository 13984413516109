import { INQUISITORIAL_AGENT } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const InquisitorialAgentController = {
    id: INQUISITORIAL_AGENT,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        const containsSistersOfSilence =
            operativesInKillTeam.filter(Boolean).filter((operative) => {
                return (
                    operative.unit === "Sister of Silence Prosecutor" ||
                    operative.unit === "Sister of Silence Witchseeker" ||
                    operative.unit === "Sister of Silence Vigilator"
                );
            }).length > 0;

        const containsTempestusScions =
            operativesInKillTeam.filter(Boolean).filter((operative) => {
                return (
                    operative.unit === "Tempestus Scion Trooper" ||
                    operative.unit === "Tempestus Scion Comms" ||
                    operative.unit ===
                        "Tempestus Scion Gunner equipped with: Gun butt, Flamer" ||
                    operative.unit ===
                        "Tempestus Scion Gunner equipped with: Gun butt, Grenade launcher" ||
                    operative.unit ===
                        "Tempestus Scion Gunner equipped with: Gun butt, Hot-shot volley gun" ||
                    operative.unit ===
                        "Tempestus Scion Gunner equipped with: Gun butt, Meltagun" ||
                    operative.unit ===
                        "Tempestus Scion Gunner equipped with: Gun butt, Plasma gun"
                );
            }).length > 0;

        const containsExactionSquad =
            operativesInKillTeam.filter(Boolean).filter((operative) => {
                return (
                    operative.unit === "Arbites Castigator" ||
                    operative.unit === "Arbites Chirurgant" ||
                    operative.unit === "Arbites Gunner" ||
                    operative.unit === "Arbites Leashmaster" ||
                    operative.unit === "Arbites Malocator" ||
                    operative.unit === "Arbites Marksman" ||
                    operative.unit === "Arbites Revelatum" ||
                    operative.unit === "Arbites Subductor" ||
                    operative.unit === "Arbites Vigilant" ||
                    operative.unit === "Arbites Vox-signifier" ||
                    operative.unit === "R-VR Cyber-mastiff"
                );
            }).length > 0;

        const containsImperialNavyBreachers =
            operativesInKillTeam.filter(Boolean).filter((operative) => {
                return (
                    operative.unit === "Navis Armsman" ||
                    operative.unit === "Navis Axejack" ||
                    operative.unit === "Navis C.A.T Unit" ||
                    operative.unit === "Navis Endurant" ||
                    operative.unit === "Navis Gheistskull" ||
                    operative.unit === "Navis Grenadier" ||
                    operative.unit ===
                        "Navis Gunner equipped with: Navis las-volley, Gun butt" ||
                    operative.unit ===
                        "Navis Gunner equipped with: Meltagun, Gun butt" ||
                    operative.unit ===
                        "Navis Gunner equipped with: Plasma gun, Gun butt" ||
                    operative.unit === "Navis Hatchcutter" ||
                    operative.unit === "Navis Surveyor" ||
                    operative.unit === "Navis Void-jammer"
                );
            }).length > 0;

        const containsKasrkin =
            operativesInKillTeam.filter(Boolean).filter((operative) => {
                return (
                    operative.unit === "Kasrkin Combat Medic" ||
                    operative.unit === "Kasrkin Demo-trooper" ||
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Flamer" ||
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Grenade launcher" ||
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Hot-shot volley gun" ||
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Meltagun" ||
                    operative.unit ===
                        "Kasrkin Gunner equipped with: Gun butt, Plasma gun" ||
                    operative.unit === "Kasrkin Recon-trooper" ||
                    operative.unit === "Kasrkin Sharpshooter" ||
                    operative.unit === "Kasrkin Trooper" ||
                    operative.unit === "Kasrkin Vox-trooper"
                );
            }).length > 0;

        const containsVeteranGuardsmen =
            operativesInKillTeam.filter(Boolean).filter((operative) => {
                return (
                    operative.unit === "Trooper Veteran" ||
                    operative.unit === "Zealot Veteran" ||
                    operative.unit === "Medic Veteran" ||
                    operative.unit === "Comms Veteran" ||
                    operative.unit === "Demolition Veteran" ||
                    operative.unit === "Bruiser Veteran" ||
                    operative.unit === "Hardened Veteran" ||
                    operative.unit === "Sniper Veteran" ||
                    operative.unit ===
                        "Gunner Veteran - equipped with a bayonet and grenade launcher" ||
                    operative.unit ===
                        "Gunner Veteran - equipped with a bayonet and meltagun" ||
                    operative.unit ===
                        "Gunner Veteran - equipped with a bayonet and plasma gun" ||
                    operative.unit ===
                        "Gunner Veteran - equipped with a bayonet and flamer"
                );
            }).length > 0;

        // const hasAncillaryOperatives =
        //     containsSistersOfSilence ||
        //     containsTempestusScions ||
        //     containsExactionSquad ||
        //     containsImperialNavyBreachers ||
        //     containsKasrkin ||
        //     containsVeteranGuardsmen;

        // contains correct number of bros
        if (containsImperialNavyBreachers || containsVeteranGuardsmen) {
            if (operativesInKillTeam.length !== 14) {
                passed = false;
                messages.push("Must include 14 operatives");
            }
        } else {
            if (operativesInKillTeam.length !== 12) {
                passed = false;
                messages.push("Must include 12 operatives");
            }
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Interrogator";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Interrogator");
        }
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Tome-skull";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Tome-skull");
        }

        [
            "Autosavant",
            "Questkeeper",
            "Death World Veteran",
            "Enlightened",
            "Gun Servitor",
            "Hexorcist",
            "Mystic",
            "Penal Legionnaire",
            "Pistolier"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        // ANCILLARY COMPARISON
        if (
            (containsSistersOfSilence && containsTempestusScions) ||
            (containsSistersOfSilence && containsExactionSquad) ||
            (containsSistersOfSilence && containsImperialNavyBreachers) ||
            (containsSistersOfSilence && containsKasrkin) ||
            (containsSistersOfSilence && containsVeteranGuardsmen) ||
            //
            (containsTempestusScions && containsExactionSquad) ||
            (containsTempestusScions && containsImperialNavyBreachers) ||
            (containsTempestusScions && containsKasrkin) ||
            (containsTempestusScions && containsVeteranGuardsmen) ||
            //
            (containsExactionSquad && containsImperialNavyBreachers) ||
            (containsExactionSquad && containsKasrkin) ||
            (containsExactionSquad && containsVeteranGuardsmen) ||
            //
            (containsImperialNavyBreachers && containsKasrkin) ||
            (containsImperialNavyBreachers && containsVeteranGuardsmen) ||
            //
            (containsKasrkin && containsVeteranGuardsmen)
        ) {
            passed = false;
            messages.push(`Maximum of 1 type of Ancillary Support`);
        }

        // containsSistersOfSilence
        // containsTempestusScions
        // containsExactionSquad
        // containsImperialNavyBreachers
        // containsKasrkin
        // containsVeteranGuardsmen

        // TEMPESTUS SCIONS
        if (containsTempestusScions) {
            [
                "Tempestus Scion Comms",
                "Tempestus Scion Gunner equipped with: Gun butt, Flamer",
                "Tempestus Scion Gunner equipped with: Gun butt, Grenade launcher",
                "Tempestus Scion Gunner equipped with: Gun butt, Hot-shot volley gun",
                "Tempestus Scion Gunner equipped with: Gun butt, Meltagun",
                "Tempestus Scion Gunner equipped with: Gun butt, Plasma gun",
                "Tempestus Scion Medic"
            ].forEach((unit) => {
                if (
                    operativesInKillTeam.filter((operative) => {
                        return unit === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(`Maximum of 1 ${unit} in KILL TEAM`);
                }
            });

            if (
                operativesInKillTeam.filter((operative) => {
                    return (
                        operative.unit ===
                            "Tempestus Scion Gunner equipped with: Gun butt, Flamer" ||
                        operative.unit ===
                            "Tempestus Scion Gunner equipped with: Gun butt, Grenade launcher" ||
                        operative.unit ===
                            "Tempestus Scion Gunner equipped with: Gun butt, Hot-shot volley gun" ||
                        operative.unit ===
                            "Tempestus Scion Gunner equipped with: Gun butt, Meltagun" ||
                        operative.unit ===
                            "Tempestus Scion Gunner equipped with: Gun butt, Plasma gun"
                    );
                }).length > 2
            ) {
                passed = false;
                messages.push(`Maximum of 2 Tempestus Scion Gunner operatives`);
            }
        }

        // EXACTION SQUAD
        if (containsExactionSquad) {
            // only 1 of these units
            [
                "Arbites Castigator",
                "Arbites Chirurgant",
                "Arbites Leashmaster",
                "Arbites Malocator",
                "Arbites Marksman",
                "Arbites Revelatum",
                "Arbites Vox-signifier",
                "R-VR Cyber-mastiff",
                "Arbites Gunner"
            ].forEach((unit) => {
                if (
                    operativesInKillTeam.filter((operative) => {
                        return unit === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(`Maximum of 1 ${unit}`);
                }
            });

            if (
                operativesInKillTeam.filter((operative) => {
                    return "Arbites Subductor" === operative.unit;
                }).length > 2
            ) {
                passed = false;
                messages.push(`Maximum of 2 Arbites Subductors`);
            }
        }

        // IMPERIAL NAVY BREACHERS
        if (containsImperialNavyBreachers) {
            // only 1 of these units
            [
                "Navis Axejack",
                "Navis C.A.T Unit",
                "Navis Endurant",
                "Navis Gheistskull",
                "Navis Grenadier",
                "Navis Gunner equipped with: Navis las-volley, Gun butt",
                "Navis Gunner equipped with: Meltagun, Gun butt",
                "Navis Gunner equipped with: Plasma gun, Gun butt",
                "Navis Hatchcutter",
                "Navis Surveyor",
                "Navis Void-jammer"
            ].forEach((unit) => {
                if (
                    operativesInKillTeam.filter((operative) => {
                        return unit === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(`Maximum of 1 ${unit}.`);
                }
            });

            // only pets if their masters are there
            // units present in KT
            const voidJammer =
                operativesInKillTeam.filter((operative) => {
                    return operative.unit === "Navis Void-jammer";
                }).length === 1;

            const gheistskull =
                operativesInKillTeam.filter((operative) => {
                    return operative.unit === "Navis Gheistskull";
                }).length === 1;

            const surveyor =
                operativesInKillTeam.filter((operative) => {
                    return operative.unit === "Navis Surveyor";
                }).length === 1;

            const catUnit =
                operativesInKillTeam.filter((operative) => {
                    return operative.unit === "Navis C.A.T. Unit";
                }).length === 1;

            if (gheistskull && !voidJammer) {
                passed = false;
                messages.push(
                    `Can only include a NAVIS GHEISTSKULL operative if it also includes a NAVIS VOID-JAMMER operative`
                );
            }

            if (catUnit && !surveyor) {
                passed = false;
                messages.push(
                    `Can only include a NAVIS C.A.T UNIT operative if it also includes a NAVIS SURVEYOR operative`
                );
            }
        }

        // KASRKIN
        if (containsKasrkin) {
            // only 1 of these units
            [
                "Kasrkin Combat Medic",
                "Kasrkin Demo-trooper",
                "Kasrkin Gunner equipped with: Gun butt, Flamer",
                "Kasrkin Gunner equipped with: Gun butt, Grenade launcher",
                "Kasrkin Gunner equipped with: Gun butt, Hot-shot volley gun",
                "Kasrkin Gunner equipped with: Gun butt, Meltagun",
                "Kasrkin Gunner equipped with: Gun butt, Plasma gun",
                "Kasrkin Recon-trooper",
                "Kasrkin Sharpshooter",
                "Kasrkin Vox-trooper"
            ].forEach((unit) => {
                if (
                    operativesInKillTeam.filter((operative) => {
                        return unit === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(`Maximum of 1 ${unit}.`);
                }
            });

            // max 4 gunner types
            if (
                operativesInKillTeam.filter((operative) => {
                    return (
                        operative.unit ===
                            "Kasrkin Gunner equipped with: Gun butt, Flamer" ||
                        operative.unit ===
                            "Kasrkin Gunner equipped with: Gun butt, Grenade launcher" ||
                        operative.unit ===
                            "Kasrkin Gunner equipped with: Gun butt, Hot-shot volley gun" ||
                        operative.unit ===
                            "Kasrkin Gunner equipped with: Gun butt, Meltagun" ||
                        operative.unit ===
                            "Kasrkin Gunner equipped with: Gun butt, Plasma gun" ||
                        operative.unit === "Kasrkin Sharpshooter"
                    );
                }).length > 2
            ) {
                passed = false;
                messages.push(
                    `Maximum of 2 KASRKIN GUNNER and KASRKIN SHARPSHOOTER units.`
                );
            }
        }

        if (containsVeteranGuardsmen) {
            // only 1 of non boy units
            [
                "Confidant Veteran",
                "Zealot Veteran",
                "Medic Veteran",
                "Comms Veteran",
                "Demolition Veteran",
                "Bruiser Veteran",
                "Hardened Veteran",
                "Sniper Veteran",
                "Spotter Veteran",
                "Gunner Veteran - equipped with a bayonet and grenade launcher",
                "Gunner Veteran - equipped with a bayonet and meltagun",
                "Gunner Veteran - equipped with a bayonet and plasma gun",
                "Gunner Veteran - equipped with a bayonet and flamer"
            ].forEach((unit) => {
                if (
                    operativesInKillTeam.filter((operative) => {
                        return unit === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(`Maximum of 1 ${unit} operative`);
                }
            });
        }

        // only 1 of these units
        // [
        //     "Hearthkyn Dôzr",
        //     "Hearthkyn Field Medic",
        //     "Hearthkyn Grenadier",
        //     "Hearthkyn Jump Pack Warrior",
        //     "Hearthkyn Kinlynk",
        //     "Hearthkyn Kognitâar",
        //     "Hearthkyn Lokâtr",
        //     "Hearthkyn Lugger"
        // ].forEach((unit) => {
        //     if (
        //         operativesInKillTeam.filter((operative) => {
        //             return unit === operative.unit;
        //         }).length > 1
        //     ) {
        //         passed = false;
        //         messages.push(`Maximum of 1 ${unit}`);
        //     }
        // });

        // max 3 gunners
        // if (
        //     operativesInKillTeam.filter((operative) => {
        //         return "Hearthkyn Gunner" === operative.unit;
        //     }).length > 3
        // ) {
        //     passed = false;
        //     messages.push(`Maximum of 3 Hearthkyn Gunners`);
        // }

        // max of 1 weapon type
        // if (
        //     operativesInKillTeam.filter((operative) => {
        //         return operative.weapons[0].includes("EtaCarn plasma beamer");
        //     }).length > 1
        // ) {
        //     passed = false;
        //     messages.push(`Maximum of 1 EtaCarn plasma beamer per Kill Team.`);
        // }

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        // const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default InquisitorialAgentController;

/*

"Interrogator",
"Tome-skull",
"Autosavant",
"Questkeeper",
"Death World Veteran",
"Enlightened",
"Gun Servitor",
"Hexorcist",
"Mystic",
"Penal Legionnaire",
"Pistolier",
"Sister of Silence Prosecutor",
"Sister of Silence Witchseeker",
"Sister of Silence Vigilator",
"Tempestus Scion Trooper",
"Tempestus Scion Comms",
"Tempestus Scion Gunner equipped with: Gun butt, Flamer",
"Tempestus Scion Gunner equipped with: Gun butt, Grenade launcher",
"Tempestus Scion Gunner equipped with: Gun butt, Hot-shot volley gun",
"Tempestus Scion Gunner equipped with: Gun butt, Meltagun",
"Tempestus Scion Gunner equipped with: Gun butt, Plasma gun",
"Arbites Castigator",
"Arbites Chirurgant",
"Arbites Gunner",
"Arbites Leashmaster",
"Arbites Malocator",
"Arbites Marksman",
"Arbites Revelatum",
"Arbites Subductor",
"Arbites Vigilant",
"Arbites Vox-signifier",
"R-VR Cyber-mastiff",
"Navis Armsman",
"Navis Axejack",
"Navis C.A.T Unit",
"Navis Endurant",
"Navis Gheistskull",
"Navis Grenadier",
"Navis Gunner equipped with: Navis las-volley, Gun butt",
"Navis Gunner equipped with: Meltagun, Gun butt",
"Navis Gunner equipped with: Plasma gun, Gun butt",
"Navis Hatchcutter",
"Navis Surveyor",
"Navis Void-jammer",
"Kasrkin Combat Medic",
"Kasrkin Demo-trooper",
"Kasrkin Gunner equipped with: Gun butt, Flamer",
"Kasrkin Gunner equipped with: Gun butt, Grenade launcher",
"Kasrkin Gunner equipped with: Gun butt, Hot-shot volley gun",
"Kasrkin Gunner equipped with: Gun butt, Meltagun",
"Kasrkin Gunner equipped with: Gun butt, Plasma gun",
"Kasrkin Recon-trooper",
"Kasrkin Sharpshooter",
"Kasrkin Trooper",
"Kasrkin Vox-trooper",
"Trooper Veteran",
"Zealot Veteran",
"Medic Veteran",
"Comms Veteran",
"Demolition Veteran",
"Bruiser Veteran",
"Hardened Veteran",
"Sniper Veteran",
"Gunner Veteran - equipped with a bayonet and grenade launcher",
"Gunner Veteran - equipped with a bayonet and meltagun",
"Gunner Veteran - equipped with a bayonet and plasma gun",
"Gunner Veteran - equipped with a bayonet and flamer",

*/

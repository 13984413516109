// names
// backgrounds etc...
import { KASRKIN_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: KASRKIN_ID,
    moniker: "Kasrkin",
    restrictions: restrictions,
    battleHonours: [
        "War-hardened",
        "Vengeful",
        "Resolute",
        "Kill shot",
        "Sharp Instincts",
        "Independent Equipage"
    ],
    // killTeamNames: [],
    operativeNames: [
        [
            "Jens",
            "Karsk",
            "Otwin",
            "Hekler",
            "Reeve",
            "Pavlo",
            "Hektor",
            "Ogan",
            "Thenmann",
            "Kyser",
            "Erlen",
            "Raphe",
            "Creed",
            "Ackerman",
            "Mattias",
            "Mortens",
            "Dansk",
            "Feodor",
            "Tomas",
            "Kolson",
            "Vance",
            "Pask",
            "Niems",
            "Gryf",
            "Willem",
            "Sonnen",
            "Echter",
            "Farestein",
            "Dekker",
            "Graf",
            "Arvans",
            "Viers",
            "Kolm",
            "Bask",
            "Vesker",
            "Henker"
        ]
    ],
    history: [
        "Elite Guards",
        "Hardened Veterans",
        "Forward Ops Experts",
        "Whiteshield Corps",
        "Cadian Through and Through",
        "Sappers"
    ],
    baseOfOperations: [
        "Chimeras",
        "Valkyries",
        "Under the Stars",
        "Barracks",
        "Forward Operating Base",
        "Wild Lair"
    ],
    squadQuirk: [
        "Parade Groud Drilled",
        "Survivors of Cadia",
        "Faithful",
        "A Cut Above the Rest",
        "Iron-hard Killers",
        "Diehards"
    ],
    strategicAssets: [
        "Mission Command",
        "Field Recovery Unit",
        "Shrine to Lost Cadia"
    ],
    equipment: [
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Foregrip",
            cost: 1
        },
        {
            name: "Long-range Scope",
            cost: 2
        },
        {
            name: "Adrenaline Shot",
            cost: 1
        },
        {
            name: "Decoy Grenade",
            cost: 2
        },
        {
            name: "Stun Grenade",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Plasteel Armour Enhancement",
            cost: 1
        },
        {
            name: "Tactical Vox-net",
            cost: 3
        },
        {
            name: "Memento of Cadia",
            cost: 4
        },
        {
            name: "Diagnosticator",
            cost: 2
        },
        {
            name: "Emperor's Light",
            cost: 3
        },
        {
            name: "Kasrkin Surveyor",
            cost: 2
        }
    ],
    specOps: ["Neutralise Threat", "Special Forces"]
};

export default info;

// raw KT data
import { WYRMBLADE_ID } from "../../../constants/factions";

const killTeam = {
    id: WYRMBLADE_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Elucia Vhane",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Digital laser, Heirloom relic pistol, Monomolecular cane-rapier"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Canid",
            limit: 1,
            weapons: [["Vicious bite"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Death Cult Executioner",
            limit: 1,
            weapons: [["Dartmask, Power weapon"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Lectro-Maester",
            limit: 1,
            weapons: [["Voltaic pistol, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Rejuvenat Adept",
            limit: 1,
            weapons: [["Laspistol, Scalpel claw"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Voidmaster",
            limit: 1,
            weapons: [["Artificer shotgun, Laspistol, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Voidsman equipped with: Lasgun and Gun-butt",
            limit: 3,
            weapons: [["Lasgun and Gun-butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Voidsman equipped with: Rotor Cannon and Gun-butt",
            limit: 1,
            weapons: [["Rotor Cannon and Gun-butt"]],
            specialisms: ["staunch", "marksman"]
        }
    ]
};

export default killTeam;

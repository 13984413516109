import styled from "styled-components";
import theme from "./theme";

const Landing = styled.div`
    padding-top: 1em;

    .landing__new-wrap {
        padding-top: 1em;
    }

    .landing__new-faction {
        display: block;

        label {
            display: block;
            font-family: ${theme.fonts.header};
            font-size: 0.8em;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            padding-bottom: 0.4em;
            padding-left: 0.3em;
        }
    }

    .landing__new-faction {
        padding-bottom: 0.8em;
    }

    .tooltip {
        color: ${theme.colors.red};
        padding-bottom: 1em;
    }

    .landing__button {
        text-align: center;
    }

    .landing__button-ready {
        font-size: 1.6em;
        border: none;
        background: ${theme.colors.orange};
        color: ${theme.colors.black};
        font-family: ${theme.fonts.header};
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding: 0.5em 2em;
        margin: 1em auto;
        transition: all 0.3s ease;

        &:hover {
            background: ${theme.colors.darkGrey};
            color: ${theme.colors.white};
        }
    }
`;

export default Landing;

// names
// backgrounds etc...
import { DEATH_GUARD_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: DEATH_GUARD_ID,
    moniker: "Death Guard",
    restrictions: restrictions,
    equipment: [
        {
            name: "Nurgling",
            cost: 3
        },
        {
            name: "Plague bell",
            cost: 3
        },
        {
            name: "Blight grenade",
            cost: 2
        },
        {
            name: "Krak grenade",
            cost: 3
        },
        {
            name: "Filth censer",
            cost: 3
        },
        {
            name: "Virulent rounds",
            cost: 1
        },
        {
            name: "Mephitic toxin",
            cost: 2
        }
    ]
};

export default info;

// raw KT data
import { HIEROTEK_CIRCLE_ID } from "../../../constants/factions";

const killTeam = {
    id: HIEROTEK_CIRCLE_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Chronomancer",
            limit: 1,
            leader: true,
            weapons: [["Aeonstave", "Entropic lance"]],
            specialisms: ["staunch", "marksman", "scout"]
        },
        {
            name: "Psychomancer",
            limit: 1,
            leader: true,
            weapons: [["Abyssal lance"]],
            specialisms: ["staunch", "marksman", "scout"]
        },
        {
            name: "Technomancer",
            limit: 1,
            leader: true,
            weapons: [["Staff of light"]],
            specialisms: ["staunch", "marksman", "scout"]
        },
        {
            name: "Plasmacyte Accelerator",
            limit: 1,
            weapons: [["Spark, Claws"]],
            specialisms: ["scout"]
        },
        {
            name: "Plasmacyte Reanimator",
            limit: 1,
            weapons: [["Spark, Claws"]],
            specialisms: ["scout"]
        },
        {
            name: "Apprentek",
            limit: 1,
            weapons: [["Arcane conduit"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Deathmark",
            weapons: [["Synaptic disintegrator, Fists"]],
            specialisms: ["marksman"]
        },
        {
            name: "Immortal Despotek",
            limit: 1,
            weapons: [["Gauss blaster, Bayonet", "Tesla carbine, Bayonet"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Immortal Guardian",
            weapons: [["Gauss blaster, Bayonet", "Tesla carbine, Bayonet"]],
            specialisms: ["staunch", "marksman"]
        }
    ]
};

export default killTeam;

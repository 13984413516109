const specialisms = {
    combat: [
        "Inexorable",
        "Bladework",
        "Dirty Fighter",
        "Vicious",
        "Duellist",
        "Savage"
    ],
    staunch: [
        "Resillient",
        "Shrug Off",
        "Focused",
        "Stalwart",
        "Invulnerable",
        "Indomitable"
    ],
    marksman: [
        "Gun Ace",
        "Crack Shot",
        "Calculated",
        "Careful Aim",
        "Sharpshooter",
        "Death From Above"
    ],
    scout: ["Runner", "Swift", "Covert", "Picket", "Evasive", "Dodge"]
};

export default specialisms;

import { FARSTALKER_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const FarstalkerController = {
    id: FARSTALKER_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length < 12) {
            passed = false;
            messages.push("Must include 11 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Kroot Kill-broker";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Kroot Kill-broker");
        }

        // only 1 of these units
        [
            "Kroot Bow-hunter",
            "Kroot Cold-blood",
            "Kroot Cut-skin",
            "Kroot Heavy Gunner",
            "Kroot Long-sight",
            "Kroot Pistoler",
            "Kroot Stalker",
            "Kroot Tracker"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}.`);
            }
        });

        // max 2 gunners
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Kroot Hound";
            }).length > 2
        ) {
            passed = false;
            messages.push(`Maximum of 2 KROOT HOUND operatives`);
        }

        return {
            passed,
            messages
        };
    },

    // randomiseTeamName: (names) => {
    //     const first = names[0][Math.floor(Math.random() * names[0].length)];
    //     const second = names[1][Math.floor(Math.random() * names[1].length)];

    //     return `${first} ${second}`;
    // },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];
        const third = names[2][Math.floor(Math.random() * names[2].length)];

        return `${first}${second} ${third}`;
    }
};

export default FarstalkerController;

// raw KT data
import { WARPCOVEN_ID } from "../../../constants/factions";

const killTeam = {
    id: WARPCOVEN_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Sorcerer",
            limit: 3,
            leader: true,
            weapons: [
                [
                    "Force stave, Inferno bolt pistol",
                    "Force stave, Prosperine khopesh",
                    "Force stave, Warpflame pistol"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Rubric Marine Gunner",
            limit: 2,
            weapons: [["Fists, Warpflamer", "Fists, Soulreaper cannon"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Rubric Marine Icon Bearer",
            limit: 1,
            weapons: [["Inferno boltgun, Fists"]],
            specialisms: ["staunch"]
        },
        {
            name: "Rubric Marine Warrior",
            limit: 5,
            weapons: [["Inferno boltgun, Fists"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Tzaangor Champion",
            limit: 1,
            weapons: [["Tzaangor greataxe", "Tzaangor greatblade"]],
            specialisms: ["combat"]
        },
        {
            name: "Tzaangor Fighter",
            limit: 10,
            weapons: [["Autopistol, Chainsword", "Tzaangor blades"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Tzaangor Horn Bearer",
            limit: 1,
            weapons: [["Dagger"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Tzaangor Icon Bearer",
            limit: 1,
            weapons: [["Dagger"]],
            specialisms: ["combat", "scout"]
        }
    ]
};

export default killTeam;

// names
// backgrounds etc...
import { CADRE_MERCENARY_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: CADRE_MERCENARY_ID,
    moniker: "Cadre Mercenary",
    restrictions: restrictions,
    equipment: [
        {
            name: "Kroot Hunting Rifle",
            cost: 3
        },
        {
            name: "Sure-foot Charm",
            cost: 2
        },
        {
            name: "Skinning Blade",
            cost: 2
        },
        {
            name: "Trophy Pistol",
            cost: 3
        },
        {
            name: "Bolas",
            cost: 3
        }
    ]
};

export default info;

import { HUNTER_CLADE_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const HunterCladeController = {
    id: HUNTER_CLADE_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        // if (operativesInKillTeam.length !== 10) {
        //     passed = false;
        //     messages.push("Must include 10 operatives");
        // }

        // must include 11 operatives - balance dataslate Q2 2022
        if (operativesInKillTeam.length !== 11) {
            passed = false;
            messages.push("Must include 11 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Skitarii Ranger Alpha" ||
                    operative.unit === "Skitarii Vanguard Alpha" ||
                    operative.unit === "Sicarian Ruststalker Princeps" ||
                    operative.unit === "Sicarian Infiltrator Princeps"
                );
            }).length !== 1
        ) {
            passed = false;
            messages.push(
                "Must include 1 Skitarii Ranger Alpha/Skitarii Vanguard Alpha/Sicarian Ruststalker Princeps/Sicarian Infiltrator Princeps"
            );
        }

        // only 1 of special units
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Skitarii Ranger Diktat" ||
                    operative.unit === "Skitarii Vanguard Diktat"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 DIKTAT operative`);
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Skitarii Ranger Surveyor" ||
                    operative.unit === "Skitarii Vanguard Surveyor"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 SURVEYOR operative`);
        }

        // more skitarii than sicarian
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Skitarii Ranger Alpha" ||
                    operative.unit === "Skitarii Vanguard Alpha" ||
                    operative.unit === "Skitarii Ranger Marksman" ||
                    operative.unit === "Skitarii Ranger Diktat" ||
                    operative.unit === "Skitarii Ranger Gunner" ||
                    operative.unit === "Skitarii Ranger Surveyor" ||
                    operative.unit === "Skitarii Vanguard Shocktrooper" ||
                    operative.unit === "Skitarii Vanguard Diktat" ||
                    operative.unit === "Skitarii Vanguard Gunner" ||
                    operative.unit === "Skitarii Vanguard Surveyor"
                );
            }).length <
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Sicarian Ruststalker Princeps" ||
                    operative.unit === "Sicarian Infiltrator Princeps" ||
                    operative.unit === "Sicarian Ruststalker Assassin" ||
                    operative.unit === "Sicarian Infiltrator Tracker"
                );
            }).length
        ) {
            passed = false;
            messages.push(
                `Must include more SKITARII RANGER and SKITARII VANGUARD operatives than SICARIAN operatives`
            );
        }

        // only 2 gunners, unless it has 3 of less sicarians, then it can have 3 gunners
        // if (
        //     operativesInKillTeam.filter((operative) => {
        //         return (
        //             operative.unit === "Skitarii Ranger Gunner" ||
        //             operative.unit === "Skitarii Vanguard Gunner"
        //         );
        //     }).length > 2
        // ) {
        //     if (
        //         operativesInKillTeam.filter((operative) => {
        //             return (
        //                 operative.unit === "Sicarian Ruststalker Princeps" ||
        //                 operative.unit === "Sicarian Infiltrator Princeps" ||
        //                 operative.unit === "Sicarian Ruststalker Assassin" ||
        //                 operative.unit === "Sicarian Infiltrator Tracker"
        //             );
        //         }).length < 4
        //     ) {
        //         if (
        //             operativesInKillTeam.filter((operative) => {
        //                 return (
        //                     operative.unit === "Skitarii Ranger Gunner" ||
        //                     operative.unit === "Skitarii Vanguard Gunner"
        //                 );
        //             }).length > 3
        //         ) {
        //             // More than 3 gunner and less than 4 sicarian
        //             passed = false;
        //             messages.push(`Maximum of 3 GUNNER operatives`);
        //         }
        //     } else {
        //         // more than 2 gunners and more than 3 sicarians
        //         passed = false;
        //         messages.push(
        //             `Maximum of 2 GUNNER operatives when there are more than 3 SICARIAN operatives`
        //         );
        //     }
        // }

        // only 3 gunners - balance dataslate Q2 2022
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Skitarii Ranger Gunner" ||
                    operative.unit === "Skitarii Vanguard Gunner"
                );
            }).length > 3
        ) {
            passed = false;
            messages.push(`Maximum of 3 GUNNER operatives`);
        }

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const num = Math.floor(Math.random() * (5 - 1) + 1);
        let name = "";

        [...Array(num)].forEach((index) => {
            name += `${names[Math.floor(Math.random() * names.length)]} `;
        });

        return name;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default HunterCladeController;

const rareEquipment = [
    {
        name: "Auto-loader",
        cost: 2
    },
    {
        name: "Flux Capacitor",
        cost: 2
    },
    {
        name: "Rending Rounds",
        cost: 2
    },
    {
        name: "Propulsion Amplifier",
        cost: 2
    },
    {
        name: "Thermal Sight",
        cost: 2
    },
    {
        name: "Seeker Spirit",
        cost: 2
    },
    //
    {
        name: "Power Enhancer",
        cost: 2
    },
    {
        name: "Inertia Displacer",
        cost: 2
    },
    {
        name: "Perfectly Weighted",
        cost: 2
    },
    {
        name: "Rending Blade",
        cost: 2
    },
    {
        name: "Monomolecular Edge",
        cost: 2
    },
    {
        name: "Arc Unit",
        cost: 2
    }
];

export default rareEquipment;

// names
// backgrounds etc...
import { FELLGORE_RAVAGERS } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: FELLGORE_RAVAGERS,
    moniker: "Fellgor Ragavers",
    restrictions: restrictions,
    battleHonours: [
        "Wild",
        "Savage Fortitude",
        "Fervent",
        "Primal Mark",
        "Ruthless",
        "Sustained By Conflict"
    ],
    // killTeamNames: [
    //     [
    //         "The Wreck Scrapers",
    //         "The Relic Hunters",
    //         "The Beast Purgers",
    //         "The Hulk Bravers",
    //         "The Warp Divers",
    //         "The Avengers of Vymm Ransa"
    //     ]
    // ],
    operativeNames: [
        [
            "Gor",
            "Gnarrukh",
            "Veghor",
            "Hukr",
            "Karzog",
            "Vakvir",
            "Noktug",
            "Knagthuz",
            "Kraak",
            "Gorlem",
            "Bawgox",
            "Kurax",
            "Taorthuk",
            "Khulghur",
            "Maukhur",
            "Belghar",
            "Gethag",
            "Bulkur",
            "Vorec",
            "Slathox",
            "Knaucrix",
            "Gurgroc",
            "Morghur",
            "Zhaaguk",
            "Vonok",
            "Zathor",
            "Ghanox",
            "Unghan",
            "Knagol",
            "Vozaac",
            "Ungrol",
            "Tovuc",
            "Khurhuz",
            "Knorrox",
            "Mircor",
            "Khazrak"
        ],
        [
            "Redhoof",
            "Prey-ripper",
            "Gods-blessed",
            "Slaughterborn",
            "Skull-taker",
            "the Fiend",
            "Prey-biter",
            "Manslayer",
            "Halfhorn",
            "Razortooth",
            "Ironhoof",
            "the Flenser",
            "Bloodhorn",
            "the Chosen",
            "Loudbray",
            "Fyre-eyes",
            "Blackhoof",
            "One-eye",
            "the Cunning",
            "Great-tusk",
            "the Chronosatyr",
            "the Wargor",
            "Skullmaster",
            "Ravagor",
            "Ironbull",
            "Longhorn",
            "the Ur-twyst",
            "Blighthorn",
            "Grysthoof",
            "Onceman",
            "Redpelt",
            "Steelfur",
            "Razorhorn",
            "Sharpclaw",
            "Bullhoof",
            "Bloodcloven"
        ]
    ],
    history: [
        "Beast-born",
        "Wrap-corrupted",
        "Once-loyal",
        "Beholden to Dark Prophecy",
        "From Under-hoof to High-horn",
        "Dedicated to the Dark Powers"
    ],
    baseOfOperations: [
        "Bone-strewn Cave",
        "Forest Lair",
        "Despoiled Tech-shrine",
        "Ruined Armoury",
        "Underhive Sump",
        "Abandoned Vehicles"
    ],
    squadQuirk: [
        "Aspiring Beastlords",
        "Looters",
        "Bloodthirsty",
        "Elaborate Ambushers",
        "Disorder from Order",
        "Relentlessly Curel"
    ],
    strategicAssets: ["Corrupted Hollow", "Fellgor Herdstone", "Fighting Pit"],
    equipment: [
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Corrupted Rounds",
            cost: 1
        },
        {
            name: "Corrupted Weapon",
            cost: 2
        },
        {
            name: "Unholy Talisman",
            cost: 2
        },
        {
            name: "War Paint",
            cost: 1
        }
    ],
    rareEquipment: [
        {
            name: "Crown of Horns",
            cost: 2
        },
        {
            name: "Brass Hooves",
            cost: 2
        },
        {
            name: "Mighty Trophy",
            cost: 4
        },
        {
            name: "Dark Talisman",
            cost: 3
        },
        {
            name: "Goremark",
            cost: 2
        },
        {
            name: "Infernal Vambraces",
            cost: 3
        }
    ],
    specOps: ["A Champion Rises", "Survival of the Strongest"]
};

export default info;

// raw KT data
import { HIVE_FLEET_ID } from "../../../constants/factions";

const killTeam = {
    id: HIVE_FLEET_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Tyranid Warrior",
            units: [
                "Tyranid Warrior Leader",
                "Tyranid Warrior Fighter",
                "Tyranid Warrior Heavy Gunner"
            ]
        },
        {
            name: "Genestealer",
            units: ["Genestealer Leader", "Genestealer Fighter"]
        },
        {
            name: "Tyranid Swarm",
            units: ["Hormagaunt", "Termagaunt"]
        }
    ],
    operatives: [
        {
            name: "Tyranid Warrior Leader",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Boneswords",
                    "Deathspitter",
                    "Devourer",
                    "Rending claws",
                    "Scything talons",
                    "Spinefists",
                    "Lash whip, Bonesword"
                ],
                [
                    "Boneswords",
                    "Rending claws",
                    "Scything talons",
                    "Lash whip, Bonesword"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Tyranid Warrior Fighter",
            limit: 3,
            weapons: [
                [
                    "Boneswords",
                    "Deathspitter",
                    "Devourer",
                    "Rending claws",
                    "Scything talons",
                    "Spinefists",
                    "Lash whip, Bonesword"
                ],
                [
                    "Boneswords",
                    "Rending claws",
                    "Scything talons",
                    "Lash whip, Bonesword"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Tyranid Warrior Heavy Gunner",
            limit: 1,
            weapons: [
                ["Barbed strangler", "Venom cannon"],
                [
                    "Boneswords",
                    "Rending claws",
                    "Scything talons",
                    "Lash whip, Bonesword"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        //
        {
            name: "Genestealer Leader",
            limit: 1,
            leader: true,
            weapons: [
                ["Double rending claws", "Rending claws, Scything talons"]
            ],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Genestealer Fighter",
            limit: 5,
            weapons: [
                ["Double rending claws", "Rending claws, Scything talons"]
            ],
            specialisms: ["combat", "scout"]
        },
        //
        {
            name: "Hormagaunt",
            limit: 8,
            weapons: [["Scything talons"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Termagaunt",
            limit: 8,
            weapons: [["Claws"], ["Devourer", "Fleshborer", "Spinefists"]],
            specialisms: ["combat", "scout"]
        }
    ]
};

export default killTeam;

import React, { useContext } from "react";
import AppStateContext from "../context";
import StyledHeader from "../styles/StyledHeader";
import {
    LANDING_PAGE,
    ROSTER_PAGE,
    KILL_TEAM_PAGE,
    SPEC_OPS_PAGE,
    EXPORT_PAGE
} from "../constants/pages";

const Header = (props) => {
    const { appState, dispatch } = useContext(AppStateContext);

    const goToPage = (page) => {
        dispatch({
            type: "UPDATE_ACTIVE_PAGE",
            payload: page
        });
    };

    const isActive = (page) => {
        return appState.activePage === page;
    };

    return (
        <StyledHeader>
            <h1>Warhammer 40,000: Kill Team COMMAND ROSTER</h1>

            {appState.activePage !== LANDING_PAGE && (
                <>
                    <button
                        className="load-new-button"
                        onClick={() => goToPage(LANDING_PAGE)}
                    >
                        Load/New
                    </button>

                    <div className="tabs">
                        <ul>
                            <li>
                                <button
                                    className={
                                        isActive(ROSTER_PAGE) ? "active" : ""
                                    }
                                    onClick={() => {
                                        goToPage(ROSTER_PAGE);
                                    }}
                                >
                                    Roster
                                </button>
                            </li>
                            <li>
                                <button
                                    className={
                                        isActive(KILL_TEAM_PAGE) ? "active" : ""
                                    }
                                    onClick={() => {
                                        goToPage(KILL_TEAM_PAGE);
                                    }}
                                >
                                    Kill Team
                                </button>
                            </li>
                            <li>
                                <button
                                    className={
                                        isActive(SPEC_OPS_PAGE) ? "active" : ""
                                    }
                                    onClick={() => {
                                        goToPage(SPEC_OPS_PAGE);
                                    }}
                                >
                                    Spec Ops
                                </button>
                            </li>
                            <li>
                                <button
                                    className={
                                        isActive(EXPORT_PAGE) ? "active" : ""
                                    }
                                    onClick={() => {
                                        goToPage(EXPORT_PAGE);
                                    }}
                                >
                                    Export
                                </button>
                            </li>
                        </ul>
                    </div>
                </>
            )}
        </StyledHeader>
    );
};

export default Header;

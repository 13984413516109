// raw KT data
import { TALONS_OF_THE_EMPEROR_ID } from "../../../constants/factions";

const killTeam = {
    id: TALONS_OF_THE_EMPEROR_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Custodian Guard",
            units: ["Custodian Guard Leader", "Custodian Guard Warrior"]
        },
        {
            name: "Sister of Silence",
            units: [
                "Sister of Silence Superior",
                "Sister of Silence Prosecutor",
                "Sister of Silence Witchseeker",
                "Sister of Silence Vigilator"
            ]
        }
    ],
    operatives: [
        {
            name: "Custodian Guard Leader",
            limit: 1,
            leader: true,
            weapons: [["Guardian spear", "Sentinel blade, Storm shield"]],
            specialisms: ["combat", "staunch", "marksman", "scout"]
        },
        {
            name: "Custodian Guard Warrior",
            limit: 2,
            weapons: [["Guardian spear", "Sentinel blade, Storm shield"]],
            specialisms: ["combat", "staunch", "marksman", "scout"]
        },
        //
        {
            name: "Sister of Silence Superior",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Boltgun, Gun butt",
                    "Flamer, Gun butt",
                    "Executioner greatblade"
                ]
            ],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Sister of Silence Prosecutor",
            limit: 5,
            weapons: [["Boltgun, Gun butt"]],
            specialisms: ["staunch", "marksman", "scout"]
        },
        {
            name: "Sister of Silence Witchseeker",
            limit: 5,
            weapons: [["Flamer, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Sister of Silence Vigilator",
            limit: 5,
            weapons: [["Executioner greatblade"]],
            specialisms: ["combat", "staunch"]
        }
    ]
};

export default killTeam;

// raw KT data
import { HUNTER_CLADE_ID } from "../../../constants/factions";

const killTeam = {
    id: HUNTER_CLADE_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Skitarii Ranger Alpha",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Galvanic rifle, Gun Butt",
                    "Master-crafted radium pistol, power weapon",
                    "Arc pistol, Arc maul",
                    "Arc pistol, Taser goad",
                    "Phosphor blast pistol, Arc maul",
                    "Phosphor blast pistol, Taser goad"
                ]
            ],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Skitarii Vanguard Alpha",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Radium carbine, Gun Butt",
                    "Master-crafted radium pistol, power weapon",
                    "Arc pistol, Arc maul",
                    "Arc pistol, Taser goad",
                    "Phosphor blast pistol, Arc maul",
                    "Phosphor blast pistol, Taser goad"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Sicarian Ruststalker Princeps",
            limit: 1,
            leader: true,
            weapons: [["Chordclaw, Transonic blades"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Sicarian Infiltrator Princeps",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Flechette blaster, Power weapon",
                    "Flechette blaster, taser goad",
                    "Stubcarbine, Power weapon",
                    "Stubcarbine, Taser goad"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },

        //
        {
            name: "Skitarii Ranger Marksman",
            limit: 1,
            weapons: [["Galvanic rifle, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Skitarii Ranger Diktat",
            limit: 1,
            weapons: [["Galvanic rifle, Gun butt"]],
            specialisms: ["scout"]
        },
        {
            name: "Skitarii Ranger Gunner",
            limit: 1,
            weapons: [
                [
                    "Arc rifle, Gun butt",
                    "Plasma caliver, Gun butt",
                    "Transuranic arquebus, Gun butt"
                ]
            ],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Skitarii Ranger Surveyor",
            limit: 1,
            weapons: [["Galvanic rifle, Gun butt"]],
            specialisms: ["scout"]
        },
        {
            name: "Skitarii Vanguard Shocktrooper",
            limit: 1,
            weapons: [["Radium carbine, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Skitarii Vanguard Diktat",
            limit: 1,
            weapons: [["Radium carbine, Gun butt"]],
            specialisms: ["staunch"]
        },
        {
            name: "Skitarii Vanguard Gunner",
            limit: 1,
            weapons: [
                [
                    "Arc rifle, Gun butt",
                    "Plasma caliver, Gun butt",
                    "Transuranic arquebus, Gun butt"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Skitarii Vanguard Surveyor",
            limit: 1,
            weapons: [["Radium carbine, Gun butt"]],
            specialisms: ["staunch"]
        },
        {
            name: "Sicarian Ruststalker Assassin",
            limit: 1,
            weapons: [["Chordclaw, Transonic razor", "Transonic blades"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Sicarian Infiltrator Tracker",
            limit: 1,
            weapons: [
                [
                    "Flechette blaster, Power weapon",
                    "Flechette Blaster, Taser goad",
                    "Stubcarbine, Power weapon",
                    "Stubcarbine, Taser goad"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        }
    ]
};

export default killTeam;

// raw KT data
import { GELLERPOX_ID } from "../../../constants/factions";

const killTeam = {
    id: GELLERPOX_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Vulgrar Thrice-cursed",
            limit: 1,
            leader: true,
            weapons: [["Pyregut, Fleshmelded weapons"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Bloatspawn",
            limit: 1,
            weapons: [["Mutant tentacles, Mutant claw"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Fleshscreamer",
            limit: 1,
            weapons: [["Mutant fist & cleaver"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Lumberghast",
            limit: 1,
            weapons: [["Mutant claw"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Gellerpox Mutant equipped with: Frag grenade, Heavy axe",
            limit: 1,
            weapons: [["Frag grenade, Heavy axe"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Gellerpox Mutant equipped with: Frag grenade, Improvised weapon, Mutated limb",
            limit: 2,
            weapons: [["Frag grenade, Improvised weapon, Mutated limb"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Glitchling",
            limit: 4,
            weapons: [["Diseased effluence, Diseased nippers"]],
            specialisms: ["scout"]
        }
    ]
};

export default killTeam;

import React, { useContext, useState } from "react";
import AppStateContext from "../context";
import { getFactions } from "../controllers/DataController";
import UserLists from "./UserLists";
import StyledLanding from "../styles/StyledLanding";
import StyledSelectInput from "../styles/StyledSelectInput";
import StyledTooltip from "../styles/StyledTooltip";

const PageLanding = (props) => {
    const { appState, dispatch } = useContext(AppStateContext);
    const factions = getFactions();
    const [newFaction, setNewFaction] = useState({
        id: "default",
        name: "Select your faction..."
    });
    const [setTooltip] = useState(false);

    const goToRoster = () => {
        if (newFaction.id === "default") {
            setTooltip(true);
            return;
        }

        dispatch({
            type: "ADD_LIST",
            payload: {
                // name: newSelectableKeyword,
                faction: newFaction.id
            }
        });
    };

    return (
        <StyledLanding>
            <StyledTooltip>
                <h1>Get Started</h1>
                <span>
                    Welcome to Command Roster! Building your roster couldn't be
                    easier, use the ROSTER and KILL TEAM tabs to add
                    opperatives, add them to your Kill Team, customise them, add
                    Spec Ops data and give them equipment to conquer their foes!
                </span>
                <span>
                    Use the SPEC OPS tab to add narrative and campaign elements
                    to your Kill Team, and the EXPORT tab whenever you want PDFs
                    of your Roster, Dataslate and Operative Datacards.
                </span>
            </StyledTooltip>

            {appState.lists.allIds.length > 0 && (
                <>
                    <UserLists />
                    {/* <SVGLine fill={theme.colors.offRed} /> */}
                </>
            )}

            <div className="landing__new-wrap">
                <span className="landing__new-faction">
                    <label>Faction</label>

                    <StyledSelectInput>
                        <div className="select-input__wrap">
                            <select
                                defaultValue="default"
                                onChange={(event) => {
                                    setNewFaction({
                                        id: event.target.value,
                                        name: event.target.options[
                                            event.target.selectedIndex
                                        ].text
                                    });
                                }}
                            >
                                <option value="default" disabled>
                                    Select your faction...
                                </option>
                                {factions.map((faction) => (
                                    <option key={faction.id} value={faction.id}>
                                        {faction.info.moniker}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </StyledSelectInput>
                </span>

                {/* <span className="landing__new-name">
                    <label>Selectable Keyword</label>
                    <input
                        maxLength="30"
                        onChange={(event) => {
                            setSelectableKeyword(event.target.value);
                        }}
                        type="text"
                        placeholder="E.G Craigs..."
                    />
                </span> */}

                <div className="landing__button">
                    <button
                        className="landing__button-ready"
                        onClick={goToRoster}
                    >
                        Ready
                    </button>
                </div>
            </div>
        </StyledLanding>
    );
};

export default PageLanding;

import { KOMMANDO_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const KommandoController = {
    id: KOMMANDO_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length < 10) {
            passed = false;
            messages.push("Must include 10 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Kommando Nob";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Kommando Nob");
        }

        // only 1 of non boy units
        [
            "Kommando Grot",
            "Kommando Slasha Boy",
            "Kommando Breacha Boy",
            "Kommando Snipa Boy",
            "Kommando Dakka Boy",
            "Kommando Comms Boy",
            "Kommando Burna Boy",
            "Kommando Rokkit Boy",
            "Bomb Squig"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default KommandoController;

// names
// backgrounds etc...
import { PATHFINDER_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: PATHFINDER_ID,
    moniker: "Pathfinder",
    restrictions: restrictions,
    battleHonours: [
        "For the Greater Good",
        "Reliant Support",
        "Cunning Hunter",
        "Merciless Hunter",
        "Martial Philosopher",
        "Capable Under Fire"
    ],
    killTeamNames: [
        ["Dark", "Shadow", "Silent", "Ghost", "Bright", "Cold"],
        [
            "Star",
            "Stars",
            "Wind",
            "Winds",
            "Sun",
            "Suns",
            "Moon",
            "Moons",
            "Sword",
            "Swords",
            "Wave",
            "Waves"
        ]
    ],
    operativeNames: [
        [
            "T'au",
            "Dal'yth",
            "T'au'n",
            "Fal'shia",
            "D'yanoi",
            "Vior'la",
            "Bork'an",
            "Au'taal",
            "Tash'var",
            "N'dras",
            "Vash'ya",
            "Ke'lshan",
            "T'olku",
            "Elsy'eir",
            "Fi'rios",
            "Mu'gulath",
            "Bay",
            "Fe'saan",
            "Kor'tal",
            "Yo'vai",
            "Sa'cea",
            "Ksi'm'yen"
        ],
        [
            "Sul'an",
            "Ho'sen",
            "Atsumo",
            "N'ea",
            "Els'im",
            "K'yen",
            "Or'os",
            "Pashera",
            "Rais",
            "Seltas",
            "Be'tol",
            "E'yaal",
            "Murakan",
            "To'jo",
            "Kurami",
            "U'so",
            "Lorresa",
            "Paluto",
            "Ren'as",
            "Lor'ma",
            "Tash'lor",
            "Watana",
            "Nomura",
            "Nishino",
            "D'tano",
            "Xo'yima",
            "T'suka",
            "Kais",
            "Shamasa",
            "Pu'jato",
            "Ju'yem",
            "Ga'mo",
            "Kasashi",
            "Lamano",
            "Mi'daro",
            "Uvash'a"
        ]
    ],
    history: [
        "Warriors of the Fifth Sphere",
        "Survived Trails of Fire",
        "Heroes of the Greater Good",
        "Elite of the Academies",
        "Fourth Sphere Veterans",
        "Sniper Team"
    ],
    baseOfOperations: [
        "Optimised Tetras",
        "Stealthtide Module",
        "We March for the Greater Good",
        "High Ground",
        "Orbital Presence",
        "Outfitted Devilfish"
    ],
    squadQuirk: [
        "Experiments",
        "Eager",
        "Vengeful",
        "Canny Tacticians",
        "No Heroes",
        "No One Left Behind"
    ],
    strategicAssets: [
        "Remote Sensor Tower",
        "Aerial Surveillance Drone",
        "Tidewall Barricade"
    ],
    equipment: [
        {
            name: "Target Analysis Optic",
            cost: 2
        },
        {
            name: "High-intensity Markerlight",
            cost: 2
        },
        {
            name: "Orbital Survey Uplink",
            cost: 3
        },
        {
            name: "Drone Repair Kit",
            cost: 2
        },
        {
            name: "EMP Grenade",
            cost: 2
        },
        {
            name: "Fusion Grenade",
            cost: 4
        },
        {
            name: "Photon Grenade",
            cost: 2
        },
        {
            name: "Climbing Equipment",
            cost: 1
        }
    ],
    rareEquipment: [
        {
            name: "Experimental Pulse Ammunition",
            cost: 2
        },
        {
            name: "Honour Blade",
            cost: 1
        },
        {
            name: "Advanced Cogitation Chip",
            cost: 1
        },
        {
            name: "Shield Generator",
            cost: 2
        },
        {
            name: "Markerlight Grenade",
            cost: 2
        },
        {
            name: "Projection Field",
            cost: 2
        }
    ],
    specOps: ["Mark for Strike", "Philosophy of War"]
};

export default info;

import { HUNTER_CADRE_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const HunterCadreController = {
    id: HUNTER_CADRE_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Fire Warrior Shas'ui" ||
                    operative.unit === "Pathfinder Shas'ui" ||
                    operative.unit === "Stealth Battlesuit Shas'vre"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Must include only 1 Fire Warrior Shas'ui/Pathfinder Shas'ui/Stealth Battlesuit Shas'vre"
            );
        }

        [
            "MV36 Guardian Drone",
            "DS8 Tactical Support Turret",
            "MV1 Gun Drone",
            "MV4 Shield Drone",
            "MV7 Marker Drone",
            "MB3 Recon Drone",
            "MV31 Pulse Accelerator Drone",
            "MV33 Grav-inhibitor Drone"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit} in Kill Team`);
            }
        });

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Fire Warrior") {
                if (operativesInFireTeams[index].length !== 6) {
                    passed = false;
                    messages.push(
                        "Fire Warrior Fire Team must include 6 operatives"
                    );
                }

                let num = 0;

                operativesInFireTeams[index].forEach((operative) => {
                    [
                        "MV36 Guardian Drone",
                        "DS8 Tactical Support Turret",
                        "MV1 Gun Drone",
                        "MV4 Shield Drone",
                        "MV7 Marker Drone",
                        "MB3 Recon Drone",
                        "MV31 Pulse Accelerator Drone",
                        "MV33 Grav-inhibitor Drone"
                    ].forEach((drone) => {
                        if (operative.unit === drone) {
                            num++;
                        }
                    });
                });

                if (num > 3) {
                    passed = false;
                    messages.push(
                        "Fire Warrior Fire Team cannot include more than 3 drones"
                    );
                }
            } else if (fireTeam === "Pathfinder") {
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return operative.unit === "Pathfinder Shas'ui";
                    }).length > 0 &&
                    operativesInFireTeams[index].length > 7
                ) {
                    passed = false;
                    messages.push(
                        "Pathfinder Fire Team cannot include more than 7 operatives"
                    );
                } else if (
                    operativesInFireTeams[index].filter((operative) => {
                        return operative.unit === "Pathfinder Shas'ui";
                    }).length > 0 &&
                    operativesInFireTeams[index].length < 7
                ) {
                    passed = false;
                    messages.push(
                        "Pathfinder Fire Team with Pathfinder Shas'ui must include 7 operatives"
                    );
                } else if (
                    operativesInFireTeams[index].filter((operative) => {
                        return operative.unit === "Pathfinder Shas'ui";
                    }).length === 0 &&
                    operativesInFireTeams[index].length !== 6
                ) {
                    passed = false;
                    messages.push(
                        "Pathfinder Fire Team must include 6 operatives"
                    );
                }

                let num = 0;

                operativesInFireTeams[index].forEach((operative) => {
                    [
                        "MV36 Guardian Drone",
                        "DS8 Tactical Support Turret",
                        "MV1 Gun Drone",
                        "MV4 Shield Drone",
                        "MV7 Marker Drone",
                        "MB3 Recon Drone",
                        "MV31 Pulse Accelerator Drone",
                        "MV33 Grav-inhibitor Drone"
                    ].forEach((drone) => {
                        if (operative.unit === drone) {
                            num++;
                        }
                    });
                });

                if (num > 3) {
                    passed = false;
                    messages.push(
                        "Pathfinder Fire Team cannot include more than 3 drones"
                    );
                }
            } else if (fireTeam === "Stealth Battlesuit") {
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit === "MV1 Gun Drone" ||
                            operative.unit === "MV4 Shield Drone" ||
                            operative.unit === "MV7 Marker Drone"
                        );
                    }).length > 0 &&
                    operativesInFireTeams[index].length > 4
                ) {
                    passed = false;
                    messages.push(
                        "Stealth Battlesuit Team cannot include more than 4 operatives"
                    );
                } else if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit !== "MV1 Gun Drone" ||
                            operative.unit !== "MV4 Shield Drone" ||
                            operative.unit !== "MV7 Marker Drone"
                        );
                    }).length > 3
                ) {
                    passed = false;
                    messages.push(
                        "Stealth Battlesuit Fire Team cannot include more than 3 operatives"
                    );
                }

                let num = 0;

                operativesInFireTeams[index].forEach((operative) => {
                    [
                        "MV36 Guardian Drone",
                        "DS8 Tactical Support Turret",
                        "MV1 Gun Drone",
                        "MV4 Shield Drone",
                        "MV7 Marker Drone",
                        "MB3 Recon Drone",
                        "MV31 Pulse Accelerator Drone",
                        "MV33 Grav-inhibitor Drone"
                    ].forEach((drone) => {
                        if (operative.unit === drone) {
                            num++;
                        }
                    });
                });

                if (num > 2) {
                    passed = false;
                    messages.push(
                        "Stealth Battlesuit Fire Team cannot include more than 2 drones"
                    );
                }
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default HunterCadreController;

import { STARSTRIDER_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const StarstriderController = {
    id: STARSTRIDER_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // only 1 of these units
        [
            "Elucia Vhane",
            "Canid",
            "Death Cult Executioner",
            "Lectro-Maester",
            "Rejuvenat Adept",
            "Voidmaster",
            "Voidsman equipped with: Rotor Cannon and Gun-butt"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length !== 1
            ) {
                passed = false;
                messages.push(`Must include 1 ${unit}`);
            }
        });

        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    "Voidsman equipped with: Lasgun and Gun-butt" ===
                    operative.unit
                );
            }).length !== 3
        ) {
            passed = false;
            messages.push(
                `Must include 3 Voidsman equipped with: Lasgun and Gun-butt`
            );
        }

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default StarstriderController;

// raw KT data
import { INQUISITORIAL_AGENT } from "../../../constants/factions";

const killTeam = {
    id: INQUISITORIAL_AGENT,
    fireTeams: false,
    operatives: [
        {
            name: "Interrogator",
            limit: 1,
            leader: true,
            weapons: [["Extended stock autopistol, Fists"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Tome-skull",
            limit: 1,
            weapons: [[" "]],
            specialisms: []
        },
        {
            name: "Autosavant",
            limit: 1,
            weapons: [["Mechanical appendages"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Questkeeper",
            limit: 1,
            weapons: [["Autopistol, Eviscerator"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Death World Veteran",
            limit: 1,
            weapons: [["Autopistol, Knife, Polearm"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Enlightened",
            limit: 1,
            weapons: [["Autopistol, Paired blades"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Gun Servitor",
            limit: 2,
            weapons: [
                [
                    "Heavy Bolter, Servo claw",
                    "Multi-melta, Servo claw",
                    "Plasma cannon, Servo claw"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Hexorcist",
            limit: 1,
            weapons: [["Shotgun, Fists"]],
            specialisms: ["staunch", "marksman", "scout"]
        },
        {
            name: "Mystic",
            limit: 1,
            weapons: [["Autopistol, Fists"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Penal Legionnaire",
            limit: 1,
            weapons: [["Hand flamer, Chainsword"]],
            specialisms: ["combat", "staunch", "marksman", "scout"]
        },
        {
            name: "Pistolier",
            limit: 1,
            weapons: [["Scoped plasma pistol, Suppressed autopistol, Fists"]],
            specialisms: ["marksman", "scout"]
        },

        // Sisters of Silence
        {
            name: "Sister of Silence Prosecutor",
            limit: 5,
            weapons: [["Boltgun, Gun butt"]],
            specialisms: ["staunch", "marksman", "scout"]
        },
        {
            name: "Sister of Silence Witchseeker",
            limit: 5,
            weapons: [["Flamer, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Sister of Silence Vigilator",
            limit: 5,
            weapons: [["Executioner greatblade"]],
            specialisms: ["combat", "staunch"]
        },

        // Tempestus Scions
        {
            name: "Tempestus Scion Trooper",
            limit: 5,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Tempestus Scion Comms",
            limit: 5,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Flamer",
            limit: 1,
            weapons: [["Gun butt, Flamer"]],
            specialisms: ["marksman"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Grenade launcher",
            limit: 1,
            weapons: [["Gun butt, Grenade launcher"]],
            specialisms: ["marksman"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Hot-shot volley gun",
            limit: 1,
            weapons: [["Gun butt, Hot-shot volley gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Meltagun",
            limit: 1,
            weapons: [["Gun butt, Meltagun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Tempestus Scion Gunner equipped with: Gun butt, Plasma gun",
            limit: 1,
            weapons: [["Gun butt, Plasma gun"]],
            specialisms: ["marksman"]
        },

        // Exaction Squad
        {
            name: "Arbites Castigator",
            limit: 1,
            weapons: [["Combat shotgun, Excruciator maul"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Arbites Chirurgant",
            limit: 1,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Arbites Gunner",
            limit: 2,
            weapons: [
                [
                    "Grenade launcher, Repression baton",
                    "Heavy stubber, Reprission baton",
                    "Webber, Repression baton"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Arbites Leashmaster",
            limit: 1,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Arbites Malocator",
            limit: 1,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Arbites Marksman",
            limit: 1,
            weapons: [["Executioner shotgun, Repression baton"]],
            specialisms: ["marksman"]
        },
        {
            name: "Arbites Revelatum",
            limit: 1,
            weapons: [["Scoped shotpistol, Repression baton"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Arbites Subductor",
            limit: 4,
            weapons: [["Shock maul & Assault shield"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Arbites Vigilant",
            limit: 10,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Arbites Vox-signifier",
            limit: 1,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "R-VR Cyber-mastiff",
            limit: 1,
            weapons: [["Mechanical bite"]],
            specialisms: ["staunch", "scout"]
        },

        // Imperial Navy Breachers
        {
            name: "Navis Armsman",
            limit: 11,
            weapons: [["Navis shotgun, Navis hatchet"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Navis Axejack",
            limit: 1,
            weapons: [["Autopistol, Power weapon"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Navis C.A.T Unit",
            limit: 1,
            weapons: [[]],
            specialisms: []
        },
        {
            name: "Navis Endurant",
            limit: 1,
            weapons: [["Navis heavy shotgun, Shield bash"]],
            specialisms: ["staunch"]
        },
        {
            name: "Navis Gheistskull",
            limit: 1,
            weapons: [[]],
            specialisms: []
        },
        {
            name: "Navis Grenadier",
            limit: 1,
            weapons: [["Demolition charge, Navis shotgun, Navis hatchet"]],
            specialisms: ["staunch"]
        },
        {
            name: "Navis Gunner equipped with: Navis las-volley, Gun butt",
            limit: 1,
            weapons: [["Navis las-volley, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Navis Gunner equipped with: Meltagun, Gun butt",
            limit: 1,
            weapons: [["Meltagun, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Navis Gunner equipped with: Plasma gun, Gun butt",
            limit: 1,
            weapons: [["Plasma gun, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Navis Hatchcutter",
            limit: 1,
            weapons: [["Autopistol, Chainfist"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Navis Surveyor",
            limit: 1,
            weapons: [["Navis shotgun, Navis hatchet"]],
            specialisms: ["scout"]
        },
        {
            name: "Navis Void-jammer",
            limit: 1,
            weapons: [["Gheistskull detonator, Navis shotgun, Navis hatchet"]],
            specialisms: ["scout"]
        },

        // Kasrkin
        {
            name: "Kasrkin Combat Medic",
            limit: 1,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Kasrkin Demo-trooper",
            limit: 1,
            weapons: [["Hot-shot laspistol, Melta mine, Gun butt"]],
            specialisms: ["staunch"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Flamer",
            limit: 1,
            weapons: [["Gun butt, Flamer"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Grenade launcher",
            limit: 1,
            weapons: [["Gun butt, Grenade launcher"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Hot-shot volley gun",
            limit: 1,
            weapons: [["Gun butt, Hot-shot volley gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Meltagun",
            limit: 1,
            weapons: [["Gun butt, Meltagun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Gunner equipped with: Gun butt, Plasma gun",
            limit: 1,
            weapons: [["Gun butt, Plasma gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Recon-trooper",
            limit: 1,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kasrkin Sharpshooter",
            limit: 1,
            weapons: [["Hot-shot marksman rifle, Gun butt"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kasrkin Trooper",
            limit: 9,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kasrkin Vox-trooper",
            limit: 1,
            weapons: [["Hot-shot lasgun, Gun butt"]],
            specialisms: ["staunch", "scout"]
        },

        // Veteran Guardsman
        {
            name: "Trooper Veteran",
            limit: 9,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Zealot Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["staunch"]
        },
        {
            name: "Medic Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Comms Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Demolition Veteran",
            limit: 1,
            weapons: [["Lasgun, Remote mine, Bayonet"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Bruiser Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet, Trench club"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Hardened Veteran",
            limit: 1,
            weapons: [["Lasgun, Bayonet, Bionic arm"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Sniper Veteran",
            limit: 1,
            weapons: [["Long-las, Bayonet"]],
            specialisms: ["marksman"]
        },
        {
            name: "Gunner Veteran - equipped with a bayonet and grenade launcher",
            limit: 1,
            weapons: [["Bayonet, Grenade Launcher"]],
            specialisms: ["marksman"]
        },
        {
            name: "Gunner Veteran - equipped with a bayonet and meltagun",
            limit: 1,
            weapons: [["Bayonet, Meltagun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Gunner Veteran - equipped with a bayonet and plasma gun",
            limit: 1,
            weapons: [["Bayonet, Plasma gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Gunner Veteran - equipped with a bayonet and flamer",
            limit: 1,
            weapons: [["Bayonet, Flamer"]],
            specialisms: ["marksman"]
        }
    ]
};

export default killTeam;

/*
{id === INQUISITORIAL_AGENT && (
                        <div className="add-operative__wrap">
                            <label>Include Ancillary Support Option:</label>
                            <StyledSelectInput>
                                <div className="select-input__wrap">
                                    <select
                                        value={list.ancillarySupport}
                                        onChange={(event) => {
                                            dispatch({
                                                type: "UPDATE_SPEC_OPS",
                                                payload: {
                                                    value: event.target.value,
                                                    key: "ancillarySupport"
                                                }
                                            });
                                        }}
                                    >
                                        <option value="default">None</option>
                                        <option value="Sisters of Silence">
                                            Sisters of Silence
                                        </option>
                                        <option value="Tempestus Scions">
                                            Tempestus Scions
                                        </option>
                                        <option value="Exaction Squad">
                                            Exaction Squad
                                        </option>
                                        <option value="Imperial Navy Breachers">
                                            Imperial Navy Breachers
                                        </option>
                                        <option value="Kasrkin">Kasrkin</option>
                                        <option value="Veteran Guardsmen">
                                            Veteran Guardsmen
                                        </option>
                                    </select>
                                </div>
                            </StyledSelectInput>
                        </div>
                    )}
*/

// raw KT data
import { EXACTION_SQUAD } from "../../../constants/factions";

const killTeam = {
    id: EXACTION_SQUAD,
    fireTeams: false,
    operatives: [
        {
            name: "Arbites Proctor-exactant",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Combat shotgun, Repression baton",
                    "Dominator maul & Assault shield"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Arbites Castigator",
            limit: 1,
            weapons: [["Combat shotgun, Excruciator maul"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Arbites Chirurgant",
            limit: 1,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Arbites Gunner",
            limit: 2,
            weapons: [
                [
                    "Grenade launcher, Repression baton",
                    "Heavy stubber, Reprission baton",
                    "Webber, Repression baton"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Arbites Leashmaster",
            limit: 1,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Arbites Malocator",
            limit: 1,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Arbites Marksman",
            limit: 1,
            weapons: [["Executioner shotgun, Repression baton"]],
            specialisms: ["marksman"]
        },
        {
            name: "Arbites Revelatum",
            limit: 1,
            weapons: [["Scoped shotpistol, Repression baton"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Arbites Subductor",
            limit: 4,
            weapons: [["Shock maul & Assault shield"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Arbites Vigilant",
            limit: 10,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Arbites Vox-signifier",
            limit: 1,
            weapons: [["Combat shotgun, Repression baton"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "R-VR Cyber-mastiff",
            limit: 1,
            weapons: [["Mechanical bite"]],
            specialisms: ["staunch", "scout"]
        }
    ]
};

export default killTeam;

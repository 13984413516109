// raw KT data
import { BLOODED_ID } from "../../../constants/factions";

const killTeam = {
    id: BLOODED_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Traitor Chieftain",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Autopistol, Chainsword",
                    "Autopistol, Power weapon",
                    "Laspistol, Chainsword",
                    "Laspistol, Power weapon",
                    "Bolt pistol, Chainsword",
                    "Boltgun, Bayonet",
                    "Plasma pistol, Improvised blade"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Traitor Brimstone Grenadier",
            limit: 1,
            weapons: [["Lasgun, Bayonet, Diabolyk bomb"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Traitor Butcher",
            limit: 1,
            weapons: [["Power weapon and cleaver"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Traitor Commsman",
            limit: 1,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Traitor Corpseman",
            limit: 1,
            weapons: [["Lasgun, Bayonet, Stim needle"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Traitor Enforcer",
            limit: 1,
            weapons: [["Bolt pistol, Power fist"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Traitor Flenser",
            limit: 1,
            weapons: [["Skinning blades"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Traitor Gunner equipped with: Bayonet, Flamer",
            limit: 1,
            weapons: [["Bayonet, Flamer"]],
            specialisms: ["marksman"]
        },
        {
            name: "Traitor Gunner equipped with: Bayonet, Grenade Launcher",
            limit: 1,
            weapons: [["Bayonet, Grenade Launcher"]],
            specialisms: ["marksman"]
        },
        {
            name: "Traitor Gunner equipped with: Bayonet, Meltagun",
            limit: 1,
            weapons: [["Bayonet, Meltagun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Traitor Gunner equipped with: Bayonet, Plasma gun",
            limit: 1,
            weapons: [["Bayonet, Plasma gun"]],
            specialisms: ["marksman"]
        },
        {
            name: "Traitor Ogryn",
            limit: 1,
            weapons: [["Power maul and mutant claw"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Traitor Sharpshooter",
            limit: 1,
            weapons: [["Sharpshooter's long-las, Bayonet"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Traitor Thug",
            limit: 1,
            weapons: [["Heavy club"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Traitor Trench Sweeper",
            limit: 1,
            weapons: [["Shotgun, Bayonet and shield"]],
            specialisms: ["staunch"]
        },
        {
            name: "Traitor Trooper",
            limit: 13,
            weapons: [["Lasgun, Bayonet"]],
            specialisms: ["marksman", "scout"]
        }
    ]
};

export default killTeam;

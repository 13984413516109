// names
// backgrounds etc...
import { INTERCESSOR_SQUAD_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: INTERCESSOR_SQUAD_ID,
    moniker: "Intercessor Squad",
    restrictions: restrictions,
    equipment: [
        {
            name: "Vengeance-class Scope",
            cost: 3
        },
        {
            name: "Reclusiam-blessed Bolts",
            cost: 3
        },
        {
            name: "Tilting Shield",
            cost: 3
        },
        {
            name: "Purity Seal",
            cost: 3
        },
        {
            name: "Auspex",
            cost: 2
        },
        {
            name: "Combat Blade",
            cost: 1
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Bastion Field Generator",
            cost: 3
        },
        {
            name: "Adamantine Mantle",
            cost: 2
        },
        {
            name: "Targeting Ocular",
            cost: 2
        },
        {
            name: "Servo-cherub",
            cost: 3
        },
        {
            name: "Artificier Armour",
            cost: 3
        },
        {
            name: "Chapter Artefact",
            cost: 1
        }
    ],
    battleHonours: [
        "Marksman's Honour",
        "Bladesman's Honour",
        "Chapter Exemplar",
        "Spiritual Resolve",
        "Specialised",
        "Honoured by the Armoury"
    ],
    strategicAssets: [
        "Hypnoindoctrination Shrin",
        "Intel-cache Uplink",
        "Arsenal Sub-vault"
    ],
    specOps: ["Defenders of Humanity", "Mankind's Vengeance"]
};

export default info;

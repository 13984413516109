import { HANDS_OF_THE_ARCHON } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const HandsOfTheArchonController = {
    id: HANDS_OF_THE_ARCHON,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length < 9) {
            passed = false;
            messages.push("Must include 9 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Kabalite Archsybarite";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Kabalite Archsybarite");
        }

        // only 1 of these units
        [
            "Kabalite Agent",
            "Kabalite Crimson Duellist",
            "Kabalite Disciple of Yaelindra",
            "Kabalite Elixicant",
            "Kabalite Flayer",
            "Kabalite Gunner",
            "Kabalite Heavy Gunner",
            "Kabalite Skysplinter Assassin"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        // max 2 darklight weapons
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.weapons[0].includes("Blast pistol") ||
                    operative.weapons[0].includes("Blaster") ||
                    operative.weapons[0].includes("Dark lance")
                );
            }).length > 2
        ) {
            passed = false;
            messages.push(`Maximum of 2 Darklight weapons.`);
        }

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default HandsOfTheArchonController;

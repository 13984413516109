import { CHAOS_DAEMON_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const ChaosDaemonController = {
    id: CHAOS_DAEMON_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains up to 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Bloodreaper" ||
                    operative.unit === "Allurress" ||
                    operative.unit === "Plagueridden" ||
                    operative.unit === "Pink Horror Iridescent"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Maximum of 1 Bloodreaper/Allurress/Plagueridden/Pink Horror Iridescent"
            );
        }

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Blue Horror") {
                if (operativesInFireTeams[index].length > 8) {
                    passed = false;
                    messages.push(
                        `${fireTeam} Fire Team cannot include more than 8 operatives`
                    );
                }
            } else if (fireTeam !== "default") {
                if (operativesInFireTeams[index].length !== 6) {
                    passed = false;
                    messages.push(
                        `${fireTeam} Fire Team cannot include more than 6 operatives`
                    );
                }

                // singles for kill team
                [`${fireTeam} Icon Bearer`, `${fireTeam} Horn Bearer`].forEach(
                    (unit) => {
                        if (
                            operativesInKillTeam.filter((operative) => {
                                return unit === operative.unit;
                            }).length > 1
                        ) {
                            passed = false;
                            messages.push(`Maximum of 1 ${unit}`);
                        }
                    }
                );
            }
        });

        // only 1 arco-flag team
        if (
            list.fireTeams.filter((team) => {
                return team === "Blue Horror";
            }).length > 1
        ) {
            passed = false;
            messages.push("Max 1 Blue Horror Fire Team");
        }

        //
        return {
            passed,
            messages
        };
    }
};

export default ChaosDaemonController;

import nullState from "../nulls/nullState";

export function getStoredData() {
    return JSON.parse(localStorage.getItem("ktUserData2"));
}

export function setStoredData(obj) {
    return localStorage.setItem("ktUserData2", JSON.stringify(obj));
}

export function getInitialState() {
    return getStoredData() || setStoredData(nullState);
}

export function setActivePage(pageToActivate) {
    return {
        activePage: pageToActivate
    };
}

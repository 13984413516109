// raw KT data
import { PHOBOS_ID } from "../../../constants/factions";

const killTeam = {
    id: PHOBOS_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Infiltrator Sergeant",
            limit: 1,
            leader: true,
            weapons: [["Marksman bolt carbine, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Incursor Sergeant",
            limit: 1,
            leader: true,
            weapons: [["Occulus bolt carbine, Combat blade"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Reiver Sergeant",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Special issue bolt pistol, Combat knife",
                    "Bolt carbine, fists"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Infiltrator Commsman",
            limit: 1,
            weapons: [["Marksman bolt carbine, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Infiltrator Helix Adept",
            limit: 1,
            weapons: [["Marksman bolt carbine, Fists"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Infiltrator Saboteur",
            limit: 1,
            weapons: [["Marksman bolt carbine, Remote explosives, Fists"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Infiltrator Veteran",
            limit: 1,
            weapons: [["Custom bolt carbine, Combat blade"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Infiltrator Voxbreaker",
            limit: 1,
            weapons: [["Marksman bolt carbine, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Infiltrator Warrior",
            limit: 5,
            weapons: [["Marksman bolt carbine, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Incursor Marksman",
            limit: 1,
            weapons: [["Stalker marksman bolt carbine, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Incursor Mineflayer",
            limit: 1,
            weapons: [["Haywire mine, Occulus bolt carbine, Combat blade"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Incursor Warrior",
            limit: 5,
            weapons: [["Occulus bolt carbine, Combat blade"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Reiver Warrior",
            limit: 5,
            weapons: [
                [
                    "Special issue bolt pistol, Combat knife",
                    "Bolt carbine, fists"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        }
    ]
};

export default killTeam;

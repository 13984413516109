// names
// backgrounds etc...
import { LEGIONARY_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: LEGIONARY_ID,
    moniker: "Legionary",
    restrictions: restrictions,
    battleHonours: [
        "Apostle of Chaos",
        "Warp-blessed",
        "Devastating Assault",
        "Servant of the Dark Gods",
        "Fuelled by Hate",
        "Vengeance Incarnate"
    ],
    killTeamNames: [
        [
            "Bringers",
            "Damned",
            "True Believers",
            "Holders",
            "Butchers",
            "Rampagers"
        ],
        [
            "of Blood",
            "of the Creed",
            "of the Gods",
            "of the Truth",
            "Abominant",
            "Malevolent"
        ]
    ],
    operativeNames: [
        [
            // black legion
            "Zekyr",
            "Sorvram",
            "Drekva",
            "Ashrok",
            "Thallos",
            "Azmodial",

            // alpha legion
            "Kyphax",
            "Inigo",
            "Sheed",
            "Jaego",
            "Alpharius",
            "Armilus",

            // world eaters
            "Khargos",
            "Drakh",
            "Ashkal",
            "Sorkhos",
            "Aggravax",
            "Gharrax",

            // world eaters
            "Antinius",
            "Teloss",
            "Lycon",
            "Xiander",
            "Abdelis",
            "Julianus",

            // word bearers
            "Atresh",
            "Bathusa",
            "Verok",
            "Qarad",
            "Kor",
            "Tarem",

            // night lords
            "Loden",
            "Jago",
            "Navir",
            "Saveth",
            "Reskil",
            "Vastiani",

            // iron warriors
            "Ferrox",
            "Sullus",
            "Porox",
            "Broug",
            "Veghor",
            "Detrus"
        ],
        [
            // black legion
            "Aximand",
            "the Vengeful",
            "Orakar",
            "the Mad",
            "Daemonsblade",
            "Naxos",

            // alpha legion
            "X632",
            "Hertzor",
            "Phors",
            "(no cypher)",
            "25-2",
            "Nul",

            // world eaters
            "the Butcher",
            "the Reaper",
            "Slaughterborn",
            "Thaxxos",
            "Skullfiend",
            "the Hound",

            // world eaters
            "the Beautiful",
            "Atonian",
            "Thest",
            "Vastorius",
            "the Perfect",
            "Kanasiar",

            // word bearers
            "Xesugal",
            "Mairon",
            "Gorlem",
            "Tchure",
            "Kartho",
            "Ranasar",

            // night lords
            "Morgaris",
            "Khade",
            "of the Ceaseless Torment",
            "of the Midnight Claw",
            "Baraski",
            "Carrow",

            // iron warriors
            "the Wallbreaker",
            "Estarox",
            "the Warpcutter",
            "of theFoundry of Agony",
            "Dronn",
            "Steelspine"
        ]
    ],
    history: [
        "Predators",
        "Warp-infused Warriors",
        "Veterans of the Long War",
        "Monotheistic",
        "Lost and Damned",
        "Worldslayers"
    ],
    baseOfOperations: [
        "Desecrated Shrine",
        "Heretic Astartes Bikes",
        "Tainted Thunderhawk",
        "Heretic Astartes Rhinos",
        "Hollowed-out Asteroid",
        "Murder Barge"
    ],
    squadQuirk: [
        "Insane",
        "Cruel",
        "Iron Discipline",
        "Embittered",
        "Berserk",
        "Arrogant"
    ],
    strategicAssets: [
        "Unlean Shrine",
        "Relics of the Heresy",
        "Sacrificial Altar"
    ],
    equipment: [
        {
            name: "Aggression Stimulants",
            cost: 3
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Warded Armour",
            cost: 3
        },
        {
            name: "Suspensor System",
            cost: 3
        },
        {
            name: "Malign Scripture",
            cost: 2
        },
        {
            name: "Tainted Rounds",
            cost: 3
        },
        {
            name: "Grisly Trohpy",
            cost: 3
        },
        {
            name: "Malefic Blade",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Brands of the Damned",
            cost: 3
        },
        {
            name: "Gauntlets of Vengeance",
            cost: 2
        },
        {
            name: "Pages of Scabrius",
            cost: 2
        },
        {
            name: "Icon of Chaos",
            cost: 4
        },
        {
            name: "Daemon Axe",
            cost: 3
        },
        {
            name: "Relic Bolt Pistol",
            cost: 2
        }
    ],
    specOps: ["Send a Message", "Tainted Journey"]
};

export default info;

// raw KT data
import { IMPERIAL_NAVY_BREACHERS_ID } from "../../../constants/factions";

const killTeam = {
    id: IMPERIAL_NAVY_BREACHERS_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Navis Sergeant-at-arms",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Navis shotgun, Navis hatchet",
                    "Bolt pistol, Chainsword",
                    "Bolt pistol, Power weapon",
                    "Heirloom autopistol, Chainsword",
                    "Heirloom autopistol, Power weapon"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Navis Armsman",
            limit: 11,
            weapons: [["Navis shotgun, Navis hatchet"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Navis Axejack",
            limit: 1,
            weapons: [["Autopistol, Power weapon"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Navis C.A.T Unit",
            limit: 1,
            weapons: [[]],
            specialisms: []
        },
        {
            name: "Navis Endurant",
            limit: 1,
            weapons: [["Navis heavy shotgun, Shield bash"]],
            specialisms: ["staunch"]
        },
        {
            name: "Navis Gheistskull",
            limit: 1,
            weapons: [[]],
            specialisms: []
        },
        {
            name: "Navis Grenadier",
            limit: 1,
            weapons: [["Demolition charge, Navis shotgun, Navis hatchet"]],
            specialisms: ["staunch"]
        },
        {
            name: "Navis Gunner equipped with: Navis las-volley, Gun butt",
            limit: 1,
            weapons: [["Navis las-volley, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Navis Gunner equipped with: Meltagun, Gun butt",
            limit: 1,
            weapons: [["Meltagun, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Navis Gunner equipped with: Plasma gun, Gun butt",
            limit: 1,
            weapons: [["Plasma gun, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Navis Hatchcutter",
            limit: 1,
            weapons: [["Autopistol, Chainfist"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Navis Surveyor",
            limit: 1,
            weapons: [["Navis shotgun, Navis hatchet"]],
            specialisms: ["scout"]
        },
        {
            name: "Navis Void-jammer",
            limit: 1,
            weapons: [["Gheistskull detonator, Navis shotgun, Navis hatchet"]],
            specialisms: ["scout"]
        }
    ]
};

export default killTeam;

// names
// backgrounds etc...
import { CHAOS_DAEMON_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: CHAOS_DAEMON_ID,
    moniker: "Chaos Daemon",
    restrictions: restrictions,
    equipment: [
        {
            name: "Brass horns",
            cost: 1
        },
        {
            name: "Scorched skull",
            cost: 3
        },
        {
            name: "Rancid vomit",
            cost: 3
        },
        {
            name: "Death's heads",
            cost: 1
        },
        {
            name: "Alluring musk",
            cost: 3
        },
        {
            name: "Piercing claws",
            cost: 2
        },
        {
            name: "Ritual dagger",
            cost: 2
        },
        {
            name: "Trinket of flux",
            cost: 2
        }
    ]
};

export default info;

// names
// backgrounds etc...
import { LEGIONARY_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: LEGIONARY_ID,
    moniker: "Corsair Voidscarred",
    restrictions: restrictions,
    battleHonours: [
        "Important Fate",
        "Pirate Lord",
        "Swift Hunter",
        "Master of Concealment",
        "Expert Marksman",
        "Cut-throat"
    ],
    killTeamNames: [
        ["Black", "Dark", "Lightning", "Sun", "Moon", "Star"],
        [
            "Brother/Sisterhood",
            "Reavers",
            "Plunderers",
            "Striders",
            "Riders",
            "Ravagers"
        ]
    ],
    operativeNames: [
        [
            // asuryani
            "Tenrith",
            "Justune",
            "Yrlla",
            "Aileer",
            "Ishylla",
            "Galanta",

            // asuryani 2
            "Fachean",
            "Yrmnoch",
            "Iradil",
            "Requiel",
            "Yrule",
            "Ra'thar",

            // kabalite
            "Anarkyss",
            "Quaez",
            "Skythe",
            "Sykil",
            "Vypus",
            "Ethriliac",

            // wych cult
            "Xela",
            "Mellyx",
            "Veshtari",
            "Thessa",
            "Vylekh",
            "Bithandrel"
        ],
        [
            // asuryani
            "the Fireheart",
            "Kyldroth",
            "Who Walks Alone",
            "of the Flowing Spirits",
            "the Huntress",
            "Umachuli",

            // asuryani 2
            "Son of Coheria",
            "the Unyielding Fire",
            "Sydarus Starstrider",
            "Ulthos ('speaker of unspeakable truths')",
            "the Deathly Eloquent",
            "Born of Twilight",

            // kabalite
            "Sar'sel",
            "the Bloodbreather",
            "the Flenser",
            "of the Obsidian Needle",
            "Flickerblade",
            "Xosh",

            // wych cult
            "the Crimson",
            "Beastbane",
            "Masdruvael",
            "Bloodslyk",
            "the Bloodsister",
            "Nervose"
        ]
    ],
    history: [
        "Bound of the Craftworld",
        "Bound to Yvraine",
        "Bloodthirsty",
        "Lethal Assassins",
        "Newly Free",
        "Stundents of Yriel"
    ],
    baseOfOperations: [
        "Webway Haven",
        "'Borrowed' Vehicles",
        "Corsair Cruiser",
        "Debris-cloud Station",
        "Craftworld",
        "Rogue Trader Outpost"
    ],
    squadQuirk: [
        "Ever on the Move",
        "Plunderers",
        "Mercenaries",
        "Independent of Spirit",
        "Vengeful",
        "Bonded in Blood"
    ],
    strategicAssets: ["Hoard of Spoils", "Webway Entrance", "Aerial Support"],
    equipment: [
        {
            name: "Diuturnal Mantle",
            cost: 2
        },
        {
            name: "Plasma Grenade",
            cost: 3
        },
        {
            name: "Corsair Blade",
            cost: 1
        },
        {
            name: "Ocular Scanner",
            cost: 2
        },
        {
            name: "Runes of Protection",
            cost: 2
        },
        {
            name: "Mistfield",
            cost: 3
        },
        {
            name: "Lodestar Helm",
            cost: 2
        },
        {
            name: "Pathfinder Cloak",
            cost: 2
        }
    ],
    rareEquipment: [
        {
            name: "Long-sight Helm",
            cost: 3
        },
        {
            name: "Flip Belt",
            cost: 2
        },
        {
            name: "Spirit Stone",
            cost: 4
        },
        {
            name: "Psychoreactive Armour",
            cost: 3
        },
        {
            name: "Relic Power Weapon",
            cost: 2
        },
        {
            name: "Relic Witch Staff",
            cost: 2
        }
    ],
    specOps: ["Path of the Reaver", "Aggressive Raid"]
};

export default info;

import { INTERCESSOR_SQUAD_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const IntercessorSquadController = {
    id: INTERCESSOR_SQUAD_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        //
        if (operativesInKillTeam.length < 6) {
            passed = false;
            messages.push("Must include 6 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Intercessor Sergeant" ||
                    operative.unit === "Assault Intercessor Sergeant"
                );
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Sergeant");
        }

        ["Assault Intercessor Grenadier", "Intercessor Gunner"].forEach(
            (unit) => {
                if (
                    operativesInKillTeam.filter((operative) => {
                        return unit === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(`Maximum of 1 ${unit}`);
                }
            }
        );

        //
        return {
            passed,
            messages
        };
    }
};

export default IntercessorSquadController;

import { TOMB_WORLD_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const TombWorldController = {
    id: TOMB_WORLD_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains up to 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Immortal Leader" ||
                    operative.unit === "Flayed One Leader" ||
                    operative.unit === "Deathmark Leader"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Maximum of 1 Immortal Leader/Flayed One Leader/Deathmark Leader"
            );
        }

        //
        if (
            list.fireTeams.filter((team) => {
                return team === "Necron Warrior";
            }).length > 1
        ) {
            passed = false;
            messages.push("Max 1 Necron Warrior Fire Team");
        }

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Necron Warrior") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Necron Warrior Fire Team must include 5 operatives"
                    );
                }
            }

            if (fireTeam === "Immortal") {
                if (operativesInFireTeams[index].length !== 4) {
                    passed = false;
                    messages.push(
                        "Immortal Fire Team must include 4 operatives"
                    );
                }
            }

            if (fireTeam === "Flayed One") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Flayed One Fire Team must include 5 operatives"
                    );
                }
            }

            if (fireTeam === "Deathmark") {
                if (operativesInFireTeams[index].length !== 4) {
                    passed = false;
                    messages.push(
                        "Deathmark Fire Team must include 4 operatives"
                    );
                }
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default TombWorldController;

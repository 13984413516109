const restrictions = () => {
    return (
        <div>
            <p>
                A <strong className="faction">KOMMANDO</strong> kill team
                consists of:
            </p>
            <ul>
                <li>
                    1 <strong>KOMMANDO NOB</strong>
                </li>
                <li>
                    9 <strong className="faction">KOMMANDO</strong> operatives
                    selected from the following list:
                    <ul>
                        <li>
                            <strong>KOMMANDO BOY</strong>
                        </li>
                        <li>
                            <strong>KOMMANDO GROT</strong>
                        </li>
                        <li>
                            <strong>KOMMANDO SLASHA BOY</strong>
                        </li>
                        <li>
                            <strong>KOMMANDO BREACHA BOY</strong>
                        </li>
                        <li>
                            <strong>KOMMANDO SNIPA BOY</strong>
                        </li>
                        <li>
                            <strong>KOMMANDO DAKKA BOY</strong>
                        </li>
                        <li>
                            <strong>KOMMANDO COMMS BOY</strong>
                        </li>
                        <li>
                            <strong>KOMMANDO BURNA BOY</strong>
                        </li>
                        <li>
                            <strong>KOMMANDO ROKKIT BOY</strong>
                        </li>
                        <li>
                            <strong>BOMB SQUIG</strong>
                        </li>
                    </ul>
                </li>
            </ul>

            <p>
                <strong>KOMMANDO BOY</strong> operatives can be selected up to
                nine times, and each other option above can selected once.
            </p>
        </div>
    );
};

export default restrictions;

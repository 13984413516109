import { EXACTION_SQUAD } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const HearthkynSalvagerController = {
    id: EXACTION_SQUAD,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length < 10) {
            passed = false;
            messages.push("Must include 10 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Hearthkyn Theyn";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Hearthkyn Theyn");
        }

        // only 1 of these units
        [
            "Hearthkyn Dôzr",
            "Hearthkyn Field Medic",
            "Hearthkyn Grenadier",
            "Hearthkyn Jump Pack Warrior",
            "Hearthkyn Kinlynk",
            "Hearthkyn Kognitâar",
            "Hearthkyn Lokâtr",
            "Hearthkyn Lugger"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        // max 3 gunners
        if (
            operativesInKillTeam.filter((operative) => {
                return "Hearthkyn Gunner" === operative.unit;
            }).length > 3
        ) {
            passed = false;
            messages.push(`Maximum of 3 Hearthkyn Gunners`);
        }

        // max of 1 weapon type
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.weapons[0].includes("EtaCarn plasma beamer");
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 EtaCarn plasma beamer per Kill Team.`);
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return operative.weapons[0].includes("HYLas auto rifle");
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 HYLas auto rifle per Kill Team.`);
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return operative.weapons[0].includes("HYLas rotary cannon");
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 HYLas rotary cannon per Kill Team.`);
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return operative.weapons[0].includes("L7 missile launcher");
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 L7 missile launcher per Kill Team.`);
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return operative.weapons[0].includes("Magna rail rifle");
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 Magna rail rifle per Kill Team.`);
        }

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        // const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default HearthkynSalvagerController;

export function arrContains(a, obj) {
    var i = a.length;
    while (i--) {
        if (a[i] === obj) {
            return true;
        }
    }
    return false;
}

export function getObjectValues(obj) {
    const values = [];

    for (const val in obj) {
        values.push(obj[val]);
    }

    return values;
}

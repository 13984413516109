// raw KT data
import { PATHFINDER_ID } from "../../../constants/factions";

const killTeam = {
    id: PATHFINDER_ID,
    operatives: [
        {
            name: "Shas'ui Pathfinder",
            limit: 1,
            leader: true,
            weapons: [["Pulse carbine, Bonding knife"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Shas'la Pathfinder",
            weapons: [["Pulse carbine, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Blooded Pathfinder",
            weapons: [["Suppressed pulse carbine, Bionic arm"]],
            specialisms: ["staunch", "marksman", "scout"]
        },
        {
            name: "Drone Controller Pathfinder",
            weapons: [["Pulse carbine, Fists"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Assault Grenadier Pathfinder",
            weapons: [["Pulse carbine, Fists"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Medical Technician Pathfinder",
            weapons: [["Pulse carbine, Fists"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Transpectral Interference Pathfinder",
            weapons: [["Pulse carbine, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Communications Specialist Pathfinder",
            weapons: [["Pulse carbine, Fists"]],
            specialisms: ["scout"]
        },
        {
            name: "Weapons Expert Pathfinder",
            weapons: [["Fists, Rail rifle", "Fists, Ion rifle"]],
            specialisms: ["combat", "staunch", "marksman", "scout"]
        },
        {
            name: "Marksman Pathfinder",
            weapons: [["Marksman rail rifle, Fists"]],
            specialisms: ["marksman"]
        },

        // DRONES
        {
            name: "MV1 Gun Drone",
            limit: 1,
            weapons: [["Twin pulse carbine, Ram"]],
            specialisms: ["marksman"]
        },
        {
            name: "MV4 Shield Drone",
            limit: 1,
            weapons: [["Ram"]],
            specialisms: ["staunch"]
        },
        {
            name: "MV7 Marker Drone",
            limit: 1,
            weapons: [["Ram"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "MB3 Recon Drone",
            limit: 1,
            weapons: [["Burst cannon, Ram"]],
            specialisms: ["staunch", "marksman", "scout"]
        },
        {
            name: "MV31 Pulse Accelerator Drone",
            limit: 1,
            weapons: [["Ram"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "MV33 Grav-inhibitor Drone",
            limit: 1,
            weapons: [["Ram"]],
            specialisms: ["staunch", "scout"]
        }
    ]
};

export default killTeam;

import styled from "styled-components";
import theme from "./theme";

const StyledUserLists = styled.div`
    margin: 1em 0;
    border: solid 2px ${theme.colors.black};

    .lists__header {
        background: ${theme.colors.black};
        display: block;
        padding: 0.6em 0.8em 0.4em;
        font-family: ${theme.fonts.header};
        color: ${theme.colors.orange};
        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .lists__item {
        padding: 0.5em 0.8em;
        border-top: solid 2px ${theme.colors.black};
        background: ${theme.colors.offWhite};

        display: flex;
        align-items: center;

        &:nth-child(even) {
            background: ${theme.colors.altOffWhite};
        }
    }

    .list__info {
        flex-grow: 5;
    }

    .list__name {
        display: block;
        font-size: 0.9em;
    }

    .list__faction {
        display: block;
        font-family: ${theme.fonts.header};
        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    button {
        background: transparent;
        border-radius: 0;
        border: solid 2px ${theme.colors.orange};
        margin-left: 0.8em;
        font-family: ${theme.fonts.header};
        text-transform: uppercase;
        letter-spacing: 0.14em;
        font-size: 0.7em;
        color: ${theme.colors.orange};
        padding: 0.6em 0.8em 0.5em;
        transition: all 0.3s ease;

        &:hover {
            background: ${theme.colors.orange};
            color: ${theme.colors.white};
            border-color: ${theme.colors.orange};
        }
    }

    .list__delete {
    }
    .list__load {
    }
`;

export default StyledUserLists;

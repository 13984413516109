import {
    KOMMANDO_ID,
    VETERAN_GUARDSMAN_ID,
    HUNTER_CLADE_ID,
    WARPCOVEN_ID,
    NOVITIATE_ID,
    PATHFINDER_ID,
    SPACE_MARINE_ID,
    GREY_KNIGHT_ID,
    IMPERIAL_GUARD_ID,
    // FORGE_WORLD_ID,
    ECCLESIARCHY_ID,
    TALONS_OF_THE_EMPEROR_ID,
    TRAITOR_SPACE_MARINE_ID,
    DEATH_GUARD_ID,
    // THOUSAND_SONS_ID,
    CHAOS_DAEMON_ID,
    CRAFTWORLD_ID,
    COMMORRITE_ID,
    // TROUPE_ID,
    GREENSKIN_ID,
    TOMB_WORLD_ID,
    HUNTER_CADRE_ID,
    CADRE_MERCENARY_ID,
    HIVE_FLEET_ID,
    BROOD_COVEN_ID,
    WYRMBLADE_ID,
    VOID_DANCER_ID,
    VOIDSCARRED_ID,
    LEGIONARY_ID,
    PHOBOS_ID,
    BLOODED_ID,
    IMPERIAL_NAVY_BREACHERS_ID,
    FARSTALKER_ID,
    STARSTRIDER_ID,
    GELLERPOX_ID,
    INTERCESSOR_SQUAD_ID,
    KASRKIN_ID,
    HIEROTEK_CIRCLE_ID,
    HANDS_OF_THE_ARCHON,
    EXACTION_SQUAD,
    HEARTHKYN_SALVAGERS,
    FELLGORE_RAVAGERS,
    INQUISITORIAL_AGENT
} from "../../constants/factions";

import kommandoKillTeam from "./kommando/kill-team";
import kommandoInfo from "./kommando/info";
import kommandoController from "./kommando/controller";

import veteranGuardsmanKillTeam from "./veteran-guardsman/kill-team";
import veteranGuardsmanInfo from "./veteran-guardsman/info";
import veteranGuardsmanController from "./veteran-guardsman/controller";

import hunterCladeKillTeam from "./hunter-clade/kill-team";
import hunterCladeInfo from "./hunter-clade/info";
import hunterCladeController from "./hunter-clade/controller";

import warpcovenKillTeam from "./warpcoven/kill-team";
import warpcovenInfo from "./warpcoven/info";
import warpcovenController from "./warpcoven/controller";

import novitiateKillTeam from "./novitiate/kill-team";
import novitiateInfo from "./novitiate/info";
import novitiateController from "./novitiate/controller";

import pathfinderKillTeam from "./pathfinder/kill-team";
import pathfinderInfo from "./pathfinder/info";
import pathfinderController from "./pathfinder/controller";

import spaceMarineKillTeam from "./space-marine/kill-team";
import spaceMarineInfo from "./space-marine/info";
import spaceMarineController from "./space-marine/controller";

import greyKnightKillTeam from "./grey-knight/kill-team";
import greyKnightInfo from "./grey-knight/info";
import greyKnightController from "./grey-knight/controller";

import imperialGuardKillTeam from "./imperial-guard/kill-team";
import imperialGuardInfo from "./imperial-guard/info";
import imperialGuardController from "./imperial-guard/controller";

// import forgeWorldKillTeam from "./forge-world/kill-team";
// import forgeWorldInfo from "./forge-world/info";
// import forgeWorldController from "./forge-world/controller";

import ecclesiarchyKillTeam from "./ecclesiarchy/kill-team";
import ecclesiarchyInfo from "./ecclesiarchy/info";
import ecclesiarchyController from "./ecclesiarchy/controller";

import talonsOfTheEmperorKillTeam from "./talons-of-the-emperor/kill-team";
import talonsOfTheEmperorInfo from "./talons-of-the-emperor/info";
import talonsOfTheEmperorController from "./talons-of-the-emperor/controller";

import traitorSpaceMarineKillTeam from "./traitor-space-marine/kill-team";
import traitorSpaceMarineInfo from "./traitor-space-marine/info";
import traitorSpaceMarineController from "./traitor-space-marine/controller";

import deathGuardKillTeam from "./death-guard/kill-team";
import deathGuardInfo from "./death-guard/info";
import deathGuardController from "./death-guard/controller";

// import thousandSonsKillTeam from "./thousand-sons/kill-team";
// import thousandSonsInfo from "./thousand-sons/info";
// import thousandSonsController from "./thousand-sons/controller";

import chaosDaemonKillTeam from "./chaos-daemon/kill-team";
import chaosDaemonInfo from "./chaos-daemon/info";
import chaosDaemonController from "./chaos-daemon/controller";

import craftworldKillTeam from "./craftworld/kill-team";
import craftworldInfo from "./craftworld/info";
import craftworldController from "./craftworld/controller";

import commorriteKillTeam from "./commorrite/kill-team";
import commorriteInfo from "./commorrite/info";
import commorriteController from "./commorrite/controller";

// import troupeKillTeam from "./troupe/kill-team";
// import troupeInfo from "./troupe/info";
// import troupeController from "./troupe/controller";

import greenskinKillTeam from "./greenskin/kill-team";
import greenskinInfo from "./greenskin/info";
import greenskinController from "./greenskin/controller";

import tombWorldKillTeam from "./tomb-world/kill-team";
import tombWorldInfo from "./tomb-world/info";
import tombWorldController from "./tomb-world/controller";

import hunterCadreKillTeam from "./hunter-cadre/kill-team";
import hunterCadreInfo from "./hunter-cadre/info";
import hunterCadreController from "./hunter-cadre/controller";

import cadreMercenaryKillTeam from "./cadre-mercenary/kill-team";
import cadreMercenaryInfo from "./cadre-mercenary/info";
import cadreMercenaryController from "./cadre-mercenary/controller";

import hiveFleetKillTeam from "./hive-fleet/kill-team";
import hiveFleetInfo from "./hive-fleet/info";
import hiveFleetController from "./hive-fleet/controller";

import broodCovenKillTeam from "./brood-coven/kill-team";
import broodCovenInfo from "./brood-coven/info";
import broodCovenController from "./brood-coven/controller";

import wyrmbladeKillTeam from "./wyrmblade/kill-team";
import wyrmbladeInfo from "./wyrmblade/info";
import wyrmbladeController from "./wyrmblade/controller";

import voidDancerKillTeam from "./void-dancer/kill-team";
import voidDancerInfo from "./void-dancer/info";
import voidDancerController from "./void-dancer/controller";

import voidscarredKillTeam from "./voidscarred/kill-team";
import voidscarredInfo from "./voidscarred/info";
import voidscarredController from "./voidscarred/controller";

import legionaryKillTeam from "./legionary/kill-team";
import legionaryInfo from "./legionary/info";
import legionaryController from "./legionary/controller";

import phobosKillTeam from "./phobos/kill-team";
import phobosInfo from "./phobos/info";
import phobosController from "./phobos/controller";

import bloodedKillTeam from "./blooded/kill-team";
import bloodedInfo from "./blooded/info";
import bloodedController from "./blooded/controller";

import breachersKillTeam from "./breachers/kill-team";
import breachersInfo from "./breachers/info";
import breachersController from "./breachers/controller";

import farstalkerKillTeam from "./farstalker/kill-team";
import farstalkerInfo from "./farstalker/info";
import farstalkerController from "./farstalker/controller";

import starstriderKillTeam from "./elucidian-starstriders/kill-team";
import starstriderInfo from "./elucidian-starstriders/info";
import starstriderController from "./elucidian-starstriders/controller";

import gellerpoxKillTeam from "./gellerpox/kill-team";
import gellerpoxInfo from "./gellerpox/info";
import gellerpoxController from "./gellerpox/controller";

import intercessorSquadKillTeam from "./intercessor-squad/kill-team";
import intercessorSquadInfo from "./intercessor-squad/info";
import intercessorSquadController from "./intercessor-squad/controller";

import kasrkinKillTeam from "./kasrkin/kill-team";
import kasrkinInfo from "./kasrkin/info";
import kasrkinController from "./kasrkin/controller";

import heirotekCircleKillTeam from "./heirotek-circle/kill-team";
import heirotekCircleInfo from "./heirotek-circle/info";
import heirotekCircleController from "./heirotek-circle/controller";

import handsOfTheArchonKillTeam from "./hands-of-the-archon/kill-team";
import handsOfTheArchonInfo from "./hands-of-the-archon/info";
import handsOfTheArchonController from "./hands-of-the-archon/controller";

import exactionSquadKillTeam from "./exaction-squad/kill-team";
import exactionSquadInfo from "./exaction-squad/info";
import exactionSquadController from "./exaction-squad/controller";

import hearthkynSalvagersKillTeam from "./hearthkyn-salvagers/kill-team";
import hearthkynSalvagersInfo from "./hearthkyn-salvagers/info";
import hearthkynSalvagersController from "./hearthkyn-salvagers/controller";

import fellgorRavagersKillTeam from "./fellgor-ravagers/kill-team";
import fellgorRavagersInfo from "./fellgor-ravagers/info";
import fellgorRavagersController from "./fellgor-ravagers/controller";

import inquisitorialAgentKillTeam from "./inquisitorial-agent/kill-team";
import inquisitorialAgentInfo from "./inquisitorial-agent/info";
import inquisitorialAgentController from "./inquisitorial-agent/controller";

const factionsData = [
    {
        id: INQUISITORIAL_AGENT,
        killTeam: inquisitorialAgentKillTeam,
        info: inquisitorialAgentInfo,
        controller: inquisitorialAgentController
    },
    {
        id: FELLGORE_RAVAGERS,
        killTeam: fellgorRavagersKillTeam,
        info: fellgorRavagersInfo,
        controller: fellgorRavagersController
    },
    {
        id: HEARTHKYN_SALVAGERS,
        killTeam: hearthkynSalvagersKillTeam,
        info: hearthkynSalvagersInfo,
        controller: hearthkynSalvagersController
    },
    {
        id: EXACTION_SQUAD,
        killTeam: exactionSquadKillTeam,
        info: exactionSquadInfo,
        controller: exactionSquadController
    },
    {
        id: HANDS_OF_THE_ARCHON,
        killTeam: handsOfTheArchonKillTeam,
        info: handsOfTheArchonInfo,
        controller: handsOfTheArchonController
    },
    {
        id: KASRKIN_ID,
        killTeam: kasrkinKillTeam,
        info: kasrkinInfo,
        controller: kasrkinController
    },
    {
        id: HIEROTEK_CIRCLE_ID,
        killTeam: heirotekCircleKillTeam,
        info: heirotekCircleInfo,
        controller: heirotekCircleController
    },
    {
        id: INTERCESSOR_SQUAD_ID,
        killTeam: intercessorSquadKillTeam,
        info: intercessorSquadInfo,
        controller: intercessorSquadController
    },
    {
        id: WYRMBLADE_ID,
        killTeam: wyrmbladeKillTeam,
        info: wyrmbladeInfo,
        controller: wyrmbladeController
    },
    {
        id: VOID_DANCER_ID,
        killTeam: voidDancerKillTeam,
        info: voidDancerInfo,
        controller: voidDancerController
    },
    {
        id: VOIDSCARRED_ID,
        killTeam: voidscarredKillTeam,
        info: voidscarredInfo,
        controller: voidscarredController
    },
    {
        id: LEGIONARY_ID,
        killTeam: legionaryKillTeam,
        info: legionaryInfo,
        controller: legionaryController
    },
    {
        id: PHOBOS_ID,
        killTeam: phobosKillTeam,
        info: phobosInfo,
        controller: phobosController
    },
    {
        id: BLOODED_ID,
        killTeam: bloodedKillTeam,
        info: bloodedInfo,
        controller: bloodedController
    },
    {
        id: IMPERIAL_NAVY_BREACHERS_ID,
        killTeam: breachersKillTeam,
        info: breachersInfo,
        controller: breachersController
    },
    {
        id: FARSTALKER_ID,
        killTeam: farstalkerKillTeam,
        info: farstalkerInfo,
        controller: farstalkerController
    },
    {
        id: STARSTRIDER_ID,
        killTeam: starstriderKillTeam,
        info: starstriderInfo,
        controller: starstriderController
    },
    {
        id: GELLERPOX_ID,
        killTeam: gellerpoxKillTeam,
        info: gellerpoxInfo,
        controller: gellerpoxController
    },
    {
        id: KOMMANDO_ID,
        killTeam: kommandoKillTeam,
        info: kommandoInfo,
        controller: kommandoController
    },
    {
        id: VETERAN_GUARDSMAN_ID,
        killTeam: veteranGuardsmanKillTeam,
        info: veteranGuardsmanInfo,
        controller: veteranGuardsmanController
    },
    {
        id: HUNTER_CLADE_ID,
        killTeam: hunterCladeKillTeam,
        info: hunterCladeInfo,
        controller: hunterCladeController
    },
    {
        id: WARPCOVEN_ID,
        killTeam: warpcovenKillTeam,
        info: warpcovenInfo,
        controller: warpcovenController
    },
    {
        id: NOVITIATE_ID,
        killTeam: novitiateKillTeam,
        info: novitiateInfo,
        controller: novitiateController
    },
    {
        id: PATHFINDER_ID,
        killTeam: pathfinderKillTeam,
        info: pathfinderInfo,
        controller: pathfinderController
    },
    //
    {
        id: SPACE_MARINE_ID,
        killTeam: spaceMarineKillTeam,
        info: spaceMarineInfo,
        controller: spaceMarineController
    },
    {
        id: GREY_KNIGHT_ID,
        killTeam: greyKnightKillTeam,
        info: greyKnightInfo,
        controller: greyKnightController
    },
    {
        id: IMPERIAL_GUARD_ID,
        killTeam: imperialGuardKillTeam,
        info: imperialGuardInfo,
        controller: imperialGuardController
    },
    // {
    //     id: FORGE_WORLD_ID,
    //     killTeam: forgeWorldKillTeam,
    //     info: forgeWorldInfo,
    //     controller: forgeWorldController
    // },
    {
        id: ECCLESIARCHY_ID,
        killTeam: ecclesiarchyKillTeam,
        info: ecclesiarchyInfo,
        controller: ecclesiarchyController
    },
    {
        id: TALONS_OF_THE_EMPEROR_ID,
        killTeam: talonsOfTheEmperorKillTeam,
        info: talonsOfTheEmperorInfo,
        controller: talonsOfTheEmperorController
    },
    {
        id: TRAITOR_SPACE_MARINE_ID,
        killTeam: traitorSpaceMarineKillTeam,
        info: traitorSpaceMarineInfo,
        controller: traitorSpaceMarineController
    },
    {
        id: DEATH_GUARD_ID,
        killTeam: deathGuardKillTeam,
        info: deathGuardInfo,
        controller: deathGuardController
    },
    // {
    //     id: THOUSAND_SONS_ID,
    //     killTeam: thousandSonsKillTeam,
    //     info: thousandSonsInfo,
    //     controller: thousandSonsController
    // },
    {
        id: CHAOS_DAEMON_ID,
        killTeam: chaosDaemonKillTeam,
        info: chaosDaemonInfo,
        controller: chaosDaemonController
    },
    {
        id: CRAFTWORLD_ID,
        killTeam: craftworldKillTeam,
        info: craftworldInfo,
        controller: craftworldController
    },
    {
        id: COMMORRITE_ID,
        killTeam: commorriteKillTeam,
        info: commorriteInfo,
        controller: commorriteController
    },
    // {
    //     id: TROUPE_ID,
    //     killTeam: troupeKillTeam,
    //     info: troupeInfo,
    //     controller: troupeController
    // },
    {
        id: GREENSKIN_ID,
        killTeam: greenskinKillTeam,
        info: greenskinInfo,
        controller: greenskinController
    },
    {
        id: TOMB_WORLD_ID,
        killTeam: tombWorldKillTeam,
        info: tombWorldInfo,
        controller: tombWorldController
    },
    {
        id: HUNTER_CADRE_ID,
        killTeam: hunterCadreKillTeam,
        info: hunterCadreInfo,
        controller: hunterCadreController
    },
    {
        id: CADRE_MERCENARY_ID,
        killTeam: cadreMercenaryKillTeam,
        info: cadreMercenaryInfo,
        controller: cadreMercenaryController
    },
    {
        id: HIVE_FLEET_ID,
        killTeam: hiveFleetKillTeam,
        info: hiveFleetInfo,
        controller: hiveFleetController
    },
    {
        id: BROOD_COVEN_ID,
        killTeam: broodCovenKillTeam,
        info: broodCovenInfo,
        controller: broodCovenController
    }
];

export default factionsData;

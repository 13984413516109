// raw KT data
import { INTERCESSOR_SQUAD_ID } from "../../../constants/factions";

const killTeam = {
    id: INTERCESSOR_SQUAD_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Assault Intercessor Sergeant",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Hand flamer, Chainsword",
                    "Hand flamer, Power fist",
                    "Hand flamer, Power weapon",
                    "Hand flamer, Thunder hammer",
                    "Heavy bolt pistol, Chainsword",
                    "Heavy bolt pistol, Power fist",
                    "Heavy bolt pistol, Power weapon",
                    "Heavy bolt pistol, Thunder hammer",
                    "Plasma pistol, Chainsword"
                ]
            ],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Intercessor Sergeant",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Auto bolt rifle",
                    "Bolt pistol",
                    "Thunder hammer",
                    "Stalker bolt rifle"
                ],
                [
                    "Chainsword",
                    "Fists",
                    "Power fist",
                    "Power weapon",
                    "Thunder hammer"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Assault Intercessor Warrior",
            limit: 5,
            weapons: [["Heavy bolt pistol, Chainsword"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Assault Intercessor Grenadier",
            limit: 1,
            weapons: [
                ["Heavy bolt pistol, Chainsword, Frag and Krak grenades"]
            ],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Intercessor Warrior",
            limit: 5,
            weapons: [
                [
                    "Auto bolt rifle, Fists",
                    "Bolt rifle, Fists",
                    "Stalker bolt rifle, Fists"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Intercessor Gunner",
            limit: 1,
            weapons: [
                [
                    "Auxiliary grenade launcher, Auto bolt rifle, Fists",
                    "Auxiliary grenade launcher, Bolt rifle, Fists",
                    "Auxiliary grenade launcher, Stalker bolt rifle, Fists"
                ]
            ],
            specialisms: ["staunch", "marksman"]
        }
    ]
};

export default killTeam;

// names
// backgrounds etc...
import { COMMORRITE_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: COMMORRITE_ID,
    moniker: "Commorrite",
    restrictions: restrictions,
    equipment: [
        {
            name: "Plasma Grenade",
            cost: 2
        },
        {
            name: "Haywire Grenade",
            cost: 2
        },
        {
            name: "Phantasm Grenade Launcher",
            cost: 3
        },
        {
            name: "Hypex",
            cost: 2
        },
        {
            name: "Agonite",
            cost: 3
        },
        {
            name: "Adrenalite",
            cost: 2
        },
        {
            name: "Painbringer",
            cost: 2
        },
        {
            name: "Grave Lotus",
            cost: 2
        },
        {
            name: "Kabalite Banner",
            cost: 3
        },
        {
            name: "Deathbloom",
            cost: 3
        },
        {
            name: "Feralex",
            cost: 3
        }
    ]
};

export default info;

// raw KT data
import { KOMMANDO_ID } from "../../../constants/factions";

const killTeam = {
    id: KOMMANDO_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Kommando Nob",
            limit: 1,
            leader: true,
            weapons: [["Slugga, Big Choppa", "Slugga, Power Claw"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Kommando Boy",
            limit: 9,
            weapons: [["Slugga, Choppa"]],
            specialisms: ["combat", "staunch", "scout"]
        },
        {
            name: "Kommando Grot",
            limit: 1,
            weapons: [["Grot Choppa"]],
            specialisms: ["scout"]
        },
        {
            name: "Kommando Slasha Boy",
            weapons: [["Throwing knives, Twin choppas"]],
            limit: 1,
            specialisms: ["combat", "staunch", "scout"]
        },
        {
            name: "Kommando Breacha Boy",
            limit: 1,
            weapons: [["Slugga, Breacha ram"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Kommando Snipa Boy",
            limit: 1,
            weapons: [["Scoped big shoota, Fists"]],
            specialisms: ["marksman"]
        },
        {
            name: "Kommando Dakka Boy",
            limit: 1,
            weapons: [["Dakka shoota, Fists"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kommando Comms Boy",
            limit: 1,
            weapons: [["Slugga, Fists"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Kommando Burna Boy",
            limit: 1,
            weapons: [["Burna, Fists"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Kommando Rokkit Boy",
            limit: 1,
            weapons: [["Rokkit launcha, Fists"]],
            specialisms: ["marksman"]
        },
        {
            name: "Bomb Squig",
            limit: 1,
            weapons: [["Dynamite, Vicious bits"]],
            specialisms: []
        }
    ]
};

export default killTeam;

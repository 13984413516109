// raw KT data
import { WYRMBLADE_ID } from "../../../constants/factions";

const killTeam = {
    id: WYRMBLADE_ID,
    fireTeams: false,
    operatives: [
        {
            name: "Kabalite Archsybarite",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Blast pistol, Venom blade",
                    "Splinter pistol, Venom blade",
                    "Splinter pistol, Agoniser",
                    "Splinter pistol, Power weapon",
                    "Splinter rifle, Array of blades"
                ]
            ],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Kabalite Agent",
            limit: 8,
            weapons: [["Splinter rifle, Array of blades"]],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kabalite Crimson Duellist",
            limit: 1,
            weapons: [["Splinter pistol, Razorflail"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Kabalite Disciple of Yaelindra",
            limit: 1,
            weapons: [["Stinger pistol, Array of blades"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Kabalite Elixicant",
            limit: 1,
            weapons: [["Splinter rifle, Stimm-needler, Array of blades"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Kabalite Flayer",
            limit: 1,
            weapons: [["Pain sculptors"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Kabalite Gunner",
            limit: 1,
            weapons: [
                ["Array of blades, Blaster", "Array of blades, Shredder"]
            ],
            specialisms: ["marksman", "scout"]
        },
        {
            name: "Kabalite Heavy Gunner",
            limit: 1,
            weapons: [
                [
                    "Array of blades, Dark lance",
                    "Array of blades, Splinter cannon"
                ]
            ],
            specialisms: ["marksman"]
        },
        {
            name: "Kabalite Skysplinter Assassin",
            limit: 1,
            weapons: [["Razorwing, Shardcarbine, Array of blades"]],
            specialisms: ["marksman", "scout"]
        }
    ]
};

export default killTeam;

// raw KT data
import { NOVITIATE_ID } from "../../../constants/factions";

const killTeam = {
    id: NOVITIATE_ID,
    operatives: [
        {
            name: "Novitiate Superior",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Boltgun, Gun butt",
                    "Bolt pistol, Power weapon",
                    "Plasma pistol, Power weapon"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Novitiate Militant",
            weapons: [["Autopistol, Novitiate blade", "Autogun, Gun butt"]],
            specialisms: ["combat", "marksman", "scout"]
        },
        {
            name: "Novitiate Penitent",
            weapons: [["Autopistol, Penitent eviscerator"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Novitiate Pronatus",
            weapons: [["Autopistol, Gun butt"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Novitiate Exactor",
            weapons: [["Neural whips, Neural whips"]],
            specialisms: ["combat", "scout"]
        },
        {
            name: "Novitiate Reliquarius",
            weapons: [["Autopistol, Gun butt"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Novitiate Hospitaller",
            weapons: [["Autopistol, Surgical saw"]],
            specialisms: ["staunch", "scout"]
        },
        {
            name: "Novitiate Preceptor",
            weapons: [["Mace of the Righteous"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Novitiate Dialogus",
            weapons: [["Autopistol, Dialogus stave"]],
            specialisms: ["scout"]
        },
        {
            name: "Novitiate Duellist",
            weapons: [["Autopistol, Duelling blades"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Novitiate Condemnor",
            weapons: [["Condemnor stakethrower, Null rod"]],
            specialisms: ["combat", "marksman"]
        },
        {
            name: "Novitiate Purgatus",
            weapons: [["Ministorum flamer, Gun butt"]],
            specialisms: ["staunch", "marksman"]
        }
    ]
};

export default killTeam;

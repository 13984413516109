// raw KT data
import { GREENSKIN_ID } from "../../../constants/factions";

const killTeam = {
    id: GREENSKIN_ID,
    fireTeams: 2,
    fireTeamTypes: [
        {
            name: "Boy",
            units: ["Boy Fighter", "Boy Gunner", "2 x Gretchin"]
        },
        {
            name: "Clan Kommando",
            units: ["Clan Kommando Nob", "Clan Kommando Fighter"]
        },
        {
            name: "Speshulist",
            units: [
                "Spanner Speshulist",
                "Burna Boy Speshulist",
                "Loota Speshulist"
            ]
        }
    ],
    operatives: [
        {
            name: "Boy Boss Nob",
            limit: 1,
            leader: true,
            weapons: [
                [
                    "Shoota, Fists",
                    "Kombi-rokkit, Big choppa",
                    "Kombi-rokkit, Choppa",
                    "Kombi-rokkit, Killsaw",
                    "Kombi-rokkit, Power klaw",
                    "Kombi-skorcha, Big choppa",
                    "Kombi-skorcha, Choppa",
                    "Kombi-skorcha, Killsaw",
                    "Kombi-skorcha, Power klaw",
                    "Slugga, Big choppa",
                    "Slugga, Choppa",
                    "Slugga, Killsaw",
                    "Slugga, Power klaw"
                ]
            ],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Boy Fighter",
            limit: 5,
            weapons: [["Shoota, Fists", "Slugga, Choppa"]],
            specialisms: ["combat", "marksman"]
        },
        {
            name: "Boy Gunner",
            limit: 1,
            weapons: [["Fists"], ["Big shoota", "Rokkit launcha"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "2 x Gretchin",
            limit: 2,
            weapons: [["Gretchin blasta"], ["Gretchin knife"]],
            specialisms: ["marksman", "scout"]
        },
        //
        {
            name: "Clan Kommando Nob",
            limit: 1,
            weapons: [["Slugga"], ["Choppa", "Power klaw"]],
            specialisms: ["combat", "staunch"]
        },
        {
            name: "Clan Kommando Fighter",
            limit: 5,
            weapons: [["Slugga", "Choppa"]],
            specialisms: ["combat", "scout"]
        },
        //
        {
            name: "Spanner Speshulist",
            limit: 1,
            weapons: [
                ["Fists"],
                ["Big shoota", "Kustom mega-blasta", "Rokkit launcha"]
            ],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Burna Boy Speshulist",
            limit: 4,
            weapons: [["Burna, Fists"]],
            specialisms: ["staunch", "marksman"]
        },
        {
            name: "Loota Speshulist",
            limit: 4,
            weapons: [["Deffgun, Fists"]],
            specialisms: ["staunch", "marksman"]
        }
    ]
};

export default killTeam;

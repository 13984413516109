// raw KT data
import { GREY_KNIGHT_ID } from "../../../constants/factions";

const killTeam = {
    id: GREY_KNIGHT_ID,
    operatives: [
        {
            name: "Grey Knight Justicar",
            limit: 1,
            leader: true,
            weapons: [
                ["Storm bolter"],
                [
                    "Nemesis daemon hammer",
                    "Nemesis falchions",
                    "Nemesis force weapon",
                    "Nemesis warding stave"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Grey Knight Warrior",
            limit: 4,
            weapons: [
                ["Storm bolter"],
                [
                    "Nemesis daemon hammer",
                    "Nemesis falchions",
                    "Nemesis force weapon",
                    "Nemesis warding stave"
                ]
            ],
            specialisms: ["combat", "staunch", "marksman"]
        },
        {
            name: "Grey Knight Gunner",
            limit: 1,
            weapons: [["Fists"], ["Incinerator", "Psilencer", "Psycannon"]],
            specialisms: ["staunch", "marksman"]
        }
    ]
};

export default killTeam;

// names
// backgrounds etc...
import { SPACE_MARINE_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: SPACE_MARINE_ID,
    moniker: "Space Marines",
    restrictions: restrictions,
    equipment: [
        {
            name: "Auxilary Grenade Launcher",
            cost: 1
        },
        {
            name: "Helix Gauntlet",
            cost: 2
        },
        {
            name: "Smoke Grenade",
            cost: 3
        },
        {
            name: "Haywire mine",
            cost: 3
        },
        {
            name: "Shock Grenade",
            cost: 3
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Purity Seal",
            cost: 3
        },
        {
            name: "Suspensor System",
            cost: 3
        },
        {
            name: "Grapnel Launcher",
            cost: 1
        },
        {
            name: "Grav-chute",
            cost: 2
        },
        {
            name: "Auspex",
            cost: 3
        }
    ]
};

export default info;

import styled from "styled-components";
import theme from "./theme";

const StyledFactionValidation = styled.div`
    background: ${theme.colors.darkGrey};
    padding: 1em;
    // border: solid 2px ${theme.colors.black};
    border-bottom: solid 4px ${theme.colors.orange};
    color: ${theme.colors.offWhite};

    .title {
        text-transform: uppercase;
        text-align: center;
        font-family: ${theme.fonts.header};
        font-weight: normal;
        font-size: 1.2em;

        span {
            display: block;
            font-family: ${theme.fonts.body};
            letter-spacing: 0.2em;
            font-size: 0.8em;
            padding-top: 0.3em;
        }
    }

    .info {
        font-size: 0.8em;

        ul {
            padding-left: 1.6em;
        }
    }

    strong {
        font-family: ${theme.fonts.header};
        font-weight: normal;
        color: ${theme.colors.white};
    }

    .faction {
        font-family: ${theme.fonts.header};
        font-weight: normal;
        color: ${theme.colors.orange};
    }

    h3 {
        font-family: ${theme.fonts.header};
        font-size: 1.3em;
        line-height: 1.4em;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        color: ${theme.colors.orange};
        border-bottom: solid 2px ${theme.colors.orange};
    }

    .validation-messages {
        padding-top: 0.8em;
        margin-top: 0.7em;
        border-top: solid 2px ${theme.colors.orange};

        span {
            text-transform: uppercase;
            color: ${theme.colors.orange};
            font-family: ${theme.fonts.header};
            letter-spacing: 0.05em;
            font-size: 0.8em;
            display: block;
            padding-bottom: 0.6em;
        }

        ul {
            margin: 0;
            padding: 0 0 0 1.5em;
        }
    }

    .validation-message {
    }
`;

export default StyledFactionValidation;

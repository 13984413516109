import React, { useContext } from "react";
import AppStateContext from "../context";
import StyledSelectText from "../styles/StyledSelectText";
import { getFactionData } from "../controllers/DataController";

const PageSpecOps = (props) => {
    const { metric, text, odd } = props;
    const { appState, dispatch } = useContext(AppStateContext);
    const list = appState.lists.byId[appState.activeList];
    const factionData = getFactionData(list.faction);

    const update = (value, key) => {
        /*
        moniker: "",
        baseOfOperations: "",
        background: "",
        squadQuirk: "",
        strategicAssets: [],
        stash: [],
        specOpsLog: [],
        requisitionPoints: 0,
        assetCapacity: 0,
        notes: ""
        */

        dispatch({
            type: "UPDATE_SPEC_OPS",
            payload: {
                key: key,
                value: value
            }
        });
    };

    return (
        <StyledSelectText>
            <div
                className={
                    odd
                        ? "select-text__wrap select-text__wrap--odd"
                        : "select-text__wrap"
                }
            >
                {factionData.info[metric] && (
                    <div className="select-text__inner">
                        <select
                            value={list[metric]}
                            onChange={(event) => {
                                update(event.target.value, metric);
                            }}
                        >
                            <option default>Select a {text}...</option>
                            {factionData.info[metric].map((base) => {
                                return <option key={base}>{base}</option>;
                            })}
                        </select>
                    </div>
                )}

                <input
                    className={factionData.info[metric] ? "" : "no-options"}
                    value={list[metric]}
                    placeholder={`Add a ${text}...`}
                    onChange={(event) => {
                        update(event.target.value, metric);
                    }}
                />
            </div>
        </StyledSelectText>
    );
};

export default PageSpecOps;

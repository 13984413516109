const specOps = [
    "Elimination",
    "Recover Archeotech",
    "Perform Ritual",
    "Demolition",
    "Inflitrate the Enemy",
    "Purge Order",
    "Breakout",
    "Extraction",
    "Honour-bound",
    "Secure District"
];

export default specOps;

import styled from "styled-components";
import theme from "./theme";

const StyledRosterAndKillTeam = styled.div`
    .add-operative__wrap {
        margin: 3em 0;

        label {
            display: block;
            text-transform: uppercase;
            font-family: ${theme.fonts.header};
            // margin-bottom: 0.2em;
            // background: ${theme.colors.darkGrey};
            color: ${theme.colors.black};
            // padding: 0.5em 1rem;
            padding: 0 0 0.3em 0.2em;
            font-size: 0.8em;
        }

        select {
            display: block;
            width: 100%;
            border-radius: 0;
            padding: 0.7em;
            // border: solid 2px ${theme.colors.lightGreen};
        }
    }

    textarea {
        display: block;
        width: 100%;
        min-height: 10em;
        background: none;
        border: none;
        font-family: ${theme.fonts.body};
        font-size: 1em;

        &:focus {
            outline: none;
        }
    }

    .equipment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: ${theme.colors.orange};
        padding: 0.6em 1rem;
        font-family: ${theme.fonts.header};
        color: ${theme.colors.white};
        letter-spacing: 0.02em;
        // margin-bottom: 2em;
        border-bottom: solid 4px ${theme.colors.orange};
    }

    .equipment__cost {
        font-size: 1.3em;
    }

    .equipment__ep {
        font-size: 0.8em;
        padding-left: 0.15em;
    }

    .fire-team {
        padding-top: 1em;

        .select__fireteam-inner {
            position: relative;
            background: ${theme.colors.orange};
            border: solid 2px ${theme.colors.black};

            &::after {
                position: absolute;
                right: 0.4em;
                top: 0.3em;
                content: "▾";
                font-weight: 700;
                font-size: 1.2em;
                // content: "﹀";
                // content: "︾";
            }
        }

        &:last-child {
            padding-top: 3em;
        }

        .select__fireteam {
            font-family: ${theme.fonts.header};
            text-transform: uppercase;
            font-size: 1.1em;
            padding: 0.5em 1rem;
            width: 100%;
            background: none;
            border: none;
            border-radius: 0;
            color: ${theme.colors.black};

            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;

            &:focus {
                outline: none;
            }
        }

        .select__operative-inner {
            position: relative;
            background: ${theme.colors.white};
            border: solid 2px ${theme.colors.black};

            &::after {
                position: absolute;
                right: 0.4em;
                top: 0;
                content: "▾";
                font-weight: 700;
                font-size: 1.2em;
                // content: "﹀";
                // content: "︾";
            }

            .select__operative {
                width: 100%;
                background: none;
                border: none;
                border-radius: 0;
                color: ${theme.colors.black};
                margin: 0;

                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;

                &:focus {
                    outline: none;
                }
            }
        }

        .add-operative__wrap {
            margin: 1.4em 0 0;
        }
    }
`;

export default StyledRosterAndKillTeam;

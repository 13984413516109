import styled from "styled-components";
import theme from "./theme";

const StyledSpecOps = styled.div`
    margin-top: 1em;

    .row {
        padding: 0.6em 1rem;
        background: ${theme.colors.offWhite};
        margin-bottom: 2px;

        &:nth-child(odd) {
            background: ${theme.colors.altOffWhite};
        }

        &.row--inline {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div {
                flex-grow: 2;
            }

            select {
                text-align: right;
            }
        }

        label {
            font-family: ${theme.fonts.header};
            text-transform: uppercase;
            color: ${theme.colors.orange};
            font-size: 0.8em;
            display: inline-block;
            padding-right: 1em;
        }
    }

    select {
        width: 100%;
        background: none;
        border: none;
        font-family: ${theme.fonts.body};
        letter-spacing: 0.01em;
        font-size: 0.9em;

        &:focus {
            outline: none;
        }
    }

    .row--list {
        select {
            margin-top: 0.5em;
            padding: 0.3em 0;
            border-top: solid 2px ${theme.colors.orange};
            font-weight: 700;
            letter-spacing: 0;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            color: ${theme.colors.black};
        }

        .list-items > * {
            display: flex;
            justify-content: space-between;
            padding: 0.25em 0;
            font-size: 0.9em;

            border-top: solid 1px ${theme.colors.grey};

            &:first-child {
                border-top: none;
            }
        }

        button {
            background: none;
            border: solid 2px #888;
            color: #888;
            font-family: ${theme.fonts.header};
            text-transform: uppercase;

            &:hover {
                border-color: ${theme.colors.darkGrey};
                color: ${theme.colors.darkGrey};
            }
        }
    }

    .row__select-wrap {
        position: relative;

        &::after {
            position: absolute;
            right: 0;
            top: 0.6em;
            content: "▾";
            font-weight: 700;
            font-size: 1.2em;
            // content: "﹀";
            // content: "︾";
        }
    }

    textarea {
        display: block;
        width: 100%;
        min-height: 10em;
        background: none;
        border: none;
        font-family: ${theme.fonts.body};
        font-size: 1em;

        &:focus {
            outline: none;
        }
    }

    input {
        background: none;
        border: none;
        border-radius: 0;
    }

    .team-name {
        display: flex;

        input {
            flex-grow: 2;
            padding: 0.5em;
            text-align: right;
            border-bottom: dotted 1px ${theme.colors.grey};
            border-radius: 0;
        }
    }

    .team-name__icon {
        width: 2em;
        height: 2em;
        display: block;
        background: none;
        border: none;
        position: relative;

        svg {
            width: 80%;
            height: 80%;
            position: absolute;
            top: 10%;
            left: 10%;
        }
    }

    .counter {
        text-align: center;
        padding-bottom: 1em;

        label {
            padding-right: 0;
            padding-bottom: 0.5em;
        }

        > div {
            display: flex;
            justify-content: center;
            // background: ${theme.colors.orange};
            align-items: stretch;

            input {
                font-size: 2em;
                line-height: 1.4em;
                text-align: center;
                background: none;
                font-family: ${theme.fonts.header};
                margin: 0;
                width: 2.6em;
                display: block;
                border: solid 2px ${theme.colors.orange};
                border-radius: 0;
                opacity: 1;
                box-sizing: content-box;

                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
            }

            button {
                display: block;
                width: 3em;
                border-radius: 0;
                border: solid 2px ${theme.colors.orange};
                background: ${theme.colors.orange};
                font-family: ${theme.fonts.header};
                color: ${theme.colors.white};
                font-size: 1.2em;
            }
        }
    }

    @media (min-width: 28em) {
        .counters {
            display: flex;
            justify-content: center;

            .counter {
                margin: 0 1em;

                > div {
                    input {
                        padding: 0 0 0 0.55em;
                    }
                }
            }
        }
    }
`;

export default StyledSpecOps;

import React, { useContext, useState } from "react";
import AppStateContext from "../context";
import StyledExport from "../styles/StyledExport";
import saveAs from "file-saver";
import { isiOS, openForiOS } from "./export/BrowserUtils";
import { createExportObj } from "../controllers/DataController";

const PageExport = (props) => {
    const { appState } = useContext(AppStateContext);
    const [rendering, setRendering] = useState(false);
    const [playerName, setPlayerName] = useState(false);

    const createRosterPDF = async () => {
        setRendering(true);

        const { renderRosterPDF } = await import("./export/RenderPDF");
        const blob = await renderRosterPDF(
            createExportObj(appState, playerName)
        );

        if (isiOS) {
            await openForiOS(blob);
        } else {
            saveAs(blob, "Kill-Team-Roster.pdf");
        }

        setRendering(false);
    };

    const createDataslatePDF = async () => {
        setRendering(true);

        const { renderDataslatePDF } = await import("./export/RenderPDF");
        const blob = await renderDataslatePDF(
            createExportObj(appState, playerName)
        );

        if (isiOS) {
            await openForiOS(blob);
        } else {
            saveAs(blob, "Kill-Team-Dataslate.pdf");
        }

        setRendering(false);
    };

    const createDatacardsPDF = async () => {
        setRendering(true);

        const { renderDatacardsPDF } = await import("./export/RenderPDF");
        const blob = await renderDatacardsPDF(
            createExportObj(appState, playerName)
        );

        if (isiOS) {
            await openForiOS(blob);
        } else {
            saveAs(blob, "Kill-Team-Datacards.pdf");
        }

        setRendering(false);
    };

    return (
        <StyledExport>
            <div className="export__buttons">
                {rendering ? (
                    <span className="loading">Loading...</span>
                ) : (
                    <>
                        <div className="player-name">
                            <label>Player Name (optional):</label>
                            <input
                                maxLength="30"
                                type="text"
                                onChange={(e) => setPlayerName(e.target.value)}
                                placeholder="Player name..."
                            />
                        </div>
                        <div className="export__button">
                            <button onClick={createRosterPDF}>
                                EXPORT ROSTER
                            </button>
                        </div>
                        <div className="export__button">
                            <button onClick={createDataslatePDF}>
                                EXPORT DATASLATE
                            </button>
                        </div>
                        <div className="export__button">
                            <button onClick={createDatacardsPDF}>
                                EXPORT DATA CARDS
                            </button>
                        </div>
                    </>
                )}
            </div>
        </StyledExport>
    );
};

export default PageExport;

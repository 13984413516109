// names
// backgrounds etc...
import { VOID_DANCER_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: VOID_DANCER_ID,
    moniker: "Void Dancer Troupe",
    restrictions: restrictions,
    battleHonours: [
        "Whimsical",
        "Well Versed",
        "Martial Artistry",
        "Dazzling Spectacle",
        "Virtuoso",
        "Pre-einent"
    ],
    killTeamNames: [
        [
            "Players of the",
            "Swords of the",
            "Ensemble of the",
            "Spears of the",
            "Cast of the",
            "Band of the",
            "Company of the",
            "Assembly of the",
            "Coterie of the",
            "Kiss of the",
            "Symphony of the",
            "Harmony of the"
        ],
        [
            "Glittering Tide",
            "Jape Most Dark",
            "Crimson Sun",
            "Swooping Falcon",
            "Never-ending Dream",
            "Shimmering Prism",
            "Crystal Stage",
            "Healer's Lament",
            "Azure Moon",
            "Crone's Talon",
            "End of Days",
            "Blinding Serpent"
        ]
    ],
    operativeNames: [
        [
            "The Sun",
            "The Star",
            "The Shadow",
            "The Void",
            "The Redtide",
            "The Sky",
            "The Moon",
            "The Highborn",
            "The Leering",
            "The Bladed",
            "The Gleeful",
            "The Trueborn",
            "The Hidden",
            "The Black",
            "The Golden",
            "The Comet",
            "The Shimmering",
            "The Dawn"
        ],
        [
            "King",
            "Queen",
            "Prince",
            "Knave",
            "Judge",
            "Witch",
            "Executioner",
            "Seer",
            "Ghoul",
            "Crone",
            "Herald",
            "Assassin",
            "Princess",
            "Lord",
            "Lady",
            "Knight",
            "Hunter",
            "Maiden"
        ]
    ],
    history: [
        "The Swords of Khaine",
        "Webway Wanderers",
        "The Solitary",
        "Fate's Messengers",
        "Sinister Killers",
        "Guardians of the Black Library"
    ],
    baseOfOperations: [
        "Webway Spur",
        "The Black Library",
        "Fated Craftworld",
        "Dark Corner of Commorragh",
        "Corsair Vessel",
        "Starweavers"
    ],
    squadQuirk: [
        "Bringers of Terror",
        "Like Shadows in the Dark",
        "Come Hither, Behold Thy Destroyers",
        "Swift as the Wind",
        "Cruelly Mocking",
        "Starlight Stride"
    ],
    strategicAssets: ["Webway Portal", "Shrine of Meditation", "Loretrove"],
    equipment: [
        {
            name: "Shrieker Toxin Rounds (Shrieker Cannon)",
            cost: 4
        },
        {
            name: "Shrieker Toxin Rounds",
            cost: 3
        },
        {
            name: "Death Mask",
            cost: 3
        },
        {
            name: "Accelerated Monofilament Wire",
            cost: 3
        },
        {
            name: "Supertensile Monofilament Wire",
            cost: 3
        },
        {
            name: "Wraithbone Talisman",
            cost: 3
        },
        {
            name: "Prismatic Grenade",
            cost: 3
        },
        {
            name: "Pure Psychocrystals",
            cost: 3
        },
        {
            name: "Support Grip",
            cost: 3
        }
    ],
    rareEquipment: [
        {
            name: "Hidden Guise",
            cost: 1
        },
        {
            name: "Crystal Shard",
            cost: 3
        },
        {
            name: "Shimmerclone",
            cost: 3
        },
        {
            name: "Falcon's Feather",
            cost: 3
        },
        {
            name: "Mocking Panoply",
            cost: 2
        },
        {
            name: "Raiment of Mirrors",
            cost: 2
        }
    ],
    specOps: ["Dance Along the Threads of Fate", "The Grandest of Tales"]
};

export default info;

import { WYRMBLADE_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const WyrmbladeController = {
    id: WYRMBLADE_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        // if (operativesInKillTeam.length < 14) {
        //     passed = false;
        //     messages.push("Must include 10 operatives");
        // }
        let count = 0;

        operativesInKillTeam.forEach((operative) => {
            if (
                operative.unit === "Kelermorph" ||
                operative.unit === "Locus" ||
                operative.unit === "Sanctus Sniper" ||
                operative.unit === "Sanctus Talon"
            ) {
                count += 2;
            } else {
                count++;
            }
        });

        if (count !== 14) {
            passed = false;
            messages.push(
                "Must include 14 selections. KELERMORPH, LOCUS & SANCTUS operatives count as 2 selections."
            );
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Neophyte Leader";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Neophyte Leader");
        }

        // only 1 of these units
        [
            "Kelermorph",
            "Locus",
            "Neophyte Gunner equipped with: Gun Butt, Flamer",
            "Neophyte Gunner equipped with: Gun Butt, Grenade Launcher",
            "Neophyte Gunner equipped with: Gun Butt, Webber",
            "Neophyte Heavy Gunner equipped with: Gun Butt, Heavy Stubber",
            "Neophyte Heavy Gunner equipped with: Gun Butt, Mining Laser",
            "Neophyte Heavy Gunner equipped with: Gun Butt, Seismic Cannon",
            "Neophyte Icon Bearer",
            "Sanctus Sniper",
            "Sanctus Talon"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        // max 2 gunners
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit ===
                        "Neophyte Gunner equipped with: Gun Butt, Flamer" ||
                    operative.unit ===
                        "Neophyte Gunner equipped with: Gun Butt, Grenade Launcher" ||
                    operative.unit ===
                        "Neophyte Gunner equipped with: Gun Butt, Webber"
                );
            }).length > 2
        ) {
            passed = false;
            messages.push(`Maximum of 2 GUNNER operatives`);
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit ===
                        "Neophyte Heavy Gunner equipped with: Gun Butt, Heavy Stubber" ||
                    operative.unit ===
                        "Neophyte Heavy Gunner equipped with: Gun Butt, Mining Laser" ||
                    operative.unit ===
                        "Neophyte Heavy Gunner equipped with: Gun Butt, Seismic Cannon"
                );
            }).length > 2
        ) {
            passed = false;
            messages.push(`Maximum of 2 HEAVY GUNNER operatives`);
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Kelermorph" ||
                    operative.unit === "Locus" ||
                    operative.unit === "Sanctus Sniper" ||
                    operative.unit === "Sanctus Talon"
                );
            }).length > 2
        ) {
            passed = false;
            messages.push(`Maximum of 2 CULT AGENT operatives`);
        }

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default WyrmbladeController;

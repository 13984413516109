// names
// backgrounds etc...
import { TOMB_WORLD_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: TOMB_WORLD_ID,
    moniker: "Tomb World",
    restrictions: restrictions,
    equipment: [
        {
            name: "Devourer Nanoscarabs",
            cost: 3
        },
        {
            name: "Sempiternal Weave",
            cost: 3
        },
        {
            name: "Starfire Core",
            cost: 2
        },
        {
            name: "Tesla Weave",
            cost: 2
        },
        {
            name: "Mindshackle Scarabs",
            cost: 3
        },
        {
            name: "Hyperphase Blade",
            cost: 2
        }
    ]
};

export default info;

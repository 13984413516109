// names
// backgrounds etc...
import { BLOODED_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: BLOODED_ID,
    moniker: "The Blooded",
    restrictions: restrictions,
    battleHonours: [
        "Ardent on the Trigger",
        "Motivation for Violence",
        "Bitter Resolve",
        "Marauder",
        "Zealous",
        "Audacious"
    ],
    killTeamNames: [
        [
            "The Chosen",
            "The Carrion",
            "The Eight-blessed",
            "The Accursed",
            "The Dread",
            "The Tainted"
        ],
        ["Hounds", "Malevolents", "Jackals", "Serpents", "Slayers", "Daemons"]
    ],
    operativeNames: [
        [
            "Zerakor",
            "Verlan",
            "Garrik",
            "Shyara",
            "Elihm",
            "Korva",
            "Lothar",
            "Jonah",
            "Leanor",
            "Verika",
            "Urgan",
            "Tamren"
        ],
        [
            "Gorl",
            "Shadach",
            "Torg",
            "Aril",
            "the Flenser",
            "the Blessed",
            "the Beast",
            "Vrannad",
            "Hagyri",
            "the fiend",
            "Skull-taker",
            "the Betrayer"
        ]
    ],
    history: [
        "The Worm, Turned",
        "The Tyrant's Fist",
        "Born of Treachery",
        "Blades of the Serpent",
        "Dark Zealots",
        "Lost and Damned"
    ],
    baseOfOperations: [
        "Renegade Chimeras",
        "Valkyrie Squadron",
        "Despoiled Shrine",
        "Ruined Fortification",
        "Traitor Stronghold",
        "Subterranean Hideout"
    ],
    squadQuirk: [
        "Bloodthirsty",
        "Agents of Entropy",
        "Addiction to Excess",
        "Obsessive Scheming",
        "Scavengers",
        "Aspiring Champions"
    ],
    strategicAssets: ["Unholy Altar", "Defiled Trophies", "Malign Influence"],
    equipment: [
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Armour Plates",
            cost: 2
        },
        {
            name: "Chem-breather",
            cost: 1
        },
        {
            name: "Sinister Trophy",
            cost: 3
        },
        {
            name: "Incendiary Shells",
            cost: 2
        },
        {
            name: "Beast Pelt",
            cost: 1
        },
        {
            name: "Wicked Blades (bayonet and shield)",
            cost: 2
        },
        {
            name: "Wicked Blades",
            cost: 1
        }
    ],
    rareEquipment: [
        {
            name: "Alchemyk Bomb",
            cost: 3
        },
        {
            name: "Unholy Sigil",
            cost: 2
        },
        {
            name: "Dark Orator",
            cost: 2
        },
        {
            name: "Cursed Rounds",
            cost: 3
        },
        {
            name: "Diabolic Icon",
            cost: 3
        },
        {
            name: "Baleflame",
            cost: 3
        }
    ],
    specOps: ["Turncoats", "Draw the Gaze of the Gods"]
};

export default info;

import React, { useContext } from "react";
import StyledRosterAndKillTeam from "../styles/StyledRosterAndKillTeam";
import AppStateContext from "../context";
import StyledSelectInput from "../styles/StyledSelectInput";
import {
    getFactionData,
    getTotalEquipmentCost
} from "../controllers/DataController";
import Operative from "./Operative";
import FactionValidation from "./FactionValidation";

const PageKillTeam = (props) => {
    const { appState, dispatch } = useContext(AppStateContext);
    const list = appState.lists.byId[appState.activeList];
    const operatives = list.roster.allIds;
    const { killTeam, id } = getFactionData(list.faction);

    return (
        <StyledRosterAndKillTeam>
            <FactionValidation />

            <div className="equipment">
                <span>TOTAL EQUIPMENT COST:</span>
                <span className="equipment__cost">
                    {getTotalEquipmentCost(list.roster.byId, id)}
                    <span className="equipment__ep">EP</span>
                </span>
            </div>

            {killTeam.fireTeams ? (
                [...Array(killTeam.fireTeams)].map((val, index) => (
                    <div key={`fireteam-${index}`} className="fire-team">
                        <div className="select__fireteam-inner">
                            <select
                                className="select__fireteam"
                                value={list.fireTeams[index]}
                                onChange={(event) => {
                                    dispatch({
                                        type: "SET_FIRETEAM",
                                        payload: {
                                            index: index,
                                            team: event.target.value
                                        }
                                    });
                                }}
                            >
                                <option value="default" disabled>
                                    Select a Fire Team...
                                </option>
                                {killTeam.fireTeamTypes.map((team) => {
                                    return (
                                        <option
                                            key={team.name}
                                            value={team.name}
                                        >
                                            {team.name} Fire Team
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        {list.fireTeams[index] !== "default" && (
                            <>
                                <div className="operatives">
                                    {operatives.length > 0 &&
                                        operatives
                                            .filter((operativeId) => {
                                                return (
                                                    list.roster.byId[
                                                        operativeId
                                                    ].fireTeam === index
                                                );
                                            })
                                            .filter((operativeId) => {
                                                return list.roster.byId[
                                                    operativeId
                                                ].inKillTeam;
                                            })
                                            .map((operativeId) => (
                                                <Operative
                                                    key={operativeId}
                                                    id={operativeId}
                                                    fireTeam={index}
                                                />
                                            ))}
                                </div>
                                <div className="add-operative__wrap">
                                    <label>Add Operative to Fire Team:</label>
                                    <div className="select__operative-inner">
                                        <select
                                            className="select__operative"
                                            value="default"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "ADD_OPERATIVE",
                                                    payload: {
                                                        operativeName:
                                                            event.target.value,
                                                        factionId: id,
                                                        fireTeam: index,
                                                        inKillTeam: true
                                                    }
                                                });
                                            }}
                                        >
                                            <option value="default" disabled>
                                                Select an Operative...
                                            </option>
                                            {killTeam.fireTeamTypes
                                                .find((fireTeam) => {
                                                    return (
                                                        fireTeam.name ===
                                                        list.fireTeams[index]
                                                    );
                                                })
                                                .units.map((operative) => {
                                                    return (
                                                        <option
                                                            key={operative}
                                                            value={operative}
                                                        >
                                                            {operative}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))
            ) : (
                <>
                    <div className="operatives">
                        {operatives.length > 0 &&
                            operatives
                                .filter((operativeId) => {
                                    return list.roster.byId[operativeId]
                                        .inKillTeam;
                                })
                                .map((operativeId) => (
                                    <Operative
                                        key={operativeId}
                                        id={operativeId}
                                    />
                                ))}
                    </div>
                    <div className="add-operative__wrap">
                        <label>Add Operatives:</label>
                        <StyledSelectInput>
                            <div className="select-input__wrap">
                                <select
                                    value="default"
                                    onChange={(event) => {
                                        dispatch({
                                            type: "ADD_OPERATIVE",
                                            payload: {
                                                operativeName:
                                                    event.target.value,
                                                factionId: id,
                                                inKillTeam: true
                                            }
                                        });
                                    }}
                                >
                                    <option value="default" disabled>
                                        Select an Operative...
                                    </option>
                                    {killTeam.operatives.map((operative) => {
                                        return (
                                            <option
                                                key={operative.name}
                                                value={operative.name}
                                            >
                                                {operative.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </StyledSelectInput>
                    </div>
                </>
            )}
        </StyledRosterAndKillTeam>
    );
};

export default PageKillTeam;

import { NOVITIATE_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const NovitiateController = {
    id: NOVITIATE_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Novitiate Superior";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Novitiate Superior operative");
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Novitiate Purgatus";
            }).length > 2
        ) {
            passed = false;
            messages.push(
                "Cannot include more than 2 Novitiate Purgatus operatives"
            );
        }

        [
            "Novitiate Penitent",
            "Novitiate Pronatus",
            "Novitiate Exactor",
            "Novitiate Reliquarius",
            "Novitiate Hospitaller",
            "Novitiate Preceptor",
            "Novitiate Dialogus",
            "Novitiate Duellist",
            "Novitiate Condemnor"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit} operative`);
            }
        });

        //
        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default NovitiateController;

/*
"Novitiate Superior",
"Novitiate Militant",
"Novitiate Penitent",
"Novitiate Pronatus",
"Novitiate Exactor",
"Novitiate Reliquarius",
"Novitiate Hospitaller",
"Novitiate Preceptor",
"Novitiate Dialogus",
"Novitiate Duellist",
"Novitiate Condemnor",
"Novitiate Purgatus",
*/

// names
// backgrounds etc...
import { CRAFTWORLD_ID } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: CRAFTWORLD_ID,
    moniker: "Craftworld",
    restrictions: restrictions,
    equipment: [
        {
            name: "Weaponised Panoply",
            cost: 1
        },
        {
            name: "Avenger Shrine Banner",
            cost: 2
        },
        {
            name: "Ranger Scope",
            cost: 3
        },
        {
            name: "Pathfinder Cloak",
            cost: 2
        },
        {
            name: "Wraithbone Talisman",
            cost: 3
        },
        {
            name: "Celestial Shield",
            cost: 2
        },
        {
            name: "Plasma Grenade",
            cost: 2
        }
    ]
};

export default info;

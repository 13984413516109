import { ECCLESIARCHY_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const EcclesiarchyController = {
    id: ECCLESIARCHY_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Battle Sister Superior" ||
                    operative.unit === "Repentia Superior"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Must include only 1 Battle Sister Superior/Repentia Superior"
            );
        }

        // kill team contains 1 heavy gunner
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Battle Sister Heavy Gunner";
            }).length > 1
        ) {
            passed = false;
            messages.push(`Maximum of 1 Battle Sister Heavy Gunner`);
        }

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Battle Sister") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Battle Sister Fire Team must include 5 operatives"
                    );
                }

                // singles for kill team
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return "Battle Sister Gunner" === operative.unit;
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 1 Battle Sister Gunner in Battle Sister fire team`
                    );
                }
            }

            if (fireTeam === "Repentia") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Repentia Fire Team must include 5 operatives"
                    );
                }
            }

            if (fireTeam === "Arco-flagellant") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Arco-flagellant Fire Team must include 5 operatives"
                    );
                }
            }
        });

        // only 1 arco-flag team
        if (
            list.fireTeams.filter((team) => {
                return team === "Arco-flagellant";
            }).length > 1
        ) {
            passed = false;
            messages.push("Max 1 Arco-flagellant Fire Team");
        }

        // only heavy gunner if 2 sister teams
        if (
            list.fireTeams.filter((team) => {
                return team === "Battle Sister";
            }).length !== 2 &&
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Battle Sister Heavy Gunner";
            }).length >= 1
        ) {
            passed = false;
            messages.push(
                "Battle Sister Heavy Gunner requires 2 Battle Sister Fire Teams"
            );
        }

        //
        return {
            passed,
            messages
        };
    }
};

export default EcclesiarchyController;

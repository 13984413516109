// names
// backgrounds etc...
import { ECCLESIARCHY_ID } from "../../../constants/factions";
import restrictions from "./restrictions";
const info = {
    id: ECCLESIARCHY_ID,
    moniker: "Ecclesiarchy",
    restrictions: restrictions,
    equipment: [
        {
            name: "Rosarius",
            cost: 2
        },
        {
            name: "Phial of Restoration",
            cost: 2
        },
        {
            name: "Frag Grenade",
            cost: 2
        },
        {
            name: "Krak Grenade",
            cost: 3
        },
        {
            name: "Purity Seal",
            cost: 3
        },
        {
            name: "Scourging Barbs",
            cost: 2
        }
    ]
};

export default info;

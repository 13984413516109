import { LEGIONARY_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const VoidDancerController = {
    id: LEGIONARY_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains correct number of bros
        if (operativesInKillTeam.length < 9) {
            passed = false;
            messages.push("Must include 9 operatives");
        }

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return operative.unit === "Voidscarred Felarch";
            }).length !== 1
        ) {
            passed = false;
            messages.push("Must include 1 Voidscarred Felarch");
        }

        // only 1 of these units
        [
            "Voidscarred Gunner",
            "Voidscarred Heavy Gunner",
            "Voidscarred Starstorm Duellist",
            "Voidscarred Kurnite Hunter",
            "Voidscarred Shade Runner",
            "Voidscarred Kurnathi",
            "Voidscarred Fate Dealer",
            "Voidscarred Way Seeker",
            "Voidscarred Soul Weaver"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}.`);
            }
        });

        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    "Voidscarred Gunner" === operative.unit ||
                    "Voidscarred Heavy Gunner" === operative.unit
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                `Cannot include both a Voidscarred Gunner and Voidscarred Heavy Gunner.`
            );
        }

        return {
            passed,
            messages
        };
    },

    randomiseTeamName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    },

    randomiseOperativeName: (names) => {
        const first = names[0][Math.floor(Math.random() * names[0].length)];
        const second = names[1][Math.floor(Math.random() * names[1].length)];

        return `${first} ${second}`;
    }
};

export default VoidDancerController;

// names
// backgrounds etc...
import { INQUISITORIAL_AGENT } from "../../../constants/factions";
import restrictions from "./restrictions";

const info = {
    id: INQUISITORIAL_AGENT,
    moniker: "Inquisitoral Agent",
    restrictions: restrictions,
    battleHonours: [
        "Rugged Survivalist",
        "Stubborn",
        "Begrudging",
        "Inveterate Toughness",
        "Void-dredger",
        "Honoured"
    ],
    killTeamNames: [
        [
            "Scry-cell",
            "0049-X//",
            "Null Team",
            "Crimson Alphus",
            "Pho Ra",
            "8466 [MX]",
            "Execution",
            "Purgation",
            "Authoritas",
            "Scrutinex",
            "Inviolate",
            "The Blade of",
            "The Veiled",
            "Terminus",
            "Proscriptioners of",
            "Soul-sentries of"
        ],
        [
            "33-i",
            "Chora VII",
            "541936-484",
            "Veritas 97f",
            "Eta Vox",
            "Sig-3 Geo",
            "Assets BR-512",
            "Segregate",
            "Advocatum",
            "Instigators",
            "Revelators",
            "Ad Umbra",
            "Redemption",
            "Censure",
            "Suppression",
            "Reclamation",
            "Domination",
            "Sanction"
        ]
    ],
    operativeNames: [
        [
            "Oarba",
            "Lucius",
            "Janus",
            "Hermes",
            "Elsine",
            "Delphan",
            "Lorphreen",
            "Logan",
            "Mirella",
            "Josef",
            "Hestia",
            "Konstantin",
            "Ketz",
            "Skordan",
            "Korvanna",
            "Damien",
            "Skyll",
            "Promeus",
            "Severina",
            "Markus",
            "Moriana",
            "Orten",
            "Shen Vey",
            "Voraddin",
            "Sevora",
            "Methuselah",
            "Ulena",
            "Jorgo",
            "Mhoraeth",
            "Mechsimus",
            "Yvesta",
            "Cornelius",
            "Edda",
            "Barreth",
            "Katja",
            "Gwillan"
        ],
        [
            "Barbaretta",
            "de Wolfe",
            "",
            "Kommodus",
            "'the Devout'",
            "Threlk",
            "'the Unseen'",
            "Gath",
            "Agnazy",
            "Octavium",
            "Monska",
            "Devlan",
            "Pelt",
            "Cadavore",
            "of Cell 23b",
            "Gruss",
            "the Merc of Garrantos",
            "Jeddeck",
            "Grydd",
            "Malicant",
            "du Pre",
            "Nostrafex",
            "Savanum",
            "Stone",
            "Spinst",
            "Storm",
            "Dalstom",
            "Quovandius",
            "Kreel",
            "Khoriv",
            "Eskander",
            "Fank",
            "Lynden",
            "Skydekkerix",
            "Vespazha",
            "Oilrelius"
        ]
    ],
    history: [
        "Local Expertise",
        "Militant Precision",
        "Simmering Rivalry",
        "Psycho-indoctrination",
        "The Saved",
        "The Witnesses"
    ],
    baseOfOperations: [
        "Aquila Orbital Landers",
        "Inquisitorial Sub-citadel",
        "Psy-shrouded Frigate",
        "Amidst the Wretched",
        "Seized Command Post",
        "Ecclesiarchal Chapel"
    ],
    squadQuirk: [
        "Puritans",
        "The Unsleeping Eye",
        "Excruciators",
        "Radicals",
        "Ulterior Motives",
        "The Subtle Blade"
    ],
    strategicAssets: [
        "Supply Hold",
        "Enhanced Pan Spectral Scanner",
        "Excavation Machinery"
    ],
    equipment: [
        {
            name: "Gravitic Concussion Grenade",
            cost: 2
        },
        {
            name: "Plasma Knife",
            cost: 1
        },
        {
            name: "Climbing Equipment",
            cost: 1
        },
        {
            name: "Weavefield Crest",
            cost: 3
        },
        {
            name: "Excavation Tool",
            cost: 2
        }
    ],
    rareEquipment: [
        {
            name: "A Grudge Declared",
            cost: 3
        },
        {
            name: "Darkstar Weapon",
            cost: 2
        },
        {
            name: "Relic Plate",
            cost: 3
        },
        {
            name: "Right of Claim",
            cost: 3
        },
        {
            name: "Grav-lift Device",
            cost: 2
        },
        {
            name: "Ion Expediter",
            cost: 2
        }
    ],
    specOps: ["Recover Kin-tech", "Grudgeband"]
};

export default info;

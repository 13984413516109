import styled from "styled-components";
import theme from "./theme";

const StyledTooltip = styled.div`
    background: ${theme.colors.orange};
    position: relative;
    margin: 0.5em 0 1.5em 0;
    padding: 1.2em 1em;

    color: ${theme.colors.white};

    h1 {
        font-family: ${theme.fonts.header};
        text-transform: uppercase;
        font-size: 1.2em;
        // color: ${theme.colors.black};
    }

    span {
        font-size: 0.95em;
        display: block;
    }

    * + span {
        padding-top: 0.8em;
    }
`;

export default StyledTooltip;

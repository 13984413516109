import { HIVE_FLEET_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const HiveFleetController = {
    id: HIVE_FLEET_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Tyranid Warrior Leader" ||
                    operative.unit === "Genestealer Leader"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Must include only 1 Tyranid Warrior Leader/Genestealer Leader"
            );
        }

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Tyranid Warrior") {
                if (operativesInFireTeams[index].length !== 3) {
                    passed = false;
                    messages.push(
                        "Tyranid Warrior Fire Team must include 3 operatives"
                    );
                }

                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            "Tyranid Warrior Heavy Gunner" === operative.unit
                        );
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 1 Tyranid Warrior Heavy Gunner operative`
                    );
                }
            } else if (fireTeam === "Genestealer") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Genestealer Fire Team must include 5 operatives"
                    );
                }
            } else if (fireTeam === "Tyranid Swarm") {
                if (operativesInFireTeams[index].length !== 8) {
                    passed = false;
                    messages.push(
                        "Tyranid Swarm Fire Team must include 8 operatives"
                    );
                }
            }
        });

        if (
            list.fireTeams.filter((team) => {
                return team === "Tyranid Swarm";
            }).length > 1
        ) {
            passed = false;
            messages.push("Max 1 Tyranid Swarm Fire Team");
        }

        //
        return {
            passed,
            messages
        };
    }
};

export default HiveFleetController;

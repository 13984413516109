import { v4 as uuid } from "uuid";
import {
    getInitialState,
    setActivePage
} from "./controllers/UserDataController";
import { ROSTER_PAGE } from "./constants/pages";
import { getOperativeFactionData } from "./controllers/DataController";

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_INITIAL_STATE":
            const newState = getInitialState();

            return { ...state, ...newState };

        // page
        case "UPDATE_ACTIVE_PAGE":
            const newActivePage = setActivePage(action.payload);

            return {
                ...state,
                ...newActivePage
            };

        // LIST
        case "ADD_LIST":
            const { selectable, faction } = action.payload;

            // create new list and push it to state
            const newListId = uuid();

            let newListData = {
                version: 2,
                faction: null,
                selectableKeyword: null,
                roster: {
                    byId: {},
                    allIds: []
                },
                moniker: "",
                baseOfOperations: "",
                history: "",
                squadQuirk: "",
                strategicAssets: [],
                stash: [],
                specOpsLog: [],
                requisitionPoints: 0,
                assetCapacity: 0,
                notes: "",
                fireTeams: ["default", "default"]
            };
            newListData.faction = faction;
            newListData.selectableKeyword = selectable;

            return {
                ...state,
                fresh: false,
                activeList: newListId,
                activePage: ROSTER_PAGE,
                lists: {
                    byId: { ...state.lists.byId, [newListId]: newListData },
                    allIds: [...state.lists.allIds, newListId]
                }
            };

        case "REMOVE_LIST":
            return {
                ...state,
                activeList: null,
                lists: {
                    byId: Object.keys(state.lists.byId)
                        .filter((listId) => listId !== action.payload.listId)
                        .reduce((list, listId) => {
                            return {
                                ...list,
                                [listId]: state.lists.byId[listId]
                            };
                        }, {}),
                    allIds: state.lists.allIds.filter((listId) => {
                        return listId !== action.payload.listId;
                    })
                }
            };

        case "LOAD_LIST":
            return {
                ...state,
                activePage: ROSTER_PAGE,
                activeList: action.payload.listId
            };

        // FIRE TEAMS
        case "SET_FIRETEAM":
            const newFireTeamsArr =
                state.lists.byId[state.activeList].fireTeams;

            newFireTeamsArr[action.payload.index] = action.payload.team;

            return {
                ...state,
                lists: {
                    ...state.lists,
                    byId: {
                        ...state.lists.byId,
                        [state.activeList]: {
                            ...state.lists.byId[state.activeList],
                            fireTeams: newFireTeamsArr
                        }
                    }
                }
            };

        // ROSTER
        case "ADD_OPERATIVE":
            let newOperative = {};
            const newOperativeId = uuid();

            const operativeFactionData = getOperativeFactionData(
                action.payload.factionId,
                action.payload.operativeName
            );

            const weaponArr = operativeFactionData.weapons.map((option) => {
                return option[0];
            });

            newOperative = {
                moniker: "",
                equipment: [],
                battleHonours: [],
                battleScars: [],
                specialism: "default",
                restedTally: 0,
                experience: 0,
                showSpecOps: false,
                inKillTeam: false,
                fireTeam: false,
                notes: "",

                unit: action.payload.operativeName,
                faction: action.payload.factionId,
                weapons: weaponArr
            };

            if (action.payload.inKillTeam) {
                newOperative.inKillTeam = true;
            }

            if (action.payload.fireTeam >= 0) {
                newOperative.fireTeam = action.payload.fireTeam;
            }

            return {
                ...state,
                lists: {
                    ...state.lists,
                    byId: {
                        ...state.lists.byId,
                        [state.activeList]: {
                            ...state.lists.byId[state.activeList],
                            roster: {
                                byId: {
                                    ...state.lists.byId[state.activeList].roster
                                        .byId,
                                    [newOperativeId]: newOperative
                                },
                                allIds: [
                                    ...state.lists.byId[state.activeList].roster
                                        .allIds,
                                    newOperativeId
                                ]
                            }
                        }
                    }
                }
            };

        case "UPDATE_OPERATIVE":
            return {
                ...state,
                lists: {
                    ...state.lists,
                    byId: {
                        ...state.lists.byId,
                        [state.activeList]: {
                            ...state.lists.byId[state.activeList],
                            roster: {
                                byId: {
                                    ...state.lists.byId[state.activeList].roster
                                        .byId,
                                    [action.payload.operativeId]:
                                        action.payload.operative
                                },
                                allIds: [
                                    ...state.lists.byId[state.activeList].roster
                                        .allIds
                                ]
                            }
                        }
                    }
                }
            };

        case "REMOVE_OPERATIVE":
            return {
                ...state,
                lists: {
                    ...state.lists,
                    byId: {
                        ...state.lists.byId,
                        [state.activeList]: {
                            ...state.lists.byId[state.activeList],
                            roster: {
                                byId: Object.keys(
                                    state.lists.byId[state.activeList].roster
                                        .byId
                                )
                                    .filter(
                                        (operativeId) =>
                                            operativeId !== action.payload.id
                                    )
                                    .reduce((list, operativeId) => {
                                        return {
                                            ...list,
                                            [operativeId]:
                                                state.lists.byId[
                                                    state.activeList
                                                ].roster.byId[operativeId]
                                        };
                                    }, {}),
                                allIds: state.lists.byId[
                                    state.activeList
                                ].roster.allIds.filter(
                                    (operativeId) =>
                                        operativeId !== action.payload.id
                                )
                            }
                        }
                    }
                }
            };

        // KILL TEAM
        case "TOGGLE_SPEC_OPS_EQUIPMENT_DISPLAY":
            return {
                ...state
            };

        // SPEC OPS
        case "UPDATE_SPEC_OPS":
            return {
                ...state,
                lists: {
                    ...state.lists,
                    byId: {
                        ...state.lists.byId,
                        [state.activeList]: {
                            ...state.lists.byId[state.activeList],
                            [action.payload.key]: action.payload.value
                        }
                    }
                }
            };

        // err
        default:
            throw new Error("Unexpected action!");
    }
};

export default reducer;

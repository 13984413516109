import { TRAITOR_SPACE_MARINE_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const TraitorSpaceMarineController = {
    id: TRAITOR_SPACE_MARINE_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Chaos Space Marine Aspiring Champion" ||
                    operative.unit === "Chaos Cultist Champion"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Must include only 1 Chaos Space Marine Aspiring Champion/Chaos Cultist Champion"
            );
        }

        // singles for kill team
        [
            "Chaos Space Marine Gunner",
            "Chaos Space Marine Heavy Gunner",
            "Chaos Space Marine Icon Bearer"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit}`);
            }
        });

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Chaos Space Marine") {
                if (operativesInFireTeams[index].length !== 3) {
                    passed = false;
                    messages.push(
                        "Chaos Space Marine Fire Team must include 3 operatives"
                    );
                }

                // only 1 type of gunner
                if (
                    operativesInKillTeam.filter((operative) => {
                        return operative.unit === "Chaos Space Marine Gunner";
                    }).length >= 1 &&
                    operativesInKillTeam.filter((operative) => {
                        return (
                            operative.unit === "Chaos Space Marine Heavy Gunner"
                        );
                    }).length >= 1
                ) {
                    passed = false;
                    messages.push(
                        `Chaos Space Marine Fire Team cannot include a Chaos Space Marine Gunner and a Chaos Space Marine Heavy Gunner`
                    );
                }
            }

            //
            if (fireTeam === "Chaos Cultist") {
                if (operativesInFireTeams[index].length !== 8) {
                    passed = false;
                    messages.push(
                        "Chaos Cultist Fire Team must include 8 operatives"
                    );
                }

                // singles for kill team
                if (
                    operativesInKillTeam.filter((operative) => {
                        return "Chaos Cultist Gunner" === operative.unit;
                    }).length > 2
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 2 Chaos Cultist Gunner operatives`
                    );
                }
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default TraitorSpaceMarineController;

import { BROOD_COVEN_ID } from "../../../constants/factions";
import { getObjectValues } from "../../../controllers/HelpersController";

const BroodCovenController = {
    id: BROOD_COVEN_ID,

    validator: (list) => {
        const operatives = getObjectValues(list.roster.byId);
        const operativesInKillTeam = operatives.filter((operative) => {
            return operative.inKillTeam;
        });

        let passed = true;
        let messages = [];

        // contains 1 and only 1 leader
        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Neophyte Hybrid Leader" ||
                    operative.unit === "Acolyte Hybrid Leader" ||
                    operative.unit === "Hybrid Metamorph Leader"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push(
                "Must include only 1 Neophyte Hybrid Leader/Acolyte Hybrid Leader/Hybrid Metamorph Leader"
            );
        }

        if (
            operativesInKillTeam.filter((operative) => {
                return (
                    operative.unit === "Neophyte Hybrid Icon Bearer" ||
                    operative.unit === "Acolyte Hybrid Icon Bearer" ||
                    operative.unit === "Hybrid Metamorph Icon Bearer"
                );
            }).length > 1
        ) {
            passed = false;
            messages.push("Can only include 1 Icon Bearer");
        }

        [
            "Neophyte Hybrid Gunner equipped with: Gun butt, Flamer",
            "Neophyte Hybrid Gunner equipped with: Gun butt, Grenade launcher",
            "Neophyte Hybrid Gunner equipped with: Gun butt, Webber",
            "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Heavy stubber",
            "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Mining laser",
            "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Seismic cannon",
            "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock cutter",
            "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock drill",
            "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock saw"
        ].forEach((unit) => {
            if (
                operativesInKillTeam.filter((operative) => {
                    return unit === operative.unit;
                }).length > 1
            ) {
                passed = false;
                messages.push(`Maximum of 1 ${unit} operative`);
            }
        });

        // Fire team specific
        const operativesInFireTeams = [[], []];
        operativesInKillTeam.forEach((operative) => {
            operativesInFireTeams[operative.fireTeam].push(operative);
        });

        // get fireteam names from list
        list.fireTeams.forEach((fireTeam, index) => {
            if (fireTeam === "Neophyte Hybrid") {
                if (operativesInFireTeams[index].length !== 7) {
                    passed = false;
                    messages.push(
                        "Neophyte Hybrid Fire Team must include 7 operatives"
                    );
                }
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit ===
                                "Neophyte Hybrid Gunner equipped with: Gun butt, Flamer" ||
                            operative.unit ===
                                "Neophyte Hybrid Gunner equipped with: Gun butt, Grenade launcher" ||
                            operative.unit ===
                                "Neophyte Hybrid Gunner equipped with: Gun butt, Webber"
                        );
                    }).length > 2
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 2 Neophyte Hybrid Gunner operatives`
                    );
                }
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit ===
                                "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Heavy stubber" ||
                            operative.unit ===
                                "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Mining laser" ||
                            operative.unit ===
                                "Neophyte Hybrid Heavy Gunner equipped with: Gun butt, Seismic cannon"
                        );
                    }).length > 1
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 1 Neophyte Hybrid Gunner operatives`
                    );
                }
            } else if (fireTeam === "Acolyte Hybrid") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Acolyte Hybrid Fire Team must include 5 operatives"
                    );
                }

                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit ===
                                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock cutter" ||
                            operative.unit ===
                                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock drill" ||
                            operative.unit ===
                                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock saw"
                        );
                    }).length > 2
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 2 Acolyte Hybrid Fighter operatives`
                    );
                }

                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return operative.unit === "Acolyte Hybrid gunner";
                    }).length > 2
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 2 Acolyte Hybrid Gunner operatives`
                    );
                }

                if (
                    (operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit ===
                                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock cutter" ||
                            operative.unit ===
                                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock drill" ||
                            operative.unit ===
                                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock saw"
                        );
                    }).length > 1 &&
                        operativesInFireTeams[index].filter((operative) => {
                            return operative.unit === "Acolyte Hybrid Gunner";
                        }).length > 0) ||
                    (operativesInFireTeams[index].filter((operative) => {
                        return (
                            operative.unit ===
                                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock cutter" ||
                            operative.unit ===
                                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock drill" ||
                            operative.unit ===
                                "Acolyte Hybrid Fighter equipped with: Autopistol, Heavy rock saw"
                        );
                    }).length > 0 &&
                        operativesInFireTeams[index].filter((operative) => {
                            return operative.unit === "Acolyte Hybrid Gunner";
                        }).length > 1)
                ) {
                    passed = false;
                    messages.push(
                        `Cannot have more than 1 of each Acolyte Hybrid Fighter and Acolyte Hybrid Gunner operatives when in the same fire team`
                    );
                }
            } else if (fireTeam === "Hybrid Metamorph") {
                if (operativesInFireTeams[index].length !== 5) {
                    passed = false;
                    messages.push(
                        "Hybrid Metamorph Fire Team must include 5 operatives"
                    );
                }
                if (
                    operativesInFireTeams[index].filter((operative) => {
                        return operative.unit === "Hybrid Metamorph Gunner";
                    }).length > 2
                ) {
                    passed = false;
                    messages.push(
                        `Maximum of 2 Hybrid Metamorph Gunner operatives`
                    );
                }
            }
        });

        //
        return {
            passed,
            messages
        };
    }
};

export default BroodCovenController;
